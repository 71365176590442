.zpf-smart-assist {
  display: block;

  [smart-assist-container] {
    overflow: hidden;
  }

  [smart-assist-main-section] {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
    position: relative;

    [smart-assist-chat-section] {
      overflow: hidden;
    }

    [smart-assist-loading-window] {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: @zs-smart-assist-loading-window-bg-color;
      z-index: 99;
    }
  }

  [smart-assist-window] {
    overflow-x: hidden;
    overflow-y: auto;
    scroll-behavior: smooth;
    order: 2;
  }

  [smart-assist-header] {
    background-image: url(../assets/smartAssistBanner.svg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 45%;

    &~[smart-assist-main-section],
    ~[smart-assist-error-window] {
      height: 55%;
    }

    .padding(3, 3, 1, 3);

    [smart-assist-title-container] {
      display: none;
    }

    [smart-assist-banner] {
      text-align: center;
      flex-grow: 1;
      align-content: end;
    }

    &:not([smart-assist-history-header]) [smart-assist-back-link-container] {
      display: none;
    }

    [smart-assist-action-header] {
      flex-direction: row-reverse;
    }

    &[chat-started],
    &[smart-assist-history-header] {
      height: auto;

      &~[smart-assist-main-section],
      &~[smart-assist-error-window] {
        height: 100%;
      }

      background: @zs-surface-color-2;
      .padding(3, 3, 3, 3);

      [smart-assist-title-container] {
        color: @zs-text-color;
        display: flex;
        align-items: center;
        flex-grow: 1;
        justify-content: center;
      }

      [smart-assist-banner] {
        display: none;
      }

      [smart-assist-action-header] {
        flex-direction: row;
      }
    }

    &[smart-assist-history-header] {
      [smart-assist-title-container] {
        overflow: hidden;
        [smart-assist-title] {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          .padding(0, 2, 0, 2);
        }
      }
    }

    [smart-assist-more-menu] {
      width: 12.25em;
      top: 2em;
    }
  }

  [smart-assist-footer] {
    order: 3;
    .padding((1/2), 3, (1/2), 2.5);

    .zs-field[smart-assist-input] {
      .margin(0, 0, 0, 0);

      [label] {
        padding: 0;
      }

      label {
        display: block;
      }

      [input][type="text"],
      [input][type="text"]:hover,
      [input][type="text"]:focus,
      [input][type="text"]:active {
        border: 0;
        background-color: transparent;
        resize: none;
        width: 100%;
      }
    }

    [stop-button]>.zpf-stop-button-icon {
      position: absolute;
      height: @zs-small;
      width: @zs-small;
      transform: translate(-50%, -50%);
    }
  }

  [smart-assist-input] {
    .margin(0, 0, 0, 0);

    [input],
    [input]:hover,
    [input]:focus,
    [input]:active {
      border: 0;
      background-color: transparent;
    }
  }

  [smart-assist-error-window] {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  [smart-assist-notification] {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  .zpf-overflowY-auto {
    overflow-y: auto;
  }

  .zpf-overflowX-auto {
    overflow-x: auto;
  }

  .zpf-chat-response-section {
    overflow: auto;

    &:not(:first-child)&:not(:last-child) {
      .margin(2, 0, 2, 0);
    }

    &:empty {
      .margin(0, 0, 0, 0);
    }

    >p {
      .margin(0, 0, 0, 0);
    }
  }

  .zpf-response-action-section {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    &[chart-actions] {
      .padding(0, 2, 0, 2);
    }

    &[table-actions] {
      .padding(2, 0, 2, 0);
    }
  }

  .zpf-chat-response {
    word-wrap: break-word;
    max-width: 80%;
    overflow-y: auto;
    min-height: 100%;
  }

  .zpf-chat-bubble {
    border-radius: 0.35em;

    &[smart-assist-request-bubble] {
      word-wrap: break-word;
      max-width: 80%;
      overflow-y: auto;
      min-height: 100%;
      border-bottom-right-radius: 0;
      background: @zs-smart-assist-request-bg-color;
      border: 0.5px solid @zs-smart-assist-request-border-color;
    }

    &[smart-assist-response-bubble] {
      border-top-left-radius: 0;
    }

    &[name="dots"] {
      width: 100%;
      border: 0;
    }
  }

  .zpf-header-logo-container {
    height: @zs-normal * 5;
    width: @zs-normal * 5;
  }

  .zpf-chat-response-badge {
    position: relative;
    border-radius: 50%;
    .padding(2.5, 2.5, 2.5, 2.5);

    .zpf-chat-response-badge-image {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      &.zpf-chat-logo {
        height: @zs-normal * 2;
        width: @zs-normal * 2;
      }
    }

  }

  .zpf-smart-assist-position-left {
    position: absolute;
    right: 0;
  }

  .zpf-smart-assist-logo {
    background: url(../assets/smartAssistLogo.svg);
    background-size: cover;
  }

  ul,
  ol {
    .margin(0, 0, 0, (4/@zs-normal));

    >li {
      .margin(0, 0, (0.5/@zs-normal), 0);
      .padding(0, 0, 0, (1/@zs-normal));
      line-height: 1.44;
      letter-spacing: -.013em;

      &:first-child {
        .margin((1/@zs-normal), 0, (0.5/@zs-normal), 0);
      }
    }

    ul,
    ol {
      .margin(0, 0, 0, (5/@zs-normal));
    }
  }

  .zpf-smart-assist-history-link {
    display: block;

    [smart-assist-history-name] {
      display: flex;
      overflow: hidden;
      align-items: center;

      [history-link] {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}

.zpf-dot-loader {
  display: flex;

  >[dot] {
    //in px since this height/width should not change as per parent's font size
    width: 8px;
    height: 8px;
    .margin(0, 0.5, 0, 0.5);
    background-color: @zs-dot-loader-static-bg-color;
    border-radius: 50%;
    animation: dotloaderanimation 1s infinite;
  }

  >[dot]:nth-child(1) {
    animation-delay: 0s;
  }

  >[dot]:nth-child(2) {
    animation-delay: 0.3s;
  }

  >[dot]:nth-child(3) {
    animation-delay: 0.7s;
  }

  @keyframes dotloaderanimation {

    0%,
    33%,
    100% {
      background-color: @zs-dot-loader-static-bg-color;
    }

    50% {
      background-color: @zs-dot-loader-moving-bg-color;
    }
  }
}

//list buttons will be changed once its UX will be there...

.option-button {
  list-style: none;
  background: #e1f9f8;
  color: #27a6a4;
  border: 0;
  border-radius: 20px;
  height: auto;
  line-height: 1;
}

.option-button:hover,
.option-button.active,
.option-button[active] {
  background-color: #27a6a4;
  cursor: pointer;
  color: white;
  border: #27a6a4;
}

.button-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &:empty {
    display: none;
  }
}

@import '../config/smartAssistConfig.less';