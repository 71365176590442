@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@font-face {
  font-family: 'Open Sans';
  src: url('../node_modules/zsui/src/core/OpenSans-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('../node_modules/zsui/src/core/OpenSans-Light-Italic.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Open Sans';
  src: url('../node_modules/zsui/src/core/OpenSans-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('../node_modules/zsui/src/core/OpenSans-Italic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Open Sans';
  src: url('../node_modules/zsui/src/core/OpenSans-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('../node_modules/zsui/src/core/OpenSans-SemiBold-Italic.woff2') format('woff2');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'Open Sans';
  src: url('../node_modules/zsui/src/core/OpenSans-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('../node_modules/zsui/src/core/OpenSans-Bold-Italic.woff2') format('woff2');
  font-weight: 700;
  font-style: italic;
}
.zs-master-style .zs-danger-text {
  color: var(--zs-danger-color, #B21111);
}
.zs-master-style .zs-danger-text:visited {
  color: var(--zs-danger-color, #B21111);
}
.zs-master-style .zs-danger-text:focus {
  color: var(--zs-danger-focus-color, #892208);
}
.zs-master-style .zs-danger-text:hover {
  color: var(--zs-danger-hover-color, #F27755);
}
.zs-master-style .zs-danger-text:active {
  color: var(--zs-danger-active-color, #5C1A0B);
}
.zs-master-style .zs-danger-border {
  border: 1px solid var(--zs-danger-color, #B21111);
}
.zs-master-style .zs-danger-border:focus {
  border: 1px solid var(--zs-danger-focus-color, #892208);
}
.zs-master-style .zs-danger-border:hover {
  border: 1px solid var(--zs-danger-hover-color, #F27755);
}
.zs-master-style .zs-danger-border:active {
  border: 1px solid var(--zs-danger-active-color, #5C1A0B);
}
.zs-master-style .zs-danger-background {
  background: var(--zs-danger-color, #B21111);
}
.zs-master-style .zs-danger-background:focus {
  background: var(--zs-danger-focus-color, #892208);
}
.zs-master-style .zs-danger-background:hover {
  background: var(--zs-danger-hover-color, #F27755);
}
.zs-master-style .zs-danger-background:active {
  background: var(--zs-danger-active-color, #5C1A0B);
}
.zs-master-style .zs-success-text {
  color: var(--zs-success-color, #00AA67);
}
.zs-master-style .zs-success-text:visited {
  color: var(--zs-success-color, #00AA67);
}
.zs-master-style .zs-success-text:focus {
  color: var(--zs-success-focus-color, #0A6E5E);
}
.zs-master-style .zs-success-text:hover {
  color: var(--zs-success-hover-color, #54D7AF);
}
.zs-master-style .zs-success-text:active {
  color: var(--zs-success-active-color, #0C4846);
}
.zs-master-style .zs-success-border {
  border: 1px solid var(--zs-success-color, #00AA67);
}
.zs-master-style .zs-success-border:focus {
  border: 1px solid var(--zs-success-focus-color, #0A6E5E);
}
.zs-master-style .zs-success-border:hover {
  border: 1px solid var(--zs-success-hover-color, #54D7AF);
}
.zs-master-style .zs-success-border:active {
  border: 1px solid var(--zs-success-active-color, #0C4846);
}
.zs-master-style .zs-success-background {
  background: var(--zs-success-color, #00AA67);
}
.zs-master-style .zs-success-background:focus {
  background: var(--zs-success-focus-color, #0A6E5E);
}
.zs-master-style .zs-success-background:hover {
  background: var(--zs-success-hover-color, #54D7AF);
}
.zs-master-style .zs-success-background:active {
  background: var(--zs-success-active-color, #0C4846);
}
.zs-master-style .zs-warning-text {
  color: var(--zs-warning-color, #E3A900);
}
.zs-master-style .zs-warning-text:visited {
  color: var(--zs-warning-color, #E3A900);
}
.zs-master-style .zs-warning-text:focus {
  color: var(--zs-warning-focus-color, #8A640C);
}
.zs-master-style .zs-warning-text:hover {
  color: var(--zs-warning-hover-color, #FDCA63);
}
.zs-master-style .zs-warning-text:active {
  color: var(--zs-warning-active-color, #563F0E);
}
.zs-master-style .zs-warning-border {
  border: 1px solid var(--zs-warning-color, #E3A900);
}
.zs-master-style .zs-warning-border:focus {
  border: 1px solid var(--zs-warning-focus-color, #8A640C);
}
.zs-master-style .zs-warning-border:hover {
  border: 1px solid var(--zs-warning-hover-color, #FDCA63);
}
.zs-master-style .zs-warning-border:active {
  border: 1px solid var(--zs-warning-active-color, #563F0E);
}
.zs-master-style .zs-warning-background {
  background: var(--zs-warning-color, #E3A900);
}
.zs-master-style .zs-warning-background:focus {
  background: var(--zs-warning-focus-color, #8A640C);
}
.zs-master-style .zs-warning-background:hover {
  background: var(--zs-warning-hover-color, #FDCA63);
}
.zs-master-style .zs-warning-background:active {
  background: var(--zs-warning-active-color, #563F0E);
}
.zs-master-style .zs-info-text {
  color: var(--zs-info-color, #2B44C7);
}
.zs-master-style .zs-info-text:visited {
  color: var(--zs-info-color, #2B44C7);
}
.zs-master-style .zs-info-text:focus {
  color: var(--zs-info-focus-color, #141187);
}
.zs-master-style .zs-info-text:hover {
  color: var(--zs-info-hover-color, #7E92E9);
}
.zs-master-style .zs-info-text:active {
  color: var(--zs-info-active-color, #160C60);
}
.zs-master-style .zs-info-border {
  border: 1px solid var(--zs-info-color, #2B44C7);
}
.zs-master-style .zs-info-border:focus {
  border: 1px solid var(--zs-info-focus-color, #141187);
}
.zs-master-style .zs-info-border:hover {
  border: 1px solid var(--zs-info-hover-color, #7E92E9);
}
.zs-master-style .zs-info-border:active {
  border: 1px solid var(--zs-info-active-color, #160C60);
}
.zs-master-style .zs-info-background {
  background: var(--zs-info-color, #2B44C7);
}
.zs-master-style .zs-info-background:focus {
  background: var(--zs-info-focus-color, #141187);
}
.zs-master-style .zs-info-background:hover {
  background: var(--zs-info-hover-color, #7E92E9);
}
.zs-master-style .zs-info-background:active {
  background: var(--zs-info-active-color, #160C60);
}
.zs-icon + .zs-icon {
  margin: 0em 0em 0em 0.125em;
}
@font-face {
  font-family: 'zsIcons';
  src: url('../node_modules/zsui/src/core/zsIcons.woff2?v6.6.0') format('woff2');
  font-weight: normal;
  font-style: normal;
}
.zs-master-style .zs-icon {
  font-family: 'zsIcons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Enable Ligatures ================ */
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga";
  font-feature-settings: "liga";
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.zs-master-style .zs-interactive-primary,
.zs-master-style .zs-interactive-secondary {
  display: inline-block;
}
.zs-master-style a:hover > .zs-icon::before,
.zs-master-style a:hover > .zs-icon::after {
  color: inherit;
}
.zs-master-style a:hover > .zs-icon:before,
.zs-master-style a > .zs-icon:before {
  margin-right: 0;
}
.zs-master-style a.zs-icon:before {
  cursor: pointer;
}
.zs-master-style a.zs-icon,
.zs-master-style a.zs-icon:hover {
  cursor: pointer;
}
.zs-master-style .zs-icon-add::before {
  content: "\ea01";
}
.zs-master-style .zs-icon-add-circle::before {
  content: "\ea02";
}
.zs-master-style .zs-icon-add-circle-fill::before {
  content: "\ea03";
}
.zs-master-style .zs-icon-admin-log::before {
  content: "\ea04";
}
.zs-master-style .zs-icon-admin-log-fill::before {
  content: "\ea05";
}
.zs-master-style .zs-icon-ampersand::before {
  content: "\ea06";
}
.zs-master-style .zs-icon-app-nav::before {
  content: "\ea07";
}
.zs-master-style .zs-icon-arrow-down::before {
  content: "\ea08";
}
.zs-master-style .zs-icon-arrow-down-circle::before {
  content: "\ea09";
}
.zs-master-style .zs-icon-arrow-down-circle-fill::before {
  content: "\ea0a";
}
.zs-master-style .zs-icon-arrow-first::before {
  content: "\ea0b";
}
.zs-master-style .zs-icon-arrow-first-fill::before {
  content: "\ea0c";
}
.zs-master-style .zs-icon-arrow-last::before {
  content: "\ea0d";
}
.zs-master-style .zs-icon-arrow-last-fill::before {
  content: "\ea0e";
}
.zs-master-style .zs-icon-arrow-left::before {
  content: "\ea0f";
}
.zs-master-style .zs-icon-arrow-next-circle::before {
  content: "\ea10";
}
.zs-master-style .zs-icon-arrow-next-circle-fill::before {
  content: "\ea11";
}
.zs-master-style .zs-icon-arrow-prev-circle::before {
  content: "\ea12";
}
.zs-master-style .zs-icon-arrow-prev-circle-fill::before {
  content: "\ea13";
}
.zs-master-style .zs-icon-arrow-redo::before {
  content: "\ea14";
}
.zs-master-style .zs-icon-arrow-reply::before {
  content: "\ea15";
}
.zs-master-style .zs-icon-arrow-reply-fill::before {
  content: "\ea16";
}
.zs-master-style .zs-icon-arrow-return::before {
  content: "\ea17";
}
.zs-master-style .zs-icon-arrow-revise::before {
  content: "\ea18";
}
.zs-master-style .zs-icon-arrow-right::before {
  content: "\ea19";
}
.zs-master-style .zs-icon-arrow-send::before {
  content: "\ea1a";
}
.zs-master-style .zs-icon-arrow-up::before {
  content: "\ea1b";
}
.zs-master-style .zs-icon-arrow-up-circle::before {
  content: "\ea1c";
}
.zs-master-style .zs-icon-arrow-up-circle-fill::before {
  content: "\ea1d";
}
.zs-master-style .zs-icon-arrow-up-down::before {
  content: "\ea1e";
}
.zs-master-style .zs-icon-bookmark::before {
  content: "\ea1f";
}
.zs-master-style .zs-icon-bookmark-fill::before {
  content: "\ea20";
}
.zs-master-style .zs-icon-brackets::before {
  content: "\ea21";
}
.zs-master-style .zs-icon-bullet::before {
  content: "\ea22";
}
.zs-master-style .zs-icon-bullet-left::before {
  content: "\ea23";
}
.zs-master-style .zs-icon-business-rule::before {
  content: "\ea24";
}
.zs-master-style .zs-icon-calendar::before {
  content: "\ea25";
}
.zs-master-style .zs-icon-carat-down::before {
  content: "\ea26";
}
.zs-master-style .zs-icon-carat-left::before {
  content: "\ea27";
}
.zs-master-style .zs-icon-carat-right::before {
  content: "\ea28";
}
.zs-master-style .zs-icon-carat-up::before {
  content: "\ea29";
}
.zs-master-style .zs-icon-card::before {
  content: "\ea2a";
}
.zs-master-style .zs-icon-card-flip::before {
  content: "\ea2b";
}
.zs-master-style .zs-icon-card-view::before {
  content: "\ea2c";
}
.zs-master-style .zs-icon-card-view-fill::before {
  content: "\ea2d";
}
.zs-master-style .zs-icon-check::before {
  content: "\ea2e";
}
.zs-master-style .zs-icon-check-circle::before {
  content: "\ea2f";
}
.zs-master-style .zs-icon-check-circle-fill::before {
  content: "\ea30";
}
.zs-master-style .zs-icon-circle::before {
  content: "\ea31";
}
.zs-master-style .zs-icon-circle-fill::before {
  content: "\ea32";
}
.zs-master-style .zs-icon-circle-outline::before {
  content: "\ea33";
}
.zs-master-style .zs-icon-clock-pending::before {
  content: "\ea34";
}
.zs-master-style .zs-icon-clock-pending-fill::before {
  content: "\ea35";
}
.zs-master-style .zs-icon-close::before {
  content: "\ea36";
}
.zs-master-style .zs-icon-close-circle::before {
  content: "\ea37";
}
.zs-master-style .zs-icon-close-circle-fill::before {
  content: "\ea38";
}
.zs-master-style .zs-icon-config-ab::before {
  content: "\ea39";
}
.zs-master-style .zs-icon-config-summary::before {
  content: "\ea3a";
}
.zs-master-style .zs-icon-config-summary-fill::before {
  content: "\ea3b";
}
.zs-master-style .zs-icon-connect::before {
  content: "\ea3c";
}
.zs-master-style .zs-icon-content-guide::before {
  content: "\ea3d";
}
.zs-master-style .zs-icon-content-guide-fill::before {
  content: "\ea3e";
}
.zs-master-style .zs-icon-delete::before {
  content: "\ea3f";
}
.zs-master-style .zs-icon-delete-fill::before {
  content: "\ea40";
}
.zs-master-style .zs-icon-dislike::before {
  content: "\ea41";
}
.zs-master-style .zs-icon-dislike-fill::before {
  content: "\ea42";
}
.zs-master-style .zs-icon-divide::before {
  content: "\ea43";
}
.zs-master-style .zs-icon-download::before {
  content: "\ea44";
}
.zs-master-style .zs-icon-edit::before {
  content: "\ea45";
}
.zs-master-style .zs-icon-edit-fill::before {
  content: "\ea46";
}
.zs-master-style .zs-icon-event::before {
  content: "\ea47";
}
.zs-master-style .zs-icon-event-fill::before {
  content: "\ea48";
}
.zs-master-style .zs-icon-eye-close::before {
  content: "\ea49";
}
.zs-master-style .zs-icon-eye-open::before {
  content: "\ea4a";
}
.zs-master-style .zs-icon-filter::before {
  content: "\ea4b";
}
.zs-master-style .zs-icon-flag::before {
  content: "\ea4c";
}
.zs-master-style .zs-icon-flag-fill::before {
  content: "\ea4d";
}
.zs-master-style .zs-icon-font-size::before {
  content: "\ea4e";
}
.zs-master-style .zs-icon-item-combination::before {
  content: "\ea4f";
}
.zs-master-style .zs-icon-item-drag::before {
  content: "\ea50";
}
.zs-master-style .zs-icon-item-group::before {
  content: "\ea51";
}
.zs-master-style .zs-icon-item-ungroup::before {
  content: "\ea52";
}
.zs-master-style .zs-icon-labels::before {
  content: "\ea53";
}
.zs-master-style .zs-icon-labels-rotate::before {
  content: "\ea54";
}
.zs-master-style .zs-icon-layers::before {
  content: "\ea55";
}
.zs-master-style .zs-icon-layers-fill::before {
  content: "\ea56";
}
.zs-master-style .zs-icon-like::before {
  content: "\ea57";
}
.zs-master-style .zs-icon-like-fill::before {
  content: "\ea58";
}
.zs-master-style .zs-icon-link::before {
  content: "\ea59";
}
.zs-master-style .zs-icon-link-input::before {
  content: "\ea5a";
}
.zs-master-style .zs-icon-list-view::before {
  content: "\ea5b";
}
.zs-master-style .zs-icon-list-view-fill::before {
  content: "\ea5c";
}
.zs-master-style .zs-icon-list-view-generous::before {
  content: "\ea5d";
}
.zs-master-style .zs-icon-loader::before {
  content: "\ea5e";
}
.zs-master-style .zs-icon-lock::before {
  content: "\ea5f";
}
.zs-master-style .zs-icon-lock-fill::before {
  content: "\ea60";
}
.zs-master-style .zs-icon-lock-unlock::before {
  content: "\ea61";
}
.zs-master-style .zs-icon-lock-unlock-fill::before {
  content: "\ea62";
}
.zs-master-style .zs-icon-login::before {
  content: "\ea63";
}
.zs-master-style .zs-icon-logout::before {
  content: "\ea64";
}
.zs-master-style .zs-icon-machine-learn::before {
  content: "\ea65";
}
.zs-master-style .zs-icon-mail::before {
  content: "\ea66";
}
.zs-master-style .zs-icon-mail-fill::before {
  content: "\ea67";
}
.zs-master-style .zs-icon-mail-settings::before {
  content: "\ea68";
}
.zs-master-style .zs-icon-mail-settings-fill::before {
  content: "\ea69";
}
.zs-master-style .zs-icon-mic::before {
  content: "\ea6a";
}
.zs-master-style .zs-icon-mic-fill::before {
  content: "\ea6b";
}
.zs-master-style .zs-icon-mobile-nav::before {
  content: "\ea6c";
}
.zs-master-style .zs-icon-more::before {
  content: "\ea6d";
}
.zs-master-style .zs-icon-multiply::before {
  content: "\ea6e";
}
.zs-master-style .zs-icon-object-group::before {
  content: "\ea6f";
}
.zs-master-style .zs-icon-palette::before {
  content: "\ea70";
}
.zs-master-style .zs-icon-palette-fill::before {
  content: "\ea71";
}
.zs-master-style .zs-icon-parens-left::before {
  content: "\ea72";
}
.zs-master-style .zs-icon-parens-right::before {
  content: "\ea73";
}
.zs-master-style .zs-icon-pause::before {
  content: "\ea74";
}
.zs-master-style .zs-icon-pause-fill::before {
  content: "\ea75";
}
.zs-master-style .zs-icon-pin::before {
  content: "\ea76";
}
.zs-master-style .zs-icon-pin-fill::before {
  content: "\ea77";
}
.zs-master-style .zs-icon-pin-undo::before {
  content: "\ea78";
}
.zs-master-style .zs-icon-pin-undo-fill::before {
  content: "\ea79";
}
.zs-master-style .zs-icon-plane-send::before {
  content: "\ea7a";
}
.zs-master-style .zs-icon-plane-send-fill::before {
  content: "\ea7b";
}
.zs-master-style .zs-icon-play-circle::before {
  content: "\ea7c";
}
.zs-master-style .zs-icon-play-circle-fill::before {
  content: "\ea7d";
}
.zs-master-style .zs-icon-print::before {
  content: "\ea7e";
}
.zs-master-style .zs-icon-priority::before {
  content: "\ea7f";
}
.zs-master-style .zs-icon-product-weight::before {
  content: "\ea80";
}
.zs-master-style .zs-icon-product-weight-fill::before {
  content: "\ea81";
}
.zs-master-style .zs-icon-prohibit::before {
  content: "\ea82";
}
.zs-master-style .zs-icon-refresh::before {
  content: "\ea83";
}
.zs-master-style .zs-icon-reminder::before {
  content: "\ea84";
}
.zs-master-style .zs-icon-save::before {
  content: "\ea85";
}
.zs-master-style .zs-icon-save-fill::before {
  content: "\ea86";
}
.zs-master-style .zs-icon-scissors::before {
  content: "\ea87";
}
.zs-master-style .zs-icon-search::before {
  content: "\ea88";
}
.zs-master-style .zs-icon-sequence::before {
  content: "\ea89";
}
.zs-master-style .zs-icon-sequence-fill::before {
  content: "\ea8a";
}
.zs-master-style .zs-icon-settings::before {
  content: "\ea8b";
}
.zs-master-style .zs-icon-settings-fill::before {
  content: "\ea8c";
}
.zs-master-style .zs-icon-share::before {
  content: "\ea8d";
}
.zs-master-style .zs-icon-share-fill::before {
  content: "\ea8e";
}
.zs-master-style .zs-icon-sort::before {
  content: "\ea8f";
}
.zs-master-style .zs-icon-sort-ascend::before {
  content: "\ea90";
}
.zs-master-style .zs-icon-sort-descend::before {
  content: "\ea91";
}
.zs-master-style .zs-icon-star::before {
  content: "\ea92";
}
.zs-master-style .zs-icon-star-fill::before {
  content: "\ea93";
}
.zs-master-style .zs-icon-stop-circle::before {
  content: "\ea94";
}
.zs-master-style .zs-icon-stop-circle-fill::before {
  content: "\ea95";
}
.zs-master-style .zs-icon-subtract::before {
  content: "\ea96";
}
.zs-master-style .zs-icon-subtract-circle::before {
  content: "\ea97";
}
.zs-master-style .zs-icon-subtract-circle-fill::before {
  content: "\ea98";
}
.zs-master-style .zs-icon-tag::before {
  content: "\ea99";
}
.zs-master-style .zs-icon-tag-fill::before {
  content: "\ea9a";
}
.zs-master-style .zs-icon-timer::before {
  content: "\ea9b";
}
.zs-master-style .zs-icon-timer-fill::before {
  content: "\ea9c";
}
.zs-master-style .zs-icon-tools::before {
  content: "\ea9d";
}
.zs-master-style .zs-icon-tools-fill::before {
  content: "\ea9e";
}
.zs-master-style .zs-icon-touchpoint-min::before {
  content: "\ea9f";
}
.zs-master-style .zs-icon-touchpoint-min-gap::before {
  content: "\eaa0";
}
.zs-master-style .zs-icon-touchpoint-qty::before {
  content: "\eaa1";
}
.zs-master-style .zs-icon-trigger-live::before {
  content: "\eaa2";
}
.zs-master-style .zs-icon-trigger-simulate::before {
  content: "\eaa3";
}
.zs-master-style .zs-icon-upload::before {
  content: "\eaa4";
}
.zs-master-style .zs-icon-upload-cloud::before {
  content: "\eaa5";
}
.zs-master-style .zs-icon-upload-cloud-fill::before {
  content: "\eaa6";
}
.zs-master-style .zs-icon-video::before {
  content: "\eaa7";
}
.zs-master-style .zs-icon-video-fill::before {
  content: "\eaa8";
}
.zs-master-style .zs-icon-visualization::before {
  content: "\eaa9";
}
.zs-master-style .zs-icon-alarm::before {
  content: "\eaaa";
}
.zs-master-style .zs-icon-alarm-fill::before {
  content: "\eaab";
}
.zs-master-style .zs-icon-announce::before {
  content: "\eaac";
}
.zs-master-style .zs-icon-announce-fill::before {
  content: "\eaad";
}
.zs-master-style .zs-icon-error-circle::before {
  content: "\eaae";
}
.zs-master-style .zs-icon-error-circle-fill::before {
  content: "\eaaf";
}
.zs-master-style .zs-icon-error-diamond::before {
  content: "\eab0";
}
.zs-master-style .zs-icon-error-diamond-fill::before {
  content: "\eab1";
}
.zs-master-style .zs-icon-error-square::before {
  content: "\eab2";
}
.zs-master-style .zs-icon-error-square-fill::before {
  content: "\eab3";
}
.zs-master-style .zs-icon-error-triangle::before {
  content: "\eab4";
}
.zs-master-style .zs-icon-error-triangle-fill::before {
  content: "\eab5";
}
.zs-master-style .zs-icon-notification::before {
  content: "\eab6";
}
.zs-master-style .zs-icon-notification-fill::before {
  content: "\eab7";
}
.zs-master-style .zs-icon-at-line::before {
  content: "\eab8";
}
.zs-master-style .zs-icon-briefcase::before {
  content: "\eab9";
}
.zs-master-style .zs-icon-briefcase-fill::before {
  content: "\eaba";
}
.zs-master-style .zs-icon-chat::before {
  content: "\eabb";
}
.zs-master-style .zs-icon-chat-fill::before {
  content: "\eabc";
}
.zs-master-style .zs-icon-face-happy::before {
  content: "\eabd";
}
.zs-master-style .zs-icon-face-happy-fill::before {
  content: "\eabe";
}
.zs-master-style .zs-icon-face-neutral::before {
  content: "\eabf";
}
.zs-master-style .zs-icon-face-neutral-fill::before {
  content: "\eac0";
}
.zs-master-style .zs-icon-face-sad::before {
  content: "\eac1";
}
.zs-master-style .zs-icon-face-sad-fill::before {
  content: "\eac2";
}
.zs-master-style .zs-icon-field-unit::before {
  content: "\eac3";
}
.zs-master-style .zs-icon-field-unit-fill::before {
  content: "\eac4";
}
.zs-master-style .zs-icon-globe::before {
  content: "\eac5";
}
.zs-master-style .zs-icon-hcp::before {
  content: "\eac6";
}
.zs-master-style .zs-icon-help::before {
  content: "\eac7";
}
.zs-master-style .zs-icon-help-fill::before {
  content: "\eac8";
}
.zs-master-style .zs-icon-home-office::before {
  content: "\eac9";
}
.zs-master-style .zs-icon-home-office-fill::before {
  content: "\eaca";
}
.zs-master-style .zs-icon-hospital::before {
  content: "\eacb";
}
.zs-master-style .zs-icon-hospital-fill::before {
  content: "\eacc";
}
.zs-master-style .zs-icon-info::before {
  content: "\eacd";
}
.zs-master-style .zs-icon-info-fill::before {
  content: "\eace";
}
.zs-master-style .zs-icon-local-team::before {
  content: "\eacf";
}
.zs-master-style .zs-icon-local-team-fill::before {
  content: "\ead0";
}
.zs-master-style .zs-icon-phone::before {
  content: "\ead1";
}
.zs-master-style .zs-icon-phone-fill::before {
  content: "\ead2";
}
.zs-master-style .zs-icon-product::before {
  content: "\ead3";
}
.zs-master-style .zs-icon-product-fill::before {
  content: "\ead4";
}
.zs-master-style .zs-icon-rep-call::before {
  content: "\ead5";
}
.zs-master-style .zs-icon-rep-call-fill::before {
  content: "\ead6";
}
.zs-master-style .zs-icon-team::before {
  content: "\ead7";
}
.zs-master-style .zs-icon-team-fill::before {
  content: "\ead8";
}
.zs-master-style .zs-icon-team-settings::before {
  content: "\ead9";
}
.zs-master-style .zs-icon-team-settings-fill::before {
  content: "\eada";
}
.zs-master-style .zs-icon-user::before {
  content: "\eadb";
}
.zs-master-style .zs-icon-user-access::before {
  content: "\eadc";
}
.zs-master-style .zs-icon-user-access-fill::before {
  content: "\eadd";
}
.zs-master-style .zs-icon-user-approval::before {
  content: "\eade";
}
.zs-master-style .zs-icon-user-approval-fill::before {
  content: "\eadf";
}
.zs-master-style .zs-icon-user-fill::before {
  content: "\eae0";
}
.zs-master-style .zs-icon-user-leader::before {
  content: "\eae1";
}
.zs-master-style .zs-icon-user-leader-fill::before {
  content: "\eae2";
}
.zs-master-style .zs-icon-user-permissions::before {
  content: "\eae3";
}
.zs-master-style .zs-icon-user-permissions-fill::before {
  content: "\eae4";
}
.zs-master-style .zs-icon-user-profile::before {
  content: "\eae5";
}
.zs-master-style .zs-icon-user-rep::before {
  content: "\eae6";
}
.zs-master-style .zs-icon-user-rep-fill::before {
  content: "\eae7";
}
.zs-master-style .zs-icon-user-rep-more::before {
  content: "\eae8";
}
.zs-master-style .zs-icon-user-role::before {
  content: "\eae9";
}
.zs-master-style .zs-icon-user-role-fill::before {
  content: "\eaea";
}
.zs-master-style .zs-icon-user-selected::before {
  content: "\eaeb";
}
.zs-master-style .zs-icon-user-selected-fill::before {
  content: "\eaec";
}
.zs-master-style .zs-icon-user-settings::before {
  content: "\eaed";
}
.zs-master-style .zs-icon-user-settings-fill::before {
  content: "\eaee";
}
.zs-master-style .zs-icon-frame-expand::before {
  content: "\eaef";
}
.zs-master-style .zs-icon-frame-resize::before {
  content: "\eaf0";
}
.zs-master-style .zs-icon-frame-restore::before {
  content: "\eaf1";
}
.zs-master-style .zs-icon-mirror::before {
  content: "\eaf2";
}
.zs-master-style .zs-icon-mirror-fill::before {
  content: "\eaf3";
}
.zs-master-style .zs-icon-resize::before {
  content: "\eaf4";
}
.zs-master-style .zs-icon-resize-corner::before {
  content: "\eaf5";
}
.zs-master-style .zs-icon-site::before {
  content: "\eaf6";
}
.zs-master-style .zs-icon-site-fill::before {
  content: "\eaf7";
}
.zs-master-style .zs-icon-window-new::before {
  content: "\eaf8";
}
.zs-master-style .zs-icon-doc::before {
  content: "\eaf9";
}
.zs-master-style .zs-icon-doc-fill::before {
  content: "\eafa";
}
.zs-master-style .zs-icon-doc-generic::before {
  content: "\eafb";
}
.zs-master-style .zs-icon-file-attach::before {
  content: "\eafc";
}
.zs-master-style .zs-icon-file-copy::before {
  content: "\eafd";
}
.zs-master-style .zs-icon-file-doc::before {
  content: "\eafe";
}
.zs-master-style .zs-icon-file-docx::before {
  content: "\eaff";
}
.zs-master-style .zs-icon-file-edit::before {
  content: "\eb00";
}
.zs-master-style .zs-icon-file-jpeg::before {
  content: "\eb01";
}
.zs-master-style .zs-icon-file-pdf::before {
  content: "\eb02";
}
.zs-master-style .zs-icon-file-png::before {
  content: "\eb03";
}
.zs-master-style .zs-icon-file-ppsx::before {
  content: "\eb04";
}
.zs-master-style .zs-icon-file-ppt::before {
  content: "\eb05";
}
.zs-master-style .zs-icon-file-pptm::before {
  content: "\eb06";
}
.zs-master-style .zs-icon-file-pptx::before {
  content: "\eb07";
}
.zs-master-style .zs-icon-file-sql::before {
  content: "\eb08";
}
.zs-master-style .zs-icon-file-txt::before {
  content: "\eb09";
}
.zs-master-style .zs-icon-file-xls::before {
  content: "\eb0a";
}
.zs-master-style .zs-icon-file-xlsm::before {
  content: "\eb0b";
}
.zs-master-style .zs-icon-file-xlsx::before {
  content: "\eb0c";
}
.zs-master-style .zs-icon-file-xml::before {
  content: "\eb0d";
}
.zs-master-style .zs-icon-file-zip::before {
  content: "\eb0e";
}
.zs-master-style .zs-icon-folder-closed::before {
  content: "\eb0f";
}
.zs-master-style .zs-icon-folder-closed-fill::before {
  content: "\eb10";
}
.zs-master-style .zs-icon-folder-open::before {
  content: "\eb11";
}
.zs-master-style .zs-icon-folder-open-fill::before {
  content: "\eb12";
}
.zs-master-style .zs-icon-folder-shortcut::before {
  content: "\eb13";
}
.zs-master-style .zs-icon-folder-shortcut-fill::before {
  content: "\eb14";
}
.zs-master-style .zs-icon-glossary::before {
  content: "\eb15";
}
.zs-master-style .zs-icon-glossary-fill::before {
  content: "\eb16";
}
.zs-master-style .zs-icon-img::before {
  content: "\eb17";
}
.zs-master-style .zs-icon-note::before {
  content: "\eb18";
}
.zs-master-style .zs-icon-preview::before {
  content: "\eb19";
}
.zs-master-style .zs-icon-style-guide::before {
  content: "\eb1a";
}
.zs-master-style .zs-icon-style-guide-fill::before {
  content: "\eb1b";
}
.zs-master-style .zs-icon-template::before {
  content: "\eb1c";
}
.zs-master-style .zs-icon-template-fill::before {
  content: "\eb1d";
}
.zs-master-style .zs-icon-upload-file::before {
  content: "\eb1e";
}
.zs-master-style .zs-icon-upload-file-fill::before {
  content: "\eb1f";
}
.zs-master-style .zs-icon-upload-folder::before {
  content: "\eb20";
}
.zs-master-style .zs-icon-upload-folder-fill::before {
  content: "\eb21";
}
.zs-master-style .zs-icon-brush::before {
  content: "\eb22";
}
.zs-master-style .zs-icon-brush-fill::before {
  content: "\eb23";
}
.zs-master-style .zs-icon-diagram-view::before {
  content: "\eb24";
}
.zs-master-style .zs-icon-geo-detail::before {
  content: "\eb25";
}
.zs-master-style .zs-icon-geo-detail-fill::before {
  content: "\eb26";
}
.zs-master-style .zs-icon-geo-level::before {
  content: "\eb27";
}
.zs-master-style .zs-icon-legend-diamond::before {
  content: "\eb28";
}
.zs-master-style .zs-icon-legend-ellipse::before {
  content: "\eb29";
}
.zs-master-style .zs-icon-legend-hexagon::before {
  content: "\eb2a";
}
.zs-master-style .zs-icon-legend-pentagon::before {
  content: "\eb2b";
}
.zs-master-style .zs-icon-legend-square::before {
  content: "\eb2c";
}
.zs-master-style .zs-icon-legend-star::before {
  content: "\eb2d";
}
.zs-master-style .zs-icon-legend-triangle::before {
  content: "\eb2e";
}
.zs-master-style .zs-icon-locate::before {
  content: "\eb2f";
}
.zs-master-style .zs-icon-locate-fill::before {
  content: "\eb30";
}
.zs-master-style .zs-icon-map-align::before {
  content: "\eb31";
}
.zs-master-style .zs-icon-map-border-dashed::before {
  content: "\eb32";
}
.zs-master-style .zs-icon-map-border-dotted::before {
  content: "\eb33";
}
.zs-master-style .zs-icon-map-border-solid::before {
  content: "\eb34";
}
.zs-master-style .zs-icon-map-color::before {
  content: "\eb35";
}
.zs-master-style .zs-icon-map-color-fill::before {
  content: "\eb36";
}
.zs-master-style .zs-icon-map-expand::before {
  content: "\eb37";
}
.zs-master-style .zs-icon-map-focus::before {
  content: "\eb38";
}
.zs-master-style .zs-icon-map-graph::before {
  content: "\eb39";
}
.zs-master-style .zs-icon-map-pin::before {
  content: "\eb3a";
}
.zs-master-style .zs-icon-map-pin-fill::before {
  content: "\eb3b";
}
.zs-master-style .zs-icon-map-route::before {
  content: "\eb3c";
}
.zs-master-style .zs-icon-map-select::before {
  content: "\eb3d";
}
.zs-master-style .zs-icon-map-select-lasso::before {
  content: "\eb3e";
}
.zs-master-style .zs-icon-map-select-rectangle::before {
  content: "\eb3f";
}
.zs-master-style .zs-icon-map-settings::before {
  content: "\eb40";
}
.zs-master-style .zs-icon-map-theme::before {
  content: "\eb41";
}
.zs-master-style .zs-icon-map-theme-fill::before {
  content: "\eb42";
}
.zs-master-style .zs-icon-map-view::before {
  content: "\eb43";
}
.zs-master-style .zs-icon-map-view-affiliations::before {
  content: "\eb44";
}
.zs-master-style .zs-icon-map-view-alignments::before {
  content: "\eb45";
}
.zs-master-style .zs-icon-map-view-fill::before {
  content: "\eb46";
}
.zs-master-style .zs-icon-map-view-split::before {
  content: "\eb47";
}
.zs-master-style .zs-icon-map-view-split-fill::before {
  content: "\eb48";
}
.zs-master-style .zs-icon-map-zoom-adjust::before {
  content: "\eb49";
}
.zs-master-style .zs-icon-map-zoom-rectangle::before {
  content: "\eb4a";
}
.zs-master-style .zs-icon-mapsettings::before {
  content: "\eb4b";
}
.zs-master-style .zs-icon-measure::before {
  content: "\eb4c";
}
.zs-master-style .zs-icon-navigation::before {
  content: "\eb4d";
}
.zs-master-style .zs-icon-navigation-fill::before {
  content: "\eb4e";
}
.zs-master-style .zs-icon-route::before {
  content: "\eb4f";
}
.zs-master-style .zs-icon-territory::before {
  content: "\eb50";
}
.zs-master-style .zs-icon-zoom-to-actual::before {
  content: "\eb51";
}
.zs-master-style .zs-icon-zoom-to-fit::before {
  content: "\eb52";
}
.zs-master-style .zs-icon-data::before {
  content: "\eb53";
}
.zs-master-style .zs-icon-field-insights::before {
  content: "\eb54";
}
.zs-master-style .zs-icon-field-insights-fill::before {
  content: "\eb55";
}
.zs-master-style .zs-icon-home::before {
  content: "\eb56";
}
.zs-master-style .zs-icon-home-fill::before {
  content: "\eb57";
}
.zs-master-style .zs-icon-incentives::before {
  content: "\eb58";
}
.zs-master-style .zs-icon-incentives-fill::before {
  content: "\eb59";
}
.zs-master-style .zs-icon-planning::before {
  content: "\eb5a";
}
.zs-master-style .zs-icon-planning-fill::before {
  content: "\eb5b";
}
.zs-master-style .zs-icon-reports::before {
  content: "\eb5c";
}
.zs-master-style .zs-icon-reports-fill::before {
  content: "\eb5d";
}
.zs-master-style .zs-icon-roster::before {
  content: "\eb5e";
}
.zs-master-style .zs-icon-roster-fill::before {
  content: "\eb5f";
}
.zs-master-style .zs-icon-award-trip::before {
  content: "\eb60";
}
.zs-master-style .zs-icon-chart-pie::before {
  content: "\eb61";
}
.zs-master-style .zs-icon-chart-pie-fill::before {
  content: "\eb62";
}
.zs-master-style .zs-icon-contest::before {
  content: "\eb63";
}
.zs-master-style .zs-icon-contest-fill::before {
  content: "\eb64";
}
.zs-master-style .zs-icon-equals::before {
  content: "\eb65";
}
.zs-master-style .zs-icon-forecast::before {
  content: "\eb66";
}
.zs-master-style .zs-icon-goal::before {
  content: "\eb67";
}
.zs-master-style .zs-icon-goal-fill::before {
  content: "\eb68";
}
.zs-master-style .zs-icon-incentive-dollar::before {
  content: "\eb69";
}
.zs-master-style .zs-icon-incentive-euro::before {
  content: "\eb6a";
}
.zs-master-style .zs-icon-incentive-pound::before {
  content: "\eb6b";
}
.zs-master-style .zs-icon-incentive-yen::before {
  content: "\eb6c";
}
.zs-master-style .zs-icon-product-metric::before {
  content: "\eb6d";
}
.zs-master-style .zs-icon-report-calc::before {
  content: "\eb6e";
}
.zs-master-style .zs-icon-report-dashboard::before {
  content: "\eb6f";
}
.zs-master-style .zs-icon-report-derived::before {
  content: "\eb70";
}
.zs-master-style .zs-icon-report-derived-fill::before {
  content: "\eb71";
}
.zs-master-style .zs-icon-report-generated::before {
  content: "\eb72";
}
.zs-master-style .zs-icon-report-goal::before {
  content: "\eb73";
}
.zs-master-style .zs-icon-report-preview::before {
  content: "\eb74";
}
.zs-master-style .zs-icon-report-publish::before {
  content: "\eb75";
}
.zs-master-style .zs-icon-report-task::before {
  content: "\eb76";
}
.zs-master-style .zs-icon-report-template::before {
  content: "\eb77";
}
.zs-master-style .zs-icon-report-trend::before {
  content: "\eb78";
}
.zs-master-style .zs-icon-report-unpublish::before {
  content: "\eb79";
}
.zs-master-style .zs-icon-report-valid::before {
  content: "\eb7a";
}
.zs-master-style .zs-icon-revenue::before {
  content: "\eb7b";
}
.zs-master-style .zs-icon-spend::before {
  content: "\eb7c";
}
.zs-master-style .zs-icon-columns::before {
  content: "\eb7d";
}
.zs-master-style .zs-icon-columns-fill::before {
  content: "\eb7e";
}
.zs-master-style .zs-icon-data-arrow-down::before {
  content: "\eb7f";
}
.zs-master-style .zs-icon-data-arrow-up::before {
  content: "\eb80";
}
.zs-master-style .zs-icon-data-flow::before {
  content: "\eb81";
}
.zs-master-style .zs-icon-data-flow-fill::before {
  content: "\eb82";
}
.zs-master-style .zs-icon-data-list::before {
  content: "\eb83";
}
.zs-master-style .zs-icon-data-publish::before {
  content: "\eb84";
}
.zs-master-style .zs-icon-data-publish-done::before {
  content: "\eb85";
}
.zs-master-style .zs-icon-data-set::before {
  content: "\eb86";
}
.zs-master-style .zs-icon-data-set-fill::before {
  content: "\eb87";
}
.zs-master-style .zs-icon-data-zero-negative::before {
  content: "\eb88";
}
.zs-master-style .zs-icon-data-zero-positive::before {
  content: "\eb89";
}
.zs-master-style .zs-icon-organize::before {
  content: "\eb8a";
}
.zs-master-style .zs-icon-pattern::before {
  content: "\eb8b";
}
.zs-master-style .zs-icon-pattern-fill::before {
  content: "\eb8c";
}
.zs-master-style .zs-icon-table::before {
  content: "\eb8d";
}
.zs-master-style .zs-icon-table-id::before {
  content: "\eb8e";
}
.zs-master-style .zs-icon-table-id-fill::before {
  content: "\eb8f";
}
.zs-master-style .zs-icon-table-key::before {
  content: "\eb90";
}
.zs-master-style .zs-icon-table-preview::before {
  content: "\eb91";
}
.zs-master-style .zs-icon-logo-apache-spark::before {
  content: "\eb92";
}
.zs-master-style .zs-icon-logo-codepen::before {
  content: "\eb93";
}
.zs-master-style .zs-icon-control::before {
  content: "\eb94";
}
.zs-master-style .zs-icon-legacy::before {
  content: "\eb95";
}
/* 
** Modern Typography styles
*/
.zs-master-style {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: var(--zs-text-color, #2F2C3C);
}
.zs-master-style .zs-h1,
.zs-master-style h1 {
  font-size: 2em;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: -0.046em;
  margin: 1em 0em 1em 0em;
}
.zs-master-style .zs-h2,
.zs-master-style h2 {
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1.33;
  letter-spacing: -0.021em;
  margin: 1.33333333em 0em 1.33333333em 0em;
}
.zs-master-style .zs-h3,
.zs-master-style h3 {
  font-size: 1.25em;
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: -0.008em;
  margin: 1.2em 0em 1.2em 0em;
}
.zs-master-style .zs-h4,
.zs-master-style h4 {
  font-size: 1em;
  font-weight: 700;
  font-style: normal;
  opacity: 1;
  line-height: 1.5;
  letter-spacing: -0.009em;
  margin: 1em 0em 1em 0em;
}
.zs-master-style .zs-h5,
.zs-master-style h5 {
  font-size: 0.875em;
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: 0.011em;
  margin: 0.57142857em 0em 0.57142857em 0em;
}
.zs-master-style .zs-h6,
.zs-master-style h6 {
  font-size: 0.75em;
  line-height: 1.4;
  font-weight: 700;
  letter-spacing: -0.013em;
  margin: 0.33333333em 0em 0.33333333em 0em;
}
.zs-master-style .zs-super-hero {
  font-size: 4em;
  line-height: 1.5;
  letter-spacing: -0.047em;
  font-weight: 400;
  margin: 0.75em 0em 0.5em 0em;
}
.zs-master-style .zs-hero {
  font-size: 3em;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.003em;
  margin: 0em 0em 1em 0em;
}
.zs-master-style .zs-subtitle-1 {
  font-size: 1em;
  line-height: 1.5;
  letter-spacing: 0.013em;
  font-weight: 400;
  margin: 0.75em 0em 0.5em 0em;
}
.zs-master-style .zs-subtitle-2 {
  font-size: 0.875em;
  line-height: 1.44;
  letter-spacing: 0.014em;
  font-weight: 400;
  margin: 0.75em 0em 0.5em 0em;
}
.zs-master-style .zs-body {
  font-size: 1em;
  line-height: 1.5;
  letter-spacing: -0.009em;
  margin: 1em 0em 1em 0em;
  font-weight: 400;
}
.zs-master-style .zs-body-small {
  font-size: 0.875em;
  line-height: 1.4;
  letter-spacing: -0.011em;
  margin: 1.14285714em 0em 1.14285714em 0em;
  font-weight: 400;
}
.zs-master-style .zs-body-extra-small {
  font-size: 0.75em;
  line-height: 1.63;
  letter-spacing: -0.03em;
}
.zs-master-style .zs-caption-1 {
  font-size: 0.75em;
  line-height: 1.5;
  letter-spacing: -0.013em;
  margin: -1em 0em 0.66666667em 0em;
}
.zs-master-style .zs-caption-2 {
  font-size: 0.625em;
  line-height: 1.5;
  letter-spacing: -0.015em;
  margin: -0.8em 0em 0.8em 0em;
}
.zs-master-style .zs-caption-2 + .zs-caption-2 {
  margin: 0.8em 0em 0.8em 0em;
}
.zs-master-style .zs-overline {
  font-size: 0.625em;
  font-weight: 600;
  line-height: 1.5;
  text-transform: uppercase;
  letter-spacing: 0.4em;
}
.zs-master-style .zs-label-1 {
  font-size: 0.875em;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: -0.011em;
}
.zs-master-style .zs-label-2 {
  font-size: 0.625em;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: -0.013em;
}
.zs-master-style .zs-label {
  font-size: 1em;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 2.5px;
}
.zs-master-style .zs-input-label {
  font-size: 1em;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.15px;
}
.zs-master-style .zs-header-block {
  margin: 2em 0em 2em 0em;
}
.zs-master-style .zs-header-block .zs-overline {
  margin: 0em 0em 0.4em 0em;
}
.zs-master-style .zs-header-block .zs-h1,
.zs-master-style .zs-header-block h1 {
  margin: 0em 0em 0.125em 0em;
}
.zs-master-style .zs-header-block .zs-h2,
.zs-master-style .zs-header-block h2 {
  margin: 0em 0em 0.16666667em 0em;
}
.zs-master-style .zs-header-block .zs-subtitle-1 {
  margin: 0em 0em 0.25em 0em;
}
.zs-master-style a {
  color: var(--zs-link-text-color, #2D8B93);
  text-decoration: none;
  letter-spacing: -0.013em;
  transition: color 0.1s ease-out;
}
@media (prefers-reduced-motion) {
  .zs-master-style a {
    transition-duration: 0s;
  }
}
.zs-master-style a:visited {
  color: var(--zs-link-visited-color, #2D535F);
}
.zs-master-style a:focus {
  color: var(--zs-link-focus-color, #27A6A4);
  outline-offset: -2px;
  outline: 2px solid;
  outline-color: var(--zs-link-focus-color, #27A6A4);
}
.zs-master-style a:hover {
  color: var(--zs-link-hover-color, #27A6A4);
  text-decoration: none;
}
.zs-master-style a:active {
  color: var(--zs-link-active-color, #2F6F7B);
}
.zs-master-style a.zs-disabled,
.zs-master-style a[disabled] {
  cursor: default;
  pointer-events: none;
  opacity: 1;
  color: var(--zs-disabled-text-color, #B2B0B6);
}
.zs-master-style .zs-link-action {
  color: var(--zs-link-action-color, #2F2C3C);
  background: var(--zs-link-action-background, transparent);
}
.zs-master-style .zs-link-action:visited {
  color: var(--zs-link-action-color, #2F2C3C);
  background: inherit;
}
.zs-master-style .zs-link-action:focus {
  color: var(--zs-link-action-focus-color, #27A6A4);
  background: var(--zs-link-action-focus-background, rgba(39, 166, 164, 0.1));
  outline: 2px solid var(--zs-link-action-focus-outline-color, #27A6A4);
  outline-offset: -2px;
}
.zs-master-style .zs-link-action:hover,
.zs-master-style .zs-link-action:hover:visited {
  color: var(--zs-link-action-hover-color, #27A6A4);
  background: var(--zs-link-action-hover-background, rgba(39, 166, 164, 0.1));
}
.zs-master-style .zs-link-action:active,
.zs-master-style .zs-link-action:active:visited {
  color: var(--zs-link-action-active-color, #ffffff);
  background: var(--zs-link-action-active-background, #2D8B93);
}
.zs-master-style .zs-link-action.zs-active,
.zs-master-style .zs-link-action.zs-active:visited {
  color: var(--zs-link-action-selected-color, #ffffff);
  background: var(--zs-link-action-selected-background, #27A6A4);
}
.zs-master-style .zs-link-action.zs-disabled,
.zs-master-style .zs-link-action[disabled] {
  color: var(--zs-disabled-text-color, #B2B0B6);
}
.zs-master-style ul.zs-list,
.zs-master-style ol.zs-list {
  margin: 0em 0em 0em 2em;
}
.zs-master-style ul.zs-list > li,
.zs-master-style ol.zs-list > li {
  margin: 0em 0em 0.25em 0em;
  padding: 0em 0em 0em 0.5em;
  line-height: 1.44;
  letter-spacing: -0.013em;
}
.zs-master-style ul.zs-list > li:first-child,
.zs-master-style ol.zs-list > li:first-child {
  margin: 0.5em 0em 0.25em 0em;
}
.zs-master-style ul.zs-list h4,
.zs-master-style ol.zs-list h4 {
  margin: 1.5em 0em 0em 0.5em;
}
.zs-master-style ul.zs-list ul,
.zs-master-style ol.zs-list ul,
.zs-master-style ul.zs-list ol,
.zs-master-style ol.zs-list ol {
  margin: 0em 0em 0em 2.5em;
}
.zs-master-style ul.zs-list-style-type-none,
.zs-master-style ol.zs-list-style-type-none,
.zs-master-style ul .zs-list-style-type-none,
.zs-master-style ol .zs-list-style-type-none {
  list-style-type: none;
}
.zs-master-style ul.zs-bullet-list {
  list-style-type: none;
  margin: 0;
}
.zs-master-style ul.zs-bullet-list > li {
  display: flex;
  align-items: center;
  padding-left: 0;
}
.zs-master-style ul.zs-bullet-list > li:before {
  font-family: 'zsIcons';
  font-size: 1.5em;
  content: "\ea23";
  padding: 0em 0em 0em 0.66666667em;
  line-height: 1;
}
.zs-master-style ul.zs-bullet-list > li.zs-bullet-list-nested-parent {
  display: list-item;
}
.zs-master-style ul.zs-bullet-list > li.zs-bullet-list-nested-parent:before {
  display: none;
}
.zs-master-style ul.zs-bullet-list h4 {
  margin: 1.5em 0em 0em 2.5em;
}
.zs-master-style ul.zs-bullet-list ul {
  margin: 0em 0em 0em 2.5em;
}
.zs-master-style strong,
.zs-master-style b {
  font-weight: 700;
}
.zs-master-style em,
.zs-master-style i {
  font-style: italic;
}
.zs-master-style p,
.zs-master-style .zs-paragraph {
  margin: 0.75em 0em 0.5em 0em;
}
.zs-master-style hr {
  border-bottom: 1px solid var(--zs-border-color, #DEDCDE);
  *zoom: 1;
  display: block !important;
}
.zs-master-style hr:before,
.zs-master-style hr:after {
  content: "";
  display: table;
}
.zs-master-style hr:after {
  clear: both;
}
.zs-master-style h1:first-child,
.zs-master-style h2:first-child,
.zs-master-style h3:first-child,
.zs-master-style h4:first-child,
.zs-master-style .zs-h1:first-child,
.zs-master-style .zs-h2:first-child,
.zs-master-style .zs-h3:first-child,
.zs-master-style .zs-h4:first-child,
.zs-master-style p:first-child,
.zs-master-style .zs-paragraph:first-child,
.zs-master-style table:first-child {
  margin-top: 0;
}
.zs-master-style > h1:last-child,
.zs-master-style > h2:last-child,
.zs-master-style > h3:last-child,
.zs-master-style > h4:last-child,
.zs-master-style > .zs-h1:last-child,
.zs-master-style > .zs-h2:last-child,
.zs-master-style > .zs-h3:last-child,
.zs-master-style > .zs-h4:last-child,
.zs-master-style > p:last-child,
.zs-master-style > .zs-paragraph:last-child,
.zs-master-style > table:last-child {
  margin-bottom: 0;
}
.zs-loader {
  display: inline-block;
  line-height: 1;
}
.zs-loader [loader] {
  display: inline-block;
  position: relative;
  width: 1em;
  height: 1em;
  border: 0.125em solid var(--zs-spinner-path-color, rgba(255, 255, 255, 0.5));
  border-radius: 50%;
}
.zs-loader [loader]:before,
.zs-loader [loader]:after,
.zs-loader [loader] span::after,
.zs-loader [loader] span::before {
  content: '';
  box-sizing: border-box;
  display: block;
  position: absolute;
  top: -0.125em;
  left: -0.125em;
  width: 1em;
  height: 1em;
  border: 0.125em solid var(--zs-spinner-color, #ffffff);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--zs-spinner-color, #ffffff) transparent transparent transparent;
}
.zs-loader [loader]::before {
  animation-delay: -0.45s;
}
.zs-loader [loader]::after {
  animation-delay: -0.3s;
}
.zs-loader [loader] span::before {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.zs-master-style,
.zs-master-style *,
.zs-master-style:before,
.zs-master-style:after,
.zs-master-style *:before,
.zs-master-style *:after {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}
.zs-master-style,
.zs-master-style * {
  font-family: "Open Sans", sans-serif;
}
.zs-version {
  content: '6.6.0';
}
.zs-custom-scrollbar {
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.zs-custom-scrollbar ::-webkit-scrollbar,
.zs-custom-scrollbar::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
.zs-custom-scrollbar ::-webkit-scrollbar-track,
.zs-custom-scrollbar::-webkit-scrollbar-track {
  background: var(--zs-scrollbar-track-background, #F4F3F3);
}
.zs-custom-scrollbar ::-webkit-scrollbar-thumb,
.zs-custom-scrollbar::-webkit-scrollbar-thumb {
  background: var(--zs-scrollbar-thumb-background, #B2B0B6);
  border-radius: 8px;
  border: 2px solid var(--zs-scrollbar-track-background, #F4F3F3);
}
.zs-custom-scrollbar ::-webkit-scrollbar-thumb:hover,
.zs-custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: var(--zs-scrollbar-thumb-hover-background, #87848D);
}
.zs-master-style .zs-interactive-primary,
.zs-master-style .zs-icon.zs-interactive-primary {
  color: var(--zs-interactive-primary-color, #27A6A4);
  transition: color 0.1s ease-out;
}
@media (prefers-reduced-motion) {
  .zs-master-style .zs-interactive-primary,
  .zs-master-style .zs-icon.zs-interactive-primary {
    transition-duration: 0s;
  }
}
.zs-master-style .zs-interactive-primary:visited,
.zs-master-style .zs-icon.zs-interactive-primary:visited {
  color: var(--zs-interactive-primary-color, #27A6A4);
}
.zs-master-style .zs-interactive-primary:focus,
.zs-master-style .zs-icon.zs-interactive-primary:focus {
  color: var(--zs-interactive-primary-focus-color, #2F6F7B);
  outline: 2px solid var(--zs-focus-outline-color, #27A6A4);
}
.zs-master-style .zs-interactive-primary:hover,
.zs-master-style .zs-icon.zs-interactive-primary:hover {
  color: var(--zs-interactive-primary-hover-color, #62D2D1);
}
.zs-master-style .zs-interactive-primary:active,
.zs-master-style .zs-icon.zs-interactive-primary:active {
  color: var(--zs-interactive-primary-active-color, #2D535F);
}
.zs-master-style .zs-interactive-secondary,
.zs-master-style .zs-icon.zs-interactive-secondary {
  color: var(--zs-interactive-secondary-color, #716E79);
  transition: color 0.1s ease-out;
}
@media (prefers-reduced-motion) {
  .zs-master-style .zs-interactive-secondary,
  .zs-master-style .zs-icon.zs-interactive-secondary {
    transition-duration: 0s;
  }
}
.zs-master-style .zs-interactive-secondary:visited,
.zs-master-style .zs-icon.zs-interactive-secondary:visited {
  color: var(--zs-interactive-secondary-color, #716E79);
}
.zs-master-style .zs-interactive-secondary:focus,
.zs-master-style .zs-icon.zs-interactive-secondary:focus {
  color: var(--zs-interactive-secondary-focus-color, #454250);
}
.zs-master-style .zs-interactive-secondary:hover,
.zs-master-style .zs-icon.zs-interactive-secondary:hover {
  color: var(--zs-interactive-secondary-hover-color, #9C9AA1);
}
.zs-master-style .zs-interactive-secondary:active,
.zs-master-style .zs-icon.zs-interactive-secondary:active {
  color: var(--zs-interactive-secondary-active-color, #2F2C3C);
}
.zs-master-style .zs-interactive-primary.zs-disabled,
.zs-master-style .zs-interactive-secondary.zs-disabled {
  color: var(--zs-disabled-text-color, #B2B0B6);
}
.zs-master-style .zs-interactive-primary.zs-danger,
.zs-master-style .zs-interactive-secondary.zs-danger {
  color: var(--zs-danger-color, #B21111);
}
.zs-master-style .zs-interactive-primary.zs-danger:visited,
.zs-master-style .zs-interactive-secondary.zs-danger:visited {
  color: var(--zs-danger-color, #B21111);
}
.zs-master-style .zs-interactive-primary.zs-danger:focus,
.zs-master-style .zs-interactive-secondary.zs-danger:focus {
  color: var(--zs-danger-focus-color, #892208);
}
.zs-master-style .zs-interactive-primary.zs-danger:hover,
.zs-master-style .zs-interactive-secondary.zs-danger:hover {
  color: var(--zs-danger-hover-color, #F27755);
}
.zs-master-style .zs-interactive-primary.zs-danger:active,
.zs-master-style .zs-interactive-secondary.zs-danger:active {
  color: var(--zs-danger-active-color, #5C1A0B);
}
.zs-master-style .zs-interactive-primary.zs-success,
.zs-master-style .zs-interactive-secondary.zs-success {
  color: var(--zs-success-color, #00AA67);
}
.zs-master-style .zs-interactive-primary.zs-success:visited,
.zs-master-style .zs-interactive-secondary.zs-success:visited {
  color: var(--zs-success-color, #00AA67);
}
.zs-master-style .zs-interactive-primary.zs-success:focus,
.zs-master-style .zs-interactive-secondary.zs-success:focus {
  color: var(--zs-success-focus-color, #0A6E5E);
}
.zs-master-style .zs-interactive-primary.zs-success:hover,
.zs-master-style .zs-interactive-secondary.zs-success:hover {
  color: var(--zs-success-hover-color, #54D7AF);
}
.zs-master-style .zs-interactive-primary.zs-success:active,
.zs-master-style .zs-interactive-secondary.zs-success:active {
  color: var(--zs-success-active-color, #0C4846);
}
.zs-master-style .zs-interactive-primary.zs-warning,
.zs-master-style .zs-interactive-secondary.zs-warning {
  color: var(--zs-warning-color, #E3A900);
}
.zs-master-style .zs-interactive-primary.zs-warning:visited,
.zs-master-style .zs-interactive-secondary.zs-warning:visited {
  color: var(--zs-warning-color, #E3A900);
}
.zs-master-style .zs-interactive-primary.zs-warning:focus,
.zs-master-style .zs-interactive-secondary.zs-warning:focus {
  color: var(--zs-warning-focus-color, #8A640C);
}
.zs-master-style .zs-interactive-primary.zs-warning:hover,
.zs-master-style .zs-interactive-secondary.zs-warning:hover {
  color: var(--zs-warning-hover-color, #FDCA63);
}
.zs-master-style .zs-interactive-primary.zs-warning:active,
.zs-master-style .zs-interactive-secondary.zs-warning:active {
  color: var(--zs-warning-active-color, #563F0E);
}
.zs-master-style .zs-interactive-primary.zs-info,
.zs-master-style .zs-interactive-secondary.zs-info {
  color: var(--zs-info-color, #2B44C7);
}
.zs-master-style .zs-interactive-primary.zs-info:visited,
.zs-master-style .zs-interactive-secondary.zs-info:visited {
  color: var(--zs-info-color, #2B44C7);
}
.zs-master-style .zs-interactive-primary.zs-info:focus,
.zs-master-style .zs-interactive-secondary.zs-info:focus {
  color: var(--zs-info-focus-color, #141187);
}
.zs-master-style .zs-interactive-primary.zs-info:hover,
.zs-master-style .zs-interactive-secondary.zs-info:hover {
  color: var(--zs-info-hover-color, #7E92E9);
}
.zs-master-style .zs-interactive-primary.zs-info:active,
.zs-master-style .zs-interactive-secondary.zs-info:active {
  color: var(--zs-info-active-color, #160C60);
}
.zs-clear {
  *zoom: 1;
  display: block !important;
}
.zs-clear:before,
.zs-clear:after {
  content: "";
  display: table;
}
.zs-clear:after {
  clear: both;
}
.zs-master-style .zs-disabled,
.zs-master-style [disabled] {
  cursor: default;
  pointer-events: none;
  opacity: 0.4;
}
.zs-highlight,
mark {
  background-color: var(--zs-highlight-color, #FFD680);
  color: inherit;
}
.zs-display-inline-grid {
  display: inline-grid !important;
}
.zs-display-grid {
  display: grid !important;
}
.zs-display-inline-flex {
  display: inline-flex !important;
}
.zs-display-flex {
  display: flex !important;
}
.zs-display-table-cell {
  display: table-cell !important;
}
.zs-display-table-row {
  display: table-row !important;
}
.zs-display-table {
  display: table !important;
}
.zs-display-none {
  display: none !important;
}
.zs-display-inline-block {
  display: inline-block !important;
}
.zs-display-inline {
  display: inline !important;
}
.zs-display-block {
  display: block !important;
}
.zs-layout-flex {
  display: flex !important;
}
.zs-flex-direction-column-reverse {
  flex-direction: column-reverse !important;
}
.zs-flex-direction-row-reverse {
  flex-direction: row-reverse !important;
}
.zs-flex-direction-column {
  flex-direction: column !important;
}
.zs-flex-direction-row {
  flex-direction: row !important;
}
.zs-flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.zs-flex-nowrap {
  flex-wrap: nowrap !important;
}
.zs-flex-wrap {
  flex-wrap: wrap !important;
}
.zs-flex-justify-space-evenly {
  justify-content: space-evenly !important;
}
.zs-flex-justify-space-around {
  justify-content: space-around !important;
}
.zs-flex-justify-space-between {
  justify-content: space-between !important;
}
.zs-flex-justify-right {
  justify-content: right !important;
}
.zs-flex-justify-left {
  justify-content: left !important;
}
.zs-flex-justify-center {
  justify-content: center !important;
}
.zs-flex-justify-end {
  justify-content: end !important;
}
.zs-flex-justify-start {
  justify-content: start !important;
}
.zs-flex-justify-flex-end {
  justify-content: flex-end !important;
}
.zs-flex-justify-flex-start {
  justify-content: flex-start !important;
}
.zs-flex-align-stretch {
  align-items: stretch !important;
}
.zs-flex-align-baseline {
  align-items: baseline !important;
}
.zs-flex-align-center {
  align-items: center !important;
}
.zs-flex-align-end {
  align-items: end !important;
}
.zs-flex-align-start {
  align-items: start !important;
}
.zs-flex-align-flex-end {
  align-items: flex-end !important;
}
.zs-flex-align-flex-start {
  align-items: flex-start !important;
}
.zs-flex-align-self-stretch {
  align-self: stretch !important;
}
.zs-flex-align-self-baseline {
  align-self: baseline !important;
}
.zs-flex-align-self-center {
  align-self: center !important;
}
.zs-flex-align-self-end {
  align-self: end !important;
}
.zs-flex-align-self-start {
  align-self: start !important;
}
.zs-flex-align-self-flex-end {
  align-self: flex-end !important;
}
.zs-flex-align-self-flex-start {
  align-self: flex-start !important;
}
.zs-flex-align-content-space-between {
  align-content: space-between !important;
}
.zs-flex-align-content-space-evenly {
  align-content: space-evenly !important;
}
.zs-flex-align-content-space-around {
  align-content: space-around !important;
}
.zs-flex-align-content-stretch {
  align-content: stretch !important;
}
.zs-flex-align-content-center {
  align-content: center !important;
}
.zs-flex-align-content-end {
  align-content: end !important;
}
.zs-flex-align-content-start {
  align-content: start !important;
}
.zs-flex-align-content-flex-end {
  align-content: flex-end !important;
}
.zs-flex-align-content-flex-start {
  align-content: flex-start !important;
}
.zs-border {
  border: 1px solid var(--zs-border-color, #DEDCDE) !important;
}
.zs-border-top {
  border-top: 1px solid var(--zs-border-color, #DEDCDE) !important;
}
.zs-border-right {
  border-right: 1px solid var(--zs-border-color, #DEDCDE) !important;
}
.zs-border-bottom {
  border-bottom: 1px solid var(--zs-border-color, #DEDCDE) !important;
}
.zs-border-left {
  border-left: 1px solid var(--zs-border-color, #DEDCDE) !important;
}
.zs-border-0 {
  border: 0 !important;
}
.zs-border-top-0 {
  border-top: 0 !important;
}
.zs-border-right-0 {
  border-right: 0 !important;
}
.zs-border-bottom-0 {
  border-bottom: 0 !important;
}
.zs-border-left-0 {
  border-left: 0 !important;
}
.zs-border-rounded {
  border-radius: 0.25em !important;
}
.zs-border-rounded-top {
  border-top-left-radius: 0.25em !important;
  border-top-right-radius: 0.25em !important;
}
.zs-border-rounded-right {
  border-top-right-radius: 0.25em !important;
  border-bottom-right-radius: 0.25em !important;
}
.zs-border-rounded-bottom {
  border-bottom-right-radius: 0.25em !important;
  border-bottom-left-radius: 0.25em !important;
}
.zs-border-rounded-left {
  border-top-left-radius: 0.25em !important;
  border-bottom-left-radius: 0.25em !important;
}
.zs-border-circle {
  border-radius: 50% !important;
}
.zs-border-rounded-0 {
  border-radius: 0 !important;
}
.zs-border-color-link {
  border-color: var(--zs-link-text-color, #2D8B93) !important;
}
.zs-border-color-disabled {
  border-color: var(--zs-disabled-background-color, #F4F3F3) !important;
}
.zs-border-color-info-secondary {
  border-color: var(--zs-info-color-secondary, #EEEEFF) !important;
}
.zs-border-color-success-secondary {
  border-color: var(--zs-success-color-secondary, #D2FAF7) !important;
}
.zs-border-color-warning-secondary {
  border-color: var(--zs-warning-color-secondary, #FFF9F1) !important;
}
.zs-border-color-error-secondary {
  border-color: var(--zs-error-color-secondary, #FFEDE9) !important;
}
.zs-border-color-info {
  border-color: var(--zs-info-color, #2B44C7) !important;
}
.zs-border-color-success {
  border-color: var(--zs-success-color, #00AA67) !important;
}
.zs-border-color-warning {
  border-color: var(--zs-warning-color, #E3A900) !important;
}
.zs-border-color-error {
  border-color: var(--zs-error-color, #B21111) !important;
}
.zs-border-color-default {
  border-color: var(--zs-text-color, #2F2C3C) !important;
}
.zs-size-tiny,
.zs-size-t {
  font-size: 0.5em !important;
}
.zs-size-extra-extra-small,
.zs-size-xxs {
  font-size: 0.625em !important;
}
.zs-size-extra-small,
.zs-size-xs {
  font-size: 0.75em !important;
}
.zs-size-small,
.zs-size-s {
  font-size: 0.875em !important;
}
.zs-size-normal,
.zs-size-n {
  font-size: 1em !important;
}
.zs-size-large,
.zs-size-l {
  font-size: 1.25em !important;
}
.zs-size-extra-large,
.zs-size-xl {
  font-size: 1.5em !important;
}
.zs-size-extra-extra-large,
.zs-size-xxl {
  font-size: 2em !important;
}
.zs-size-super {
  font-size: 4em !important;
}
.zs-size-hero {
  font-size: 3em !important;
}
.zs-font-weight-light {
  font-weight: 300 !important;
}
.zs-font-weight-semi-bold {
  font-weight: 600 !important;
}
.zs-font-weight-normal {
  font-weight: normal !important;
}
.zs-font-weight-bold {
  font-weight: 700 !important;
}
.zs-font-style-oblique {
  font-style: oblique !important;
}
.zs-font-style-normal {
  font-style: normal !important;
}
.zs-font-style-italic {
  font-style: italic !important;
}
.zs-text-uppercase {
  text-transform: uppercase !important;
}
[class~="zs-padding-0"] {
  padding: 0em 0em 0em 0em !important;
}
[class~="zs-margin-0"] {
  margin: 0em 0em 0em 0em !important;
}
[class~="zs-padding-0.5"] {
  padding: 0.25em 0.25em 0.25em 0.25em !important;
}
[class~="zs-margin-0.5"] {
  margin: 0.25em 0.25em 0.25em 0.25em !important;
}
[class~="zs-padding-1"] {
  padding: 0.5em 0.5em 0.5em 0.5em !important;
}
[class~="zs-margin-1"] {
  margin: 0.5em 0.5em 0.5em 0.5em !important;
}
[class~="zs-padding-1.5"] {
  padding: 0.75em 0.75em 0.75em 0.75em !important;
}
[class~="zs-margin-1.5"] {
  margin: 0.75em 0.75em 0.75em 0.75em !important;
}
[class~="zs-padding-2"] {
  padding: 1em 1em 1em 1em !important;
}
[class~="zs-margin-2"] {
  margin: 1em 1em 1em 1em !important;
}
[class~="zs-padding-0-0"] {
  padding: 0em 0em 0em 0em !important;
}
[class~="zs-margin-0-0"] {
  margin: 0em 0em 0em 0em !important;
}
[class~="zs-padding-0-0.5"] {
  padding: 0em 0.25em 0em 0.25em !important;
}
[class~="zs-margin-0-0.5"] {
  margin: 0em 0.25em 0em 0.25em !important;
}
[class~="zs-padding-0-1"] {
  padding: 0em 0.5em 0em 0.5em !important;
}
[class~="zs-margin-0-1"] {
  margin: 0em 0.5em 0em 0.5em !important;
}
[class~="zs-padding-0-1.5"] {
  padding: 0em 0.75em 0em 0.75em !important;
}
[class~="zs-margin-0-1.5"] {
  margin: 0em 0.75em 0em 0.75em !important;
}
[class~="zs-padding-0-2"] {
  padding: 0em 1em 0em 1em !important;
}
[class~="zs-margin-0-2"] {
  margin: 0em 1em 0em 1em !important;
}
[class~="zs-padding-0.5-0"] {
  padding: 0.25em 0em 0.25em 0em !important;
}
[class~="zs-margin-0.5-0"] {
  margin: 0.25em 0em 0.25em 0em !important;
}
[class~="zs-padding-0.5-0.5"] {
  padding: 0.25em 0.25em 0.25em 0.25em !important;
}
[class~="zs-margin-0.5-0.5"] {
  margin: 0.25em 0.25em 0.25em 0.25em !important;
}
[class~="zs-padding-0.5-1"] {
  padding: 0.25em 0.5em 0.25em 0.5em !important;
}
[class~="zs-margin-0.5-1"] {
  margin: 0.25em 0.5em 0.25em 0.5em !important;
}
[class~="zs-padding-0.5-1.5"] {
  padding: 0.25em 0.75em 0.25em 0.75em !important;
}
[class~="zs-margin-0.5-1.5"] {
  margin: 0.25em 0.75em 0.25em 0.75em !important;
}
[class~="zs-padding-0.5-2"] {
  padding: 0.25em 1em 0.25em 1em !important;
}
[class~="zs-margin-0.5-2"] {
  margin: 0.25em 1em 0.25em 1em !important;
}
[class~="zs-padding-1-0"] {
  padding: 0.5em 0em 0.5em 0em !important;
}
[class~="zs-margin-1-0"] {
  margin: 0.5em 0em 0.5em 0em !important;
}
[class~="zs-padding-1-0.5"] {
  padding: 0.5em 0.25em 0.5em 0.25em !important;
}
[class~="zs-margin-1-0.5"] {
  margin: 0.5em 0.25em 0.5em 0.25em !important;
}
[class~="zs-padding-1-1"] {
  padding: 0.5em 0.5em 0.5em 0.5em !important;
}
[class~="zs-margin-1-1"] {
  margin: 0.5em 0.5em 0.5em 0.5em !important;
}
[class~="zs-padding-1-1.5"] {
  padding: 0.5em 0.75em 0.5em 0.75em !important;
}
[class~="zs-margin-1-1.5"] {
  margin: 0.5em 0.75em 0.5em 0.75em !important;
}
[class~="zs-padding-1-2"] {
  padding: 0.5em 1em 0.5em 1em !important;
}
[class~="zs-margin-1-2"] {
  margin: 0.5em 1em 0.5em 1em !important;
}
[class~="zs-padding-1.5-0"] {
  padding: 0.75em 0em 0.75em 0em !important;
}
[class~="zs-margin-1.5-0"] {
  margin: 0.75em 0em 0.75em 0em !important;
}
[class~="zs-padding-1.5-0.5"] {
  padding: 0.75em 0.25em 0.75em 0.25em !important;
}
[class~="zs-margin-1.5-0.5"] {
  margin: 0.75em 0.25em 0.75em 0.25em !important;
}
[class~="zs-padding-1.5-1"] {
  padding: 0.75em 0.5em 0.75em 0.5em !important;
}
[class~="zs-margin-1.5-1"] {
  margin: 0.75em 0.5em 0.75em 0.5em !important;
}
[class~="zs-padding-1.5-1.5"] {
  padding: 0.75em 0.75em 0.75em 0.75em !important;
}
[class~="zs-margin-1.5-1.5"] {
  margin: 0.75em 0.75em 0.75em 0.75em !important;
}
[class~="zs-padding-1.5-2"] {
  padding: 0.75em 1em 0.75em 1em !important;
}
[class~="zs-margin-1.5-2"] {
  margin: 0.75em 1em 0.75em 1em !important;
}
[class~="zs-padding-2-0"] {
  padding: 1em 0em 1em 0em !important;
}
[class~="zs-margin-2-0"] {
  margin: 1em 0em 1em 0em !important;
}
[class~="zs-padding-2-0.5"] {
  padding: 1em 0.25em 1em 0.25em !important;
}
[class~="zs-margin-2-0.5"] {
  margin: 1em 0.25em 1em 0.25em !important;
}
[class~="zs-padding-2-1"] {
  padding: 1em 0.5em 1em 0.5em !important;
}
[class~="zs-margin-2-1"] {
  margin: 1em 0.5em 1em 0.5em !important;
}
[class~="zs-padding-2-1.5"] {
  padding: 1em 0.75em 1em 0.75em !important;
}
[class~="zs-margin-2-1.5"] {
  margin: 1em 0.75em 1em 0.75em !important;
}
[class~="zs-padding-2-2"] {
  padding: 1em 1em 1em 1em !important;
}
[class~="zs-margin-2-2"] {
  margin: 1em 1em 1em 1em !important;
}
[class~="zs-padding-0-0-0-0"] {
  padding: 0em 0em 0em 0em !important;
}
[class~="zs-margin-0-0-0-0"] {
  margin: 0em 0em 0em 0em !important;
}
[class~="zs-padding-0-0-0-0.5"] {
  padding: 0em 0em 0em 0.25em !important;
}
[class~="zs-margin-0-0-0-0.5"] {
  margin: 0em 0em 0em 0.25em !important;
}
[class~="zs-padding-0-0-0-1"] {
  padding: 0em 0em 0em 0.5em !important;
}
[class~="zs-margin-0-0-0-1"] {
  margin: 0em 0em 0em 0.5em !important;
}
[class~="zs-padding-0-0-0-1.5"] {
  padding: 0em 0em 0em 0.75em !important;
}
[class~="zs-margin-0-0-0-1.5"] {
  margin: 0em 0em 0em 0.75em !important;
}
[class~="zs-padding-0-0-0-2"] {
  padding: 0em 0em 0em 1em !important;
}
[class~="zs-margin-0-0-0-2"] {
  margin: 0em 0em 0em 1em !important;
}
[class~="zs-padding-0-0-0.5-0"] {
  padding: 0em 0em 0.25em 0em !important;
}
[class~="zs-margin-0-0-0.5-0"] {
  margin: 0em 0em 0.25em 0em !important;
}
[class~="zs-padding-0-0-0.5-0.5"] {
  padding: 0em 0em 0.25em 0.25em !important;
}
[class~="zs-margin-0-0-0.5-0.5"] {
  margin: 0em 0em 0.25em 0.25em !important;
}
[class~="zs-padding-0-0-0.5-1"] {
  padding: 0em 0em 0.25em 0.5em !important;
}
[class~="zs-margin-0-0-0.5-1"] {
  margin: 0em 0em 0.25em 0.5em !important;
}
[class~="zs-padding-0-0-0.5-1.5"] {
  padding: 0em 0em 0.25em 0.75em !important;
}
[class~="zs-margin-0-0-0.5-1.5"] {
  margin: 0em 0em 0.25em 0.75em !important;
}
[class~="zs-padding-0-0-0.5-2"] {
  padding: 0em 0em 0.25em 1em !important;
}
[class~="zs-margin-0-0-0.5-2"] {
  margin: 0em 0em 0.25em 1em !important;
}
[class~="zs-padding-0-0-1-0"] {
  padding: 0em 0em 0.5em 0em !important;
}
[class~="zs-margin-0-0-1-0"] {
  margin: 0em 0em 0.5em 0em !important;
}
[class~="zs-padding-0-0-1-0.5"] {
  padding: 0em 0em 0.5em 0.25em !important;
}
[class~="zs-margin-0-0-1-0.5"] {
  margin: 0em 0em 0.5em 0.25em !important;
}
[class~="zs-padding-0-0-1-1"] {
  padding: 0em 0em 0.5em 0.5em !important;
}
[class~="zs-margin-0-0-1-1"] {
  margin: 0em 0em 0.5em 0.5em !important;
}
[class~="zs-padding-0-0-1-1.5"] {
  padding: 0em 0em 0.5em 0.75em !important;
}
[class~="zs-margin-0-0-1-1.5"] {
  margin: 0em 0em 0.5em 0.75em !important;
}
[class~="zs-padding-0-0-1-2"] {
  padding: 0em 0em 0.5em 1em !important;
}
[class~="zs-margin-0-0-1-2"] {
  margin: 0em 0em 0.5em 1em !important;
}
[class~="zs-padding-0-0-1.5-0"] {
  padding: 0em 0em 0.75em 0em !important;
}
[class~="zs-margin-0-0-1.5-0"] {
  margin: 0em 0em 0.75em 0em !important;
}
[class~="zs-padding-0-0-1.5-0.5"] {
  padding: 0em 0em 0.75em 0.25em !important;
}
[class~="zs-margin-0-0-1.5-0.5"] {
  margin: 0em 0em 0.75em 0.25em !important;
}
[class~="zs-padding-0-0-1.5-1"] {
  padding: 0em 0em 0.75em 0.5em !important;
}
[class~="zs-margin-0-0-1.5-1"] {
  margin: 0em 0em 0.75em 0.5em !important;
}
[class~="zs-padding-0-0-1.5-1.5"] {
  padding: 0em 0em 0.75em 0.75em !important;
}
[class~="zs-margin-0-0-1.5-1.5"] {
  margin: 0em 0em 0.75em 0.75em !important;
}
[class~="zs-padding-0-0-1.5-2"] {
  padding: 0em 0em 0.75em 1em !important;
}
[class~="zs-margin-0-0-1.5-2"] {
  margin: 0em 0em 0.75em 1em !important;
}
[class~="zs-padding-0-0-2-0"] {
  padding: 0em 0em 1em 0em !important;
}
[class~="zs-margin-0-0-2-0"] {
  margin: 0em 0em 1em 0em !important;
}
[class~="zs-padding-0-0-2-0.5"] {
  padding: 0em 0em 1em 0.25em !important;
}
[class~="zs-margin-0-0-2-0.5"] {
  margin: 0em 0em 1em 0.25em !important;
}
[class~="zs-padding-0-0-2-1"] {
  padding: 0em 0em 1em 0.5em !important;
}
[class~="zs-margin-0-0-2-1"] {
  margin: 0em 0em 1em 0.5em !important;
}
[class~="zs-padding-0-0-2-1.5"] {
  padding: 0em 0em 1em 0.75em !important;
}
[class~="zs-margin-0-0-2-1.5"] {
  margin: 0em 0em 1em 0.75em !important;
}
[class~="zs-padding-0-0-2-2"] {
  padding: 0em 0em 1em 1em !important;
}
[class~="zs-margin-0-0-2-2"] {
  margin: 0em 0em 1em 1em !important;
}
[class~="zs-padding-0-0.5-0-0"] {
  padding: 0em 0.25em 0em 0em !important;
}
[class~="zs-margin-0-0.5-0-0"] {
  margin: 0em 0.25em 0em 0em !important;
}
[class~="zs-padding-0-0.5-0-0.5"] {
  padding: 0em 0.25em 0em 0.25em !important;
}
[class~="zs-margin-0-0.5-0-0.5"] {
  margin: 0em 0.25em 0em 0.25em !important;
}
[class~="zs-padding-0-0.5-0-1"] {
  padding: 0em 0.25em 0em 0.5em !important;
}
[class~="zs-margin-0-0.5-0-1"] {
  margin: 0em 0.25em 0em 0.5em !important;
}
[class~="zs-padding-0-0.5-0-1.5"] {
  padding: 0em 0.25em 0em 0.75em !important;
}
[class~="zs-margin-0-0.5-0-1.5"] {
  margin: 0em 0.25em 0em 0.75em !important;
}
[class~="zs-padding-0-0.5-0-2"] {
  padding: 0em 0.25em 0em 1em !important;
}
[class~="zs-margin-0-0.5-0-2"] {
  margin: 0em 0.25em 0em 1em !important;
}
[class~="zs-padding-0-0.5-0.5-0"] {
  padding: 0em 0.25em 0.25em 0em !important;
}
[class~="zs-margin-0-0.5-0.5-0"] {
  margin: 0em 0.25em 0.25em 0em !important;
}
[class~="zs-padding-0-0.5-0.5-0.5"] {
  padding: 0em 0.25em 0.25em 0.25em !important;
}
[class~="zs-margin-0-0.5-0.5-0.5"] {
  margin: 0em 0.25em 0.25em 0.25em !important;
}
[class~="zs-padding-0-0.5-0.5-1"] {
  padding: 0em 0.25em 0.25em 0.5em !important;
}
[class~="zs-margin-0-0.5-0.5-1"] {
  margin: 0em 0.25em 0.25em 0.5em !important;
}
[class~="zs-padding-0-0.5-0.5-1.5"] {
  padding: 0em 0.25em 0.25em 0.75em !important;
}
[class~="zs-margin-0-0.5-0.5-1.5"] {
  margin: 0em 0.25em 0.25em 0.75em !important;
}
[class~="zs-padding-0-0.5-0.5-2"] {
  padding: 0em 0.25em 0.25em 1em !important;
}
[class~="zs-margin-0-0.5-0.5-2"] {
  margin: 0em 0.25em 0.25em 1em !important;
}
[class~="zs-padding-0-0.5-1-0"] {
  padding: 0em 0.25em 0.5em 0em !important;
}
[class~="zs-margin-0-0.5-1-0"] {
  margin: 0em 0.25em 0.5em 0em !important;
}
[class~="zs-padding-0-0.5-1-0.5"] {
  padding: 0em 0.25em 0.5em 0.25em !important;
}
[class~="zs-margin-0-0.5-1-0.5"] {
  margin: 0em 0.25em 0.5em 0.25em !important;
}
[class~="zs-padding-0-0.5-1-1"] {
  padding: 0em 0.25em 0.5em 0.5em !important;
}
[class~="zs-margin-0-0.5-1-1"] {
  margin: 0em 0.25em 0.5em 0.5em !important;
}
[class~="zs-padding-0-0.5-1-1.5"] {
  padding: 0em 0.25em 0.5em 0.75em !important;
}
[class~="zs-margin-0-0.5-1-1.5"] {
  margin: 0em 0.25em 0.5em 0.75em !important;
}
[class~="zs-padding-0-0.5-1-2"] {
  padding: 0em 0.25em 0.5em 1em !important;
}
[class~="zs-margin-0-0.5-1-2"] {
  margin: 0em 0.25em 0.5em 1em !important;
}
[class~="zs-padding-0-0.5-1.5-0"] {
  padding: 0em 0.25em 0.75em 0em !important;
}
[class~="zs-margin-0-0.5-1.5-0"] {
  margin: 0em 0.25em 0.75em 0em !important;
}
[class~="zs-padding-0-0.5-1.5-0.5"] {
  padding: 0em 0.25em 0.75em 0.25em !important;
}
[class~="zs-margin-0-0.5-1.5-0.5"] {
  margin: 0em 0.25em 0.75em 0.25em !important;
}
[class~="zs-padding-0-0.5-1.5-1"] {
  padding: 0em 0.25em 0.75em 0.5em !important;
}
[class~="zs-margin-0-0.5-1.5-1"] {
  margin: 0em 0.25em 0.75em 0.5em !important;
}
[class~="zs-padding-0-0.5-1.5-1.5"] {
  padding: 0em 0.25em 0.75em 0.75em !important;
}
[class~="zs-margin-0-0.5-1.5-1.5"] {
  margin: 0em 0.25em 0.75em 0.75em !important;
}
[class~="zs-padding-0-0.5-1.5-2"] {
  padding: 0em 0.25em 0.75em 1em !important;
}
[class~="zs-margin-0-0.5-1.5-2"] {
  margin: 0em 0.25em 0.75em 1em !important;
}
[class~="zs-padding-0-0.5-2-0"] {
  padding: 0em 0.25em 1em 0em !important;
}
[class~="zs-margin-0-0.5-2-0"] {
  margin: 0em 0.25em 1em 0em !important;
}
[class~="zs-padding-0-0.5-2-0.5"] {
  padding: 0em 0.25em 1em 0.25em !important;
}
[class~="zs-margin-0-0.5-2-0.5"] {
  margin: 0em 0.25em 1em 0.25em !important;
}
[class~="zs-padding-0-0.5-2-1"] {
  padding: 0em 0.25em 1em 0.5em !important;
}
[class~="zs-margin-0-0.5-2-1"] {
  margin: 0em 0.25em 1em 0.5em !important;
}
[class~="zs-padding-0-0.5-2-1.5"] {
  padding: 0em 0.25em 1em 0.75em !important;
}
[class~="zs-margin-0-0.5-2-1.5"] {
  margin: 0em 0.25em 1em 0.75em !important;
}
[class~="zs-padding-0-0.5-2-2"] {
  padding: 0em 0.25em 1em 1em !important;
}
[class~="zs-margin-0-0.5-2-2"] {
  margin: 0em 0.25em 1em 1em !important;
}
[class~="zs-padding-0-1-0-0"] {
  padding: 0em 0.5em 0em 0em !important;
}
[class~="zs-margin-0-1-0-0"] {
  margin: 0em 0.5em 0em 0em !important;
}
[class~="zs-padding-0-1-0-0.5"] {
  padding: 0em 0.5em 0em 0.25em !important;
}
[class~="zs-margin-0-1-0-0.5"] {
  margin: 0em 0.5em 0em 0.25em !important;
}
[class~="zs-padding-0-1-0-1"] {
  padding: 0em 0.5em 0em 0.5em !important;
}
[class~="zs-margin-0-1-0-1"] {
  margin: 0em 0.5em 0em 0.5em !important;
}
[class~="zs-padding-0-1-0-1.5"] {
  padding: 0em 0.5em 0em 0.75em !important;
}
[class~="zs-margin-0-1-0-1.5"] {
  margin: 0em 0.5em 0em 0.75em !important;
}
[class~="zs-padding-0-1-0-2"] {
  padding: 0em 0.5em 0em 1em !important;
}
[class~="zs-margin-0-1-0-2"] {
  margin: 0em 0.5em 0em 1em !important;
}
[class~="zs-padding-0-1-0.5-0"] {
  padding: 0em 0.5em 0.25em 0em !important;
}
[class~="zs-margin-0-1-0.5-0"] {
  margin: 0em 0.5em 0.25em 0em !important;
}
[class~="zs-padding-0-1-0.5-0.5"] {
  padding: 0em 0.5em 0.25em 0.25em !important;
}
[class~="zs-margin-0-1-0.5-0.5"] {
  margin: 0em 0.5em 0.25em 0.25em !important;
}
[class~="zs-padding-0-1-0.5-1"] {
  padding: 0em 0.5em 0.25em 0.5em !important;
}
[class~="zs-margin-0-1-0.5-1"] {
  margin: 0em 0.5em 0.25em 0.5em !important;
}
[class~="zs-padding-0-1-0.5-1.5"] {
  padding: 0em 0.5em 0.25em 0.75em !important;
}
[class~="zs-margin-0-1-0.5-1.5"] {
  margin: 0em 0.5em 0.25em 0.75em !important;
}
[class~="zs-padding-0-1-0.5-2"] {
  padding: 0em 0.5em 0.25em 1em !important;
}
[class~="zs-margin-0-1-0.5-2"] {
  margin: 0em 0.5em 0.25em 1em !important;
}
[class~="zs-padding-0-1-1-0"] {
  padding: 0em 0.5em 0.5em 0em !important;
}
[class~="zs-margin-0-1-1-0"] {
  margin: 0em 0.5em 0.5em 0em !important;
}
[class~="zs-padding-0-1-1-0.5"] {
  padding: 0em 0.5em 0.5em 0.25em !important;
}
[class~="zs-margin-0-1-1-0.5"] {
  margin: 0em 0.5em 0.5em 0.25em !important;
}
[class~="zs-padding-0-1-1-1"] {
  padding: 0em 0.5em 0.5em 0.5em !important;
}
[class~="zs-margin-0-1-1-1"] {
  margin: 0em 0.5em 0.5em 0.5em !important;
}
[class~="zs-padding-0-1-1-1.5"] {
  padding: 0em 0.5em 0.5em 0.75em !important;
}
[class~="zs-margin-0-1-1-1.5"] {
  margin: 0em 0.5em 0.5em 0.75em !important;
}
[class~="zs-padding-0-1-1-2"] {
  padding: 0em 0.5em 0.5em 1em !important;
}
[class~="zs-margin-0-1-1-2"] {
  margin: 0em 0.5em 0.5em 1em !important;
}
[class~="zs-padding-0-1-1.5-0"] {
  padding: 0em 0.5em 0.75em 0em !important;
}
[class~="zs-margin-0-1-1.5-0"] {
  margin: 0em 0.5em 0.75em 0em !important;
}
[class~="zs-padding-0-1-1.5-0.5"] {
  padding: 0em 0.5em 0.75em 0.25em !important;
}
[class~="zs-margin-0-1-1.5-0.5"] {
  margin: 0em 0.5em 0.75em 0.25em !important;
}
[class~="zs-padding-0-1-1.5-1"] {
  padding: 0em 0.5em 0.75em 0.5em !important;
}
[class~="zs-margin-0-1-1.5-1"] {
  margin: 0em 0.5em 0.75em 0.5em !important;
}
[class~="zs-padding-0-1-1.5-1.5"] {
  padding: 0em 0.5em 0.75em 0.75em !important;
}
[class~="zs-margin-0-1-1.5-1.5"] {
  margin: 0em 0.5em 0.75em 0.75em !important;
}
[class~="zs-padding-0-1-1.5-2"] {
  padding: 0em 0.5em 0.75em 1em !important;
}
[class~="zs-margin-0-1-1.5-2"] {
  margin: 0em 0.5em 0.75em 1em !important;
}
[class~="zs-padding-0-1-2-0"] {
  padding: 0em 0.5em 1em 0em !important;
}
[class~="zs-margin-0-1-2-0"] {
  margin: 0em 0.5em 1em 0em !important;
}
[class~="zs-padding-0-1-2-0.5"] {
  padding: 0em 0.5em 1em 0.25em !important;
}
[class~="zs-margin-0-1-2-0.5"] {
  margin: 0em 0.5em 1em 0.25em !important;
}
[class~="zs-padding-0-1-2-1"] {
  padding: 0em 0.5em 1em 0.5em !important;
}
[class~="zs-margin-0-1-2-1"] {
  margin: 0em 0.5em 1em 0.5em !important;
}
[class~="zs-padding-0-1-2-1.5"] {
  padding: 0em 0.5em 1em 0.75em !important;
}
[class~="zs-margin-0-1-2-1.5"] {
  margin: 0em 0.5em 1em 0.75em !important;
}
[class~="zs-padding-0-1-2-2"] {
  padding: 0em 0.5em 1em 1em !important;
}
[class~="zs-margin-0-1-2-2"] {
  margin: 0em 0.5em 1em 1em !important;
}
[class~="zs-padding-0-1.5-0-0"] {
  padding: 0em 0.75em 0em 0em !important;
}
[class~="zs-margin-0-1.5-0-0"] {
  margin: 0em 0.75em 0em 0em !important;
}
[class~="zs-padding-0-1.5-0-0.5"] {
  padding: 0em 0.75em 0em 0.25em !important;
}
[class~="zs-margin-0-1.5-0-0.5"] {
  margin: 0em 0.75em 0em 0.25em !important;
}
[class~="zs-padding-0-1.5-0-1"] {
  padding: 0em 0.75em 0em 0.5em !important;
}
[class~="zs-margin-0-1.5-0-1"] {
  margin: 0em 0.75em 0em 0.5em !important;
}
[class~="zs-padding-0-1.5-0-1.5"] {
  padding: 0em 0.75em 0em 0.75em !important;
}
[class~="zs-margin-0-1.5-0-1.5"] {
  margin: 0em 0.75em 0em 0.75em !important;
}
[class~="zs-padding-0-1.5-0-2"] {
  padding: 0em 0.75em 0em 1em !important;
}
[class~="zs-margin-0-1.5-0-2"] {
  margin: 0em 0.75em 0em 1em !important;
}
[class~="zs-padding-0-1.5-0.5-0"] {
  padding: 0em 0.75em 0.25em 0em !important;
}
[class~="zs-margin-0-1.5-0.5-0"] {
  margin: 0em 0.75em 0.25em 0em !important;
}
[class~="zs-padding-0-1.5-0.5-0.5"] {
  padding: 0em 0.75em 0.25em 0.25em !important;
}
[class~="zs-margin-0-1.5-0.5-0.5"] {
  margin: 0em 0.75em 0.25em 0.25em !important;
}
[class~="zs-padding-0-1.5-0.5-1"] {
  padding: 0em 0.75em 0.25em 0.5em !important;
}
[class~="zs-margin-0-1.5-0.5-1"] {
  margin: 0em 0.75em 0.25em 0.5em !important;
}
[class~="zs-padding-0-1.5-0.5-1.5"] {
  padding: 0em 0.75em 0.25em 0.75em !important;
}
[class~="zs-margin-0-1.5-0.5-1.5"] {
  margin: 0em 0.75em 0.25em 0.75em !important;
}
[class~="zs-padding-0-1.5-0.5-2"] {
  padding: 0em 0.75em 0.25em 1em !important;
}
[class~="zs-margin-0-1.5-0.5-2"] {
  margin: 0em 0.75em 0.25em 1em !important;
}
[class~="zs-padding-0-1.5-1-0"] {
  padding: 0em 0.75em 0.5em 0em !important;
}
[class~="zs-margin-0-1.5-1-0"] {
  margin: 0em 0.75em 0.5em 0em !important;
}
[class~="zs-padding-0-1.5-1-0.5"] {
  padding: 0em 0.75em 0.5em 0.25em !important;
}
[class~="zs-margin-0-1.5-1-0.5"] {
  margin: 0em 0.75em 0.5em 0.25em !important;
}
[class~="zs-padding-0-1.5-1-1"] {
  padding: 0em 0.75em 0.5em 0.5em !important;
}
[class~="zs-margin-0-1.5-1-1"] {
  margin: 0em 0.75em 0.5em 0.5em !important;
}
[class~="zs-padding-0-1.5-1-1.5"] {
  padding: 0em 0.75em 0.5em 0.75em !important;
}
[class~="zs-margin-0-1.5-1-1.5"] {
  margin: 0em 0.75em 0.5em 0.75em !important;
}
[class~="zs-padding-0-1.5-1-2"] {
  padding: 0em 0.75em 0.5em 1em !important;
}
[class~="zs-margin-0-1.5-1-2"] {
  margin: 0em 0.75em 0.5em 1em !important;
}
[class~="zs-padding-0-1.5-1.5-0"] {
  padding: 0em 0.75em 0.75em 0em !important;
}
[class~="zs-margin-0-1.5-1.5-0"] {
  margin: 0em 0.75em 0.75em 0em !important;
}
[class~="zs-padding-0-1.5-1.5-0.5"] {
  padding: 0em 0.75em 0.75em 0.25em !important;
}
[class~="zs-margin-0-1.5-1.5-0.5"] {
  margin: 0em 0.75em 0.75em 0.25em !important;
}
[class~="zs-padding-0-1.5-1.5-1"] {
  padding: 0em 0.75em 0.75em 0.5em !important;
}
[class~="zs-margin-0-1.5-1.5-1"] {
  margin: 0em 0.75em 0.75em 0.5em !important;
}
[class~="zs-padding-0-1.5-1.5-1.5"] {
  padding: 0em 0.75em 0.75em 0.75em !important;
}
[class~="zs-margin-0-1.5-1.5-1.5"] {
  margin: 0em 0.75em 0.75em 0.75em !important;
}
[class~="zs-padding-0-1.5-1.5-2"] {
  padding: 0em 0.75em 0.75em 1em !important;
}
[class~="zs-margin-0-1.5-1.5-2"] {
  margin: 0em 0.75em 0.75em 1em !important;
}
[class~="zs-padding-0-1.5-2-0"] {
  padding: 0em 0.75em 1em 0em !important;
}
[class~="zs-margin-0-1.5-2-0"] {
  margin: 0em 0.75em 1em 0em !important;
}
[class~="zs-padding-0-1.5-2-0.5"] {
  padding: 0em 0.75em 1em 0.25em !important;
}
[class~="zs-margin-0-1.5-2-0.5"] {
  margin: 0em 0.75em 1em 0.25em !important;
}
[class~="zs-padding-0-1.5-2-1"] {
  padding: 0em 0.75em 1em 0.5em !important;
}
[class~="zs-margin-0-1.5-2-1"] {
  margin: 0em 0.75em 1em 0.5em !important;
}
[class~="zs-padding-0-1.5-2-1.5"] {
  padding: 0em 0.75em 1em 0.75em !important;
}
[class~="zs-margin-0-1.5-2-1.5"] {
  margin: 0em 0.75em 1em 0.75em !important;
}
[class~="zs-padding-0-1.5-2-2"] {
  padding: 0em 0.75em 1em 1em !important;
}
[class~="zs-margin-0-1.5-2-2"] {
  margin: 0em 0.75em 1em 1em !important;
}
[class~="zs-padding-0-2-0-0"] {
  padding: 0em 1em 0em 0em !important;
}
[class~="zs-margin-0-2-0-0"] {
  margin: 0em 1em 0em 0em !important;
}
[class~="zs-padding-0-2-0-0.5"] {
  padding: 0em 1em 0em 0.25em !important;
}
[class~="zs-margin-0-2-0-0.5"] {
  margin: 0em 1em 0em 0.25em !important;
}
[class~="zs-padding-0-2-0-1"] {
  padding: 0em 1em 0em 0.5em !important;
}
[class~="zs-margin-0-2-0-1"] {
  margin: 0em 1em 0em 0.5em !important;
}
[class~="zs-padding-0-2-0-1.5"] {
  padding: 0em 1em 0em 0.75em !important;
}
[class~="zs-margin-0-2-0-1.5"] {
  margin: 0em 1em 0em 0.75em !important;
}
[class~="zs-padding-0-2-0-2"] {
  padding: 0em 1em 0em 1em !important;
}
[class~="zs-margin-0-2-0-2"] {
  margin: 0em 1em 0em 1em !important;
}
[class~="zs-padding-0-2-0.5-0"] {
  padding: 0em 1em 0.25em 0em !important;
}
[class~="zs-margin-0-2-0.5-0"] {
  margin: 0em 1em 0.25em 0em !important;
}
[class~="zs-padding-0-2-0.5-0.5"] {
  padding: 0em 1em 0.25em 0.25em !important;
}
[class~="zs-margin-0-2-0.5-0.5"] {
  margin: 0em 1em 0.25em 0.25em !important;
}
[class~="zs-padding-0-2-0.5-1"] {
  padding: 0em 1em 0.25em 0.5em !important;
}
[class~="zs-margin-0-2-0.5-1"] {
  margin: 0em 1em 0.25em 0.5em !important;
}
[class~="zs-padding-0-2-0.5-1.5"] {
  padding: 0em 1em 0.25em 0.75em !important;
}
[class~="zs-margin-0-2-0.5-1.5"] {
  margin: 0em 1em 0.25em 0.75em !important;
}
[class~="zs-padding-0-2-0.5-2"] {
  padding: 0em 1em 0.25em 1em !important;
}
[class~="zs-margin-0-2-0.5-2"] {
  margin: 0em 1em 0.25em 1em !important;
}
[class~="zs-padding-0-2-1-0"] {
  padding: 0em 1em 0.5em 0em !important;
}
[class~="zs-margin-0-2-1-0"] {
  margin: 0em 1em 0.5em 0em !important;
}
[class~="zs-padding-0-2-1-0.5"] {
  padding: 0em 1em 0.5em 0.25em !important;
}
[class~="zs-margin-0-2-1-0.5"] {
  margin: 0em 1em 0.5em 0.25em !important;
}
[class~="zs-padding-0-2-1-1"] {
  padding: 0em 1em 0.5em 0.5em !important;
}
[class~="zs-margin-0-2-1-1"] {
  margin: 0em 1em 0.5em 0.5em !important;
}
[class~="zs-padding-0-2-1-1.5"] {
  padding: 0em 1em 0.5em 0.75em !important;
}
[class~="zs-margin-0-2-1-1.5"] {
  margin: 0em 1em 0.5em 0.75em !important;
}
[class~="zs-padding-0-2-1-2"] {
  padding: 0em 1em 0.5em 1em !important;
}
[class~="zs-margin-0-2-1-2"] {
  margin: 0em 1em 0.5em 1em !important;
}
[class~="zs-padding-0-2-1.5-0"] {
  padding: 0em 1em 0.75em 0em !important;
}
[class~="zs-margin-0-2-1.5-0"] {
  margin: 0em 1em 0.75em 0em !important;
}
[class~="zs-padding-0-2-1.5-0.5"] {
  padding: 0em 1em 0.75em 0.25em !important;
}
[class~="zs-margin-0-2-1.5-0.5"] {
  margin: 0em 1em 0.75em 0.25em !important;
}
[class~="zs-padding-0-2-1.5-1"] {
  padding: 0em 1em 0.75em 0.5em !important;
}
[class~="zs-margin-0-2-1.5-1"] {
  margin: 0em 1em 0.75em 0.5em !important;
}
[class~="zs-padding-0-2-1.5-1.5"] {
  padding: 0em 1em 0.75em 0.75em !important;
}
[class~="zs-margin-0-2-1.5-1.5"] {
  margin: 0em 1em 0.75em 0.75em !important;
}
[class~="zs-padding-0-2-1.5-2"] {
  padding: 0em 1em 0.75em 1em !important;
}
[class~="zs-margin-0-2-1.5-2"] {
  margin: 0em 1em 0.75em 1em !important;
}
[class~="zs-padding-0-2-2-0"] {
  padding: 0em 1em 1em 0em !important;
}
[class~="zs-margin-0-2-2-0"] {
  margin: 0em 1em 1em 0em !important;
}
[class~="zs-padding-0-2-2-0.5"] {
  padding: 0em 1em 1em 0.25em !important;
}
[class~="zs-margin-0-2-2-0.5"] {
  margin: 0em 1em 1em 0.25em !important;
}
[class~="zs-padding-0-2-2-1"] {
  padding: 0em 1em 1em 0.5em !important;
}
[class~="zs-margin-0-2-2-1"] {
  margin: 0em 1em 1em 0.5em !important;
}
[class~="zs-padding-0-2-2-1.5"] {
  padding: 0em 1em 1em 0.75em !important;
}
[class~="zs-margin-0-2-2-1.5"] {
  margin: 0em 1em 1em 0.75em !important;
}
[class~="zs-padding-0-2-2-2"] {
  padding: 0em 1em 1em 1em !important;
}
[class~="zs-margin-0-2-2-2"] {
  margin: 0em 1em 1em 1em !important;
}
[class~="zs-padding-0.5-0-0-0"] {
  padding: 0.25em 0em 0em 0em !important;
}
[class~="zs-margin-0.5-0-0-0"] {
  margin: 0.25em 0em 0em 0em !important;
}
[class~="zs-padding-0.5-0-0-0.5"] {
  padding: 0.25em 0em 0em 0.25em !important;
}
[class~="zs-margin-0.5-0-0-0.5"] {
  margin: 0.25em 0em 0em 0.25em !important;
}
[class~="zs-padding-0.5-0-0-1"] {
  padding: 0.25em 0em 0em 0.5em !important;
}
[class~="zs-margin-0.5-0-0-1"] {
  margin: 0.25em 0em 0em 0.5em !important;
}
[class~="zs-padding-0.5-0-0-1.5"] {
  padding: 0.25em 0em 0em 0.75em !important;
}
[class~="zs-margin-0.5-0-0-1.5"] {
  margin: 0.25em 0em 0em 0.75em !important;
}
[class~="zs-padding-0.5-0-0-2"] {
  padding: 0.25em 0em 0em 1em !important;
}
[class~="zs-margin-0.5-0-0-2"] {
  margin: 0.25em 0em 0em 1em !important;
}
[class~="zs-padding-0.5-0-0.5-0"] {
  padding: 0.25em 0em 0.25em 0em !important;
}
[class~="zs-margin-0.5-0-0.5-0"] {
  margin: 0.25em 0em 0.25em 0em !important;
}
[class~="zs-padding-0.5-0-0.5-0.5"] {
  padding: 0.25em 0em 0.25em 0.25em !important;
}
[class~="zs-margin-0.5-0-0.5-0.5"] {
  margin: 0.25em 0em 0.25em 0.25em !important;
}
[class~="zs-padding-0.5-0-0.5-1"] {
  padding: 0.25em 0em 0.25em 0.5em !important;
}
[class~="zs-margin-0.5-0-0.5-1"] {
  margin: 0.25em 0em 0.25em 0.5em !important;
}
[class~="zs-padding-0.5-0-0.5-1.5"] {
  padding: 0.25em 0em 0.25em 0.75em !important;
}
[class~="zs-margin-0.5-0-0.5-1.5"] {
  margin: 0.25em 0em 0.25em 0.75em !important;
}
[class~="zs-padding-0.5-0-0.5-2"] {
  padding: 0.25em 0em 0.25em 1em !important;
}
[class~="zs-margin-0.5-0-0.5-2"] {
  margin: 0.25em 0em 0.25em 1em !important;
}
[class~="zs-padding-0.5-0-1-0"] {
  padding: 0.25em 0em 0.5em 0em !important;
}
[class~="zs-margin-0.5-0-1-0"] {
  margin: 0.25em 0em 0.5em 0em !important;
}
[class~="zs-padding-0.5-0-1-0.5"] {
  padding: 0.25em 0em 0.5em 0.25em !important;
}
[class~="zs-margin-0.5-0-1-0.5"] {
  margin: 0.25em 0em 0.5em 0.25em !important;
}
[class~="zs-padding-0.5-0-1-1"] {
  padding: 0.25em 0em 0.5em 0.5em !important;
}
[class~="zs-margin-0.5-0-1-1"] {
  margin: 0.25em 0em 0.5em 0.5em !important;
}
[class~="zs-padding-0.5-0-1-1.5"] {
  padding: 0.25em 0em 0.5em 0.75em !important;
}
[class~="zs-margin-0.5-0-1-1.5"] {
  margin: 0.25em 0em 0.5em 0.75em !important;
}
[class~="zs-padding-0.5-0-1-2"] {
  padding: 0.25em 0em 0.5em 1em !important;
}
[class~="zs-margin-0.5-0-1-2"] {
  margin: 0.25em 0em 0.5em 1em !important;
}
[class~="zs-padding-0.5-0-1.5-0"] {
  padding: 0.25em 0em 0.75em 0em !important;
}
[class~="zs-margin-0.5-0-1.5-0"] {
  margin: 0.25em 0em 0.75em 0em !important;
}
[class~="zs-padding-0.5-0-1.5-0.5"] {
  padding: 0.25em 0em 0.75em 0.25em !important;
}
[class~="zs-margin-0.5-0-1.5-0.5"] {
  margin: 0.25em 0em 0.75em 0.25em !important;
}
[class~="zs-padding-0.5-0-1.5-1"] {
  padding: 0.25em 0em 0.75em 0.5em !important;
}
[class~="zs-margin-0.5-0-1.5-1"] {
  margin: 0.25em 0em 0.75em 0.5em !important;
}
[class~="zs-padding-0.5-0-1.5-1.5"] {
  padding: 0.25em 0em 0.75em 0.75em !important;
}
[class~="zs-margin-0.5-0-1.5-1.5"] {
  margin: 0.25em 0em 0.75em 0.75em !important;
}
[class~="zs-padding-0.5-0-1.5-2"] {
  padding: 0.25em 0em 0.75em 1em !important;
}
[class~="zs-margin-0.5-0-1.5-2"] {
  margin: 0.25em 0em 0.75em 1em !important;
}
[class~="zs-padding-0.5-0-2-0"] {
  padding: 0.25em 0em 1em 0em !important;
}
[class~="zs-margin-0.5-0-2-0"] {
  margin: 0.25em 0em 1em 0em !important;
}
[class~="zs-padding-0.5-0-2-0.5"] {
  padding: 0.25em 0em 1em 0.25em !important;
}
[class~="zs-margin-0.5-0-2-0.5"] {
  margin: 0.25em 0em 1em 0.25em !important;
}
[class~="zs-padding-0.5-0-2-1"] {
  padding: 0.25em 0em 1em 0.5em !important;
}
[class~="zs-margin-0.5-0-2-1"] {
  margin: 0.25em 0em 1em 0.5em !important;
}
[class~="zs-padding-0.5-0-2-1.5"] {
  padding: 0.25em 0em 1em 0.75em !important;
}
[class~="zs-margin-0.5-0-2-1.5"] {
  margin: 0.25em 0em 1em 0.75em !important;
}
[class~="zs-padding-0.5-0-2-2"] {
  padding: 0.25em 0em 1em 1em !important;
}
[class~="zs-margin-0.5-0-2-2"] {
  margin: 0.25em 0em 1em 1em !important;
}
[class~="zs-padding-0.5-0.5-0-0"] {
  padding: 0.25em 0.25em 0em 0em !important;
}
[class~="zs-margin-0.5-0.5-0-0"] {
  margin: 0.25em 0.25em 0em 0em !important;
}
[class~="zs-padding-0.5-0.5-0-0.5"] {
  padding: 0.25em 0.25em 0em 0.25em !important;
}
[class~="zs-margin-0.5-0.5-0-0.5"] {
  margin: 0.25em 0.25em 0em 0.25em !important;
}
[class~="zs-padding-0.5-0.5-0-1"] {
  padding: 0.25em 0.25em 0em 0.5em !important;
}
[class~="zs-margin-0.5-0.5-0-1"] {
  margin: 0.25em 0.25em 0em 0.5em !important;
}
[class~="zs-padding-0.5-0.5-0-1.5"] {
  padding: 0.25em 0.25em 0em 0.75em !important;
}
[class~="zs-margin-0.5-0.5-0-1.5"] {
  margin: 0.25em 0.25em 0em 0.75em !important;
}
[class~="zs-padding-0.5-0.5-0-2"] {
  padding: 0.25em 0.25em 0em 1em !important;
}
[class~="zs-margin-0.5-0.5-0-2"] {
  margin: 0.25em 0.25em 0em 1em !important;
}
[class~="zs-padding-0.5-0.5-0.5-0"] {
  padding: 0.25em 0.25em 0.25em 0em !important;
}
[class~="zs-margin-0.5-0.5-0.5-0"] {
  margin: 0.25em 0.25em 0.25em 0em !important;
}
[class~="zs-padding-0.5-0.5-0.5-0.5"] {
  padding: 0.25em 0.25em 0.25em 0.25em !important;
}
[class~="zs-margin-0.5-0.5-0.5-0.5"] {
  margin: 0.25em 0.25em 0.25em 0.25em !important;
}
[class~="zs-padding-0.5-0.5-0.5-1"] {
  padding: 0.25em 0.25em 0.25em 0.5em !important;
}
[class~="zs-margin-0.5-0.5-0.5-1"] {
  margin: 0.25em 0.25em 0.25em 0.5em !important;
}
[class~="zs-padding-0.5-0.5-0.5-1.5"] {
  padding: 0.25em 0.25em 0.25em 0.75em !important;
}
[class~="zs-margin-0.5-0.5-0.5-1.5"] {
  margin: 0.25em 0.25em 0.25em 0.75em !important;
}
[class~="zs-padding-0.5-0.5-0.5-2"] {
  padding: 0.25em 0.25em 0.25em 1em !important;
}
[class~="zs-margin-0.5-0.5-0.5-2"] {
  margin: 0.25em 0.25em 0.25em 1em !important;
}
[class~="zs-padding-0.5-0.5-1-0"] {
  padding: 0.25em 0.25em 0.5em 0em !important;
}
[class~="zs-margin-0.5-0.5-1-0"] {
  margin: 0.25em 0.25em 0.5em 0em !important;
}
[class~="zs-padding-0.5-0.5-1-0.5"] {
  padding: 0.25em 0.25em 0.5em 0.25em !important;
}
[class~="zs-margin-0.5-0.5-1-0.5"] {
  margin: 0.25em 0.25em 0.5em 0.25em !important;
}
[class~="zs-padding-0.5-0.5-1-1"] {
  padding: 0.25em 0.25em 0.5em 0.5em !important;
}
[class~="zs-margin-0.5-0.5-1-1"] {
  margin: 0.25em 0.25em 0.5em 0.5em !important;
}
[class~="zs-padding-0.5-0.5-1-1.5"] {
  padding: 0.25em 0.25em 0.5em 0.75em !important;
}
[class~="zs-margin-0.5-0.5-1-1.5"] {
  margin: 0.25em 0.25em 0.5em 0.75em !important;
}
[class~="zs-padding-0.5-0.5-1-2"] {
  padding: 0.25em 0.25em 0.5em 1em !important;
}
[class~="zs-margin-0.5-0.5-1-2"] {
  margin: 0.25em 0.25em 0.5em 1em !important;
}
[class~="zs-padding-0.5-0.5-1.5-0"] {
  padding: 0.25em 0.25em 0.75em 0em !important;
}
[class~="zs-margin-0.5-0.5-1.5-0"] {
  margin: 0.25em 0.25em 0.75em 0em !important;
}
[class~="zs-padding-0.5-0.5-1.5-0.5"] {
  padding: 0.25em 0.25em 0.75em 0.25em !important;
}
[class~="zs-margin-0.5-0.5-1.5-0.5"] {
  margin: 0.25em 0.25em 0.75em 0.25em !important;
}
[class~="zs-padding-0.5-0.5-1.5-1"] {
  padding: 0.25em 0.25em 0.75em 0.5em !important;
}
[class~="zs-margin-0.5-0.5-1.5-1"] {
  margin: 0.25em 0.25em 0.75em 0.5em !important;
}
[class~="zs-padding-0.5-0.5-1.5-1.5"] {
  padding: 0.25em 0.25em 0.75em 0.75em !important;
}
[class~="zs-margin-0.5-0.5-1.5-1.5"] {
  margin: 0.25em 0.25em 0.75em 0.75em !important;
}
[class~="zs-padding-0.5-0.5-1.5-2"] {
  padding: 0.25em 0.25em 0.75em 1em !important;
}
[class~="zs-margin-0.5-0.5-1.5-2"] {
  margin: 0.25em 0.25em 0.75em 1em !important;
}
[class~="zs-padding-0.5-0.5-2-0"] {
  padding: 0.25em 0.25em 1em 0em !important;
}
[class~="zs-margin-0.5-0.5-2-0"] {
  margin: 0.25em 0.25em 1em 0em !important;
}
[class~="zs-padding-0.5-0.5-2-0.5"] {
  padding: 0.25em 0.25em 1em 0.25em !important;
}
[class~="zs-margin-0.5-0.5-2-0.5"] {
  margin: 0.25em 0.25em 1em 0.25em !important;
}
[class~="zs-padding-0.5-0.5-2-1"] {
  padding: 0.25em 0.25em 1em 0.5em !important;
}
[class~="zs-margin-0.5-0.5-2-1"] {
  margin: 0.25em 0.25em 1em 0.5em !important;
}
[class~="zs-padding-0.5-0.5-2-1.5"] {
  padding: 0.25em 0.25em 1em 0.75em !important;
}
[class~="zs-margin-0.5-0.5-2-1.5"] {
  margin: 0.25em 0.25em 1em 0.75em !important;
}
[class~="zs-padding-0.5-0.5-2-2"] {
  padding: 0.25em 0.25em 1em 1em !important;
}
[class~="zs-margin-0.5-0.5-2-2"] {
  margin: 0.25em 0.25em 1em 1em !important;
}
[class~="zs-padding-0.5-1-0-0"] {
  padding: 0.25em 0.5em 0em 0em !important;
}
[class~="zs-margin-0.5-1-0-0"] {
  margin: 0.25em 0.5em 0em 0em !important;
}
[class~="zs-padding-0.5-1-0-0.5"] {
  padding: 0.25em 0.5em 0em 0.25em !important;
}
[class~="zs-margin-0.5-1-0-0.5"] {
  margin: 0.25em 0.5em 0em 0.25em !important;
}
[class~="zs-padding-0.5-1-0-1"] {
  padding: 0.25em 0.5em 0em 0.5em !important;
}
[class~="zs-margin-0.5-1-0-1"] {
  margin: 0.25em 0.5em 0em 0.5em !important;
}
[class~="zs-padding-0.5-1-0-1.5"] {
  padding: 0.25em 0.5em 0em 0.75em !important;
}
[class~="zs-margin-0.5-1-0-1.5"] {
  margin: 0.25em 0.5em 0em 0.75em !important;
}
[class~="zs-padding-0.5-1-0-2"] {
  padding: 0.25em 0.5em 0em 1em !important;
}
[class~="zs-margin-0.5-1-0-2"] {
  margin: 0.25em 0.5em 0em 1em !important;
}
[class~="zs-padding-0.5-1-0.5-0"] {
  padding: 0.25em 0.5em 0.25em 0em !important;
}
[class~="zs-margin-0.5-1-0.5-0"] {
  margin: 0.25em 0.5em 0.25em 0em !important;
}
[class~="zs-padding-0.5-1-0.5-0.5"] {
  padding: 0.25em 0.5em 0.25em 0.25em !important;
}
[class~="zs-margin-0.5-1-0.5-0.5"] {
  margin: 0.25em 0.5em 0.25em 0.25em !important;
}
[class~="zs-padding-0.5-1-0.5-1"] {
  padding: 0.25em 0.5em 0.25em 0.5em !important;
}
[class~="zs-margin-0.5-1-0.5-1"] {
  margin: 0.25em 0.5em 0.25em 0.5em !important;
}
[class~="zs-padding-0.5-1-0.5-1.5"] {
  padding: 0.25em 0.5em 0.25em 0.75em !important;
}
[class~="zs-margin-0.5-1-0.5-1.5"] {
  margin: 0.25em 0.5em 0.25em 0.75em !important;
}
[class~="zs-padding-0.5-1-0.5-2"] {
  padding: 0.25em 0.5em 0.25em 1em !important;
}
[class~="zs-margin-0.5-1-0.5-2"] {
  margin: 0.25em 0.5em 0.25em 1em !important;
}
[class~="zs-padding-0.5-1-1-0"] {
  padding: 0.25em 0.5em 0.5em 0em !important;
}
[class~="zs-margin-0.5-1-1-0"] {
  margin: 0.25em 0.5em 0.5em 0em !important;
}
[class~="zs-padding-0.5-1-1-0.5"] {
  padding: 0.25em 0.5em 0.5em 0.25em !important;
}
[class~="zs-margin-0.5-1-1-0.5"] {
  margin: 0.25em 0.5em 0.5em 0.25em !important;
}
[class~="zs-padding-0.5-1-1-1"] {
  padding: 0.25em 0.5em 0.5em 0.5em !important;
}
[class~="zs-margin-0.5-1-1-1"] {
  margin: 0.25em 0.5em 0.5em 0.5em !important;
}
[class~="zs-padding-0.5-1-1-1.5"] {
  padding: 0.25em 0.5em 0.5em 0.75em !important;
}
[class~="zs-margin-0.5-1-1-1.5"] {
  margin: 0.25em 0.5em 0.5em 0.75em !important;
}
[class~="zs-padding-0.5-1-1-2"] {
  padding: 0.25em 0.5em 0.5em 1em !important;
}
[class~="zs-margin-0.5-1-1-2"] {
  margin: 0.25em 0.5em 0.5em 1em !important;
}
[class~="zs-padding-0.5-1-1.5-0"] {
  padding: 0.25em 0.5em 0.75em 0em !important;
}
[class~="zs-margin-0.5-1-1.5-0"] {
  margin: 0.25em 0.5em 0.75em 0em !important;
}
[class~="zs-padding-0.5-1-1.5-0.5"] {
  padding: 0.25em 0.5em 0.75em 0.25em !important;
}
[class~="zs-margin-0.5-1-1.5-0.5"] {
  margin: 0.25em 0.5em 0.75em 0.25em !important;
}
[class~="zs-padding-0.5-1-1.5-1"] {
  padding: 0.25em 0.5em 0.75em 0.5em !important;
}
[class~="zs-margin-0.5-1-1.5-1"] {
  margin: 0.25em 0.5em 0.75em 0.5em !important;
}
[class~="zs-padding-0.5-1-1.5-1.5"] {
  padding: 0.25em 0.5em 0.75em 0.75em !important;
}
[class~="zs-margin-0.5-1-1.5-1.5"] {
  margin: 0.25em 0.5em 0.75em 0.75em !important;
}
[class~="zs-padding-0.5-1-1.5-2"] {
  padding: 0.25em 0.5em 0.75em 1em !important;
}
[class~="zs-margin-0.5-1-1.5-2"] {
  margin: 0.25em 0.5em 0.75em 1em !important;
}
[class~="zs-padding-0.5-1-2-0"] {
  padding: 0.25em 0.5em 1em 0em !important;
}
[class~="zs-margin-0.5-1-2-0"] {
  margin: 0.25em 0.5em 1em 0em !important;
}
[class~="zs-padding-0.5-1-2-0.5"] {
  padding: 0.25em 0.5em 1em 0.25em !important;
}
[class~="zs-margin-0.5-1-2-0.5"] {
  margin: 0.25em 0.5em 1em 0.25em !important;
}
[class~="zs-padding-0.5-1-2-1"] {
  padding: 0.25em 0.5em 1em 0.5em !important;
}
[class~="zs-margin-0.5-1-2-1"] {
  margin: 0.25em 0.5em 1em 0.5em !important;
}
[class~="zs-padding-0.5-1-2-1.5"] {
  padding: 0.25em 0.5em 1em 0.75em !important;
}
[class~="zs-margin-0.5-1-2-1.5"] {
  margin: 0.25em 0.5em 1em 0.75em !important;
}
[class~="zs-padding-0.5-1-2-2"] {
  padding: 0.25em 0.5em 1em 1em !important;
}
[class~="zs-margin-0.5-1-2-2"] {
  margin: 0.25em 0.5em 1em 1em !important;
}
[class~="zs-padding-0.5-1.5-0-0"] {
  padding: 0.25em 0.75em 0em 0em !important;
}
[class~="zs-margin-0.5-1.5-0-0"] {
  margin: 0.25em 0.75em 0em 0em !important;
}
[class~="zs-padding-0.5-1.5-0-0.5"] {
  padding: 0.25em 0.75em 0em 0.25em !important;
}
[class~="zs-margin-0.5-1.5-0-0.5"] {
  margin: 0.25em 0.75em 0em 0.25em !important;
}
[class~="zs-padding-0.5-1.5-0-1"] {
  padding: 0.25em 0.75em 0em 0.5em !important;
}
[class~="zs-margin-0.5-1.5-0-1"] {
  margin: 0.25em 0.75em 0em 0.5em !important;
}
[class~="zs-padding-0.5-1.5-0-1.5"] {
  padding: 0.25em 0.75em 0em 0.75em !important;
}
[class~="zs-margin-0.5-1.5-0-1.5"] {
  margin: 0.25em 0.75em 0em 0.75em !important;
}
[class~="zs-padding-0.5-1.5-0-2"] {
  padding: 0.25em 0.75em 0em 1em !important;
}
[class~="zs-margin-0.5-1.5-0-2"] {
  margin: 0.25em 0.75em 0em 1em !important;
}
[class~="zs-padding-0.5-1.5-0.5-0"] {
  padding: 0.25em 0.75em 0.25em 0em !important;
}
[class~="zs-margin-0.5-1.5-0.5-0"] {
  margin: 0.25em 0.75em 0.25em 0em !important;
}
[class~="zs-padding-0.5-1.5-0.5-0.5"] {
  padding: 0.25em 0.75em 0.25em 0.25em !important;
}
[class~="zs-margin-0.5-1.5-0.5-0.5"] {
  margin: 0.25em 0.75em 0.25em 0.25em !important;
}
[class~="zs-padding-0.5-1.5-0.5-1"] {
  padding: 0.25em 0.75em 0.25em 0.5em !important;
}
[class~="zs-margin-0.5-1.5-0.5-1"] {
  margin: 0.25em 0.75em 0.25em 0.5em !important;
}
[class~="zs-padding-0.5-1.5-0.5-1.5"] {
  padding: 0.25em 0.75em 0.25em 0.75em !important;
}
[class~="zs-margin-0.5-1.5-0.5-1.5"] {
  margin: 0.25em 0.75em 0.25em 0.75em !important;
}
[class~="zs-padding-0.5-1.5-0.5-2"] {
  padding: 0.25em 0.75em 0.25em 1em !important;
}
[class~="zs-margin-0.5-1.5-0.5-2"] {
  margin: 0.25em 0.75em 0.25em 1em !important;
}
[class~="zs-padding-0.5-1.5-1-0"] {
  padding: 0.25em 0.75em 0.5em 0em !important;
}
[class~="zs-margin-0.5-1.5-1-0"] {
  margin: 0.25em 0.75em 0.5em 0em !important;
}
[class~="zs-padding-0.5-1.5-1-0.5"] {
  padding: 0.25em 0.75em 0.5em 0.25em !important;
}
[class~="zs-margin-0.5-1.5-1-0.5"] {
  margin: 0.25em 0.75em 0.5em 0.25em !important;
}
[class~="zs-padding-0.5-1.5-1-1"] {
  padding: 0.25em 0.75em 0.5em 0.5em !important;
}
[class~="zs-margin-0.5-1.5-1-1"] {
  margin: 0.25em 0.75em 0.5em 0.5em !important;
}
[class~="zs-padding-0.5-1.5-1-1.5"] {
  padding: 0.25em 0.75em 0.5em 0.75em !important;
}
[class~="zs-margin-0.5-1.5-1-1.5"] {
  margin: 0.25em 0.75em 0.5em 0.75em !important;
}
[class~="zs-padding-0.5-1.5-1-2"] {
  padding: 0.25em 0.75em 0.5em 1em !important;
}
[class~="zs-margin-0.5-1.5-1-2"] {
  margin: 0.25em 0.75em 0.5em 1em !important;
}
[class~="zs-padding-0.5-1.5-1.5-0"] {
  padding: 0.25em 0.75em 0.75em 0em !important;
}
[class~="zs-margin-0.5-1.5-1.5-0"] {
  margin: 0.25em 0.75em 0.75em 0em !important;
}
[class~="zs-padding-0.5-1.5-1.5-0.5"] {
  padding: 0.25em 0.75em 0.75em 0.25em !important;
}
[class~="zs-margin-0.5-1.5-1.5-0.5"] {
  margin: 0.25em 0.75em 0.75em 0.25em !important;
}
[class~="zs-padding-0.5-1.5-1.5-1"] {
  padding: 0.25em 0.75em 0.75em 0.5em !important;
}
[class~="zs-margin-0.5-1.5-1.5-1"] {
  margin: 0.25em 0.75em 0.75em 0.5em !important;
}
[class~="zs-padding-0.5-1.5-1.5-1.5"] {
  padding: 0.25em 0.75em 0.75em 0.75em !important;
}
[class~="zs-margin-0.5-1.5-1.5-1.5"] {
  margin: 0.25em 0.75em 0.75em 0.75em !important;
}
[class~="zs-padding-0.5-1.5-1.5-2"] {
  padding: 0.25em 0.75em 0.75em 1em !important;
}
[class~="zs-margin-0.5-1.5-1.5-2"] {
  margin: 0.25em 0.75em 0.75em 1em !important;
}
[class~="zs-padding-0.5-1.5-2-0"] {
  padding: 0.25em 0.75em 1em 0em !important;
}
[class~="zs-margin-0.5-1.5-2-0"] {
  margin: 0.25em 0.75em 1em 0em !important;
}
[class~="zs-padding-0.5-1.5-2-0.5"] {
  padding: 0.25em 0.75em 1em 0.25em !important;
}
[class~="zs-margin-0.5-1.5-2-0.5"] {
  margin: 0.25em 0.75em 1em 0.25em !important;
}
[class~="zs-padding-0.5-1.5-2-1"] {
  padding: 0.25em 0.75em 1em 0.5em !important;
}
[class~="zs-margin-0.5-1.5-2-1"] {
  margin: 0.25em 0.75em 1em 0.5em !important;
}
[class~="zs-padding-0.5-1.5-2-1.5"] {
  padding: 0.25em 0.75em 1em 0.75em !important;
}
[class~="zs-margin-0.5-1.5-2-1.5"] {
  margin: 0.25em 0.75em 1em 0.75em !important;
}
[class~="zs-padding-0.5-1.5-2-2"] {
  padding: 0.25em 0.75em 1em 1em !important;
}
[class~="zs-margin-0.5-1.5-2-2"] {
  margin: 0.25em 0.75em 1em 1em !important;
}
[class~="zs-padding-0.5-2-0-0"] {
  padding: 0.25em 1em 0em 0em !important;
}
[class~="zs-margin-0.5-2-0-0"] {
  margin: 0.25em 1em 0em 0em !important;
}
[class~="zs-padding-0.5-2-0-0.5"] {
  padding: 0.25em 1em 0em 0.25em !important;
}
[class~="zs-margin-0.5-2-0-0.5"] {
  margin: 0.25em 1em 0em 0.25em !important;
}
[class~="zs-padding-0.5-2-0-1"] {
  padding: 0.25em 1em 0em 0.5em !important;
}
[class~="zs-margin-0.5-2-0-1"] {
  margin: 0.25em 1em 0em 0.5em !important;
}
[class~="zs-padding-0.5-2-0-1.5"] {
  padding: 0.25em 1em 0em 0.75em !important;
}
[class~="zs-margin-0.5-2-0-1.5"] {
  margin: 0.25em 1em 0em 0.75em !important;
}
[class~="zs-padding-0.5-2-0-2"] {
  padding: 0.25em 1em 0em 1em !important;
}
[class~="zs-margin-0.5-2-0-2"] {
  margin: 0.25em 1em 0em 1em !important;
}
[class~="zs-padding-0.5-2-0.5-0"] {
  padding: 0.25em 1em 0.25em 0em !important;
}
[class~="zs-margin-0.5-2-0.5-0"] {
  margin: 0.25em 1em 0.25em 0em !important;
}
[class~="zs-padding-0.5-2-0.5-0.5"] {
  padding: 0.25em 1em 0.25em 0.25em !important;
}
[class~="zs-margin-0.5-2-0.5-0.5"] {
  margin: 0.25em 1em 0.25em 0.25em !important;
}
[class~="zs-padding-0.5-2-0.5-1"] {
  padding: 0.25em 1em 0.25em 0.5em !important;
}
[class~="zs-margin-0.5-2-0.5-1"] {
  margin: 0.25em 1em 0.25em 0.5em !important;
}
[class~="zs-padding-0.5-2-0.5-1.5"] {
  padding: 0.25em 1em 0.25em 0.75em !important;
}
[class~="zs-margin-0.5-2-0.5-1.5"] {
  margin: 0.25em 1em 0.25em 0.75em !important;
}
[class~="zs-padding-0.5-2-0.5-2"] {
  padding: 0.25em 1em 0.25em 1em !important;
}
[class~="zs-margin-0.5-2-0.5-2"] {
  margin: 0.25em 1em 0.25em 1em !important;
}
[class~="zs-padding-0.5-2-1-0"] {
  padding: 0.25em 1em 0.5em 0em !important;
}
[class~="zs-margin-0.5-2-1-0"] {
  margin: 0.25em 1em 0.5em 0em !important;
}
[class~="zs-padding-0.5-2-1-0.5"] {
  padding: 0.25em 1em 0.5em 0.25em !important;
}
[class~="zs-margin-0.5-2-1-0.5"] {
  margin: 0.25em 1em 0.5em 0.25em !important;
}
[class~="zs-padding-0.5-2-1-1"] {
  padding: 0.25em 1em 0.5em 0.5em !important;
}
[class~="zs-margin-0.5-2-1-1"] {
  margin: 0.25em 1em 0.5em 0.5em !important;
}
[class~="zs-padding-0.5-2-1-1.5"] {
  padding: 0.25em 1em 0.5em 0.75em !important;
}
[class~="zs-margin-0.5-2-1-1.5"] {
  margin: 0.25em 1em 0.5em 0.75em !important;
}
[class~="zs-padding-0.5-2-1-2"] {
  padding: 0.25em 1em 0.5em 1em !important;
}
[class~="zs-margin-0.5-2-1-2"] {
  margin: 0.25em 1em 0.5em 1em !important;
}
[class~="zs-padding-0.5-2-1.5-0"] {
  padding: 0.25em 1em 0.75em 0em !important;
}
[class~="zs-margin-0.5-2-1.5-0"] {
  margin: 0.25em 1em 0.75em 0em !important;
}
[class~="zs-padding-0.5-2-1.5-0.5"] {
  padding: 0.25em 1em 0.75em 0.25em !important;
}
[class~="zs-margin-0.5-2-1.5-0.5"] {
  margin: 0.25em 1em 0.75em 0.25em !important;
}
[class~="zs-padding-0.5-2-1.5-1"] {
  padding: 0.25em 1em 0.75em 0.5em !important;
}
[class~="zs-margin-0.5-2-1.5-1"] {
  margin: 0.25em 1em 0.75em 0.5em !important;
}
[class~="zs-padding-0.5-2-1.5-1.5"] {
  padding: 0.25em 1em 0.75em 0.75em !important;
}
[class~="zs-margin-0.5-2-1.5-1.5"] {
  margin: 0.25em 1em 0.75em 0.75em !important;
}
[class~="zs-padding-0.5-2-1.5-2"] {
  padding: 0.25em 1em 0.75em 1em !important;
}
[class~="zs-margin-0.5-2-1.5-2"] {
  margin: 0.25em 1em 0.75em 1em !important;
}
[class~="zs-padding-0.5-2-2-0"] {
  padding: 0.25em 1em 1em 0em !important;
}
[class~="zs-margin-0.5-2-2-0"] {
  margin: 0.25em 1em 1em 0em !important;
}
[class~="zs-padding-0.5-2-2-0.5"] {
  padding: 0.25em 1em 1em 0.25em !important;
}
[class~="zs-margin-0.5-2-2-0.5"] {
  margin: 0.25em 1em 1em 0.25em !important;
}
[class~="zs-padding-0.5-2-2-1"] {
  padding: 0.25em 1em 1em 0.5em !important;
}
[class~="zs-margin-0.5-2-2-1"] {
  margin: 0.25em 1em 1em 0.5em !important;
}
[class~="zs-padding-0.5-2-2-1.5"] {
  padding: 0.25em 1em 1em 0.75em !important;
}
[class~="zs-margin-0.5-2-2-1.5"] {
  margin: 0.25em 1em 1em 0.75em !important;
}
[class~="zs-padding-0.5-2-2-2"] {
  padding: 0.25em 1em 1em 1em !important;
}
[class~="zs-margin-0.5-2-2-2"] {
  margin: 0.25em 1em 1em 1em !important;
}
[class~="zs-padding-1-0-0-0"] {
  padding: 0.5em 0em 0em 0em !important;
}
[class~="zs-margin-1-0-0-0"] {
  margin: 0.5em 0em 0em 0em !important;
}
[class~="zs-padding-1-0-0-0.5"] {
  padding: 0.5em 0em 0em 0.25em !important;
}
[class~="zs-margin-1-0-0-0.5"] {
  margin: 0.5em 0em 0em 0.25em !important;
}
[class~="zs-padding-1-0-0-1"] {
  padding: 0.5em 0em 0em 0.5em !important;
}
[class~="zs-margin-1-0-0-1"] {
  margin: 0.5em 0em 0em 0.5em !important;
}
[class~="zs-padding-1-0-0-1.5"] {
  padding: 0.5em 0em 0em 0.75em !important;
}
[class~="zs-margin-1-0-0-1.5"] {
  margin: 0.5em 0em 0em 0.75em !important;
}
[class~="zs-padding-1-0-0-2"] {
  padding: 0.5em 0em 0em 1em !important;
}
[class~="zs-margin-1-0-0-2"] {
  margin: 0.5em 0em 0em 1em !important;
}
[class~="zs-padding-1-0-0.5-0"] {
  padding: 0.5em 0em 0.25em 0em !important;
}
[class~="zs-margin-1-0-0.5-0"] {
  margin: 0.5em 0em 0.25em 0em !important;
}
[class~="zs-padding-1-0-0.5-0.5"] {
  padding: 0.5em 0em 0.25em 0.25em !important;
}
[class~="zs-margin-1-0-0.5-0.5"] {
  margin: 0.5em 0em 0.25em 0.25em !important;
}
[class~="zs-padding-1-0-0.5-1"] {
  padding: 0.5em 0em 0.25em 0.5em !important;
}
[class~="zs-margin-1-0-0.5-1"] {
  margin: 0.5em 0em 0.25em 0.5em !important;
}
[class~="zs-padding-1-0-0.5-1.5"] {
  padding: 0.5em 0em 0.25em 0.75em !important;
}
[class~="zs-margin-1-0-0.5-1.5"] {
  margin: 0.5em 0em 0.25em 0.75em !important;
}
[class~="zs-padding-1-0-0.5-2"] {
  padding: 0.5em 0em 0.25em 1em !important;
}
[class~="zs-margin-1-0-0.5-2"] {
  margin: 0.5em 0em 0.25em 1em !important;
}
[class~="zs-padding-1-0-1-0"] {
  padding: 0.5em 0em 0.5em 0em !important;
}
[class~="zs-margin-1-0-1-0"] {
  margin: 0.5em 0em 0.5em 0em !important;
}
[class~="zs-padding-1-0-1-0.5"] {
  padding: 0.5em 0em 0.5em 0.25em !important;
}
[class~="zs-margin-1-0-1-0.5"] {
  margin: 0.5em 0em 0.5em 0.25em !important;
}
[class~="zs-padding-1-0-1-1"] {
  padding: 0.5em 0em 0.5em 0.5em !important;
}
[class~="zs-margin-1-0-1-1"] {
  margin: 0.5em 0em 0.5em 0.5em !important;
}
[class~="zs-padding-1-0-1-1.5"] {
  padding: 0.5em 0em 0.5em 0.75em !important;
}
[class~="zs-margin-1-0-1-1.5"] {
  margin: 0.5em 0em 0.5em 0.75em !important;
}
[class~="zs-padding-1-0-1-2"] {
  padding: 0.5em 0em 0.5em 1em !important;
}
[class~="zs-margin-1-0-1-2"] {
  margin: 0.5em 0em 0.5em 1em !important;
}
[class~="zs-padding-1-0-1.5-0"] {
  padding: 0.5em 0em 0.75em 0em !important;
}
[class~="zs-margin-1-0-1.5-0"] {
  margin: 0.5em 0em 0.75em 0em !important;
}
[class~="zs-padding-1-0-1.5-0.5"] {
  padding: 0.5em 0em 0.75em 0.25em !important;
}
[class~="zs-margin-1-0-1.5-0.5"] {
  margin: 0.5em 0em 0.75em 0.25em !important;
}
[class~="zs-padding-1-0-1.5-1"] {
  padding: 0.5em 0em 0.75em 0.5em !important;
}
[class~="zs-margin-1-0-1.5-1"] {
  margin: 0.5em 0em 0.75em 0.5em !important;
}
[class~="zs-padding-1-0-1.5-1.5"] {
  padding: 0.5em 0em 0.75em 0.75em !important;
}
[class~="zs-margin-1-0-1.5-1.5"] {
  margin: 0.5em 0em 0.75em 0.75em !important;
}
[class~="zs-padding-1-0-1.5-2"] {
  padding: 0.5em 0em 0.75em 1em !important;
}
[class~="zs-margin-1-0-1.5-2"] {
  margin: 0.5em 0em 0.75em 1em !important;
}
[class~="zs-padding-1-0-2-0"] {
  padding: 0.5em 0em 1em 0em !important;
}
[class~="zs-margin-1-0-2-0"] {
  margin: 0.5em 0em 1em 0em !important;
}
[class~="zs-padding-1-0-2-0.5"] {
  padding: 0.5em 0em 1em 0.25em !important;
}
[class~="zs-margin-1-0-2-0.5"] {
  margin: 0.5em 0em 1em 0.25em !important;
}
[class~="zs-padding-1-0-2-1"] {
  padding: 0.5em 0em 1em 0.5em !important;
}
[class~="zs-margin-1-0-2-1"] {
  margin: 0.5em 0em 1em 0.5em !important;
}
[class~="zs-padding-1-0-2-1.5"] {
  padding: 0.5em 0em 1em 0.75em !important;
}
[class~="zs-margin-1-0-2-1.5"] {
  margin: 0.5em 0em 1em 0.75em !important;
}
[class~="zs-padding-1-0-2-2"] {
  padding: 0.5em 0em 1em 1em !important;
}
[class~="zs-margin-1-0-2-2"] {
  margin: 0.5em 0em 1em 1em !important;
}
[class~="zs-padding-1-0.5-0-0"] {
  padding: 0.5em 0.25em 0em 0em !important;
}
[class~="zs-margin-1-0.5-0-0"] {
  margin: 0.5em 0.25em 0em 0em !important;
}
[class~="zs-padding-1-0.5-0-0.5"] {
  padding: 0.5em 0.25em 0em 0.25em !important;
}
[class~="zs-margin-1-0.5-0-0.5"] {
  margin: 0.5em 0.25em 0em 0.25em !important;
}
[class~="zs-padding-1-0.5-0-1"] {
  padding: 0.5em 0.25em 0em 0.5em !important;
}
[class~="zs-margin-1-0.5-0-1"] {
  margin: 0.5em 0.25em 0em 0.5em !important;
}
[class~="zs-padding-1-0.5-0-1.5"] {
  padding: 0.5em 0.25em 0em 0.75em !important;
}
[class~="zs-margin-1-0.5-0-1.5"] {
  margin: 0.5em 0.25em 0em 0.75em !important;
}
[class~="zs-padding-1-0.5-0-2"] {
  padding: 0.5em 0.25em 0em 1em !important;
}
[class~="zs-margin-1-0.5-0-2"] {
  margin: 0.5em 0.25em 0em 1em !important;
}
[class~="zs-padding-1-0.5-0.5-0"] {
  padding: 0.5em 0.25em 0.25em 0em !important;
}
[class~="zs-margin-1-0.5-0.5-0"] {
  margin: 0.5em 0.25em 0.25em 0em !important;
}
[class~="zs-padding-1-0.5-0.5-0.5"] {
  padding: 0.5em 0.25em 0.25em 0.25em !important;
}
[class~="zs-margin-1-0.5-0.5-0.5"] {
  margin: 0.5em 0.25em 0.25em 0.25em !important;
}
[class~="zs-padding-1-0.5-0.5-1"] {
  padding: 0.5em 0.25em 0.25em 0.5em !important;
}
[class~="zs-margin-1-0.5-0.5-1"] {
  margin: 0.5em 0.25em 0.25em 0.5em !important;
}
[class~="zs-padding-1-0.5-0.5-1.5"] {
  padding: 0.5em 0.25em 0.25em 0.75em !important;
}
[class~="zs-margin-1-0.5-0.5-1.5"] {
  margin: 0.5em 0.25em 0.25em 0.75em !important;
}
[class~="zs-padding-1-0.5-0.5-2"] {
  padding: 0.5em 0.25em 0.25em 1em !important;
}
[class~="zs-margin-1-0.5-0.5-2"] {
  margin: 0.5em 0.25em 0.25em 1em !important;
}
[class~="zs-padding-1-0.5-1-0"] {
  padding: 0.5em 0.25em 0.5em 0em !important;
}
[class~="zs-margin-1-0.5-1-0"] {
  margin: 0.5em 0.25em 0.5em 0em !important;
}
[class~="zs-padding-1-0.5-1-0.5"] {
  padding: 0.5em 0.25em 0.5em 0.25em !important;
}
[class~="zs-margin-1-0.5-1-0.5"] {
  margin: 0.5em 0.25em 0.5em 0.25em !important;
}
[class~="zs-padding-1-0.5-1-1"] {
  padding: 0.5em 0.25em 0.5em 0.5em !important;
}
[class~="zs-margin-1-0.5-1-1"] {
  margin: 0.5em 0.25em 0.5em 0.5em !important;
}
[class~="zs-padding-1-0.5-1-1.5"] {
  padding: 0.5em 0.25em 0.5em 0.75em !important;
}
[class~="zs-margin-1-0.5-1-1.5"] {
  margin: 0.5em 0.25em 0.5em 0.75em !important;
}
[class~="zs-padding-1-0.5-1-2"] {
  padding: 0.5em 0.25em 0.5em 1em !important;
}
[class~="zs-margin-1-0.5-1-2"] {
  margin: 0.5em 0.25em 0.5em 1em !important;
}
[class~="zs-padding-1-0.5-1.5-0"] {
  padding: 0.5em 0.25em 0.75em 0em !important;
}
[class~="zs-margin-1-0.5-1.5-0"] {
  margin: 0.5em 0.25em 0.75em 0em !important;
}
[class~="zs-padding-1-0.5-1.5-0.5"] {
  padding: 0.5em 0.25em 0.75em 0.25em !important;
}
[class~="zs-margin-1-0.5-1.5-0.5"] {
  margin: 0.5em 0.25em 0.75em 0.25em !important;
}
[class~="zs-padding-1-0.5-1.5-1"] {
  padding: 0.5em 0.25em 0.75em 0.5em !important;
}
[class~="zs-margin-1-0.5-1.5-1"] {
  margin: 0.5em 0.25em 0.75em 0.5em !important;
}
[class~="zs-padding-1-0.5-1.5-1.5"] {
  padding: 0.5em 0.25em 0.75em 0.75em !important;
}
[class~="zs-margin-1-0.5-1.5-1.5"] {
  margin: 0.5em 0.25em 0.75em 0.75em !important;
}
[class~="zs-padding-1-0.5-1.5-2"] {
  padding: 0.5em 0.25em 0.75em 1em !important;
}
[class~="zs-margin-1-0.5-1.5-2"] {
  margin: 0.5em 0.25em 0.75em 1em !important;
}
[class~="zs-padding-1-0.5-2-0"] {
  padding: 0.5em 0.25em 1em 0em !important;
}
[class~="zs-margin-1-0.5-2-0"] {
  margin: 0.5em 0.25em 1em 0em !important;
}
[class~="zs-padding-1-0.5-2-0.5"] {
  padding: 0.5em 0.25em 1em 0.25em !important;
}
[class~="zs-margin-1-0.5-2-0.5"] {
  margin: 0.5em 0.25em 1em 0.25em !important;
}
[class~="zs-padding-1-0.5-2-1"] {
  padding: 0.5em 0.25em 1em 0.5em !important;
}
[class~="zs-margin-1-0.5-2-1"] {
  margin: 0.5em 0.25em 1em 0.5em !important;
}
[class~="zs-padding-1-0.5-2-1.5"] {
  padding: 0.5em 0.25em 1em 0.75em !important;
}
[class~="zs-margin-1-0.5-2-1.5"] {
  margin: 0.5em 0.25em 1em 0.75em !important;
}
[class~="zs-padding-1-0.5-2-2"] {
  padding: 0.5em 0.25em 1em 1em !important;
}
[class~="zs-margin-1-0.5-2-2"] {
  margin: 0.5em 0.25em 1em 1em !important;
}
[class~="zs-padding-1-1-0-0"] {
  padding: 0.5em 0.5em 0em 0em !important;
}
[class~="zs-margin-1-1-0-0"] {
  margin: 0.5em 0.5em 0em 0em !important;
}
[class~="zs-padding-1-1-0-0.5"] {
  padding: 0.5em 0.5em 0em 0.25em !important;
}
[class~="zs-margin-1-1-0-0.5"] {
  margin: 0.5em 0.5em 0em 0.25em !important;
}
[class~="zs-padding-1-1-0-1"] {
  padding: 0.5em 0.5em 0em 0.5em !important;
}
[class~="zs-margin-1-1-0-1"] {
  margin: 0.5em 0.5em 0em 0.5em !important;
}
[class~="zs-padding-1-1-0-1.5"] {
  padding: 0.5em 0.5em 0em 0.75em !important;
}
[class~="zs-margin-1-1-0-1.5"] {
  margin: 0.5em 0.5em 0em 0.75em !important;
}
[class~="zs-padding-1-1-0-2"] {
  padding: 0.5em 0.5em 0em 1em !important;
}
[class~="zs-margin-1-1-0-2"] {
  margin: 0.5em 0.5em 0em 1em !important;
}
[class~="zs-padding-1-1-0.5-0"] {
  padding: 0.5em 0.5em 0.25em 0em !important;
}
[class~="zs-margin-1-1-0.5-0"] {
  margin: 0.5em 0.5em 0.25em 0em !important;
}
[class~="zs-padding-1-1-0.5-0.5"] {
  padding: 0.5em 0.5em 0.25em 0.25em !important;
}
[class~="zs-margin-1-1-0.5-0.5"] {
  margin: 0.5em 0.5em 0.25em 0.25em !important;
}
[class~="zs-padding-1-1-0.5-1"] {
  padding: 0.5em 0.5em 0.25em 0.5em !important;
}
[class~="zs-margin-1-1-0.5-1"] {
  margin: 0.5em 0.5em 0.25em 0.5em !important;
}
[class~="zs-padding-1-1-0.5-1.5"] {
  padding: 0.5em 0.5em 0.25em 0.75em !important;
}
[class~="zs-margin-1-1-0.5-1.5"] {
  margin: 0.5em 0.5em 0.25em 0.75em !important;
}
[class~="zs-padding-1-1-0.5-2"] {
  padding: 0.5em 0.5em 0.25em 1em !important;
}
[class~="zs-margin-1-1-0.5-2"] {
  margin: 0.5em 0.5em 0.25em 1em !important;
}
[class~="zs-padding-1-1-1-0"] {
  padding: 0.5em 0.5em 0.5em 0em !important;
}
[class~="zs-margin-1-1-1-0"] {
  margin: 0.5em 0.5em 0.5em 0em !important;
}
[class~="zs-padding-1-1-1-0.5"] {
  padding: 0.5em 0.5em 0.5em 0.25em !important;
}
[class~="zs-margin-1-1-1-0.5"] {
  margin: 0.5em 0.5em 0.5em 0.25em !important;
}
[class~="zs-padding-1-1-1-1"] {
  padding: 0.5em 0.5em 0.5em 0.5em !important;
}
[class~="zs-margin-1-1-1-1"] {
  margin: 0.5em 0.5em 0.5em 0.5em !important;
}
[class~="zs-padding-1-1-1-1.5"] {
  padding: 0.5em 0.5em 0.5em 0.75em !important;
}
[class~="zs-margin-1-1-1-1.5"] {
  margin: 0.5em 0.5em 0.5em 0.75em !important;
}
[class~="zs-padding-1-1-1-2"] {
  padding: 0.5em 0.5em 0.5em 1em !important;
}
[class~="zs-margin-1-1-1-2"] {
  margin: 0.5em 0.5em 0.5em 1em !important;
}
[class~="zs-padding-1-1-1.5-0"] {
  padding: 0.5em 0.5em 0.75em 0em !important;
}
[class~="zs-margin-1-1-1.5-0"] {
  margin: 0.5em 0.5em 0.75em 0em !important;
}
[class~="zs-padding-1-1-1.5-0.5"] {
  padding: 0.5em 0.5em 0.75em 0.25em !important;
}
[class~="zs-margin-1-1-1.5-0.5"] {
  margin: 0.5em 0.5em 0.75em 0.25em !important;
}
[class~="zs-padding-1-1-1.5-1"] {
  padding: 0.5em 0.5em 0.75em 0.5em !important;
}
[class~="zs-margin-1-1-1.5-1"] {
  margin: 0.5em 0.5em 0.75em 0.5em !important;
}
[class~="zs-padding-1-1-1.5-1.5"] {
  padding: 0.5em 0.5em 0.75em 0.75em !important;
}
[class~="zs-margin-1-1-1.5-1.5"] {
  margin: 0.5em 0.5em 0.75em 0.75em !important;
}
[class~="zs-padding-1-1-1.5-2"] {
  padding: 0.5em 0.5em 0.75em 1em !important;
}
[class~="zs-margin-1-1-1.5-2"] {
  margin: 0.5em 0.5em 0.75em 1em !important;
}
[class~="zs-padding-1-1-2-0"] {
  padding: 0.5em 0.5em 1em 0em !important;
}
[class~="zs-margin-1-1-2-0"] {
  margin: 0.5em 0.5em 1em 0em !important;
}
[class~="zs-padding-1-1-2-0.5"] {
  padding: 0.5em 0.5em 1em 0.25em !important;
}
[class~="zs-margin-1-1-2-0.5"] {
  margin: 0.5em 0.5em 1em 0.25em !important;
}
[class~="zs-padding-1-1-2-1"] {
  padding: 0.5em 0.5em 1em 0.5em !important;
}
[class~="zs-margin-1-1-2-1"] {
  margin: 0.5em 0.5em 1em 0.5em !important;
}
[class~="zs-padding-1-1-2-1.5"] {
  padding: 0.5em 0.5em 1em 0.75em !important;
}
[class~="zs-margin-1-1-2-1.5"] {
  margin: 0.5em 0.5em 1em 0.75em !important;
}
[class~="zs-padding-1-1-2-2"] {
  padding: 0.5em 0.5em 1em 1em !important;
}
[class~="zs-margin-1-1-2-2"] {
  margin: 0.5em 0.5em 1em 1em !important;
}
[class~="zs-padding-1-1.5-0-0"] {
  padding: 0.5em 0.75em 0em 0em !important;
}
[class~="zs-margin-1-1.5-0-0"] {
  margin: 0.5em 0.75em 0em 0em !important;
}
[class~="zs-padding-1-1.5-0-0.5"] {
  padding: 0.5em 0.75em 0em 0.25em !important;
}
[class~="zs-margin-1-1.5-0-0.5"] {
  margin: 0.5em 0.75em 0em 0.25em !important;
}
[class~="zs-padding-1-1.5-0-1"] {
  padding: 0.5em 0.75em 0em 0.5em !important;
}
[class~="zs-margin-1-1.5-0-1"] {
  margin: 0.5em 0.75em 0em 0.5em !important;
}
[class~="zs-padding-1-1.5-0-1.5"] {
  padding: 0.5em 0.75em 0em 0.75em !important;
}
[class~="zs-margin-1-1.5-0-1.5"] {
  margin: 0.5em 0.75em 0em 0.75em !important;
}
[class~="zs-padding-1-1.5-0-2"] {
  padding: 0.5em 0.75em 0em 1em !important;
}
[class~="zs-margin-1-1.5-0-2"] {
  margin: 0.5em 0.75em 0em 1em !important;
}
[class~="zs-padding-1-1.5-0.5-0"] {
  padding: 0.5em 0.75em 0.25em 0em !important;
}
[class~="zs-margin-1-1.5-0.5-0"] {
  margin: 0.5em 0.75em 0.25em 0em !important;
}
[class~="zs-padding-1-1.5-0.5-0.5"] {
  padding: 0.5em 0.75em 0.25em 0.25em !important;
}
[class~="zs-margin-1-1.5-0.5-0.5"] {
  margin: 0.5em 0.75em 0.25em 0.25em !important;
}
[class~="zs-padding-1-1.5-0.5-1"] {
  padding: 0.5em 0.75em 0.25em 0.5em !important;
}
[class~="zs-margin-1-1.5-0.5-1"] {
  margin: 0.5em 0.75em 0.25em 0.5em !important;
}
[class~="zs-padding-1-1.5-0.5-1.5"] {
  padding: 0.5em 0.75em 0.25em 0.75em !important;
}
[class~="zs-margin-1-1.5-0.5-1.5"] {
  margin: 0.5em 0.75em 0.25em 0.75em !important;
}
[class~="zs-padding-1-1.5-0.5-2"] {
  padding: 0.5em 0.75em 0.25em 1em !important;
}
[class~="zs-margin-1-1.5-0.5-2"] {
  margin: 0.5em 0.75em 0.25em 1em !important;
}
[class~="zs-padding-1-1.5-1-0"] {
  padding: 0.5em 0.75em 0.5em 0em !important;
}
[class~="zs-margin-1-1.5-1-0"] {
  margin: 0.5em 0.75em 0.5em 0em !important;
}
[class~="zs-padding-1-1.5-1-0.5"] {
  padding: 0.5em 0.75em 0.5em 0.25em !important;
}
[class~="zs-margin-1-1.5-1-0.5"] {
  margin: 0.5em 0.75em 0.5em 0.25em !important;
}
[class~="zs-padding-1-1.5-1-1"] {
  padding: 0.5em 0.75em 0.5em 0.5em !important;
}
[class~="zs-margin-1-1.5-1-1"] {
  margin: 0.5em 0.75em 0.5em 0.5em !important;
}
[class~="zs-padding-1-1.5-1-1.5"] {
  padding: 0.5em 0.75em 0.5em 0.75em !important;
}
[class~="zs-margin-1-1.5-1-1.5"] {
  margin: 0.5em 0.75em 0.5em 0.75em !important;
}
[class~="zs-padding-1-1.5-1-2"] {
  padding: 0.5em 0.75em 0.5em 1em !important;
}
[class~="zs-margin-1-1.5-1-2"] {
  margin: 0.5em 0.75em 0.5em 1em !important;
}
[class~="zs-padding-1-1.5-1.5-0"] {
  padding: 0.5em 0.75em 0.75em 0em !important;
}
[class~="zs-margin-1-1.5-1.5-0"] {
  margin: 0.5em 0.75em 0.75em 0em !important;
}
[class~="zs-padding-1-1.5-1.5-0.5"] {
  padding: 0.5em 0.75em 0.75em 0.25em !important;
}
[class~="zs-margin-1-1.5-1.5-0.5"] {
  margin: 0.5em 0.75em 0.75em 0.25em !important;
}
[class~="zs-padding-1-1.5-1.5-1"] {
  padding: 0.5em 0.75em 0.75em 0.5em !important;
}
[class~="zs-margin-1-1.5-1.5-1"] {
  margin: 0.5em 0.75em 0.75em 0.5em !important;
}
[class~="zs-padding-1-1.5-1.5-1.5"] {
  padding: 0.5em 0.75em 0.75em 0.75em !important;
}
[class~="zs-margin-1-1.5-1.5-1.5"] {
  margin: 0.5em 0.75em 0.75em 0.75em !important;
}
[class~="zs-padding-1-1.5-1.5-2"] {
  padding: 0.5em 0.75em 0.75em 1em !important;
}
[class~="zs-margin-1-1.5-1.5-2"] {
  margin: 0.5em 0.75em 0.75em 1em !important;
}
[class~="zs-padding-1-1.5-2-0"] {
  padding: 0.5em 0.75em 1em 0em !important;
}
[class~="zs-margin-1-1.5-2-0"] {
  margin: 0.5em 0.75em 1em 0em !important;
}
[class~="zs-padding-1-1.5-2-0.5"] {
  padding: 0.5em 0.75em 1em 0.25em !important;
}
[class~="zs-margin-1-1.5-2-0.5"] {
  margin: 0.5em 0.75em 1em 0.25em !important;
}
[class~="zs-padding-1-1.5-2-1"] {
  padding: 0.5em 0.75em 1em 0.5em !important;
}
[class~="zs-margin-1-1.5-2-1"] {
  margin: 0.5em 0.75em 1em 0.5em !important;
}
[class~="zs-padding-1-1.5-2-1.5"] {
  padding: 0.5em 0.75em 1em 0.75em !important;
}
[class~="zs-margin-1-1.5-2-1.5"] {
  margin: 0.5em 0.75em 1em 0.75em !important;
}
[class~="zs-padding-1-1.5-2-2"] {
  padding: 0.5em 0.75em 1em 1em !important;
}
[class~="zs-margin-1-1.5-2-2"] {
  margin: 0.5em 0.75em 1em 1em !important;
}
[class~="zs-padding-1-2-0-0"] {
  padding: 0.5em 1em 0em 0em !important;
}
[class~="zs-margin-1-2-0-0"] {
  margin: 0.5em 1em 0em 0em !important;
}
[class~="zs-padding-1-2-0-0.5"] {
  padding: 0.5em 1em 0em 0.25em !important;
}
[class~="zs-margin-1-2-0-0.5"] {
  margin: 0.5em 1em 0em 0.25em !important;
}
[class~="zs-padding-1-2-0-1"] {
  padding: 0.5em 1em 0em 0.5em !important;
}
[class~="zs-margin-1-2-0-1"] {
  margin: 0.5em 1em 0em 0.5em !important;
}
[class~="zs-padding-1-2-0-1.5"] {
  padding: 0.5em 1em 0em 0.75em !important;
}
[class~="zs-margin-1-2-0-1.5"] {
  margin: 0.5em 1em 0em 0.75em !important;
}
[class~="zs-padding-1-2-0-2"] {
  padding: 0.5em 1em 0em 1em !important;
}
[class~="zs-margin-1-2-0-2"] {
  margin: 0.5em 1em 0em 1em !important;
}
[class~="zs-padding-1-2-0.5-0"] {
  padding: 0.5em 1em 0.25em 0em !important;
}
[class~="zs-margin-1-2-0.5-0"] {
  margin: 0.5em 1em 0.25em 0em !important;
}
[class~="zs-padding-1-2-0.5-0.5"] {
  padding: 0.5em 1em 0.25em 0.25em !important;
}
[class~="zs-margin-1-2-0.5-0.5"] {
  margin: 0.5em 1em 0.25em 0.25em !important;
}
[class~="zs-padding-1-2-0.5-1"] {
  padding: 0.5em 1em 0.25em 0.5em !important;
}
[class~="zs-margin-1-2-0.5-1"] {
  margin: 0.5em 1em 0.25em 0.5em !important;
}
[class~="zs-padding-1-2-0.5-1.5"] {
  padding: 0.5em 1em 0.25em 0.75em !important;
}
[class~="zs-margin-1-2-0.5-1.5"] {
  margin: 0.5em 1em 0.25em 0.75em !important;
}
[class~="zs-padding-1-2-0.5-2"] {
  padding: 0.5em 1em 0.25em 1em !important;
}
[class~="zs-margin-1-2-0.5-2"] {
  margin: 0.5em 1em 0.25em 1em !important;
}
[class~="zs-padding-1-2-1-0"] {
  padding: 0.5em 1em 0.5em 0em !important;
}
[class~="zs-margin-1-2-1-0"] {
  margin: 0.5em 1em 0.5em 0em !important;
}
[class~="zs-padding-1-2-1-0.5"] {
  padding: 0.5em 1em 0.5em 0.25em !important;
}
[class~="zs-margin-1-2-1-0.5"] {
  margin: 0.5em 1em 0.5em 0.25em !important;
}
[class~="zs-padding-1-2-1-1"] {
  padding: 0.5em 1em 0.5em 0.5em !important;
}
[class~="zs-margin-1-2-1-1"] {
  margin: 0.5em 1em 0.5em 0.5em !important;
}
[class~="zs-padding-1-2-1-1.5"] {
  padding: 0.5em 1em 0.5em 0.75em !important;
}
[class~="zs-margin-1-2-1-1.5"] {
  margin: 0.5em 1em 0.5em 0.75em !important;
}
[class~="zs-padding-1-2-1-2"] {
  padding: 0.5em 1em 0.5em 1em !important;
}
[class~="zs-margin-1-2-1-2"] {
  margin: 0.5em 1em 0.5em 1em !important;
}
[class~="zs-padding-1-2-1.5-0"] {
  padding: 0.5em 1em 0.75em 0em !important;
}
[class~="zs-margin-1-2-1.5-0"] {
  margin: 0.5em 1em 0.75em 0em !important;
}
[class~="zs-padding-1-2-1.5-0.5"] {
  padding: 0.5em 1em 0.75em 0.25em !important;
}
[class~="zs-margin-1-2-1.5-0.5"] {
  margin: 0.5em 1em 0.75em 0.25em !important;
}
[class~="zs-padding-1-2-1.5-1"] {
  padding: 0.5em 1em 0.75em 0.5em !important;
}
[class~="zs-margin-1-2-1.5-1"] {
  margin: 0.5em 1em 0.75em 0.5em !important;
}
[class~="zs-padding-1-2-1.5-1.5"] {
  padding: 0.5em 1em 0.75em 0.75em !important;
}
[class~="zs-margin-1-2-1.5-1.5"] {
  margin: 0.5em 1em 0.75em 0.75em !important;
}
[class~="zs-padding-1-2-1.5-2"] {
  padding: 0.5em 1em 0.75em 1em !important;
}
[class~="zs-margin-1-2-1.5-2"] {
  margin: 0.5em 1em 0.75em 1em !important;
}
[class~="zs-padding-1-2-2-0"] {
  padding: 0.5em 1em 1em 0em !important;
}
[class~="zs-margin-1-2-2-0"] {
  margin: 0.5em 1em 1em 0em !important;
}
[class~="zs-padding-1-2-2-0.5"] {
  padding: 0.5em 1em 1em 0.25em !important;
}
[class~="zs-margin-1-2-2-0.5"] {
  margin: 0.5em 1em 1em 0.25em !important;
}
[class~="zs-padding-1-2-2-1"] {
  padding: 0.5em 1em 1em 0.5em !important;
}
[class~="zs-margin-1-2-2-1"] {
  margin: 0.5em 1em 1em 0.5em !important;
}
[class~="zs-padding-1-2-2-1.5"] {
  padding: 0.5em 1em 1em 0.75em !important;
}
[class~="zs-margin-1-2-2-1.5"] {
  margin: 0.5em 1em 1em 0.75em !important;
}
[class~="zs-padding-1-2-2-2"] {
  padding: 0.5em 1em 1em 1em !important;
}
[class~="zs-margin-1-2-2-2"] {
  margin: 0.5em 1em 1em 1em !important;
}
[class~="zs-padding-1.5-0-0-0"] {
  padding: 0.75em 0em 0em 0em !important;
}
[class~="zs-margin-1.5-0-0-0"] {
  margin: 0.75em 0em 0em 0em !important;
}
[class~="zs-padding-1.5-0-0-0.5"] {
  padding: 0.75em 0em 0em 0.25em !important;
}
[class~="zs-margin-1.5-0-0-0.5"] {
  margin: 0.75em 0em 0em 0.25em !important;
}
[class~="zs-padding-1.5-0-0-1"] {
  padding: 0.75em 0em 0em 0.5em !important;
}
[class~="zs-margin-1.5-0-0-1"] {
  margin: 0.75em 0em 0em 0.5em !important;
}
[class~="zs-padding-1.5-0-0-1.5"] {
  padding: 0.75em 0em 0em 0.75em !important;
}
[class~="zs-margin-1.5-0-0-1.5"] {
  margin: 0.75em 0em 0em 0.75em !important;
}
[class~="zs-padding-1.5-0-0-2"] {
  padding: 0.75em 0em 0em 1em !important;
}
[class~="zs-margin-1.5-0-0-2"] {
  margin: 0.75em 0em 0em 1em !important;
}
[class~="zs-padding-1.5-0-0.5-0"] {
  padding: 0.75em 0em 0.25em 0em !important;
}
[class~="zs-margin-1.5-0-0.5-0"] {
  margin: 0.75em 0em 0.25em 0em !important;
}
[class~="zs-padding-1.5-0-0.5-0.5"] {
  padding: 0.75em 0em 0.25em 0.25em !important;
}
[class~="zs-margin-1.5-0-0.5-0.5"] {
  margin: 0.75em 0em 0.25em 0.25em !important;
}
[class~="zs-padding-1.5-0-0.5-1"] {
  padding: 0.75em 0em 0.25em 0.5em !important;
}
[class~="zs-margin-1.5-0-0.5-1"] {
  margin: 0.75em 0em 0.25em 0.5em !important;
}
[class~="zs-padding-1.5-0-0.5-1.5"] {
  padding: 0.75em 0em 0.25em 0.75em !important;
}
[class~="zs-margin-1.5-0-0.5-1.5"] {
  margin: 0.75em 0em 0.25em 0.75em !important;
}
[class~="zs-padding-1.5-0-0.5-2"] {
  padding: 0.75em 0em 0.25em 1em !important;
}
[class~="zs-margin-1.5-0-0.5-2"] {
  margin: 0.75em 0em 0.25em 1em !important;
}
[class~="zs-padding-1.5-0-1-0"] {
  padding: 0.75em 0em 0.5em 0em !important;
}
[class~="zs-margin-1.5-0-1-0"] {
  margin: 0.75em 0em 0.5em 0em !important;
}
[class~="zs-padding-1.5-0-1-0.5"] {
  padding: 0.75em 0em 0.5em 0.25em !important;
}
[class~="zs-margin-1.5-0-1-0.5"] {
  margin: 0.75em 0em 0.5em 0.25em !important;
}
[class~="zs-padding-1.5-0-1-1"] {
  padding: 0.75em 0em 0.5em 0.5em !important;
}
[class~="zs-margin-1.5-0-1-1"] {
  margin: 0.75em 0em 0.5em 0.5em !important;
}
[class~="zs-padding-1.5-0-1-1.5"] {
  padding: 0.75em 0em 0.5em 0.75em !important;
}
[class~="zs-margin-1.5-0-1-1.5"] {
  margin: 0.75em 0em 0.5em 0.75em !important;
}
[class~="zs-padding-1.5-0-1-2"] {
  padding: 0.75em 0em 0.5em 1em !important;
}
[class~="zs-margin-1.5-0-1-2"] {
  margin: 0.75em 0em 0.5em 1em !important;
}
[class~="zs-padding-1.5-0-1.5-0"] {
  padding: 0.75em 0em 0.75em 0em !important;
}
[class~="zs-margin-1.5-0-1.5-0"] {
  margin: 0.75em 0em 0.75em 0em !important;
}
[class~="zs-padding-1.5-0-1.5-0.5"] {
  padding: 0.75em 0em 0.75em 0.25em !important;
}
[class~="zs-margin-1.5-0-1.5-0.5"] {
  margin: 0.75em 0em 0.75em 0.25em !important;
}
[class~="zs-padding-1.5-0-1.5-1"] {
  padding: 0.75em 0em 0.75em 0.5em !important;
}
[class~="zs-margin-1.5-0-1.5-1"] {
  margin: 0.75em 0em 0.75em 0.5em !important;
}
[class~="zs-padding-1.5-0-1.5-1.5"] {
  padding: 0.75em 0em 0.75em 0.75em !important;
}
[class~="zs-margin-1.5-0-1.5-1.5"] {
  margin: 0.75em 0em 0.75em 0.75em !important;
}
[class~="zs-padding-1.5-0-1.5-2"] {
  padding: 0.75em 0em 0.75em 1em !important;
}
[class~="zs-margin-1.5-0-1.5-2"] {
  margin: 0.75em 0em 0.75em 1em !important;
}
[class~="zs-padding-1.5-0-2-0"] {
  padding: 0.75em 0em 1em 0em !important;
}
[class~="zs-margin-1.5-0-2-0"] {
  margin: 0.75em 0em 1em 0em !important;
}
[class~="zs-padding-1.5-0-2-0.5"] {
  padding: 0.75em 0em 1em 0.25em !important;
}
[class~="zs-margin-1.5-0-2-0.5"] {
  margin: 0.75em 0em 1em 0.25em !important;
}
[class~="zs-padding-1.5-0-2-1"] {
  padding: 0.75em 0em 1em 0.5em !important;
}
[class~="zs-margin-1.5-0-2-1"] {
  margin: 0.75em 0em 1em 0.5em !important;
}
[class~="zs-padding-1.5-0-2-1.5"] {
  padding: 0.75em 0em 1em 0.75em !important;
}
[class~="zs-margin-1.5-0-2-1.5"] {
  margin: 0.75em 0em 1em 0.75em !important;
}
[class~="zs-padding-1.5-0-2-2"] {
  padding: 0.75em 0em 1em 1em !important;
}
[class~="zs-margin-1.5-0-2-2"] {
  margin: 0.75em 0em 1em 1em !important;
}
[class~="zs-padding-1.5-0.5-0-0"] {
  padding: 0.75em 0.25em 0em 0em !important;
}
[class~="zs-margin-1.5-0.5-0-0"] {
  margin: 0.75em 0.25em 0em 0em !important;
}
[class~="zs-padding-1.5-0.5-0-0.5"] {
  padding: 0.75em 0.25em 0em 0.25em !important;
}
[class~="zs-margin-1.5-0.5-0-0.5"] {
  margin: 0.75em 0.25em 0em 0.25em !important;
}
[class~="zs-padding-1.5-0.5-0-1"] {
  padding: 0.75em 0.25em 0em 0.5em !important;
}
[class~="zs-margin-1.5-0.5-0-1"] {
  margin: 0.75em 0.25em 0em 0.5em !important;
}
[class~="zs-padding-1.5-0.5-0-1.5"] {
  padding: 0.75em 0.25em 0em 0.75em !important;
}
[class~="zs-margin-1.5-0.5-0-1.5"] {
  margin: 0.75em 0.25em 0em 0.75em !important;
}
[class~="zs-padding-1.5-0.5-0-2"] {
  padding: 0.75em 0.25em 0em 1em !important;
}
[class~="zs-margin-1.5-0.5-0-2"] {
  margin: 0.75em 0.25em 0em 1em !important;
}
[class~="zs-padding-1.5-0.5-0.5-0"] {
  padding: 0.75em 0.25em 0.25em 0em !important;
}
[class~="zs-margin-1.5-0.5-0.5-0"] {
  margin: 0.75em 0.25em 0.25em 0em !important;
}
[class~="zs-padding-1.5-0.5-0.5-0.5"] {
  padding: 0.75em 0.25em 0.25em 0.25em !important;
}
[class~="zs-margin-1.5-0.5-0.5-0.5"] {
  margin: 0.75em 0.25em 0.25em 0.25em !important;
}
[class~="zs-padding-1.5-0.5-0.5-1"] {
  padding: 0.75em 0.25em 0.25em 0.5em !important;
}
[class~="zs-margin-1.5-0.5-0.5-1"] {
  margin: 0.75em 0.25em 0.25em 0.5em !important;
}
[class~="zs-padding-1.5-0.5-0.5-1.5"] {
  padding: 0.75em 0.25em 0.25em 0.75em !important;
}
[class~="zs-margin-1.5-0.5-0.5-1.5"] {
  margin: 0.75em 0.25em 0.25em 0.75em !important;
}
[class~="zs-padding-1.5-0.5-0.5-2"] {
  padding: 0.75em 0.25em 0.25em 1em !important;
}
[class~="zs-margin-1.5-0.5-0.5-2"] {
  margin: 0.75em 0.25em 0.25em 1em !important;
}
[class~="zs-padding-1.5-0.5-1-0"] {
  padding: 0.75em 0.25em 0.5em 0em !important;
}
[class~="zs-margin-1.5-0.5-1-0"] {
  margin: 0.75em 0.25em 0.5em 0em !important;
}
[class~="zs-padding-1.5-0.5-1-0.5"] {
  padding: 0.75em 0.25em 0.5em 0.25em !important;
}
[class~="zs-margin-1.5-0.5-1-0.5"] {
  margin: 0.75em 0.25em 0.5em 0.25em !important;
}
[class~="zs-padding-1.5-0.5-1-1"] {
  padding: 0.75em 0.25em 0.5em 0.5em !important;
}
[class~="zs-margin-1.5-0.5-1-1"] {
  margin: 0.75em 0.25em 0.5em 0.5em !important;
}
[class~="zs-padding-1.5-0.5-1-1.5"] {
  padding: 0.75em 0.25em 0.5em 0.75em !important;
}
[class~="zs-margin-1.5-0.5-1-1.5"] {
  margin: 0.75em 0.25em 0.5em 0.75em !important;
}
[class~="zs-padding-1.5-0.5-1-2"] {
  padding: 0.75em 0.25em 0.5em 1em !important;
}
[class~="zs-margin-1.5-0.5-1-2"] {
  margin: 0.75em 0.25em 0.5em 1em !important;
}
[class~="zs-padding-1.5-0.5-1.5-0"] {
  padding: 0.75em 0.25em 0.75em 0em !important;
}
[class~="zs-margin-1.5-0.5-1.5-0"] {
  margin: 0.75em 0.25em 0.75em 0em !important;
}
[class~="zs-padding-1.5-0.5-1.5-0.5"] {
  padding: 0.75em 0.25em 0.75em 0.25em !important;
}
[class~="zs-margin-1.5-0.5-1.5-0.5"] {
  margin: 0.75em 0.25em 0.75em 0.25em !important;
}
[class~="zs-padding-1.5-0.5-1.5-1"] {
  padding: 0.75em 0.25em 0.75em 0.5em !important;
}
[class~="zs-margin-1.5-0.5-1.5-1"] {
  margin: 0.75em 0.25em 0.75em 0.5em !important;
}
[class~="zs-padding-1.5-0.5-1.5-1.5"] {
  padding: 0.75em 0.25em 0.75em 0.75em !important;
}
[class~="zs-margin-1.5-0.5-1.5-1.5"] {
  margin: 0.75em 0.25em 0.75em 0.75em !important;
}
[class~="zs-padding-1.5-0.5-1.5-2"] {
  padding: 0.75em 0.25em 0.75em 1em !important;
}
[class~="zs-margin-1.5-0.5-1.5-2"] {
  margin: 0.75em 0.25em 0.75em 1em !important;
}
[class~="zs-padding-1.5-0.5-2-0"] {
  padding: 0.75em 0.25em 1em 0em !important;
}
[class~="zs-margin-1.5-0.5-2-0"] {
  margin: 0.75em 0.25em 1em 0em !important;
}
[class~="zs-padding-1.5-0.5-2-0.5"] {
  padding: 0.75em 0.25em 1em 0.25em !important;
}
[class~="zs-margin-1.5-0.5-2-0.5"] {
  margin: 0.75em 0.25em 1em 0.25em !important;
}
[class~="zs-padding-1.5-0.5-2-1"] {
  padding: 0.75em 0.25em 1em 0.5em !important;
}
[class~="zs-margin-1.5-0.5-2-1"] {
  margin: 0.75em 0.25em 1em 0.5em !important;
}
[class~="zs-padding-1.5-0.5-2-1.5"] {
  padding: 0.75em 0.25em 1em 0.75em !important;
}
[class~="zs-margin-1.5-0.5-2-1.5"] {
  margin: 0.75em 0.25em 1em 0.75em !important;
}
[class~="zs-padding-1.5-0.5-2-2"] {
  padding: 0.75em 0.25em 1em 1em !important;
}
[class~="zs-margin-1.5-0.5-2-2"] {
  margin: 0.75em 0.25em 1em 1em !important;
}
[class~="zs-padding-1.5-1-0-0"] {
  padding: 0.75em 0.5em 0em 0em !important;
}
[class~="zs-margin-1.5-1-0-0"] {
  margin: 0.75em 0.5em 0em 0em !important;
}
[class~="zs-padding-1.5-1-0-0.5"] {
  padding: 0.75em 0.5em 0em 0.25em !important;
}
[class~="zs-margin-1.5-1-0-0.5"] {
  margin: 0.75em 0.5em 0em 0.25em !important;
}
[class~="zs-padding-1.5-1-0-1"] {
  padding: 0.75em 0.5em 0em 0.5em !important;
}
[class~="zs-margin-1.5-1-0-1"] {
  margin: 0.75em 0.5em 0em 0.5em !important;
}
[class~="zs-padding-1.5-1-0-1.5"] {
  padding: 0.75em 0.5em 0em 0.75em !important;
}
[class~="zs-margin-1.5-1-0-1.5"] {
  margin: 0.75em 0.5em 0em 0.75em !important;
}
[class~="zs-padding-1.5-1-0-2"] {
  padding: 0.75em 0.5em 0em 1em !important;
}
[class~="zs-margin-1.5-1-0-2"] {
  margin: 0.75em 0.5em 0em 1em !important;
}
[class~="zs-padding-1.5-1-0.5-0"] {
  padding: 0.75em 0.5em 0.25em 0em !important;
}
[class~="zs-margin-1.5-1-0.5-0"] {
  margin: 0.75em 0.5em 0.25em 0em !important;
}
[class~="zs-padding-1.5-1-0.5-0.5"] {
  padding: 0.75em 0.5em 0.25em 0.25em !important;
}
[class~="zs-margin-1.5-1-0.5-0.5"] {
  margin: 0.75em 0.5em 0.25em 0.25em !important;
}
[class~="zs-padding-1.5-1-0.5-1"] {
  padding: 0.75em 0.5em 0.25em 0.5em !important;
}
[class~="zs-margin-1.5-1-0.5-1"] {
  margin: 0.75em 0.5em 0.25em 0.5em !important;
}
[class~="zs-padding-1.5-1-0.5-1.5"] {
  padding: 0.75em 0.5em 0.25em 0.75em !important;
}
[class~="zs-margin-1.5-1-0.5-1.5"] {
  margin: 0.75em 0.5em 0.25em 0.75em !important;
}
[class~="zs-padding-1.5-1-0.5-2"] {
  padding: 0.75em 0.5em 0.25em 1em !important;
}
[class~="zs-margin-1.5-1-0.5-2"] {
  margin: 0.75em 0.5em 0.25em 1em !important;
}
[class~="zs-padding-1.5-1-1-0"] {
  padding: 0.75em 0.5em 0.5em 0em !important;
}
[class~="zs-margin-1.5-1-1-0"] {
  margin: 0.75em 0.5em 0.5em 0em !important;
}
[class~="zs-padding-1.5-1-1-0.5"] {
  padding: 0.75em 0.5em 0.5em 0.25em !important;
}
[class~="zs-margin-1.5-1-1-0.5"] {
  margin: 0.75em 0.5em 0.5em 0.25em !important;
}
[class~="zs-padding-1.5-1-1-1"] {
  padding: 0.75em 0.5em 0.5em 0.5em !important;
}
[class~="zs-margin-1.5-1-1-1"] {
  margin: 0.75em 0.5em 0.5em 0.5em !important;
}
[class~="zs-padding-1.5-1-1-1.5"] {
  padding: 0.75em 0.5em 0.5em 0.75em !important;
}
[class~="zs-margin-1.5-1-1-1.5"] {
  margin: 0.75em 0.5em 0.5em 0.75em !important;
}
[class~="zs-padding-1.5-1-1-2"] {
  padding: 0.75em 0.5em 0.5em 1em !important;
}
[class~="zs-margin-1.5-1-1-2"] {
  margin: 0.75em 0.5em 0.5em 1em !important;
}
[class~="zs-padding-1.5-1-1.5-0"] {
  padding: 0.75em 0.5em 0.75em 0em !important;
}
[class~="zs-margin-1.5-1-1.5-0"] {
  margin: 0.75em 0.5em 0.75em 0em !important;
}
[class~="zs-padding-1.5-1-1.5-0.5"] {
  padding: 0.75em 0.5em 0.75em 0.25em !important;
}
[class~="zs-margin-1.5-1-1.5-0.5"] {
  margin: 0.75em 0.5em 0.75em 0.25em !important;
}
[class~="zs-padding-1.5-1-1.5-1"] {
  padding: 0.75em 0.5em 0.75em 0.5em !important;
}
[class~="zs-margin-1.5-1-1.5-1"] {
  margin: 0.75em 0.5em 0.75em 0.5em !important;
}
[class~="zs-padding-1.5-1-1.5-1.5"] {
  padding: 0.75em 0.5em 0.75em 0.75em !important;
}
[class~="zs-margin-1.5-1-1.5-1.5"] {
  margin: 0.75em 0.5em 0.75em 0.75em !important;
}
[class~="zs-padding-1.5-1-1.5-2"] {
  padding: 0.75em 0.5em 0.75em 1em !important;
}
[class~="zs-margin-1.5-1-1.5-2"] {
  margin: 0.75em 0.5em 0.75em 1em !important;
}
[class~="zs-padding-1.5-1-2-0"] {
  padding: 0.75em 0.5em 1em 0em !important;
}
[class~="zs-margin-1.5-1-2-0"] {
  margin: 0.75em 0.5em 1em 0em !important;
}
[class~="zs-padding-1.5-1-2-0.5"] {
  padding: 0.75em 0.5em 1em 0.25em !important;
}
[class~="zs-margin-1.5-1-2-0.5"] {
  margin: 0.75em 0.5em 1em 0.25em !important;
}
[class~="zs-padding-1.5-1-2-1"] {
  padding: 0.75em 0.5em 1em 0.5em !important;
}
[class~="zs-margin-1.5-1-2-1"] {
  margin: 0.75em 0.5em 1em 0.5em !important;
}
[class~="zs-padding-1.5-1-2-1.5"] {
  padding: 0.75em 0.5em 1em 0.75em !important;
}
[class~="zs-margin-1.5-1-2-1.5"] {
  margin: 0.75em 0.5em 1em 0.75em !important;
}
[class~="zs-padding-1.5-1-2-2"] {
  padding: 0.75em 0.5em 1em 1em !important;
}
[class~="zs-margin-1.5-1-2-2"] {
  margin: 0.75em 0.5em 1em 1em !important;
}
[class~="zs-padding-1.5-1.5-0-0"] {
  padding: 0.75em 0.75em 0em 0em !important;
}
[class~="zs-margin-1.5-1.5-0-0"] {
  margin: 0.75em 0.75em 0em 0em !important;
}
[class~="zs-padding-1.5-1.5-0-0.5"] {
  padding: 0.75em 0.75em 0em 0.25em !important;
}
[class~="zs-margin-1.5-1.5-0-0.5"] {
  margin: 0.75em 0.75em 0em 0.25em !important;
}
[class~="zs-padding-1.5-1.5-0-1"] {
  padding: 0.75em 0.75em 0em 0.5em !important;
}
[class~="zs-margin-1.5-1.5-0-1"] {
  margin: 0.75em 0.75em 0em 0.5em !important;
}
[class~="zs-padding-1.5-1.5-0-1.5"] {
  padding: 0.75em 0.75em 0em 0.75em !important;
}
[class~="zs-margin-1.5-1.5-0-1.5"] {
  margin: 0.75em 0.75em 0em 0.75em !important;
}
[class~="zs-padding-1.5-1.5-0-2"] {
  padding: 0.75em 0.75em 0em 1em !important;
}
[class~="zs-margin-1.5-1.5-0-2"] {
  margin: 0.75em 0.75em 0em 1em !important;
}
[class~="zs-padding-1.5-1.5-0.5-0"] {
  padding: 0.75em 0.75em 0.25em 0em !important;
}
[class~="zs-margin-1.5-1.5-0.5-0"] {
  margin: 0.75em 0.75em 0.25em 0em !important;
}
[class~="zs-padding-1.5-1.5-0.5-0.5"] {
  padding: 0.75em 0.75em 0.25em 0.25em !important;
}
[class~="zs-margin-1.5-1.5-0.5-0.5"] {
  margin: 0.75em 0.75em 0.25em 0.25em !important;
}
[class~="zs-padding-1.5-1.5-0.5-1"] {
  padding: 0.75em 0.75em 0.25em 0.5em !important;
}
[class~="zs-margin-1.5-1.5-0.5-1"] {
  margin: 0.75em 0.75em 0.25em 0.5em !important;
}
[class~="zs-padding-1.5-1.5-0.5-1.5"] {
  padding: 0.75em 0.75em 0.25em 0.75em !important;
}
[class~="zs-margin-1.5-1.5-0.5-1.5"] {
  margin: 0.75em 0.75em 0.25em 0.75em !important;
}
[class~="zs-padding-1.5-1.5-0.5-2"] {
  padding: 0.75em 0.75em 0.25em 1em !important;
}
[class~="zs-margin-1.5-1.5-0.5-2"] {
  margin: 0.75em 0.75em 0.25em 1em !important;
}
[class~="zs-padding-1.5-1.5-1-0"] {
  padding: 0.75em 0.75em 0.5em 0em !important;
}
[class~="zs-margin-1.5-1.5-1-0"] {
  margin: 0.75em 0.75em 0.5em 0em !important;
}
[class~="zs-padding-1.5-1.5-1-0.5"] {
  padding: 0.75em 0.75em 0.5em 0.25em !important;
}
[class~="zs-margin-1.5-1.5-1-0.5"] {
  margin: 0.75em 0.75em 0.5em 0.25em !important;
}
[class~="zs-padding-1.5-1.5-1-1"] {
  padding: 0.75em 0.75em 0.5em 0.5em !important;
}
[class~="zs-margin-1.5-1.5-1-1"] {
  margin: 0.75em 0.75em 0.5em 0.5em !important;
}
[class~="zs-padding-1.5-1.5-1-1.5"] {
  padding: 0.75em 0.75em 0.5em 0.75em !important;
}
[class~="zs-margin-1.5-1.5-1-1.5"] {
  margin: 0.75em 0.75em 0.5em 0.75em !important;
}
[class~="zs-padding-1.5-1.5-1-2"] {
  padding: 0.75em 0.75em 0.5em 1em !important;
}
[class~="zs-margin-1.5-1.5-1-2"] {
  margin: 0.75em 0.75em 0.5em 1em !important;
}
[class~="zs-padding-1.5-1.5-1.5-0"] {
  padding: 0.75em 0.75em 0.75em 0em !important;
}
[class~="zs-margin-1.5-1.5-1.5-0"] {
  margin: 0.75em 0.75em 0.75em 0em !important;
}
[class~="zs-padding-1.5-1.5-1.5-0.5"] {
  padding: 0.75em 0.75em 0.75em 0.25em !important;
}
[class~="zs-margin-1.5-1.5-1.5-0.5"] {
  margin: 0.75em 0.75em 0.75em 0.25em !important;
}
[class~="zs-padding-1.5-1.5-1.5-1"] {
  padding: 0.75em 0.75em 0.75em 0.5em !important;
}
[class~="zs-margin-1.5-1.5-1.5-1"] {
  margin: 0.75em 0.75em 0.75em 0.5em !important;
}
[class~="zs-padding-1.5-1.5-1.5-1.5"] {
  padding: 0.75em 0.75em 0.75em 0.75em !important;
}
[class~="zs-margin-1.5-1.5-1.5-1.5"] {
  margin: 0.75em 0.75em 0.75em 0.75em !important;
}
[class~="zs-padding-1.5-1.5-1.5-2"] {
  padding: 0.75em 0.75em 0.75em 1em !important;
}
[class~="zs-margin-1.5-1.5-1.5-2"] {
  margin: 0.75em 0.75em 0.75em 1em !important;
}
[class~="zs-padding-1.5-1.5-2-0"] {
  padding: 0.75em 0.75em 1em 0em !important;
}
[class~="zs-margin-1.5-1.5-2-0"] {
  margin: 0.75em 0.75em 1em 0em !important;
}
[class~="zs-padding-1.5-1.5-2-0.5"] {
  padding: 0.75em 0.75em 1em 0.25em !important;
}
[class~="zs-margin-1.5-1.5-2-0.5"] {
  margin: 0.75em 0.75em 1em 0.25em !important;
}
[class~="zs-padding-1.5-1.5-2-1"] {
  padding: 0.75em 0.75em 1em 0.5em !important;
}
[class~="zs-margin-1.5-1.5-2-1"] {
  margin: 0.75em 0.75em 1em 0.5em !important;
}
[class~="zs-padding-1.5-1.5-2-1.5"] {
  padding: 0.75em 0.75em 1em 0.75em !important;
}
[class~="zs-margin-1.5-1.5-2-1.5"] {
  margin: 0.75em 0.75em 1em 0.75em !important;
}
[class~="zs-padding-1.5-1.5-2-2"] {
  padding: 0.75em 0.75em 1em 1em !important;
}
[class~="zs-margin-1.5-1.5-2-2"] {
  margin: 0.75em 0.75em 1em 1em !important;
}
[class~="zs-padding-1.5-2-0-0"] {
  padding: 0.75em 1em 0em 0em !important;
}
[class~="zs-margin-1.5-2-0-0"] {
  margin: 0.75em 1em 0em 0em !important;
}
[class~="zs-padding-1.5-2-0-0.5"] {
  padding: 0.75em 1em 0em 0.25em !important;
}
[class~="zs-margin-1.5-2-0-0.5"] {
  margin: 0.75em 1em 0em 0.25em !important;
}
[class~="zs-padding-1.5-2-0-1"] {
  padding: 0.75em 1em 0em 0.5em !important;
}
[class~="zs-margin-1.5-2-0-1"] {
  margin: 0.75em 1em 0em 0.5em !important;
}
[class~="zs-padding-1.5-2-0-1.5"] {
  padding: 0.75em 1em 0em 0.75em !important;
}
[class~="zs-margin-1.5-2-0-1.5"] {
  margin: 0.75em 1em 0em 0.75em !important;
}
[class~="zs-padding-1.5-2-0-2"] {
  padding: 0.75em 1em 0em 1em !important;
}
[class~="zs-margin-1.5-2-0-2"] {
  margin: 0.75em 1em 0em 1em !important;
}
[class~="zs-padding-1.5-2-0.5-0"] {
  padding: 0.75em 1em 0.25em 0em !important;
}
[class~="zs-margin-1.5-2-0.5-0"] {
  margin: 0.75em 1em 0.25em 0em !important;
}
[class~="zs-padding-1.5-2-0.5-0.5"] {
  padding: 0.75em 1em 0.25em 0.25em !important;
}
[class~="zs-margin-1.5-2-0.5-0.5"] {
  margin: 0.75em 1em 0.25em 0.25em !important;
}
[class~="zs-padding-1.5-2-0.5-1"] {
  padding: 0.75em 1em 0.25em 0.5em !important;
}
[class~="zs-margin-1.5-2-0.5-1"] {
  margin: 0.75em 1em 0.25em 0.5em !important;
}
[class~="zs-padding-1.5-2-0.5-1.5"] {
  padding: 0.75em 1em 0.25em 0.75em !important;
}
[class~="zs-margin-1.5-2-0.5-1.5"] {
  margin: 0.75em 1em 0.25em 0.75em !important;
}
[class~="zs-padding-1.5-2-0.5-2"] {
  padding: 0.75em 1em 0.25em 1em !important;
}
[class~="zs-margin-1.5-2-0.5-2"] {
  margin: 0.75em 1em 0.25em 1em !important;
}
[class~="zs-padding-1.5-2-1-0"] {
  padding: 0.75em 1em 0.5em 0em !important;
}
[class~="zs-margin-1.5-2-1-0"] {
  margin: 0.75em 1em 0.5em 0em !important;
}
[class~="zs-padding-1.5-2-1-0.5"] {
  padding: 0.75em 1em 0.5em 0.25em !important;
}
[class~="zs-margin-1.5-2-1-0.5"] {
  margin: 0.75em 1em 0.5em 0.25em !important;
}
[class~="zs-padding-1.5-2-1-1"] {
  padding: 0.75em 1em 0.5em 0.5em !important;
}
[class~="zs-margin-1.5-2-1-1"] {
  margin: 0.75em 1em 0.5em 0.5em !important;
}
[class~="zs-padding-1.5-2-1-1.5"] {
  padding: 0.75em 1em 0.5em 0.75em !important;
}
[class~="zs-margin-1.5-2-1-1.5"] {
  margin: 0.75em 1em 0.5em 0.75em !important;
}
[class~="zs-padding-1.5-2-1-2"] {
  padding: 0.75em 1em 0.5em 1em !important;
}
[class~="zs-margin-1.5-2-1-2"] {
  margin: 0.75em 1em 0.5em 1em !important;
}
[class~="zs-padding-1.5-2-1.5-0"] {
  padding: 0.75em 1em 0.75em 0em !important;
}
[class~="zs-margin-1.5-2-1.5-0"] {
  margin: 0.75em 1em 0.75em 0em !important;
}
[class~="zs-padding-1.5-2-1.5-0.5"] {
  padding: 0.75em 1em 0.75em 0.25em !important;
}
[class~="zs-margin-1.5-2-1.5-0.5"] {
  margin: 0.75em 1em 0.75em 0.25em !important;
}
[class~="zs-padding-1.5-2-1.5-1"] {
  padding: 0.75em 1em 0.75em 0.5em !important;
}
[class~="zs-margin-1.5-2-1.5-1"] {
  margin: 0.75em 1em 0.75em 0.5em !important;
}
[class~="zs-padding-1.5-2-1.5-1.5"] {
  padding: 0.75em 1em 0.75em 0.75em !important;
}
[class~="zs-margin-1.5-2-1.5-1.5"] {
  margin: 0.75em 1em 0.75em 0.75em !important;
}
[class~="zs-padding-1.5-2-1.5-2"] {
  padding: 0.75em 1em 0.75em 1em !important;
}
[class~="zs-margin-1.5-2-1.5-2"] {
  margin: 0.75em 1em 0.75em 1em !important;
}
[class~="zs-padding-1.5-2-2-0"] {
  padding: 0.75em 1em 1em 0em !important;
}
[class~="zs-margin-1.5-2-2-0"] {
  margin: 0.75em 1em 1em 0em !important;
}
[class~="zs-padding-1.5-2-2-0.5"] {
  padding: 0.75em 1em 1em 0.25em !important;
}
[class~="zs-margin-1.5-2-2-0.5"] {
  margin: 0.75em 1em 1em 0.25em !important;
}
[class~="zs-padding-1.5-2-2-1"] {
  padding: 0.75em 1em 1em 0.5em !important;
}
[class~="zs-margin-1.5-2-2-1"] {
  margin: 0.75em 1em 1em 0.5em !important;
}
[class~="zs-padding-1.5-2-2-1.5"] {
  padding: 0.75em 1em 1em 0.75em !important;
}
[class~="zs-margin-1.5-2-2-1.5"] {
  margin: 0.75em 1em 1em 0.75em !important;
}
[class~="zs-padding-1.5-2-2-2"] {
  padding: 0.75em 1em 1em 1em !important;
}
[class~="zs-margin-1.5-2-2-2"] {
  margin: 0.75em 1em 1em 1em !important;
}
[class~="zs-padding-2-0-0-0"] {
  padding: 1em 0em 0em 0em !important;
}
[class~="zs-margin-2-0-0-0"] {
  margin: 1em 0em 0em 0em !important;
}
[class~="zs-padding-2-0-0-0.5"] {
  padding: 1em 0em 0em 0.25em !important;
}
[class~="zs-margin-2-0-0-0.5"] {
  margin: 1em 0em 0em 0.25em !important;
}
[class~="zs-padding-2-0-0-1"] {
  padding: 1em 0em 0em 0.5em !important;
}
[class~="zs-margin-2-0-0-1"] {
  margin: 1em 0em 0em 0.5em !important;
}
[class~="zs-padding-2-0-0-1.5"] {
  padding: 1em 0em 0em 0.75em !important;
}
[class~="zs-margin-2-0-0-1.5"] {
  margin: 1em 0em 0em 0.75em !important;
}
[class~="zs-padding-2-0-0-2"] {
  padding: 1em 0em 0em 1em !important;
}
[class~="zs-margin-2-0-0-2"] {
  margin: 1em 0em 0em 1em !important;
}
[class~="zs-padding-2-0-0.5-0"] {
  padding: 1em 0em 0.25em 0em !important;
}
[class~="zs-margin-2-0-0.5-0"] {
  margin: 1em 0em 0.25em 0em !important;
}
[class~="zs-padding-2-0-0.5-0.5"] {
  padding: 1em 0em 0.25em 0.25em !important;
}
[class~="zs-margin-2-0-0.5-0.5"] {
  margin: 1em 0em 0.25em 0.25em !important;
}
[class~="zs-padding-2-0-0.5-1"] {
  padding: 1em 0em 0.25em 0.5em !important;
}
[class~="zs-margin-2-0-0.5-1"] {
  margin: 1em 0em 0.25em 0.5em !important;
}
[class~="zs-padding-2-0-0.5-1.5"] {
  padding: 1em 0em 0.25em 0.75em !important;
}
[class~="zs-margin-2-0-0.5-1.5"] {
  margin: 1em 0em 0.25em 0.75em !important;
}
[class~="zs-padding-2-0-0.5-2"] {
  padding: 1em 0em 0.25em 1em !important;
}
[class~="zs-margin-2-0-0.5-2"] {
  margin: 1em 0em 0.25em 1em !important;
}
[class~="zs-padding-2-0-1-0"] {
  padding: 1em 0em 0.5em 0em !important;
}
[class~="zs-margin-2-0-1-0"] {
  margin: 1em 0em 0.5em 0em !important;
}
[class~="zs-padding-2-0-1-0.5"] {
  padding: 1em 0em 0.5em 0.25em !important;
}
[class~="zs-margin-2-0-1-0.5"] {
  margin: 1em 0em 0.5em 0.25em !important;
}
[class~="zs-padding-2-0-1-1"] {
  padding: 1em 0em 0.5em 0.5em !important;
}
[class~="zs-margin-2-0-1-1"] {
  margin: 1em 0em 0.5em 0.5em !important;
}
[class~="zs-padding-2-0-1-1.5"] {
  padding: 1em 0em 0.5em 0.75em !important;
}
[class~="zs-margin-2-0-1-1.5"] {
  margin: 1em 0em 0.5em 0.75em !important;
}
[class~="zs-padding-2-0-1-2"] {
  padding: 1em 0em 0.5em 1em !important;
}
[class~="zs-margin-2-0-1-2"] {
  margin: 1em 0em 0.5em 1em !important;
}
[class~="zs-padding-2-0-1.5-0"] {
  padding: 1em 0em 0.75em 0em !important;
}
[class~="zs-margin-2-0-1.5-0"] {
  margin: 1em 0em 0.75em 0em !important;
}
[class~="zs-padding-2-0-1.5-0.5"] {
  padding: 1em 0em 0.75em 0.25em !important;
}
[class~="zs-margin-2-0-1.5-0.5"] {
  margin: 1em 0em 0.75em 0.25em !important;
}
[class~="zs-padding-2-0-1.5-1"] {
  padding: 1em 0em 0.75em 0.5em !important;
}
[class~="zs-margin-2-0-1.5-1"] {
  margin: 1em 0em 0.75em 0.5em !important;
}
[class~="zs-padding-2-0-1.5-1.5"] {
  padding: 1em 0em 0.75em 0.75em !important;
}
[class~="zs-margin-2-0-1.5-1.5"] {
  margin: 1em 0em 0.75em 0.75em !important;
}
[class~="zs-padding-2-0-1.5-2"] {
  padding: 1em 0em 0.75em 1em !important;
}
[class~="zs-margin-2-0-1.5-2"] {
  margin: 1em 0em 0.75em 1em !important;
}
[class~="zs-padding-2-0-2-0"] {
  padding: 1em 0em 1em 0em !important;
}
[class~="zs-margin-2-0-2-0"] {
  margin: 1em 0em 1em 0em !important;
}
[class~="zs-padding-2-0-2-0.5"] {
  padding: 1em 0em 1em 0.25em !important;
}
[class~="zs-margin-2-0-2-0.5"] {
  margin: 1em 0em 1em 0.25em !important;
}
[class~="zs-padding-2-0-2-1"] {
  padding: 1em 0em 1em 0.5em !important;
}
[class~="zs-margin-2-0-2-1"] {
  margin: 1em 0em 1em 0.5em !important;
}
[class~="zs-padding-2-0-2-1.5"] {
  padding: 1em 0em 1em 0.75em !important;
}
[class~="zs-margin-2-0-2-1.5"] {
  margin: 1em 0em 1em 0.75em !important;
}
[class~="zs-padding-2-0-2-2"] {
  padding: 1em 0em 1em 1em !important;
}
[class~="zs-margin-2-0-2-2"] {
  margin: 1em 0em 1em 1em !important;
}
[class~="zs-padding-2-0.5-0-0"] {
  padding: 1em 0.25em 0em 0em !important;
}
[class~="zs-margin-2-0.5-0-0"] {
  margin: 1em 0.25em 0em 0em !important;
}
[class~="zs-padding-2-0.5-0-0.5"] {
  padding: 1em 0.25em 0em 0.25em !important;
}
[class~="zs-margin-2-0.5-0-0.5"] {
  margin: 1em 0.25em 0em 0.25em !important;
}
[class~="zs-padding-2-0.5-0-1"] {
  padding: 1em 0.25em 0em 0.5em !important;
}
[class~="zs-margin-2-0.5-0-1"] {
  margin: 1em 0.25em 0em 0.5em !important;
}
[class~="zs-padding-2-0.5-0-1.5"] {
  padding: 1em 0.25em 0em 0.75em !important;
}
[class~="zs-margin-2-0.5-0-1.5"] {
  margin: 1em 0.25em 0em 0.75em !important;
}
[class~="zs-padding-2-0.5-0-2"] {
  padding: 1em 0.25em 0em 1em !important;
}
[class~="zs-margin-2-0.5-0-2"] {
  margin: 1em 0.25em 0em 1em !important;
}
[class~="zs-padding-2-0.5-0.5-0"] {
  padding: 1em 0.25em 0.25em 0em !important;
}
[class~="zs-margin-2-0.5-0.5-0"] {
  margin: 1em 0.25em 0.25em 0em !important;
}
[class~="zs-padding-2-0.5-0.5-0.5"] {
  padding: 1em 0.25em 0.25em 0.25em !important;
}
[class~="zs-margin-2-0.5-0.5-0.5"] {
  margin: 1em 0.25em 0.25em 0.25em !important;
}
[class~="zs-padding-2-0.5-0.5-1"] {
  padding: 1em 0.25em 0.25em 0.5em !important;
}
[class~="zs-margin-2-0.5-0.5-1"] {
  margin: 1em 0.25em 0.25em 0.5em !important;
}
[class~="zs-padding-2-0.5-0.5-1.5"] {
  padding: 1em 0.25em 0.25em 0.75em !important;
}
[class~="zs-margin-2-0.5-0.5-1.5"] {
  margin: 1em 0.25em 0.25em 0.75em !important;
}
[class~="zs-padding-2-0.5-0.5-2"] {
  padding: 1em 0.25em 0.25em 1em !important;
}
[class~="zs-margin-2-0.5-0.5-2"] {
  margin: 1em 0.25em 0.25em 1em !important;
}
[class~="zs-padding-2-0.5-1-0"] {
  padding: 1em 0.25em 0.5em 0em !important;
}
[class~="zs-margin-2-0.5-1-0"] {
  margin: 1em 0.25em 0.5em 0em !important;
}
[class~="zs-padding-2-0.5-1-0.5"] {
  padding: 1em 0.25em 0.5em 0.25em !important;
}
[class~="zs-margin-2-0.5-1-0.5"] {
  margin: 1em 0.25em 0.5em 0.25em !important;
}
[class~="zs-padding-2-0.5-1-1"] {
  padding: 1em 0.25em 0.5em 0.5em !important;
}
[class~="zs-margin-2-0.5-1-1"] {
  margin: 1em 0.25em 0.5em 0.5em !important;
}
[class~="zs-padding-2-0.5-1-1.5"] {
  padding: 1em 0.25em 0.5em 0.75em !important;
}
[class~="zs-margin-2-0.5-1-1.5"] {
  margin: 1em 0.25em 0.5em 0.75em !important;
}
[class~="zs-padding-2-0.5-1-2"] {
  padding: 1em 0.25em 0.5em 1em !important;
}
[class~="zs-margin-2-0.5-1-2"] {
  margin: 1em 0.25em 0.5em 1em !important;
}
[class~="zs-padding-2-0.5-1.5-0"] {
  padding: 1em 0.25em 0.75em 0em !important;
}
[class~="zs-margin-2-0.5-1.5-0"] {
  margin: 1em 0.25em 0.75em 0em !important;
}
[class~="zs-padding-2-0.5-1.5-0.5"] {
  padding: 1em 0.25em 0.75em 0.25em !important;
}
[class~="zs-margin-2-0.5-1.5-0.5"] {
  margin: 1em 0.25em 0.75em 0.25em !important;
}
[class~="zs-padding-2-0.5-1.5-1"] {
  padding: 1em 0.25em 0.75em 0.5em !important;
}
[class~="zs-margin-2-0.5-1.5-1"] {
  margin: 1em 0.25em 0.75em 0.5em !important;
}
[class~="zs-padding-2-0.5-1.5-1.5"] {
  padding: 1em 0.25em 0.75em 0.75em !important;
}
[class~="zs-margin-2-0.5-1.5-1.5"] {
  margin: 1em 0.25em 0.75em 0.75em !important;
}
[class~="zs-padding-2-0.5-1.5-2"] {
  padding: 1em 0.25em 0.75em 1em !important;
}
[class~="zs-margin-2-0.5-1.5-2"] {
  margin: 1em 0.25em 0.75em 1em !important;
}
[class~="zs-padding-2-0.5-2-0"] {
  padding: 1em 0.25em 1em 0em !important;
}
[class~="zs-margin-2-0.5-2-0"] {
  margin: 1em 0.25em 1em 0em !important;
}
[class~="zs-padding-2-0.5-2-0.5"] {
  padding: 1em 0.25em 1em 0.25em !important;
}
[class~="zs-margin-2-0.5-2-0.5"] {
  margin: 1em 0.25em 1em 0.25em !important;
}
[class~="zs-padding-2-0.5-2-1"] {
  padding: 1em 0.25em 1em 0.5em !important;
}
[class~="zs-margin-2-0.5-2-1"] {
  margin: 1em 0.25em 1em 0.5em !important;
}
[class~="zs-padding-2-0.5-2-1.5"] {
  padding: 1em 0.25em 1em 0.75em !important;
}
[class~="zs-margin-2-0.5-2-1.5"] {
  margin: 1em 0.25em 1em 0.75em !important;
}
[class~="zs-padding-2-0.5-2-2"] {
  padding: 1em 0.25em 1em 1em !important;
}
[class~="zs-margin-2-0.5-2-2"] {
  margin: 1em 0.25em 1em 1em !important;
}
[class~="zs-padding-2-1-0-0"] {
  padding: 1em 0.5em 0em 0em !important;
}
[class~="zs-margin-2-1-0-0"] {
  margin: 1em 0.5em 0em 0em !important;
}
[class~="zs-padding-2-1-0-0.5"] {
  padding: 1em 0.5em 0em 0.25em !important;
}
[class~="zs-margin-2-1-0-0.5"] {
  margin: 1em 0.5em 0em 0.25em !important;
}
[class~="zs-padding-2-1-0-1"] {
  padding: 1em 0.5em 0em 0.5em !important;
}
[class~="zs-margin-2-1-0-1"] {
  margin: 1em 0.5em 0em 0.5em !important;
}
[class~="zs-padding-2-1-0-1.5"] {
  padding: 1em 0.5em 0em 0.75em !important;
}
[class~="zs-margin-2-1-0-1.5"] {
  margin: 1em 0.5em 0em 0.75em !important;
}
[class~="zs-padding-2-1-0-2"] {
  padding: 1em 0.5em 0em 1em !important;
}
[class~="zs-margin-2-1-0-2"] {
  margin: 1em 0.5em 0em 1em !important;
}
[class~="zs-padding-2-1-0.5-0"] {
  padding: 1em 0.5em 0.25em 0em !important;
}
[class~="zs-margin-2-1-0.5-0"] {
  margin: 1em 0.5em 0.25em 0em !important;
}
[class~="zs-padding-2-1-0.5-0.5"] {
  padding: 1em 0.5em 0.25em 0.25em !important;
}
[class~="zs-margin-2-1-0.5-0.5"] {
  margin: 1em 0.5em 0.25em 0.25em !important;
}
[class~="zs-padding-2-1-0.5-1"] {
  padding: 1em 0.5em 0.25em 0.5em !important;
}
[class~="zs-margin-2-1-0.5-1"] {
  margin: 1em 0.5em 0.25em 0.5em !important;
}
[class~="zs-padding-2-1-0.5-1.5"] {
  padding: 1em 0.5em 0.25em 0.75em !important;
}
[class~="zs-margin-2-1-0.5-1.5"] {
  margin: 1em 0.5em 0.25em 0.75em !important;
}
[class~="zs-padding-2-1-0.5-2"] {
  padding: 1em 0.5em 0.25em 1em !important;
}
[class~="zs-margin-2-1-0.5-2"] {
  margin: 1em 0.5em 0.25em 1em !important;
}
[class~="zs-padding-2-1-1-0"] {
  padding: 1em 0.5em 0.5em 0em !important;
}
[class~="zs-margin-2-1-1-0"] {
  margin: 1em 0.5em 0.5em 0em !important;
}
[class~="zs-padding-2-1-1-0.5"] {
  padding: 1em 0.5em 0.5em 0.25em !important;
}
[class~="zs-margin-2-1-1-0.5"] {
  margin: 1em 0.5em 0.5em 0.25em !important;
}
[class~="zs-padding-2-1-1-1"] {
  padding: 1em 0.5em 0.5em 0.5em !important;
}
[class~="zs-margin-2-1-1-1"] {
  margin: 1em 0.5em 0.5em 0.5em !important;
}
[class~="zs-padding-2-1-1-1.5"] {
  padding: 1em 0.5em 0.5em 0.75em !important;
}
[class~="zs-margin-2-1-1-1.5"] {
  margin: 1em 0.5em 0.5em 0.75em !important;
}
[class~="zs-padding-2-1-1-2"] {
  padding: 1em 0.5em 0.5em 1em !important;
}
[class~="zs-margin-2-1-1-2"] {
  margin: 1em 0.5em 0.5em 1em !important;
}
[class~="zs-padding-2-1-1.5-0"] {
  padding: 1em 0.5em 0.75em 0em !important;
}
[class~="zs-margin-2-1-1.5-0"] {
  margin: 1em 0.5em 0.75em 0em !important;
}
[class~="zs-padding-2-1-1.5-0.5"] {
  padding: 1em 0.5em 0.75em 0.25em !important;
}
[class~="zs-margin-2-1-1.5-0.5"] {
  margin: 1em 0.5em 0.75em 0.25em !important;
}
[class~="zs-padding-2-1-1.5-1"] {
  padding: 1em 0.5em 0.75em 0.5em !important;
}
[class~="zs-margin-2-1-1.5-1"] {
  margin: 1em 0.5em 0.75em 0.5em !important;
}
[class~="zs-padding-2-1-1.5-1.5"] {
  padding: 1em 0.5em 0.75em 0.75em !important;
}
[class~="zs-margin-2-1-1.5-1.5"] {
  margin: 1em 0.5em 0.75em 0.75em !important;
}
[class~="zs-padding-2-1-1.5-2"] {
  padding: 1em 0.5em 0.75em 1em !important;
}
[class~="zs-margin-2-1-1.5-2"] {
  margin: 1em 0.5em 0.75em 1em !important;
}
[class~="zs-padding-2-1-2-0"] {
  padding: 1em 0.5em 1em 0em !important;
}
[class~="zs-margin-2-1-2-0"] {
  margin: 1em 0.5em 1em 0em !important;
}
[class~="zs-padding-2-1-2-0.5"] {
  padding: 1em 0.5em 1em 0.25em !important;
}
[class~="zs-margin-2-1-2-0.5"] {
  margin: 1em 0.5em 1em 0.25em !important;
}
[class~="zs-padding-2-1-2-1"] {
  padding: 1em 0.5em 1em 0.5em !important;
}
[class~="zs-margin-2-1-2-1"] {
  margin: 1em 0.5em 1em 0.5em !important;
}
[class~="zs-padding-2-1-2-1.5"] {
  padding: 1em 0.5em 1em 0.75em !important;
}
[class~="zs-margin-2-1-2-1.5"] {
  margin: 1em 0.5em 1em 0.75em !important;
}
[class~="zs-padding-2-1-2-2"] {
  padding: 1em 0.5em 1em 1em !important;
}
[class~="zs-margin-2-1-2-2"] {
  margin: 1em 0.5em 1em 1em !important;
}
[class~="zs-padding-2-1.5-0-0"] {
  padding: 1em 0.75em 0em 0em !important;
}
[class~="zs-margin-2-1.5-0-0"] {
  margin: 1em 0.75em 0em 0em !important;
}
[class~="zs-padding-2-1.5-0-0.5"] {
  padding: 1em 0.75em 0em 0.25em !important;
}
[class~="zs-margin-2-1.5-0-0.5"] {
  margin: 1em 0.75em 0em 0.25em !important;
}
[class~="zs-padding-2-1.5-0-1"] {
  padding: 1em 0.75em 0em 0.5em !important;
}
[class~="zs-margin-2-1.5-0-1"] {
  margin: 1em 0.75em 0em 0.5em !important;
}
[class~="zs-padding-2-1.5-0-1.5"] {
  padding: 1em 0.75em 0em 0.75em !important;
}
[class~="zs-margin-2-1.5-0-1.5"] {
  margin: 1em 0.75em 0em 0.75em !important;
}
[class~="zs-padding-2-1.5-0-2"] {
  padding: 1em 0.75em 0em 1em !important;
}
[class~="zs-margin-2-1.5-0-2"] {
  margin: 1em 0.75em 0em 1em !important;
}
[class~="zs-padding-2-1.5-0.5-0"] {
  padding: 1em 0.75em 0.25em 0em !important;
}
[class~="zs-margin-2-1.5-0.5-0"] {
  margin: 1em 0.75em 0.25em 0em !important;
}
[class~="zs-padding-2-1.5-0.5-0.5"] {
  padding: 1em 0.75em 0.25em 0.25em !important;
}
[class~="zs-margin-2-1.5-0.5-0.5"] {
  margin: 1em 0.75em 0.25em 0.25em !important;
}
[class~="zs-padding-2-1.5-0.5-1"] {
  padding: 1em 0.75em 0.25em 0.5em !important;
}
[class~="zs-margin-2-1.5-0.5-1"] {
  margin: 1em 0.75em 0.25em 0.5em !important;
}
[class~="zs-padding-2-1.5-0.5-1.5"] {
  padding: 1em 0.75em 0.25em 0.75em !important;
}
[class~="zs-margin-2-1.5-0.5-1.5"] {
  margin: 1em 0.75em 0.25em 0.75em !important;
}
[class~="zs-padding-2-1.5-0.5-2"] {
  padding: 1em 0.75em 0.25em 1em !important;
}
[class~="zs-margin-2-1.5-0.5-2"] {
  margin: 1em 0.75em 0.25em 1em !important;
}
[class~="zs-padding-2-1.5-1-0"] {
  padding: 1em 0.75em 0.5em 0em !important;
}
[class~="zs-margin-2-1.5-1-0"] {
  margin: 1em 0.75em 0.5em 0em !important;
}
[class~="zs-padding-2-1.5-1-0.5"] {
  padding: 1em 0.75em 0.5em 0.25em !important;
}
[class~="zs-margin-2-1.5-1-0.5"] {
  margin: 1em 0.75em 0.5em 0.25em !important;
}
[class~="zs-padding-2-1.5-1-1"] {
  padding: 1em 0.75em 0.5em 0.5em !important;
}
[class~="zs-margin-2-1.5-1-1"] {
  margin: 1em 0.75em 0.5em 0.5em !important;
}
[class~="zs-padding-2-1.5-1-1.5"] {
  padding: 1em 0.75em 0.5em 0.75em !important;
}
[class~="zs-margin-2-1.5-1-1.5"] {
  margin: 1em 0.75em 0.5em 0.75em !important;
}
[class~="zs-padding-2-1.5-1-2"] {
  padding: 1em 0.75em 0.5em 1em !important;
}
[class~="zs-margin-2-1.5-1-2"] {
  margin: 1em 0.75em 0.5em 1em !important;
}
[class~="zs-padding-2-1.5-1.5-0"] {
  padding: 1em 0.75em 0.75em 0em !important;
}
[class~="zs-margin-2-1.5-1.5-0"] {
  margin: 1em 0.75em 0.75em 0em !important;
}
[class~="zs-padding-2-1.5-1.5-0.5"] {
  padding: 1em 0.75em 0.75em 0.25em !important;
}
[class~="zs-margin-2-1.5-1.5-0.5"] {
  margin: 1em 0.75em 0.75em 0.25em !important;
}
[class~="zs-padding-2-1.5-1.5-1"] {
  padding: 1em 0.75em 0.75em 0.5em !important;
}
[class~="zs-margin-2-1.5-1.5-1"] {
  margin: 1em 0.75em 0.75em 0.5em !important;
}
[class~="zs-padding-2-1.5-1.5-1.5"] {
  padding: 1em 0.75em 0.75em 0.75em !important;
}
[class~="zs-margin-2-1.5-1.5-1.5"] {
  margin: 1em 0.75em 0.75em 0.75em !important;
}
[class~="zs-padding-2-1.5-1.5-2"] {
  padding: 1em 0.75em 0.75em 1em !important;
}
[class~="zs-margin-2-1.5-1.5-2"] {
  margin: 1em 0.75em 0.75em 1em !important;
}
[class~="zs-padding-2-1.5-2-0"] {
  padding: 1em 0.75em 1em 0em !important;
}
[class~="zs-margin-2-1.5-2-0"] {
  margin: 1em 0.75em 1em 0em !important;
}
[class~="zs-padding-2-1.5-2-0.5"] {
  padding: 1em 0.75em 1em 0.25em !important;
}
[class~="zs-margin-2-1.5-2-0.5"] {
  margin: 1em 0.75em 1em 0.25em !important;
}
[class~="zs-padding-2-1.5-2-1"] {
  padding: 1em 0.75em 1em 0.5em !important;
}
[class~="zs-margin-2-1.5-2-1"] {
  margin: 1em 0.75em 1em 0.5em !important;
}
[class~="zs-padding-2-1.5-2-1.5"] {
  padding: 1em 0.75em 1em 0.75em !important;
}
[class~="zs-margin-2-1.5-2-1.5"] {
  margin: 1em 0.75em 1em 0.75em !important;
}
[class~="zs-padding-2-1.5-2-2"] {
  padding: 1em 0.75em 1em 1em !important;
}
[class~="zs-margin-2-1.5-2-2"] {
  margin: 1em 0.75em 1em 1em !important;
}
[class~="zs-padding-2-2-0-0"] {
  padding: 1em 1em 0em 0em !important;
}
[class~="zs-margin-2-2-0-0"] {
  margin: 1em 1em 0em 0em !important;
}
[class~="zs-padding-2-2-0-0.5"] {
  padding: 1em 1em 0em 0.25em !important;
}
[class~="zs-margin-2-2-0-0.5"] {
  margin: 1em 1em 0em 0.25em !important;
}
[class~="zs-padding-2-2-0-1"] {
  padding: 1em 1em 0em 0.5em !important;
}
[class~="zs-margin-2-2-0-1"] {
  margin: 1em 1em 0em 0.5em !important;
}
[class~="zs-padding-2-2-0-1.5"] {
  padding: 1em 1em 0em 0.75em !important;
}
[class~="zs-margin-2-2-0-1.5"] {
  margin: 1em 1em 0em 0.75em !important;
}
[class~="zs-padding-2-2-0-2"] {
  padding: 1em 1em 0em 1em !important;
}
[class~="zs-margin-2-2-0-2"] {
  margin: 1em 1em 0em 1em !important;
}
[class~="zs-padding-2-2-0.5-0"] {
  padding: 1em 1em 0.25em 0em !important;
}
[class~="zs-margin-2-2-0.5-0"] {
  margin: 1em 1em 0.25em 0em !important;
}
[class~="zs-padding-2-2-0.5-0.5"] {
  padding: 1em 1em 0.25em 0.25em !important;
}
[class~="zs-margin-2-2-0.5-0.5"] {
  margin: 1em 1em 0.25em 0.25em !important;
}
[class~="zs-padding-2-2-0.5-1"] {
  padding: 1em 1em 0.25em 0.5em !important;
}
[class~="zs-margin-2-2-0.5-1"] {
  margin: 1em 1em 0.25em 0.5em !important;
}
[class~="zs-padding-2-2-0.5-1.5"] {
  padding: 1em 1em 0.25em 0.75em !important;
}
[class~="zs-margin-2-2-0.5-1.5"] {
  margin: 1em 1em 0.25em 0.75em !important;
}
[class~="zs-padding-2-2-0.5-2"] {
  padding: 1em 1em 0.25em 1em !important;
}
[class~="zs-margin-2-2-0.5-2"] {
  margin: 1em 1em 0.25em 1em !important;
}
[class~="zs-padding-2-2-1-0"] {
  padding: 1em 1em 0.5em 0em !important;
}
[class~="zs-margin-2-2-1-0"] {
  margin: 1em 1em 0.5em 0em !important;
}
[class~="zs-padding-2-2-1-0.5"] {
  padding: 1em 1em 0.5em 0.25em !important;
}
[class~="zs-margin-2-2-1-0.5"] {
  margin: 1em 1em 0.5em 0.25em !important;
}
[class~="zs-padding-2-2-1-1"] {
  padding: 1em 1em 0.5em 0.5em !important;
}
[class~="zs-margin-2-2-1-1"] {
  margin: 1em 1em 0.5em 0.5em !important;
}
[class~="zs-padding-2-2-1-1.5"] {
  padding: 1em 1em 0.5em 0.75em !important;
}
[class~="zs-margin-2-2-1-1.5"] {
  margin: 1em 1em 0.5em 0.75em !important;
}
[class~="zs-padding-2-2-1-2"] {
  padding: 1em 1em 0.5em 1em !important;
}
[class~="zs-margin-2-2-1-2"] {
  margin: 1em 1em 0.5em 1em !important;
}
[class~="zs-padding-2-2-1.5-0"] {
  padding: 1em 1em 0.75em 0em !important;
}
[class~="zs-margin-2-2-1.5-0"] {
  margin: 1em 1em 0.75em 0em !important;
}
[class~="zs-padding-2-2-1.5-0.5"] {
  padding: 1em 1em 0.75em 0.25em !important;
}
[class~="zs-margin-2-2-1.5-0.5"] {
  margin: 1em 1em 0.75em 0.25em !important;
}
[class~="zs-padding-2-2-1.5-1"] {
  padding: 1em 1em 0.75em 0.5em !important;
}
[class~="zs-margin-2-2-1.5-1"] {
  margin: 1em 1em 0.75em 0.5em !important;
}
[class~="zs-padding-2-2-1.5-1.5"] {
  padding: 1em 1em 0.75em 0.75em !important;
}
[class~="zs-margin-2-2-1.5-1.5"] {
  margin: 1em 1em 0.75em 0.75em !important;
}
[class~="zs-padding-2-2-1.5-2"] {
  padding: 1em 1em 0.75em 1em !important;
}
[class~="zs-margin-2-2-1.5-2"] {
  margin: 1em 1em 0.75em 1em !important;
}
[class~="zs-padding-2-2-2-0"] {
  padding: 1em 1em 1em 0em !important;
}
[class~="zs-margin-2-2-2-0"] {
  margin: 1em 1em 1em 0em !important;
}
[class~="zs-padding-2-2-2-0.5"] {
  padding: 1em 1em 1em 0.25em !important;
}
[class~="zs-margin-2-2-2-0.5"] {
  margin: 1em 1em 1em 0.25em !important;
}
[class~="zs-padding-2-2-2-1"] {
  padding: 1em 1em 1em 0.5em !important;
}
[class~="zs-margin-2-2-2-1"] {
  margin: 1em 1em 1em 0.5em !important;
}
[class~="zs-padding-2-2-2-1.5"] {
  padding: 1em 1em 1em 0.75em !important;
}
[class~="zs-margin-2-2-2-1.5"] {
  margin: 1em 1em 1em 0.75em !important;
}
[class~="zs-padding-2-2-2-2"] {
  padding: 1em 1em 1em 1em !important;
}
[class~="zs-margin-2-2-2-2"] {
  margin: 1em 1em 1em 1em !important;
}
.zs-height-0 {
  height: 0% !important;
}
.zs-width-0 {
  width: 0% !important;
}
.zs-height-10 {
  height: 10% !important;
}
.zs-width-10 {
  width: 10% !important;
}
.zs-height-20 {
  height: 20% !important;
}
.zs-width-20 {
  width: 20% !important;
}
.zs-height-25 {
  height: 25% !important;
}
.zs-width-25 {
  width: 25% !important;
}
.zs-height-30 {
  height: 30% !important;
}
.zs-width-30 {
  width: 30% !important;
}
.zs-height-40 {
  height: 40% !important;
}
.zs-width-40 {
  width: 40% !important;
}
.zs-height-50 {
  height: 50% !important;
}
.zs-width-50 {
  width: 50% !important;
}
.zs-height-60 {
  height: 60% !important;
}
.zs-width-60 {
  width: 60% !important;
}
.zs-height-70 {
  height: 70% !important;
}
.zs-width-70 {
  width: 70% !important;
}
.zs-height-75 {
  height: 75% !important;
}
.zs-width-75 {
  width: 75% !important;
}
.zs-height-80 {
  height: 80% !important;
}
.zs-width-80 {
  width: 80% !important;
}
.zs-height-90 {
  height: 90% !important;
}
.zs-width-90 {
  width: 90% !important;
}
.zs-height-100 {
  height: 100% !important;
}
.zs-width-100 {
  width: 100% !important;
}
.zs-height-auto {
  height: auto !important;
}
.zs-width-auto {
  width: auto !important;
}
.zs-valign-super {
  vertical-align: super !important;
}
.zs-valign-sub {
  vertical-align: sub !important;
}
.zs-valign-text-top {
  vertical-align: text-top !important;
}
.zs-valign-text-bottom {
  vertical-align: text-bottom !important;
}
.zs-valign-bottom {
  vertical-align: bottom !important;
}
.zs-valign-middle {
  vertical-align: middle !important;
}
.zs-valign-top {
  vertical-align: top !important;
}
.zs-valign-baseline {
  vertical-align: baseline !important;
}
.zs-text-align-justify {
  text-align: justify !important;
}
.zs-text-align-center {
  text-align: center !important;
}
.zs-text-align-right {
  text-align: right !important;
}
.zs-text-align-left {
  text-align: left !important;
}
.zs-float-none {
  float: none !important;
}
.zs-float-right {
  float: right !important;
}
.zs-float-left {
  float: left !important;
}
.zs-clear-float:after {
  content: " ";
  display: block;
  clear: both;
}
.zs-visibility-a11y-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  position: absolute !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden !important;
  white-space: nowrap !important;
}
[hidden] {
  display: none !important;
}
[disabled] [input][disabled] {
  opacity: 1 !important;
}
.zs-field-shadow {
  display: block;
}
.zs-field {
  display: block;
}
.zs-field > label {
  display: inline-block;
}
.zs-field [input],
.zs-field [textarea] {
  outline: none;
  font-weight: normal;
  font-size: 1em;
  color: var(--zs-field-text-color, #2F2C3C);
  border-radius: 0;
  padding: 0.75em 0.75em 0.75em 0.75em;
  display: block;
}
.zs-field [input][type="number"],
.zs-field [textarea][type="number"] {
  text-align: right;
}
.zs-field [input][type="checkbox"],
.zs-field [textarea][type="checkbox"],
.zs-field [input][type="radio"],
.zs-field [textarea][type="radio"] {
  border: none;
}
.zs-field [input]:focus,
.zs-field [textarea]:focus,
.zs-field [input] :invalid:focus,
.zs-field [textarea] :invalid:focus {
  outline: none;
  box-shadow: none;
}
.zs-field [label] {
  display: block;
  font-size: 0.75em;
  padding: 0em 0em 0.66666667em 0em;
  font-weight: 700;
  letter-spacing: -0.013em;
  line-height: 1.37;
  color: var(--zs-field-label-color, #1A1628);
}
.zs-field [field].zs-input-icon {
  display: block;
  position: relative;
  z-index: 1;
}
.zs-field [field].zs-input-icon textarea[input] {
  padding-right: 3em !important;
}
.zs-field [field].zs-input-icon [input] {
  padding-right: 3em !important;
}
.zs-field [field].zs-input-icon::before {
  font-family: 'zsIcons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Enable Ligatures ================ */
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga";
  font-feature-settings: "liga";
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  display: inline-block;
  pointer-events: none;
  top: 50%;
  font-size: 1.5em;
  width: auto;
  right: 0.5em;
  padding: 0;
  transform: translate(0, -50%);
  z-index: 1;
  text-align: center;
  background: transparent;
  position: absolute;
}
.zs-field [clear] {
  font-size: 1.5em;
  width: auto;
  right: 0.5em;
  padding: 0;
}
.zs-field [input]:not([size]):not([width]):not([type="image"]):not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="reset"]) {
  width: 100%;
}
.zs-field [field] {
  position: relative;
  display: block;
}
.zs-field textarea + [clear] {
  top: 0.66666667em;
  transform: none;
}
.zs-field [clear] {
  position: absolute;
  display: inline-block;
  top: 50%;
  transform: translate(0, -50%);
  border: 0;
  z-index: 2;
  text-align: center;
}
.zs-field [input][type="text"],
.zs-field [input][type="password"],
.zs-field [input][type="number"],
.zs-field [input][type="tel"],
.zs-field [input][type="url"],
.zs-field [input][type="email"],
.zs-field [input][type="search"],
.zs-field [input][type="time"],
.zs-field [input][type="week"],
.zs-field [input][type="date"] {
  line-height: 1.5;
  border: 1px solid var(--zs-field-border-color, #DEDCDE);
  background: var(--zs-field-filled-background-color, #FAFAFA);
}
.zs-field [input][type="text"]::-webkit-input-placeholder,
.zs-field [input][type="password"]::-webkit-input-placeholder,
.zs-field [input][type="number"]::-webkit-input-placeholder,
.zs-field [input][type="tel"]::-webkit-input-placeholder,
.zs-field [input][type="url"]::-webkit-input-placeholder,
.zs-field [input][type="email"]::-webkit-input-placeholder,
.zs-field [input][type="search"]::-webkit-input-placeholder,
.zs-field [input][type="time"]::-webkit-input-placeholder,
.zs-field [input][type="week"]::-webkit-input-placeholder,
.zs-field [input][type="date"]::-webkit-input-placeholder {
  font-style: italic;
  color: var(--zs-field-placeholder-color, #716E79);
}
.zs-field [input][type="text"]:-moz-placeholder,
.zs-field [input][type="password"]:-moz-placeholder,
.zs-field [input][type="number"]:-moz-placeholder,
.zs-field [input][type="tel"]:-moz-placeholder,
.zs-field [input][type="url"]:-moz-placeholder,
.zs-field [input][type="email"]:-moz-placeholder,
.zs-field [input][type="search"]:-moz-placeholder,
.zs-field [input][type="time"]:-moz-placeholder,
.zs-field [input][type="week"]:-moz-placeholder,
.zs-field [input][type="date"]:-moz-placeholder {
  /* Firefox 18- */
  font-style: italic;
  color: var(--zs-field-placeholder-color, #716E79);
}
.zs-field [input][type="text"]::-moz-placeholder,
.zs-field [input][type="password"]::-moz-placeholder,
.zs-field [input][type="number"]::-moz-placeholder,
.zs-field [input][type="tel"]::-moz-placeholder,
.zs-field [input][type="url"]::-moz-placeholder,
.zs-field [input][type="email"]::-moz-placeholder,
.zs-field [input][type="search"]::-moz-placeholder,
.zs-field [input][type="time"]::-moz-placeholder,
.zs-field [input][type="week"]::-moz-placeholder,
.zs-field [input][type="date"]::-moz-placeholder {
  /* Firefox 19+ */
  font-style: italic;
  color: var(--zs-field-placeholder-color, #716E79);
}
.zs-field [input][type="text"]:focus,
.zs-field [input][type="password"]:focus,
.zs-field [input][type="number"]:focus,
.zs-field [input][type="tel"]:focus,
.zs-field [input][type="url"]:focus,
.zs-field [input][type="email"]:focus,
.zs-field [input][type="search"]:focus,
.zs-field [input][type="time"]:focus,
.zs-field [input][type="week"]:focus,
.zs-field [input][type="date"]:focus {
  border: 1px solid var(--zs-field-focus-border-color, #27A6A4);
}
.zs-field [input][type="text"]:hover,
.zs-field [input][type="password"]:hover,
.zs-field [input][type="number"]:hover,
.zs-field [input][type="tel"]:hover,
.zs-field [input][type="url"]:hover,
.zs-field [input][type="email"]:hover,
.zs-field [input][type="search"]:hover,
.zs-field [input][type="time"]:hover,
.zs-field [input][type="week"]:hover,
.zs-field [input][type="date"]:hover {
  border: 1px solid var(--zs-field-hover-border-color, #9C9AA1);
}
.zs-field [input][type="text"]:active,
.zs-field [input][type="password"]:active,
.zs-field [input][type="number"]:active,
.zs-field [input][type="tel"]:active,
.zs-field [input][type="url"]:active,
.zs-field [input][type="email"]:active,
.zs-field [input][type="search"]:active,
.zs-field [input][type="time"]:active,
.zs-field [input][type="week"]:active,
.zs-field [input][type="date"]:active {
  border: 1px solid var(--zs-field-active-border-color, #27A6A4);
}
.zs-field [input][type="text"]:focus,
.zs-field [input][type="password"]:focus,
.zs-field [input][type="number"]:focus,
.zs-field [input][type="tel"]:focus,
.zs-field [input][type="url"]:focus,
.zs-field [input][type="email"]:focus,
.zs-field [input][type="search"]:focus,
.zs-field [input][type="time"]:focus,
.zs-field [input][type="week"]:focus,
.zs-field [input][type="date"]:focus {
  background: var(--zs-field-focus-background-color, #F4F3F3);
}
.zs-field [input][type="text"]:hover,
.zs-field [input][type="password"]:hover,
.zs-field [input][type="number"]:hover,
.zs-field [input][type="tel"]:hover,
.zs-field [input][type="url"]:hover,
.zs-field [input][type="email"]:hover,
.zs-field [input][type="search"]:hover,
.zs-field [input][type="time"]:hover,
.zs-field [input][type="week"]:hover,
.zs-field [input][type="date"]:hover {
  background: var(--zs-field-hover-background-color, #FAFAFA);
}
.zs-field [input][type="text"]:active,
.zs-field [input][type="password"]:active,
.zs-field [input][type="number"]:active,
.zs-field [input][type="tel"]:active,
.zs-field [input][type="url"]:active,
.zs-field [input][type="email"]:active,
.zs-field [input][type="search"]:active,
.zs-field [input][type="time"]:active,
.zs-field [input][type="week"]:active,
.zs-field [input][type="date"]:active {
  background: var(--zs-field-active-background-color, #F4F3F3);
}
.zs-field [input][type="text"]:focus:hover,
.zs-field [input][type="password"]:focus:hover,
.zs-field [input][type="number"]:focus:hover,
.zs-field [input][type="tel"]:focus:hover,
.zs-field [input][type="url"]:focus:hover,
.zs-field [input][type="email"]:focus:hover,
.zs-field [input][type="search"]:focus:hover,
.zs-field [input][type="time"]:focus:hover,
.zs-field [input][type="week"]:focus:hover,
.zs-field [input][type="date"]:focus:hover {
  border: 1px solid var(--zs-field-focus-border-color, #27A6A4);
  background: var(--zs-field-focus-background-color, #F4F3F3);
}
.zs-field [input][type="text"]:not(:focus):not(:hover):not([value]):not(:disabled):not(.zs-disabled),
.zs-field [input][type="password"]:not(:focus):not(:hover):not([value]):not(:disabled):not(.zs-disabled),
.zs-field [input][type="number"]:not(:focus):not(:hover):not([value]):not(:disabled):not(.zs-disabled),
.zs-field [input][type="tel"]:not(:focus):not(:hover):not([value]):not(:disabled):not(.zs-disabled),
.zs-field [input][type="url"]:not(:focus):not(:hover):not([value]):not(:disabled):not(.zs-disabled),
.zs-field [input][type="email"]:not(:focus):not(:hover):not([value]):not(:disabled):not(.zs-disabled),
.zs-field [input][type="search"]:not(:focus):not(:hover):not([value]):not(:disabled):not(.zs-disabled),
.zs-field [input][type="time"]:not(:focus):not(:hover):not([value]):not(:disabled):not(.zs-disabled),
.zs-field [input][type="week"]:not(:focus):not(:hover):not([value]):not(:disabled):not(.zs-disabled),
.zs-field [input][type="date"]:not(:focus):not(:hover):not([value]):not(:disabled):not(.zs-disabled),
.zs-field [input][type="text"]:not(:focus):not(:hover)[value=""]:not(:disabled):not(.zs-disabled),
.zs-field [input][type="password"]:not(:focus):not(:hover)[value=""]:not(:disabled):not(.zs-disabled),
.zs-field [input][type="number"]:not(:focus):not(:hover)[value=""]:not(:disabled):not(.zs-disabled),
.zs-field [input][type="tel"]:not(:focus):not(:hover)[value=""]:not(:disabled):not(.zs-disabled),
.zs-field [input][type="url"]:not(:focus):not(:hover)[value=""]:not(:disabled):not(.zs-disabled),
.zs-field [input][type="email"]:not(:focus):not(:hover)[value=""]:not(:disabled):not(.zs-disabled),
.zs-field [input][type="search"]:not(:focus):not(:hover)[value=""]:not(:disabled):not(.zs-disabled),
.zs-field [input][type="time"]:not(:focus):not(:hover)[value=""]:not(:disabled):not(.zs-disabled),
.zs-field [input][type="week"]:not(:focus):not(:hover)[value=""]:not(:disabled):not(.zs-disabled),
.zs-field [input][type="date"]:not(:focus):not(:hover)[value=""]:not(:disabled):not(.zs-disabled) {
  background: var(--zs-field-background-color, #ffffff);
}
.zs-field textarea[input] {
  transition: background 0.1s ease-out, border-color 0.1s ease-out;
  font-family: "Open Sans", sans-serif;
  line-height: 1.5;
  font-size: 1em;
  border-radius: 0;
  padding: 1em 1em 1em 1em;
  letter-spacing: 0.013em;
  outline: none;
  border: 1px solid var(--zs-field-border-color, #DEDCDE);
  background: var(--zs-field-filled-background-color, #FAFAFA);
}
@media (prefers-reduced-motion) {
  .zs-field textarea[input] {
    transition-duration: 0s;
  }
}
.zs-field textarea[input]::selection {
  background-color: var(--zs-textarea-selection-color, #FFD680);
}
.zs-field textarea[input]::-moz-selection {
  background-color: var(--zs-textarea-selection-color, #FFD680);
}
.zs-field textarea[input]::-webkit-input-placeholder {
  font-style: italic;
  color: var(--zs-field-placeholder-color, #716E79);
}
.zs-field textarea[input]:-moz-placeholder {
  /* Firefox 18- */
  font-style: italic;
  color: var(--zs-field-placeholder-color, #716E79);
}
.zs-field textarea[input]::-moz-placeholder {
  /* Firefox 19+ */
  font-style: italic;
  color: var(--zs-field-placeholder-color, #716E79);
}
.zs-field textarea[input]:focus {
  border: 1px solid var(--zs-field-focus-border-color, #27A6A4);
}
.zs-field textarea[input]:hover {
  border: 1px solid var(--zs-field-hover-border-color, #9C9AA1);
}
.zs-field textarea[input]:active {
  border: 1px solid var(--zs-field-active-border-color, #27A6A4);
}
.zs-field textarea[input]:focus {
  background: var(--zs-field-focus-background-color, #F4F3F3);
}
.zs-field textarea[input]:hover {
  background: var(--zs-field-hover-background-color, #FAFAFA);
}
.zs-field textarea[input]:active {
  background: var(--zs-field-active-background-color, #F4F3F3);
}
.zs-field textarea[input]:focus:hover {
  border: 1px solid var(--zs-field-focus-border-color, #27A6A4);
  background: var(--zs-field-focus-background-color, #F4F3F3);
}
.zs-field textarea[input]:not(:focus):not(:hover):not([value]):not(:disabled):not(.zs-disabled),
.zs-field textarea[input]:not(:focus):not(:hover)[value=""]:not(:disabled):not(.zs-disabled) {
  background: var(--zs-field-background-color, #ffffff);
}
.zs-field.zs-error [input][type="text"],
.zs-field.zs-danger [input][type="text"],
.zs-field[invalid] [input][type="text"],
.zs-field.zs-error [input][type="password"],
.zs-field.zs-danger [input][type="password"],
.zs-field[invalid] [input][type="password"],
.zs-field.zs-error [input][type="number"],
.zs-field.zs-danger [input][type="number"],
.zs-field[invalid] [input][type="number"],
.zs-field.zs-error [input][type="tel"],
.zs-field.zs-danger [input][type="tel"],
.zs-field[invalid] [input][type="tel"],
.zs-field.zs-error [input][type="url"],
.zs-field.zs-danger [input][type="url"],
.zs-field[invalid] [input][type="url"],
.zs-field.zs-error [input][type="email"],
.zs-field.zs-danger [input][type="email"],
.zs-field[invalid] [input][type="email"],
.zs-field.zs-error [input][type="search"],
.zs-field.zs-danger [input][type="search"],
.zs-field[invalid] [input][type="search"],
.zs-field.zs-error [input][type="time"],
.zs-field.zs-danger [input][type="time"],
.zs-field[invalid] [input][type="time"],
.zs-field.zs-error [input][type="week"],
.zs-field.zs-danger [input][type="week"],
.zs-field[invalid] [input][type="week"],
.zs-field.zs-error [input][type="date"],
.zs-field.zs-danger [input][type="date"],
.zs-field[invalid] [input][type="date"] {
  border: 1px solid var(--zs-danger-color, #B21111);
}
.zs-field.zs-error [input][type="text"]:focus,
.zs-field.zs-danger [input][type="text"]:focus,
.zs-field[invalid] [input][type="text"]:focus,
.zs-field.zs-error [input][type="password"]:focus,
.zs-field.zs-danger [input][type="password"]:focus,
.zs-field[invalid] [input][type="password"]:focus,
.zs-field.zs-error [input][type="number"]:focus,
.zs-field.zs-danger [input][type="number"]:focus,
.zs-field[invalid] [input][type="number"]:focus,
.zs-field.zs-error [input][type="tel"]:focus,
.zs-field.zs-danger [input][type="tel"]:focus,
.zs-field[invalid] [input][type="tel"]:focus,
.zs-field.zs-error [input][type="url"]:focus,
.zs-field.zs-danger [input][type="url"]:focus,
.zs-field[invalid] [input][type="url"]:focus,
.zs-field.zs-error [input][type="email"]:focus,
.zs-field.zs-danger [input][type="email"]:focus,
.zs-field[invalid] [input][type="email"]:focus,
.zs-field.zs-error [input][type="search"]:focus,
.zs-field.zs-danger [input][type="search"]:focus,
.zs-field[invalid] [input][type="search"]:focus,
.zs-field.zs-error [input][type="time"]:focus,
.zs-field.zs-danger [input][type="time"]:focus,
.zs-field[invalid] [input][type="time"]:focus,
.zs-field.zs-error [input][type="week"]:focus,
.zs-field.zs-danger [input][type="week"]:focus,
.zs-field[invalid] [input][type="week"]:focus,
.zs-field.zs-error [input][type="date"]:focus,
.zs-field.zs-danger [input][type="date"]:focus,
.zs-field[invalid] [input][type="date"]:focus {
  border: 1px solid var(--zs-danger-focus-color, #892208);
}
.zs-field.zs-error [input][type="text"]:hover,
.zs-field.zs-danger [input][type="text"]:hover,
.zs-field[invalid] [input][type="text"]:hover,
.zs-field.zs-error [input][type="password"]:hover,
.zs-field.zs-danger [input][type="password"]:hover,
.zs-field[invalid] [input][type="password"]:hover,
.zs-field.zs-error [input][type="number"]:hover,
.zs-field.zs-danger [input][type="number"]:hover,
.zs-field[invalid] [input][type="number"]:hover,
.zs-field.zs-error [input][type="tel"]:hover,
.zs-field.zs-danger [input][type="tel"]:hover,
.zs-field[invalid] [input][type="tel"]:hover,
.zs-field.zs-error [input][type="url"]:hover,
.zs-field.zs-danger [input][type="url"]:hover,
.zs-field[invalid] [input][type="url"]:hover,
.zs-field.zs-error [input][type="email"]:hover,
.zs-field.zs-danger [input][type="email"]:hover,
.zs-field[invalid] [input][type="email"]:hover,
.zs-field.zs-error [input][type="search"]:hover,
.zs-field.zs-danger [input][type="search"]:hover,
.zs-field[invalid] [input][type="search"]:hover,
.zs-field.zs-error [input][type="time"]:hover,
.zs-field.zs-danger [input][type="time"]:hover,
.zs-field[invalid] [input][type="time"]:hover,
.zs-field.zs-error [input][type="week"]:hover,
.zs-field.zs-danger [input][type="week"]:hover,
.zs-field[invalid] [input][type="week"]:hover,
.zs-field.zs-error [input][type="date"]:hover,
.zs-field.zs-danger [input][type="date"]:hover,
.zs-field[invalid] [input][type="date"]:hover {
  border: 1px solid var(--zs-danger-hover-color, #F27755);
}
.zs-field.zs-error [input][type="text"]:active,
.zs-field.zs-danger [input][type="text"]:active,
.zs-field[invalid] [input][type="text"]:active,
.zs-field.zs-error [input][type="password"]:active,
.zs-field.zs-danger [input][type="password"]:active,
.zs-field[invalid] [input][type="password"]:active,
.zs-field.zs-error [input][type="number"]:active,
.zs-field.zs-danger [input][type="number"]:active,
.zs-field[invalid] [input][type="number"]:active,
.zs-field.zs-error [input][type="tel"]:active,
.zs-field.zs-danger [input][type="tel"]:active,
.zs-field[invalid] [input][type="tel"]:active,
.zs-field.zs-error [input][type="url"]:active,
.zs-field.zs-danger [input][type="url"]:active,
.zs-field[invalid] [input][type="url"]:active,
.zs-field.zs-error [input][type="email"]:active,
.zs-field.zs-danger [input][type="email"]:active,
.zs-field[invalid] [input][type="email"]:active,
.zs-field.zs-error [input][type="search"]:active,
.zs-field.zs-danger [input][type="search"]:active,
.zs-field[invalid] [input][type="search"]:active,
.zs-field.zs-error [input][type="time"]:active,
.zs-field.zs-danger [input][type="time"]:active,
.zs-field[invalid] [input][type="time"]:active,
.zs-field.zs-error [input][type="week"]:active,
.zs-field.zs-danger [input][type="week"]:active,
.zs-field[invalid] [input][type="week"]:active,
.zs-field.zs-error [input][type="date"]:active,
.zs-field.zs-danger [input][type="date"]:active,
.zs-field[invalid] [input][type="date"]:active {
  border: 1px solid var(--zs-danger-active-color, #5C1A0B);
}
.zs-field.zs-error textarea,
.zs-field.zs-danger textarea,
.zs-field[invalid] textarea,
.zs-field.zs-error textarea[input],
.zs-field.zs-danger textarea[input],
.zs-field[invalid] textarea[input] {
  border: 1px solid var(--zs-danger-color, #B21111);
}
.zs-field.zs-error textarea:focus,
.zs-field.zs-danger textarea:focus,
.zs-field[invalid] textarea:focus,
.zs-field.zs-error textarea[input]:focus,
.zs-field.zs-danger textarea[input]:focus,
.zs-field[invalid] textarea[input]:focus {
  border: 1px solid var(--zs-danger-focus-color, #892208);
}
.zs-field.zs-error textarea:hover,
.zs-field.zs-danger textarea:hover,
.zs-field[invalid] textarea:hover,
.zs-field.zs-error textarea[input]:hover,
.zs-field.zs-danger textarea[input]:hover,
.zs-field[invalid] textarea[input]:hover {
  border: 1px solid var(--zs-danger-hover-color, #F27755);
}
.zs-field.zs-error textarea:active,
.zs-field.zs-danger textarea:active,
.zs-field[invalid] textarea:active,
.zs-field.zs-error textarea[input]:active,
.zs-field.zs-danger textarea[input]:active,
.zs-field[invalid] textarea[input]:active {
  border: 1px solid var(--zs-danger-active-color, #5C1A0B);
}
.zs-field.zs-error [clear],
.zs-field.zs-danger [clear],
.zs-field[invalid] [clear] {
  color: var(--zs-danger-color, #B21111);
}
.zs-field.zs-error [clear]:visited,
.zs-field.zs-danger [clear]:visited,
.zs-field[invalid] [clear]:visited {
  color: var(--zs-danger-color, #B21111);
}
.zs-field.zs-error [clear]:focus,
.zs-field.zs-danger [clear]:focus,
.zs-field[invalid] [clear]:focus {
  color: var(--zs-danger-focus-color, #892208);
}
.zs-field.zs-error [clear]:hover,
.zs-field.zs-danger [clear]:hover,
.zs-field[invalid] [clear]:hover {
  color: var(--zs-danger-hover-color, #F27755);
}
.zs-field.zs-error [clear]:active,
.zs-field.zs-danger [clear]:active,
.zs-field[invalid] [clear]:active {
  color: var(--zs-danger-active-color, #5C1A0B);
}
.zs-field.zs-success [input][type="text"],
.zs-field.zs-success [input][type="password"],
.zs-field.zs-success [input][type="number"],
.zs-field.zs-success [input][type="tel"],
.zs-field.zs-success [input][type="url"],
.zs-field.zs-success [input][type="email"],
.zs-field.zs-success [input][type="search"],
.zs-field.zs-success [input][type="time"],
.zs-field.zs-success [input][type="week"],
.zs-field.zs-success [input][type="date"] {
  border: 1px solid var(--zs-success-color, #00AA67);
}
.zs-field.zs-success [input][type="text"]:focus,
.zs-field.zs-success [input][type="password"]:focus,
.zs-field.zs-success [input][type="number"]:focus,
.zs-field.zs-success [input][type="tel"]:focus,
.zs-field.zs-success [input][type="url"]:focus,
.zs-field.zs-success [input][type="email"]:focus,
.zs-field.zs-success [input][type="search"]:focus,
.zs-field.zs-success [input][type="time"]:focus,
.zs-field.zs-success [input][type="week"]:focus,
.zs-field.zs-success [input][type="date"]:focus {
  border: 1px solid var(--zs-success-focus-color, #0A6E5E);
}
.zs-field.zs-success [input][type="text"]:hover,
.zs-field.zs-success [input][type="password"]:hover,
.zs-field.zs-success [input][type="number"]:hover,
.zs-field.zs-success [input][type="tel"]:hover,
.zs-field.zs-success [input][type="url"]:hover,
.zs-field.zs-success [input][type="email"]:hover,
.zs-field.zs-success [input][type="search"]:hover,
.zs-field.zs-success [input][type="time"]:hover,
.zs-field.zs-success [input][type="week"]:hover,
.zs-field.zs-success [input][type="date"]:hover {
  border: 1px solid var(--zs-success-hover-color, #54D7AF);
}
.zs-field.zs-success [input][type="text"]:active,
.zs-field.zs-success [input][type="password"]:active,
.zs-field.zs-success [input][type="number"]:active,
.zs-field.zs-success [input][type="tel"]:active,
.zs-field.zs-success [input][type="url"]:active,
.zs-field.zs-success [input][type="email"]:active,
.zs-field.zs-success [input][type="search"]:active,
.zs-field.zs-success [input][type="time"]:active,
.zs-field.zs-success [input][type="week"]:active,
.zs-field.zs-success [input][type="date"]:active {
  border: 1px solid var(--zs-success-active-color, #0C4846);
}
.zs-field.zs-success textarea,
.zs-field.zs-success textarea[input] {
  border: 1px solid var(--zs-success-color, #00AA67);
}
.zs-field.zs-success textarea:focus,
.zs-field.zs-success textarea[input]:focus {
  border: 1px solid var(--zs-success-focus-color, #0A6E5E);
}
.zs-field.zs-success textarea:hover,
.zs-field.zs-success textarea[input]:hover {
  border: 1px solid var(--zs-success-hover-color, #54D7AF);
}
.zs-field.zs-success textarea:active,
.zs-field.zs-success textarea[input]:active {
  border: 1px solid var(--zs-success-active-color, #0C4846);
}
.zs-field.zs-success [clear] {
  color: var(--zs-success-color, #00AA67);
}
.zs-field.zs-success [clear]:visited {
  color: var(--zs-success-color, #00AA67);
}
.zs-field.zs-success [clear]:focus {
  color: var(--zs-success-focus-color, #0A6E5E);
}
.zs-field.zs-success [clear]:hover {
  color: var(--zs-success-hover-color, #54D7AF);
}
.zs-field.zs-success [clear]:active {
  color: var(--zs-success-active-color, #0C4846);
}
.zs-field.zs-warning [input][type="text"],
.zs-field.zs-warning [input][type="password"],
.zs-field.zs-warning [input][type="number"],
.zs-field.zs-warning [input][type="tel"],
.zs-field.zs-warning [input][type="url"],
.zs-field.zs-warning [input][type="email"],
.zs-field.zs-warning [input][type="search"],
.zs-field.zs-warning [input][type="time"],
.zs-field.zs-warning [input][type="week"],
.zs-field.zs-warning [input][type="date"] {
  border: 1px solid var(--zs-warning-color, #E3A900);
}
.zs-field.zs-warning [input][type="text"]:focus,
.zs-field.zs-warning [input][type="password"]:focus,
.zs-field.zs-warning [input][type="number"]:focus,
.zs-field.zs-warning [input][type="tel"]:focus,
.zs-field.zs-warning [input][type="url"]:focus,
.zs-field.zs-warning [input][type="email"]:focus,
.zs-field.zs-warning [input][type="search"]:focus,
.zs-field.zs-warning [input][type="time"]:focus,
.zs-field.zs-warning [input][type="week"]:focus,
.zs-field.zs-warning [input][type="date"]:focus {
  border: 1px solid var(--zs-warning-focus-color, #8A640C);
}
.zs-field.zs-warning [input][type="text"]:hover,
.zs-field.zs-warning [input][type="password"]:hover,
.zs-field.zs-warning [input][type="number"]:hover,
.zs-field.zs-warning [input][type="tel"]:hover,
.zs-field.zs-warning [input][type="url"]:hover,
.zs-field.zs-warning [input][type="email"]:hover,
.zs-field.zs-warning [input][type="search"]:hover,
.zs-field.zs-warning [input][type="time"]:hover,
.zs-field.zs-warning [input][type="week"]:hover,
.zs-field.zs-warning [input][type="date"]:hover {
  border: 1px solid var(--zs-warning-hover-color, #FDCA63);
}
.zs-field.zs-warning [input][type="text"]:active,
.zs-field.zs-warning [input][type="password"]:active,
.zs-field.zs-warning [input][type="number"]:active,
.zs-field.zs-warning [input][type="tel"]:active,
.zs-field.zs-warning [input][type="url"]:active,
.zs-field.zs-warning [input][type="email"]:active,
.zs-field.zs-warning [input][type="search"]:active,
.zs-field.zs-warning [input][type="time"]:active,
.zs-field.zs-warning [input][type="week"]:active,
.zs-field.zs-warning [input][type="date"]:active {
  border: 1px solid var(--zs-warning-active-color, #563F0E);
}
.zs-field.zs-warning textarea,
.zs-field.zs-warning textarea[input] {
  border: 1px solid var(--zs-warning-color, #E3A900);
}
.zs-field.zs-warning textarea:focus,
.zs-field.zs-warning textarea[input]:focus {
  border: 1px solid var(--zs-warning-focus-color, #8A640C);
}
.zs-field.zs-warning textarea:hover,
.zs-field.zs-warning textarea[input]:hover {
  border: 1px solid var(--zs-warning-hover-color, #FDCA63);
}
.zs-field.zs-warning textarea:active,
.zs-field.zs-warning textarea[input]:active {
  border: 1px solid var(--zs-warning-active-color, #563F0E);
}
.zs-field.zs-warning [clear] {
  color: var(--zs-warning-color, #E3A900);
}
.zs-field.zs-warning [clear]:visited {
  color: var(--zs-warning-color, #E3A900);
}
.zs-field.zs-warning [clear]:focus {
  color: var(--zs-warning-focus-color, #8A640C);
}
.zs-field.zs-warning [clear]:hover {
  color: var(--zs-warning-hover-color, #FDCA63);
}
.zs-field.zs-warning [clear]:active {
  color: var(--zs-warning-active-color, #563F0E);
}
.zs-field.zs-info [input][type="text"],
.zs-field.zs-info [input][type="password"],
.zs-field.zs-info [input][type="number"],
.zs-field.zs-info [input][type="tel"],
.zs-field.zs-info [input][type="url"],
.zs-field.zs-info [input][type="email"],
.zs-field.zs-info [input][type="search"],
.zs-field.zs-info [input][type="time"],
.zs-field.zs-info [input][type="week"],
.zs-field.zs-info [input][type="date"] {
  border: 1px solid var(--zs-info-color, #2B44C7);
}
.zs-field.zs-info [input][type="text"]:focus,
.zs-field.zs-info [input][type="password"]:focus,
.zs-field.zs-info [input][type="number"]:focus,
.zs-field.zs-info [input][type="tel"]:focus,
.zs-field.zs-info [input][type="url"]:focus,
.zs-field.zs-info [input][type="email"]:focus,
.zs-field.zs-info [input][type="search"]:focus,
.zs-field.zs-info [input][type="time"]:focus,
.zs-field.zs-info [input][type="week"]:focus,
.zs-field.zs-info [input][type="date"]:focus {
  border: 1px solid var(--zs-info-focus-color, #141187);
}
.zs-field.zs-info [input][type="text"]:hover,
.zs-field.zs-info [input][type="password"]:hover,
.zs-field.zs-info [input][type="number"]:hover,
.zs-field.zs-info [input][type="tel"]:hover,
.zs-field.zs-info [input][type="url"]:hover,
.zs-field.zs-info [input][type="email"]:hover,
.zs-field.zs-info [input][type="search"]:hover,
.zs-field.zs-info [input][type="time"]:hover,
.zs-field.zs-info [input][type="week"]:hover,
.zs-field.zs-info [input][type="date"]:hover {
  border: 1px solid var(--zs-info-hover-color, #7E92E9);
}
.zs-field.zs-info [input][type="text"]:active,
.zs-field.zs-info [input][type="password"]:active,
.zs-field.zs-info [input][type="number"]:active,
.zs-field.zs-info [input][type="tel"]:active,
.zs-field.zs-info [input][type="url"]:active,
.zs-field.zs-info [input][type="email"]:active,
.zs-field.zs-info [input][type="search"]:active,
.zs-field.zs-info [input][type="time"]:active,
.zs-field.zs-info [input][type="week"]:active,
.zs-field.zs-info [input][type="date"]:active {
  border: 1px solid var(--zs-info-active-color, #160C60);
}
.zs-field.zs-info textarea,
.zs-field.zs-info textarea[input] {
  border: 1px solid var(--zs-info-color, #2B44C7);
}
.zs-field.zs-info textarea:focus,
.zs-field.zs-info textarea[input]:focus {
  border: 1px solid var(--zs-info-focus-color, #141187);
}
.zs-field.zs-info textarea:hover,
.zs-field.zs-info textarea[input]:hover {
  border: 1px solid var(--zs-info-hover-color, #7E92E9);
}
.zs-field.zs-info textarea:active,
.zs-field.zs-info textarea[input]:active {
  border: 1px solid var(--zs-info-active-color, #160C60);
}
.zs-field.zs-info [clear] {
  color: var(--zs-info-color, #2B44C7);
}
.zs-field.zs-info [clear]:visited {
  color: var(--zs-info-color, #2B44C7);
}
.zs-field.zs-info [clear]:focus {
  color: var(--zs-info-focus-color, #141187);
}
.zs-field.zs-info [clear]:hover {
  color: var(--zs-info-hover-color, #7E92E9);
}
.zs-field.zs-info [clear]:active {
  color: var(--zs-info-active-color, #160C60);
}
.zs-field[type="radio"] [label],
.zs-field[type="checkbox"] [label] {
  padding: 0;
  font-size: 1em;
  line-height: 1.5;
  letter-spacing: -0.013em;
}
.zs-field[disabled],
.zs-field.zs-disabled {
  cursor: default;
  pointer-events: none;
  opacity: 1;
}
.zs-field[disabled] [input][type="text"],
.zs-field.zs-disabled [input][type="text"],
.zs-field[disabled] [input][type="password"],
.zs-field.zs-disabled [input][type="password"],
.zs-field[disabled] [input][type="number"],
.zs-field.zs-disabled [input][type="number"],
.zs-field[disabled] [input][type="tel"],
.zs-field.zs-disabled [input][type="tel"],
.zs-field[disabled] [input][type="url"],
.zs-field.zs-disabled [input][type="url"],
.zs-field[disabled] [input][type="email"],
.zs-field.zs-disabled [input][type="email"],
.zs-field[disabled] [input][type="search"],
.zs-field.zs-disabled [input][type="search"],
.zs-field[disabled] [input][type="time"],
.zs-field.zs-disabled [input][type="time"],
.zs-field[disabled] [input][type="week"],
.zs-field.zs-disabled [input][type="week"],
.zs-field[disabled] [input][type="date"],
.zs-field.zs-disabled [input][type="date"] {
  border: 1px solid var(--zs-disabled-border-color, #B2B0B6);
  color: var(--zs-disabled-text-color, #B2B0B6);
  background: none;
  background-color: var(--zs-disabled-background-color, #F4F3F3);
}
.zs-field[disabled] [input][type="text"]::-webkit-input-placeholder,
.zs-field.zs-disabled [input][type="text"]::-webkit-input-placeholder,
.zs-field[disabled] [input][type="password"]::-webkit-input-placeholder,
.zs-field.zs-disabled [input][type="password"]::-webkit-input-placeholder,
.zs-field[disabled] [input][type="number"]::-webkit-input-placeholder,
.zs-field.zs-disabled [input][type="number"]::-webkit-input-placeholder,
.zs-field[disabled] [input][type="tel"]::-webkit-input-placeholder,
.zs-field.zs-disabled [input][type="tel"]::-webkit-input-placeholder,
.zs-field[disabled] [input][type="url"]::-webkit-input-placeholder,
.zs-field.zs-disabled [input][type="url"]::-webkit-input-placeholder,
.zs-field[disabled] [input][type="email"]::-webkit-input-placeholder,
.zs-field.zs-disabled [input][type="email"]::-webkit-input-placeholder,
.zs-field[disabled] [input][type="search"]::-webkit-input-placeholder,
.zs-field.zs-disabled [input][type="search"]::-webkit-input-placeholder,
.zs-field[disabled] [input][type="time"]::-webkit-input-placeholder,
.zs-field.zs-disabled [input][type="time"]::-webkit-input-placeholder,
.zs-field[disabled] [input][type="week"]::-webkit-input-placeholder,
.zs-field.zs-disabled [input][type="week"]::-webkit-input-placeholder,
.zs-field[disabled] [input][type="date"]::-webkit-input-placeholder,
.zs-field.zs-disabled [input][type="date"]::-webkit-input-placeholder {
  color: var(--zs-field-disabled-placeholder-color, #B2B0B6);
}
.zs-field[disabled] [input][type="text"]:-moz-placeholder,
.zs-field.zs-disabled [input][type="text"]:-moz-placeholder,
.zs-field[disabled] [input][type="password"]:-moz-placeholder,
.zs-field.zs-disabled [input][type="password"]:-moz-placeholder,
.zs-field[disabled] [input][type="number"]:-moz-placeholder,
.zs-field.zs-disabled [input][type="number"]:-moz-placeholder,
.zs-field[disabled] [input][type="tel"]:-moz-placeholder,
.zs-field.zs-disabled [input][type="tel"]:-moz-placeholder,
.zs-field[disabled] [input][type="url"]:-moz-placeholder,
.zs-field.zs-disabled [input][type="url"]:-moz-placeholder,
.zs-field[disabled] [input][type="email"]:-moz-placeholder,
.zs-field.zs-disabled [input][type="email"]:-moz-placeholder,
.zs-field[disabled] [input][type="search"]:-moz-placeholder,
.zs-field.zs-disabled [input][type="search"]:-moz-placeholder,
.zs-field[disabled] [input][type="time"]:-moz-placeholder,
.zs-field.zs-disabled [input][type="time"]:-moz-placeholder,
.zs-field[disabled] [input][type="week"]:-moz-placeholder,
.zs-field.zs-disabled [input][type="week"]:-moz-placeholder,
.zs-field[disabled] [input][type="date"]:-moz-placeholder,
.zs-field.zs-disabled [input][type="date"]:-moz-placeholder {
  /* Firefox 18- */
  color: var(--zs-field-disabled-placeholder-color, #B2B0B6);
}
.zs-field[disabled] [input][type="text"]::-moz-placeholder,
.zs-field.zs-disabled [input][type="text"]::-moz-placeholder,
.zs-field[disabled] [input][type="password"]::-moz-placeholder,
.zs-field.zs-disabled [input][type="password"]::-moz-placeholder,
.zs-field[disabled] [input][type="number"]::-moz-placeholder,
.zs-field.zs-disabled [input][type="number"]::-moz-placeholder,
.zs-field[disabled] [input][type="tel"]::-moz-placeholder,
.zs-field.zs-disabled [input][type="tel"]::-moz-placeholder,
.zs-field[disabled] [input][type="url"]::-moz-placeholder,
.zs-field.zs-disabled [input][type="url"]::-moz-placeholder,
.zs-field[disabled] [input][type="email"]::-moz-placeholder,
.zs-field.zs-disabled [input][type="email"]::-moz-placeholder,
.zs-field[disabled] [input][type="search"]::-moz-placeholder,
.zs-field.zs-disabled [input][type="search"]::-moz-placeholder,
.zs-field[disabled] [input][type="time"]::-moz-placeholder,
.zs-field.zs-disabled [input][type="time"]::-moz-placeholder,
.zs-field[disabled] [input][type="week"]::-moz-placeholder,
.zs-field.zs-disabled [input][type="week"]::-moz-placeholder,
.zs-field[disabled] [input][type="date"]::-moz-placeholder,
.zs-field.zs-disabled [input][type="date"]::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--zs-field-disabled-placeholder-color, #B2B0B6);
}
.zs-field[disabled] textarea,
.zs-field.zs-disabled textarea {
  border: 1px solid var(--zs-disabled-border-color, #B2B0B6);
  color: var(--zs-disabled-text-color, #B2B0B6);
  background: none;
  background-color: var(--zs-disabled-background-color, #F4F3F3);
}
.zs-field[disabled] textarea::-webkit-input-placeholder,
.zs-field.zs-disabled textarea::-webkit-input-placeholder {
  color: var(--zs-field-disabled-placeholder-color, #B2B0B6);
}
.zs-field[disabled] textarea:-moz-placeholder,
.zs-field.zs-disabled textarea:-moz-placeholder {
  /* Firefox 18- */
  color: var(--zs-field-disabled-placeholder-color, #B2B0B6);
}
.zs-field[disabled] textarea::-moz-placeholder,
.zs-field.zs-disabled textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--zs-field-disabled-placeholder-color, #B2B0B6);
}
.zs-field[disabled] [clear],
.zs-field.zs-disabled [clear],
.zs-field[disabled] [field].zs-input-icon::before,
.zs-field.zs-disabled [field].zs-input-icon::before {
  color: var(--zs-disabled-text-color, #B2B0B6);
}
.zs-field[type="checkbox"] > label,
.zs-field[type="radio"] > label {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  font-weight: normal;
  cursor: pointer;
}
.zs-field[type="checkbox"] > label [label],
.zs-field[type="radio"] > label [label] {
  font-weight: normal;
}
.zs-field[type="checkbox"][disabled],
.zs-field[type="radio"][disabled],
.zs-field[type="checkbox"].zs-disabled,
.zs-field[type="radio"].zs-disabled {
  cursor: default;
  pointer-events: none;
  opacity: 1;
}
.zs-field[type="checkbox"][disabled] [input],
.zs-field[type="radio"][disabled] [input],
.zs-field[type="checkbox"].zs-disabled [input],
.zs-field[type="radio"].zs-disabled [input] {
  opacity: 0 !important;
}
.zs-field[type="checkbox"][disabled] [label],
.zs-field[type="radio"][disabled] [label],
.zs-field[type="checkbox"].zs-disabled [label],
.zs-field[type="radio"].zs-disabled [label] {
  color: var(--zs-disabled-text-color, #B2B0B6);
}
.zs-field[type="checkbox"] .zs-input-icon::before,
.zs-field[type="radio"] .zs-input-icon::before {
  display: none;
}
.zs-field[type="checkbox"] [clear],
.zs-field[type="radio"] [clear] {
  display: none;
}
.zs-field[type="checkbox"] [input],
.zs-field[type="radio"] [input] {
  width: auto;
}
.zs-field[type="color"] [input],
.zs-field[type="image"] [input] {
  padding: 0;
  border: 0;
}
.zs-field[clear][icon]:not([value=""]) [field]::before {
  display: none;
}
.zs-field[clear][icon]:not([value]) [field]::before {
  display: inline-block;
}
.zs-field-fluid,
.zs-master-style p.zs-field-fluid {
  margin: 0;
}
.zs-field-fluid:not([type="checkbox"]):not([type="radio"]) > label,
.zs-master-style p.zs-field-fluid:not([type="checkbox"]):not([type="radio"]) > label {
  display: block;
}
.zs-checkbox {
  display: block;
  position: relative;
  font-size: 1em;
  cursor: pointer;
}
.zs-checkbox input {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  font-size: inherit;
}
.zs-checkbox input ~ [checkbox] {
  position: relative;
  padding: 0em 0em 0em 1.5em;
  line-height: 1.5;
  display: inline;
  letter-spacing: -0.013em;
}
.zs-checkbox input ~ [checkbox]::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 1em;
  height: 1em;
  background: var(--zs-checkbox-background-color, #ffffff);
  border: 1px solid var(--zs-checkbox-border-color, #DEDCDE);
  transform: translateY(-50%);
  transition: background-color 0.1s ease-out;
}
@media (prefers-reduced-motion) {
  .zs-checkbox input ~ [checkbox]::before {
    transition-duration: 0s;
  }
}
.zs-checkbox input ~ [checkbox]::after {
  transform: translateY(-50%);
  position: absolute;
  display: inline;
  color: var(--zs-checkbox-checkmark-color, #ffffff);
  font-family: 'zsIcons';
  top: 50%;
  left: 0;
}
.zs-checkbox input:hover ~ [checkbox]::before {
  border-color: var(--zs-checkbox-hover-border-color, #27A6A4);
  background-color: var(--zs-checkbox-hover-background-color, rgba(39, 166, 164, 0.1));
}
.zs-checkbox input:checked ~ [checkbox]::before {
  border-color: var(--zs-checkbox-checked-border-color, #27A6A4);
  background: var(--zs-checkbox-checked-background-color, #27A6A4);
}
.zs-checkbox input:checked ~ [checkbox]::after {
  content: "\ea2e";
  animation: zs-reveal-check 0.2s forwards ease;
  font-weight: 400;
}
@media (prefers-reduced-motion) {
  .zs-checkbox input:checked ~ [checkbox]::after {
    animation-duration: 0s;
  }
}
@keyframes zs-reveal-check {
  from {
    clip-path: inset(0 100% 0 0);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}
.zs-checkbox input:indeterminate ~ [checkbox]::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 1em;
  height: 1em;
  background: var(--zs-checkbox-background-color, #ffffff);
  border: 1px solid var(--zs-checkbox-border-color, #DEDCDE);
  transform: translateY(-50%);
}
.zs-checkbox input:indeterminate ~ [checkbox]::after {
  display: inline;
  content: '';
  height: 0.5em;
  width: 0.5em;
  background: var(--zs-checkbox-checked-background-color, #27A6A4);
  top: 50%;
  position: absolute;
  left: 0;
  transform: translate(50%, -50%);
}
.zs-checkbox input:focus ~ [checkbox]::before {
  border-color: var(--zs-checkbox-focus-border-color, #2F6F7B);
  background: var(--zs-checkbox-focus-background-color, #F3FCFE);
}
.zs-checkbox input:checked:focus ~ [checkbox]::before {
  background: var(--zs-checkbox-checked-focus-background-color, #2F6F7B);
}
.zs-checkbox input:focus:indeterminate ~ [checkbox]::before {
  background: var(--zs-checkbox-background-color, #ffffff);
}
.zs-checkbox input:focus:indeterminate ~ [checkbox]::after {
  background: var(--zs-checkbox-checked-focus-background-color, #2F6F7B);
}
.zs-checkbox[disabled],
.zs-checkbox.zs-disabled {
  cursor: default;
  pointer-events: none;
  opacity: 1;
}
.zs-checkbox[disabled] input ~ [checkbox],
.zs-checkbox.zs-disabled input ~ [checkbox] {
  color: var(--zs-disabled-text-color, #B2B0B6);
}
.zs-checkbox[disabled] input ~ [checkbox]::before,
.zs-checkbox.zs-disabled input ~ [checkbox]::before {
  color: var(--zs-disabled-text-color, #B2B0B6);
  background: none;
  background-color: var(--zs-disabled-background-color, #F4F3F3);
  border-color: var(--zs-disabled-border-color, #B2B0B6);
  transition: none;
}
@media (prefers-reduced-motion) {
  .zs-checkbox[disabled] input ~ [checkbox]::before,
  .zs-checkbox.zs-disabled input ~ [checkbox]::before {
    transition-duration: 0s;
  }
}
.zs-checkbox[disabled] input:checked ~ [checkbox]::before,
.zs-checkbox.zs-disabled input:checked ~ [checkbox]::before {
  color: var(--zs-disabled-text-color, #B2B0B6);
  background: none;
  background-color: var(--zs-disabled-background-color, #F4F3F3);
  border-color: var(--zs-disabled-border-color, #B2B0B6);
}
.zs-checkbox[disabled] input:checked ~ [checkbox]::after,
.zs-checkbox.zs-disabled input:checked ~ [checkbox]::after {
  color: var(--zs-disabled-text-color, #B2B0B6);
  animation: none 300ms 0 ease;
}
@media (prefers-reduced-motion) {
  .zs-checkbox[disabled] input:checked ~ [checkbox]::after,
  .zs-checkbox.zs-disabled input:checked ~ [checkbox]::after {
    animation-duration: 0s;
  }
}
.zs-checkbox[disabled] input:indeterminate ~ [checkbox]::after,
.zs-checkbox.zs-disabled input:indeterminate ~ [checkbox]::after {
  background: var(--zs-checkbox-indeterminate-disabled-color, #B2B0B6);
}
.zs-checkbox-no-animation .zs-checkbox input:checked ~ [checkbox]::after {
  animation-duration: 0s;
}
.zs-radio {
  display: block;
  position: relative;
  font-size: 1em;
  cursor: pointer;
}
.zs-radio input {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  font-size: inherit;
}
.zs-radio input ~ [radio] {
  position: relative;
  padding: 0em 0em 0em 1.5em;
  font-size: 1em;
  line-height: 1.5;
  display: inline;
  letter-spacing: -0.013em;
}
.zs-radio input ~ [radio]::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background: var(--zs-radio-background-color, #FAFAFA);
  border: 1px solid var(--zs-radio-border-color, #DEDCDE);
  transform: translateY(-50%);
  transition: border-color 0.1s ease-out, background-color 0.1s ease-out;
}
@media (prefers-reduced-motion) {
  .zs-radio input ~ [radio]::before {
    transition-duration: 0s;
  }
}
.zs-radio input ~ [radio]::after {
  content: '';
  transform: translateY(-50%) scale(0);
  transition: transform 0.2s;
}
@media (prefers-reduced-motion) {
  .zs-radio input ~ [radio]::after {
    transition-duration: 0s;
  }
}
.zs-radio input:hover ~ [radio]::before {
  border-color: var(--zs-radio-hover-border-color, #27A6A4);
  background: var(--zs-radio-hover-background-color, rgba(39, 166, 164, 0.1));
}
.zs-radio input:checked ~ [radio]::before {
  border-color: var(--zs-radio-hover-border-color, #27A6A4);
}
.zs-radio input:checked ~ [radio]::after {
  position: absolute;
  top: 50%;
  left: 0.25em;
  content: '';
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  background: var(--zs-radio-check-color, #27A6A4);
  transform: translateY(-50%) scale(1);
}
.zs-radio input:focus ~ [radio]::before {
  border-color: var(--zs-radio-focus-border-color, #2F6F7B);
  background: var(--zs-radio-focus-background-color, #F3FCFE);
}
.zs-radio input:focus:checked ~ [radio]::before {
  background: var(--zs-radio-background-color, #FAFAFA);
}
.zs-radio input:focus:checked ~ [radio]::after {
  background: var(--zs-radio-checked-focus-background-color, #2F6F7B);
}
.zs-radio[disabled],
.zs-radio.zs-disabled {
  cursor: default;
  pointer-events: none;
  opacity: 1;
}
.zs-radio[disabled] input ~ [radio],
.zs-radio.zs-disabled input ~ [radio] {
  color: var(--zs-disabled-text-color, #B2B0B6);
}
.zs-radio[disabled] input ~ [radio]::before,
.zs-radio.zs-disabled input ~ [radio]::before {
  color: var(--zs-disabled-text-color, #B2B0B6);
  background: none;
  background-color: var(--zs-disabled-background-color, #F4F3F3);
  border-color: var(--zs-disabled-border-color, #B2B0B6);
}
.zs-radio[disabled] input:checked ~ [radio]::before,
.zs-radio.zs-disabled input:checked ~ [radio]::before {
  color: var(--zs-disabled-text-color, #B2B0B6);
  background: none;
  background-color: var(--zs-disabled-background-color, #F4F3F3);
  border-color: var(--zs-disabled-border-color, #B2B0B6);
}
.zs-radio[disabled] input:checked ~ [radio]::after,
.zs-radio.zs-disabled input:checked ~ [radio]::after {
  background: var(--zs-radio-indicator-disabled-color, #B2B0B6);
}
.zs-action-field {
  font-size: 1em;
  display: inline-block;
}
.zs-action-field > label,
.zs-action-field [field-container] {
  display: grid;
  grid-template-rows: auto auto auto;
  grid-template-columns: auto 1fr auto auto;
}
.zs-action-field [label] {
  display: inline-block;
  grid-row-start: 1;
  grid-column-start: 1;
  grid-column-end: span 4;
  font-size: 0.75em;
  padding: 0em 0em 0.66666667em 0em;
  font-weight: 700;
  letter-spacing: -0.013em;
  line-height: 1.37;
  color: var(--zs-field-label-color, #1A1628);
}
.zs-action-field [label]:empty {
  padding: 0;
}
.zs-action-field [helper] {
  display: inline-block;
  grid-row-start: 3;
  grid-column-start: 1;
  grid-column-end: span 4;
  font-size: 0.75em;
  padding: 0.66666667em 0em 0em 0em;
  letter-spacing: -0.013em;
  line-height: 1.5;
  color: var(--zs-field-helper-text-color, #716E79);
}
.zs-action-field [helper]:empty {
  padding: 0;
}
.zs-action-field [input] {
  grid-row-start: 2;
  grid-column-start: 1;
  grid-column-end: span 4;
  line-height: 1.5;
  font-size: 1em;
  border-radius: 0;
  padding: 0.75em 0.75em 0.75em 0.75em;
  outline: none;
  border: 1px solid var(--zs-field-border-color, #DEDCDE);
  background: var(--zs-field-filled-background-color, #FAFAFA);
  transition: background 0.1s ease-out, border-color 0.1s ease-out;
}
.zs-action-field [input]::-webkit-input-placeholder {
  font-style: italic;
  color: var(--zs-field-placeholder-color, #716E79);
}
.zs-action-field [input]:-moz-placeholder {
  /* Firefox 18- */
  font-style: italic;
  color: var(--zs-field-placeholder-color, #716E79);
}
.zs-action-field [input]::-moz-placeholder {
  /* Firefox 19+ */
  font-style: italic;
  color: var(--zs-field-placeholder-color, #716E79);
}
.zs-action-field [input]:focus {
  border: 1px solid var(--zs-field-focus-border-color, #27A6A4);
}
.zs-action-field [input]:hover {
  border: 1px solid var(--zs-field-hover-border-color, #9C9AA1);
}
.zs-action-field [input]:active {
  border: 1px solid var(--zs-field-active-border-color, #27A6A4);
}
.zs-action-field [input]:focus {
  background: var(--zs-field-focus-background-color, #F4F3F3);
}
.zs-action-field [input]:hover {
  background: var(--zs-field-hover-background-color, #FAFAFA);
}
.zs-action-field [input]:active {
  background: var(--zs-field-active-background-color, #F4F3F3);
}
.zs-action-field [input]:focus:hover {
  border: 1px solid var(--zs-field-focus-border-color, #27A6A4);
  background: var(--zs-field-focus-background-color, #F4F3F3);
}
.zs-action-field [input]:hover ~ [signifier] {
  color: var(--zs-signifier-icon-hover-color, #716E79);
}
.zs-action-field [input][value]:not([value=""]):not(:focus):not(:hover):not(:active) ~ [signifier] {
  color: var(--zs-signifier-icon-filled-color, #1A1628);
}
.zs-action-field [input]:focus:hover ~ [signifier] {
  color: var(--zs-signifier-icon-focus-color, #27A6A4);
}
.zs-action-field [input]:focus ~ [signifier] {
  color: var(--zs-signifier-icon-focus-color, #27A6A4);
}
.zs-action-field [input]:active ~ [signifier] {
  color: var(--zs-signifier-icon-active-color, #27A6A4);
}
@media (prefers-reduced-motion) {
  .zs-action-field [input] {
    transition-duration: 0s;
  }
}
.zs-action-field input[type="text"]:not(:focus):not(:hover):not([value]):not(:disabled):not(.zs-disabled),
.zs-action-field input[type="text"]:not(:focus):not(:hover)[value=""]:not(:disabled):not(.zs-disabled) {
  background: var(--zs-field-background-color, #ffffff);
}
.zs-action-field input[type="search"]::-webkit-search-cancel-button,
.zs-action-field input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}
.zs-action-field input[type="password"]::-ms-reveal,
.zs-action-field input[type="password"]::-ms-clear {
  display: none;
}
.zs-action-field [action] {
  grid-row-start: 2;
  grid-column-start: 4;
  align-self: center;
  justify-self: center;
  font-size: 1.5em;
  margin: 0em 0.5em 0em 0em;
}
.zs-action-field [signifier] {
  grid-row-start: 2;
  grid-column-start: 1;
  align-self: center;
  justify-self: center;
  color: var(--zs-signifier-icon-color, #716E79);
  font-size: 1.5em;
  margin: 0em 0.5em 0em 0.5em;
}
.zs-action-field [hint] {
  grid-row-start: 2;
  grid-column-start: 3;
  align-self: center;
  justify-self: center;
  font-size: 1.5em;
  margin: 0em 0.33333333em 0em 0em;
}
.zs-action-field [clear] {
  grid-row-start: 2;
  grid-column-start: 3;
  align-self: center;
  justify-self: center;
  font-size: 1.5em;
  margin: 0em 0.33333333em 0em 0em;
}
.zs-action-field[clear]:not([action-icon]) [clear] {
  margin: 0em 0.5em 0em 0em;
}
.zs-action-field[hint-icon]:not([action-icon]) [hint] {
  margin: 0em 0.5em 0em 0em;
}
.zs-action-field.zs-error [input],
.zs-action-field.zs-danger [input],
.zs-action-field[invalid] [input] {
  border: 1px solid var(--zs-danger-color, #B21111);
}
.zs-action-field.zs-error [input]:focus,
.zs-action-field.zs-danger [input]:focus,
.zs-action-field[invalid] [input]:focus {
  border: 1px solid var(--zs-danger-focus-color, #892208);
}
.zs-action-field.zs-error [input]:hover,
.zs-action-field.zs-danger [input]:hover,
.zs-action-field[invalid] [input]:hover {
  border: 1px solid var(--zs-danger-hover-color, #F27755);
}
.zs-action-field.zs-error [input]:active,
.zs-action-field.zs-danger [input]:active,
.zs-action-field[invalid] [input]:active {
  border: 1px solid var(--zs-danger-active-color, #5C1A0B);
}
.zs-action-field.zs-error [action],
.zs-action-field.zs-danger [action],
.zs-action-field[invalid] [action],
.zs-action-field.zs-error [hint],
.zs-action-field.zs-danger [hint],
.zs-action-field[invalid] [hint],
.zs-action-field.zs-error [clear],
.zs-action-field.zs-danger [clear],
.zs-action-field[invalid] [clear] {
  color: var(--zs-danger-color, #B21111);
}
.zs-action-field.zs-error [action]:visited,
.zs-action-field.zs-danger [action]:visited,
.zs-action-field[invalid] [action]:visited,
.zs-action-field.zs-error [hint]:visited,
.zs-action-field.zs-danger [hint]:visited,
.zs-action-field[invalid] [hint]:visited,
.zs-action-field.zs-error [clear]:visited,
.zs-action-field.zs-danger [clear]:visited,
.zs-action-field[invalid] [clear]:visited {
  color: var(--zs-danger-color, #B21111);
}
.zs-action-field.zs-error [action]:focus,
.zs-action-field.zs-danger [action]:focus,
.zs-action-field[invalid] [action]:focus,
.zs-action-field.zs-error [hint]:focus,
.zs-action-field.zs-danger [hint]:focus,
.zs-action-field[invalid] [hint]:focus,
.zs-action-field.zs-error [clear]:focus,
.zs-action-field.zs-danger [clear]:focus,
.zs-action-field[invalid] [clear]:focus {
  color: var(--zs-danger-focus-color, #892208);
}
.zs-action-field.zs-error [action]:hover,
.zs-action-field.zs-danger [action]:hover,
.zs-action-field[invalid] [action]:hover,
.zs-action-field.zs-error [hint]:hover,
.zs-action-field.zs-danger [hint]:hover,
.zs-action-field[invalid] [hint]:hover,
.zs-action-field.zs-error [clear]:hover,
.zs-action-field.zs-danger [clear]:hover,
.zs-action-field[invalid] [clear]:hover {
  color: var(--zs-danger-hover-color, #F27755);
}
.zs-action-field.zs-error [action]:active,
.zs-action-field.zs-danger [action]:active,
.zs-action-field[invalid] [action]:active,
.zs-action-field.zs-error [hint]:active,
.zs-action-field.zs-danger [hint]:active,
.zs-action-field[invalid] [hint]:active,
.zs-action-field.zs-error [clear]:active,
.zs-action-field.zs-danger [clear]:active,
.zs-action-field[invalid] [clear]:active {
  color: var(--zs-danger-active-color, #5C1A0B);
}
.zs-action-field.zs-error [helper],
.zs-action-field.zs-danger [helper],
.zs-action-field[invalid] [helper] {
  color: var(--zs-field-helper-error-text-color, #B21111);
}
.zs-action-field.zs-success [input] {
  border: 1px solid var(--zs-success-color, #00AA67);
}
.zs-action-field.zs-success [input]:focus {
  border: 1px solid var(--zs-success-focus-color, #0A6E5E);
}
.zs-action-field.zs-success [input]:hover {
  border: 1px solid var(--zs-success-hover-color, #54D7AF);
}
.zs-action-field.zs-success [input]:active {
  border: 1px solid var(--zs-success-active-color, #0C4846);
}
.zs-action-field.zs-success [action],
.zs-action-field.zs-success [hint],
.zs-action-field.zs-success [clear] {
  color: var(--zs-success-color, #00AA67);
}
.zs-action-field.zs-success [action]:visited,
.zs-action-field.zs-success [hint]:visited,
.zs-action-field.zs-success [clear]:visited {
  color: var(--zs-success-color, #00AA67);
}
.zs-action-field.zs-success [action]:focus,
.zs-action-field.zs-success [hint]:focus,
.zs-action-field.zs-success [clear]:focus {
  color: var(--zs-success-focus-color, #0A6E5E);
}
.zs-action-field.zs-success [action]:hover,
.zs-action-field.zs-success [hint]:hover,
.zs-action-field.zs-success [clear]:hover {
  color: var(--zs-success-hover-color, #54D7AF);
}
.zs-action-field.zs-success [action]:active,
.zs-action-field.zs-success [hint]:active,
.zs-action-field.zs-success [clear]:active {
  color: var(--zs-success-active-color, #0C4846);
}
.zs-action-field.zs-success [helper] {
  color: var(--zs-field-helper-success-text-color, #00AA67);
}
.zs-action-field.zs-warning [input] {
  border: 1px solid var(--zs-warning-color, #E3A900);
}
.zs-action-field.zs-warning [input]:focus {
  border: 1px solid var(--zs-warning-focus-color, #8A640C);
}
.zs-action-field.zs-warning [input]:hover {
  border: 1px solid var(--zs-warning-hover-color, #FDCA63);
}
.zs-action-field.zs-warning [input]:active {
  border: 1px solid var(--zs-warning-active-color, #563F0E);
}
.zs-action-field.zs-warning [action],
.zs-action-field.zs-warning [hint],
.zs-action-field.zs-warning [clear] {
  color: var(--zs-warning-color, #E3A900);
}
.zs-action-field.zs-warning [action]:visited,
.zs-action-field.zs-warning [hint]:visited,
.zs-action-field.zs-warning [clear]:visited {
  color: var(--zs-warning-color, #E3A900);
}
.zs-action-field.zs-warning [action]:focus,
.zs-action-field.zs-warning [hint]:focus,
.zs-action-field.zs-warning [clear]:focus {
  color: var(--zs-warning-focus-color, #8A640C);
}
.zs-action-field.zs-warning [action]:hover,
.zs-action-field.zs-warning [hint]:hover,
.zs-action-field.zs-warning [clear]:hover {
  color: var(--zs-warning-hover-color, #FDCA63);
}
.zs-action-field.zs-warning [action]:active,
.zs-action-field.zs-warning [hint]:active,
.zs-action-field.zs-warning [clear]:active {
  color: var(--zs-warning-active-color, #563F0E);
}
.zs-action-field.zs-warning [helper] {
  color: var(--zs-field-helper-warning-text-color, #E3A900);
}
.zs-action-field.zs-info [input] {
  border: 1px solid var(--zs-info-color, #2B44C7);
}
.zs-action-field.zs-info [input]:focus {
  border: 1px solid var(--zs-info-focus-color, #141187);
}
.zs-action-field.zs-info [input]:hover {
  border: 1px solid var(--zs-info-hover-color, #7E92E9);
}
.zs-action-field.zs-info [input]:active {
  border: 1px solid var(--zs-info-active-color, #160C60);
}
.zs-action-field.zs-info [action],
.zs-action-field.zs-info [hint],
.zs-action-field.zs-info [clear] {
  color: var(--zs-info-color, #2B44C7);
}
.zs-action-field.zs-info [action]:visited,
.zs-action-field.zs-info [hint]:visited,
.zs-action-field.zs-info [clear]:visited {
  color: var(--zs-info-color, #2B44C7);
}
.zs-action-field.zs-info [action]:focus,
.zs-action-field.zs-info [hint]:focus,
.zs-action-field.zs-info [clear]:focus {
  color: var(--zs-info-focus-color, #141187);
}
.zs-action-field.zs-info [action]:hover,
.zs-action-field.zs-info [hint]:hover,
.zs-action-field.zs-info [clear]:hover {
  color: var(--zs-info-hover-color, #7E92E9);
}
.zs-action-field.zs-info [action]:active,
.zs-action-field.zs-info [hint]:active,
.zs-action-field.zs-info [clear]:active {
  color: var(--zs-info-active-color, #160C60);
}
.zs-action-field.zs-info [helper] {
  color: var(--zs-field-helper-info-text-color, #2B44C7);
}
.zs-action-field[disabled],
.zs-action-field.zs-disabled {
  cursor: default;
  pointer-events: none;
  opacity: 1;
}
.zs-action-field[disabled] [input],
.zs-action-field.zs-disabled [input] {
  cursor: default;
  pointer-events: none;
  opacity: 1;
  border: 1px solid var(--zs-disabled-border-color, #B2B0B6);
  color: var(--zs-disabled-text-color, #B2B0B6);
  background: none;
  background-color: var(--zs-disabled-background-color, #F4F3F3);
}
.zs-action-field[disabled] [input]::-webkit-input-placeholder,
.zs-action-field.zs-disabled [input]::-webkit-input-placeholder {
  color: var(--zs-field-disabled-placeholder-color, #B2B0B6);
}
.zs-action-field[disabled] [input]:-moz-placeholder,
.zs-action-field.zs-disabled [input]:-moz-placeholder {
  /* Firefox 18- */
  color: var(--zs-field-disabled-placeholder-color, #B2B0B6);
}
.zs-action-field[disabled] [input]::-moz-placeholder,
.zs-action-field.zs-disabled [input]::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--zs-field-disabled-placeholder-color, #B2B0B6);
}
.zs-action-field[disabled] [clear],
.zs-action-field.zs-disabled [clear],
.zs-action-field[disabled] [hint],
.zs-action-field.zs-disabled [hint],
.zs-action-field[disabled] [action],
.zs-action-field.zs-disabled [action],
.zs-action-field[disabled] [signifier],
.zs-action-field.zs-disabled [signifier] {
  color: var(--zs-disabled-text-color, #B2B0B6);
}
.zs-action-field.zs-action-field-solid [input] {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 -2px 0 0 var(--zs-field-filled-border-color, #DEDCDE);
  background: var(--zs-field-solid-filled-background-color, #FAFAFA);
  height: 3.125em;
  transition: background 0.1s ease-out, box-shadow 0.1s ease-out;
}
.zs-action-field.zs-action-field-solid [input]:focus {
  box-shadow: inset 0 -2px 0 0 var(--zs-field-focus-border-color, #27A6A4);
}
.zs-action-field.zs-action-field-solid [input]:hover {
  box-shadow: inset 0 -2px 0 0 var(--zs-field-hover-border-color, #9C9AA1);
}
.zs-action-field.zs-action-field-solid [input]:active {
  box-shadow: inset 0 -2px 0 0 var(--zs-field-active-border-color, #27A6A4);
}
.zs-action-field.zs-action-field-solid [input]:focus {
  background: var(--zs-field-solid-focus-background-color, #F4F3F3);
}
.zs-action-field.zs-action-field-solid [input]:hover {
  background: var(--zs-field-solid-hover-background-color, #F4F3F3);
}
.zs-action-field.zs-action-field-solid [input]:active {
  background: var(--zs-field-solid-active-background-color, #F4F3F3);
}
.zs-action-field.zs-action-field-solid [input]:focus:hover {
  border: none;
  box-shadow: inset 0 -2px 0 0 var(--zs-field-focus-border-color, #27A6A4);
}
@media (prefers-reduced-motion) {
  .zs-action-field.zs-action-field-solid [input] {
    transition-duration: 0s;
  }
}
.zs-action-field.zs-action-field-solid input[type="text"]:not(:focus):not(:hover):not([value]),
.zs-action-field.zs-action-field-solid input[type="text"]:not(:focus):not(:hover)[value=""] {
  box-shadow: none;
}
.zs-action-field.zs-action-field-solid input[type="text"]:not(:focus):not(:hover):not([value]):not(:disabled):not(.zs-disabled),
.zs-action-field.zs-action-field-solid input[type="text"]:not(:focus):not(:hover)[value=""]:not(:disabled):not(.zs-disabled) {
  background: var(--zs-field-solid-background-color, #FAFAFA);
}
.zs-action-field.zs-action-field-solid.zs-error [input],
.zs-action-field.zs-action-field-solid.zs-danger [input],
.zs-action-field.zs-action-field-solid[invalid] [input] {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 -2px 0 0 var(--zs-danger-color, #B21111);
}
.zs-action-field.zs-action-field-solid.zs-error [input]:focus,
.zs-action-field.zs-action-field-solid.zs-danger [input]:focus,
.zs-action-field.zs-action-field-solid[invalid] [input]:focus {
  box-shadow: inset 0 -2px 0 0 var(--zs-danger-focus-color, #892208);
}
.zs-action-field.zs-action-field-solid.zs-error [input]:hover,
.zs-action-field.zs-action-field-solid.zs-danger [input]:hover,
.zs-action-field.zs-action-field-solid[invalid] [input]:hover {
  box-shadow: inset 0 -2px 0 0 var(--zs-danger-hover-color, #F27755);
}
.zs-action-field.zs-action-field-solid.zs-error [input]:active,
.zs-action-field.zs-action-field-solid.zs-danger [input]:active,
.zs-action-field.zs-action-field-solid[invalid] [input]:active {
  box-shadow: inset 0 -2px 0 0 var(--zs-danger-active-color, #5C1A0B);
}
.zs-action-field.zs-action-field-solid.zs-success [input] {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 -2px 0 0 var(--zs-success-color, #00AA67);
}
.zs-action-field.zs-action-field-solid.zs-success [input]:focus {
  box-shadow: inset 0 -2px 0 0 var(--zs-success-focus-color, #0A6E5E);
}
.zs-action-field.zs-action-field-solid.zs-success [input]:hover {
  box-shadow: inset 0 -2px 0 0 var(--zs-success-hover-color, #54D7AF);
}
.zs-action-field.zs-action-field-solid.zs-success [input]:active {
  box-shadow: inset 0 -2px 0 0 var(--zs-success-active-color, #0C4846);
}
.zs-action-field.zs-action-field-solid.zs-warning [input] {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 -2px 0 0 var(--zs-warning-color, #E3A900);
}
.zs-action-field.zs-action-field-solid.zs-warning [input]:focus {
  box-shadow: inset 0 -2px 0 0 var(--zs-warning-focus-color, #8A640C);
}
.zs-action-field.zs-action-field-solid.zs-warning [input]:hover {
  box-shadow: inset 0 -2px 0 0 var(--zs-warning-hover-color, #FDCA63);
}
.zs-action-field.zs-action-field-solid.zs-warning [input]:active {
  box-shadow: inset 0 -2px 0 0 var(--zs-warning-active-color, #563F0E);
}
.zs-action-field.zs-action-field-solid.zs-info [input] {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 -2px 0 0 var(--zs-info-color, #2B44C7);
}
.zs-action-field.zs-action-field-solid.zs-info [input]:focus {
  box-shadow: inset 0 -2px 0 0 var(--zs-info-focus-color, #141187);
}
.zs-action-field.zs-action-field-solid.zs-info [input]:hover {
  box-shadow: inset 0 -2px 0 0 var(--zs-info-hover-color, #7E92E9);
}
.zs-action-field.zs-action-field-solid.zs-info [input]:active {
  box-shadow: inset 0 -2px 0 0 var(--zs-info-active-color, #160C60);
}
.zs-action-field.zs-action-field-secondary [input] {
  background: var(--zs-field-secondary-filled-background-color, #ffffff);
}
.zs-action-field.zs-action-field-secondary [input]:focus {
  background: var(--zs-field-secondary-focus-background-color, #ffffff);
}
.zs-action-field.zs-action-field-secondary [input]:hover {
  background: var(--zs-field-secondary-hover-background-color, #ffffff);
}
.zs-action-field.zs-action-field-secondary [input]:active {
  background: var(--zs-field-secondary-active-background-color, #ffffff);
}
.zs-action-field.zs-action-field-secondary [input]:focus:hover {
  background: var(--zs-field-secondary-focus-background-color, #ffffff);
}
.zs-action-field.zs-action-field-secondary input[type="text"]:not(:focus):not(:hover):not([value]):not(:disabled):not(.zs-disabled),
.zs-action-field.zs-action-field-secondary input[type="text"]:not(:focus):not(:hover)[value=""]:not(:disabled):not(.zs-disabled) {
  background: var(--zs-field-secondary-background-color, #ffffff);
}
.zs-action-field.zs-action-field-secondary.zs-action-field-solid [input] {
  background: var(--zs-field-solid-secondary-filled-background-color, #ffffff);
}
.zs-action-field.zs-action-field-secondary.zs-action-field-solid [input]:focus {
  background: var(--zs-field-solid-secondary-focus-background-color, #ffffff);
}
.zs-action-field.zs-action-field-secondary.zs-action-field-solid [input]:hover {
  background: var(--zs-field-solid-secondary-hover-background-color, #ffffff);
}
.zs-action-field.zs-action-field-secondary.zs-action-field-solid [input]:active {
  background: var(--zs-field-solid-secondary-active-background-color, #ffffff);
}
.zs-action-field.zs-action-field-secondary.zs-action-field-solid [input]:focus:hover {
  background: var(--zs-field-solid-secondary-focus-background-color, #ffffff);
}
.zs-action-field.zs-action-field-secondary.zs-action-field-solid input[type="text"]:not(:focus):not(:hover):not([value]):not(:disabled):not(.zs-disabled),
.zs-action-field.zs-action-field-secondary.zs-action-field-solid input[type="text"]:not(:focus):not(:hover)[value=""]:not(:disabled):not(.zs-disabled) {
  background: var(--zs-field-solid-secondary-background-color, #ffffff);
}
.zs-action-field[icon] [input] {
  padding: 0.75em 0.75em 0.75em 3em;
}
.zs-action-field[action-icon] [input],
.zs-action-field[hint-icon] [input],
.zs-action-field[clear] [input] {
  padding-right: 3em;
}
.zs-action-field[action-icon][hint-icon] [input],
.zs-action-field[clear][action-icon] [input] {
  padding-right: 5em;
}
.zs-display-none {
  display: none;
}
[hidden] {
  display: none !important;
}
.zs-position-fixed {
  position: fixed !important;
}
.zs-position-absolute {
  position: absolute !important;
}
.zs-position-relative {
  position: relative !important;
}
.zs-position-static {
  position: static !important;
}
.zs-bg-navigation-overlay-horizontal {
  background: var(--zs-navigation-overlay-horizontal-background-color, linear-gradient(89.99deg, #1A1628 -0.86%, #1A1628 -0.85%, rgba(2, 45, 66, 0.1) 6.5%, rgba(113, 110, 121, 0.4) 45.89%, #2F2C3C 99.99%)) !important;
}
.zs-bg-navigation-overlay-vertical {
  background: var(--zs-navigation-overlay-vertical-background-color, linear-gradient(180deg, #1A1628 -1.83%, #1A1628 -1.82%, rgba(2, 45, 66, 0.1) 6.39%, rgba(113, 110, 121, 0.4) 50.47%, #2F2C3C 111%)) !important;
}
.zs-bg-navigation {
  background: var(--zs-navigation-background-color, #1A1628) !important;
}
.zs-bg-neutral-overlay-90 {
  background: var(--zs-neutral-overlay-color-90, rgba(26, 22, 40, 0.9)) !important;
}
.zs-bg-neutral-overlay-80 {
  background: var(--zs-neutral-overlay-color-80, rgba(26, 22, 40, 0.8)) !important;
}
.zs-bg-neutral-overlay-70 {
  background: var(--zs-neutral-overlay-color-70, rgba(26, 22, 40, 0.7)) !important;
}
.zs-bg-neutral-overlay-60 {
  background: var(--zs-neutral-overlay-color-60, rgba(26, 22, 40, 0.6)) !important;
}
.zs-bg-neutral-overlay-50 {
  background: var(--zs-neutral-overlay-color-50, rgba(26, 22, 40, 0.5)) !important;
}
.zs-bg-neutral-overlay-40 {
  background: var(--zs-neutral-overlay-color-40, rgba(26, 22, 40, 0.4)) !important;
}
.zs-bg-neutral-overlay-30 {
  background: var(--zs-neutral-overlay-color-30, rgba(26, 22, 40, 0.3)) !important;
}
.zs-bg-neutral-overlay-20 {
  background: var(--zs-neutral-overlay-color-20, rgba(26, 22, 40, 0.2)) !important;
}
.zs-bg-neutral-overlay-10 {
  background: var(--zs-neutral-overlay-color-10, rgba(26, 22, 40, 0.1)) !important;
}
.zs-bg-tertiary-overlay-90 {
  background: var(--zs-tertiary-overlay-color-90, rgba(2, 45, 66, 0.9)) !important;
}
.zs-bg-tertiary-overlay-80 {
  background: var(--zs-tertiary-overlay-color-80, rgba(2, 45, 66, 0.8)) !important;
}
.zs-bg-tertiary-overlay-70 {
  background: var(--zs-tertiary-overlay-color-70, rgba(2, 45, 66, 0.7)) !important;
}
.zs-bg-tertiary-overlay-60 {
  background: var(--zs-tertiary-overlay-color-60, rgba(2, 45, 66, 0.6)) !important;
}
.zs-bg-tertiary-overlay-50 {
  background: var(--zs-tertiary-overlay-color-50, rgba(2, 45, 66, 0.5)) !important;
}
.zs-bg-tertiary-overlay-40 {
  background: var(--zs-tertiary-overlay-color-40, rgba(2, 45, 66, 0.4)) !important;
}
.zs-bg-tertiary-overlay-30 {
  background: var(--zs-tertiary-overlay-color-30, rgba(2, 45, 66, 0.3)) !important;
}
.zs-bg-tertiary-overlay-20 {
  background: var(--zs-tertiary-overlay-color-20, rgba(2, 45, 66, 0.2)) !important;
}
.zs-bg-tertiary-overlay-10 {
  background: var(--zs-tertiary-overlay-color-10, rgba(2, 45, 66, 0.1)) !important;
}
.zs-bg-secondary-overlay-90 {
  background: var(--zs-secondary-overlay-color-90, rgba(236, 114, 0, 0.9)) !important;
}
.zs-bg-secondary-overlay-80 {
  background: var(--zs-secondary-overlay-color-80, rgba(236, 114, 0, 0.8)) !important;
}
.zs-bg-secondary-overlay-70 {
  background: var(--zs-secondary-overlay-color-70, rgba(236, 114, 0, 0.7)) !important;
}
.zs-bg-secondary-overlay-60 {
  background: var(--zs-secondary-overlay-color-60, rgba(236, 114, 0, 0.6)) !important;
}
.zs-bg-secondary-overlay-50 {
  background: var(--zs-secondary-overlay-color-50, rgba(236, 114, 0, 0.5)) !important;
}
.zs-bg-secondary-overlay-40 {
  background: var(--zs-secondary-overlay-color-40, rgba(236, 114, 0, 0.4)) !important;
}
.zs-bg-secondary-overlay-30 {
  background: var(--zs-secondary-overlay-color-30, rgba(236, 114, 0, 0.3)) !important;
}
.zs-bg-secondary-overlay-20 {
  background: var(--zs-secondary-overlay-color-20, rgba(236, 114, 0, 0.2)) !important;
}
.zs-bg-secondary-overlay-10 {
  background: var(--zs-secondary-overlay-color-10, rgba(236, 114, 0, 0.1)) !important;
}
.zs-bg-primary-overlay-90 {
  background: var(--zs-primary-overlay-color-90, rgba(39, 166, 164, 0.9)) !important;
}
.zs-bg-primary-overlay-80 {
  background: var(--zs-primary-overlay-color-80, rgba(39, 166, 164, 0.8)) !important;
}
.zs-bg-primary-overlay-70 {
  background: var(--zs-primary-overlay-color-70, rgba(39, 166, 164, 0.7)) !important;
}
.zs-bg-primary-overlay-60 {
  background: var(--zs-primary-overlay-color-60, rgba(39, 166, 164, 0.6)) !important;
}
.zs-bg-primary-overlay-50 {
  background: var(--zs-primary-overlay-color-50, rgba(39, 166, 164, 0.5)) !important;
}
.zs-bg-primary-overlay-40 {
  background: var(--zs-primary-overlay-color-40, rgba(39, 166, 164, 0.4)) !important;
}
.zs-bg-primary-overlay-30 {
  background: var(--zs-primary-overlay-color-30, rgba(39, 166, 164, 0.3)) !important;
}
.zs-bg-primary-overlay-20 {
  background: var(--zs-primary-overlay-color-20, rgba(39, 166, 164, 0.2)) !important;
}
.zs-bg-primary-overlay-10 {
  background: var(--zs-primary-overlay-color-10, rgba(39, 166, 164, 0.1)) !important;
}
.zs-bg-overlay-90 {
  background: var(--zs-overlay-color-90, rgba(255, 255, 255, 0.9)) !important;
}
.zs-bg-overlay-80 {
  background: var(--zs-overlay-color-80, rgba(255, 255, 255, 0.8)) !important;
}
.zs-bg-overlay-70 {
  background: var(--zs-overlay-color-70, rgba(255, 255, 255, 0.7)) !important;
}
.zs-bg-overlay-60 {
  background: var(--zs-overlay-color-60, rgba(255, 255, 255, 0.6)) !important;
}
.zs-bg-overlay-50 {
  background: var(--zs-overlay-color-50, rgba(255, 255, 255, 0.5)) !important;
}
.zs-bg-overlay-40 {
  background: var(--zs-overlay-color-40, rgba(255, 255, 255, 0.4)) !important;
}
.zs-bg-overlay-30 {
  background: var(--zs-overlay-color-30, rgba(255, 255, 255, 0.3)) !important;
}
.zs-bg-overlay-20 {
  background: var(--zs-overlay-color-20, rgba(255, 255, 255, 0.2)) !important;
}
.zs-bg-overlay-10 {
  background: var(--zs-overlay-color-10, rgba(255, 255, 255, 0.1)) !important;
}
.zs-bg-table-zebra-odd {
  background: var(--zs-table-zebra-odd, #FAFAFA) !important;
}
.zs-bg-table-zebra-even {
  background: var(--zs-table-zebra-even, #F4F3F3) !important;
}
.zs-bg-table-data-color-12 {
  background: var(--zs-table-data-color-12, #FFFFA9) !important;
}
.zs-bg-table-data-color-11 {
  background: var(--zs-table-data-color-11, #FECDCF) !important;
}
.zs-bg-table-data-color-10 {
  background: var(--zs-table-data-color-10, #8BFFF5) !important;
}
.zs-bg-table-data-color-9 {
  background: var(--zs-table-data-color-9, #FE9766) !important;
}
.zs-bg-table-data-color-8 {
  background: var(--zs-table-data-color-8, #E9FFAC) !important;
}
.zs-bg-table-data-color-7 {
  background: var(--zs-table-data-color-7, #A8C6FF) !important;
}
.zs-bg-table-data-color-6 {
  background: var(--zs-table-data-color-6, #FFF6C7) !important;
}
.zs-bg-table-data-color-5 {
  background: var(--zs-table-data-color-5, #FDBEF0) !important;
}
.zs-bg-table-data-color-4 {
  background: var(--zs-table-data-color-4, #D4F5D7) !important;
}
.zs-bg-table-data-color-3 {
  background: var(--zs-table-data-color-3, #E0C6FF) !important;
}
.zs-bg-table-data-color-2 {
  background: var(--zs-table-data-color-2, #AFDCFC) !important;
}
.zs-bg-table-data-color-1 {
  background: var(--zs-table-data-color-1, #FFE0C2) !important;
}
.zs-bg-transparent {
  background: var(--zs-transparent-background-color, transparent) !important;
}
.zs-bg-surface-5 {
  background: var(--zs-surface-color-5, #1A1628) !important;
}
.zs-bg-surface-4 {
  background: var(--zs-surface-color-4, #454250) !important;
}
.zs-bg-surface-3 {
  background: var(--zs-surface-color-3, #716E79) !important;
}
.zs-bg-surface-2 {
  background: var(--zs-surface-color-2, #F4F3F3) !important;
}
.zs-bg-surface-1 {
  background: var(--zs-surface-color-1, #FAFAFA) !important;
}
.zs-bg-data-color-12 {
  background: var(--zs-data-color-12, #FFE949) !important;
}
.zs-bg-data-color-11 {
  background: var(--zs-data-color-11, #FD595F) !important;
}
.zs-bg-data-color-10 {
  background: var(--zs-data-color-10, #18F3CC) !important;
}
.zs-bg-data-color-9 {
  background: var(--zs-data-color-9, #EB6620) !important;
}
.zs-bg-data-color-8 {
  background: var(--zs-data-color-8, #E1F65D) !important;
}
.zs-bg-data-color-7 {
  background: var(--zs-data-color-7, #5648FB) !important;
}
.zs-bg-data-color-6 {
  background: var(--zs-data-color-6, #FFC62B) !important;
}
.zs-bg-data-color-5 {
  background: var(--zs-data-color-5, #ED39DB) !important;
}
.zs-bg-data-color-4 {
  background: var(--zs-data-color-4, #5AD767) !important;
}
.zs-bg-data-color-3 {
  background: var(--zs-data-color-3, #6328B8) !important;
}
.zs-bg-data-color-2 {
  background: var(--zs-data-color-2, #70C2FE) !important;
}
.zs-bg-data-color-1 {
  background: var(--zs-data-color-1, #FF9836) !important;
}
.zs-bg-border {
  background: var(--zs-border-color, #DEDCDE) !important;
}
.zs-bg-shadow {
  background: var(--zs-shadow-color, #2F2C3C) !important;
}
.zs-bg-selected {
  background: var(--zs-selected-color, #027AFF) !important;
}
.zs-bg-neutral-inverse {
  background: var(--zs-neutral-background-color, #FAFAFA) !important;
}
.zs-bg-info-inverse {
  background: var(--zs-info-background-color, #EEEEFF) !important;
}
.zs-bg-warning-inverse {
  background: var(--zs-warning-background-color, #FFF9F1) !important;
}
.zs-bg-error-inverse {
  background: var(--zs-error-background-color, #FFEDE9) !important;
}
.zs-bg-success-inverse {
  background: var(--zs-success-background-color, #F1FEFF) !important;
}
.zs-bg-info-secondary {
  background: var(--zs-info-color-secondary, #EEEEFF) !important;
}
.zs-bg-warning-secondary {
  background: var(--zs-warning-color-secondary, #FFF9F1) !important;
}
.zs-bg-error-secondary {
  background: var(--zs-error-color-secondary, #FFEDE9) !important;
}
.zs-bg-success-secondary {
  background: var(--zs-success-color-secondary, #D2FAF7) !important;
}
.zs-bg-neutral {
  background: var(--zs-neutral-background-color, #FAFAFA) !important;
}
.zs-bg-info {
  background: var(--zs-info-color, #2B44C7) !important;
}
.zs-bg-warning {
  background: var(--zs-warning-color, #E3A900) !important;
}
.zs-bg-error {
  background: var(--zs-error-color, #B21111) !important;
}
.zs-bg-success {
  background: var(--zs-success-color, #00AA67) !important;
}
.zs-bg-link-hover {
  background: var(--zs-link-hover-color, #27A6A4) !important;
}
.zs-bg-link {
  background: var(--zs-link-text-color, #2D8B93) !important;
}
.zs-bg-disabled {
  background: var(--zs-disabled-background-color, #F4F3F3) !important;
}
.zs-bg-standard {
  background: var(--zs-background, #ffffff) !important;
}
.zs-text-disabled {
  color: var(--zs-disabled-text-color, #B2B0B6) !important;
}
.zs-text-medium {
  color: var(--zs-medium-text-color, #454250) !important;
}
.zs-text-caption {
  color: var(--zs-caption-text-color, #716E79) !important;
}
.zs-text-headline {
  color: var(--zs-headline-text-color, #1A1628) !important;
}
.zs-text-inverse {
  color: var(--zs-inverse-text-color, #FAFAFA) !important;
}
.zs-text-data-color-12 {
  color: var(--zs-data-color-12, #FFE949) !important;
}
.zs-text-data-color-11 {
  color: var(--zs-data-color-11, #FD595F) !important;
}
.zs-text-data-color-10 {
  color: var(--zs-data-color-10, #18F3CC) !important;
}
.zs-text-data-color-9 {
  color: var(--zs-data-color-9, #EB6620) !important;
}
.zs-text-data-color-8 {
  color: var(--zs-data-color-8, #E1F65D) !important;
}
.zs-text-data-color-7 {
  color: var(--zs-data-color-7, #5648FB) !important;
}
.zs-text-data-color-6 {
  color: var(--zs-data-color-6, #FFC62B) !important;
}
.zs-text-data-color-5 {
  color: var(--zs-data-color-5, #ED39DB) !important;
}
.zs-text-data-color-4 {
  color: var(--zs-data-color-4, #5AD767) !important;
}
.zs-text-data-color-3 {
  color: var(--zs-data-color-3, #6328B8) !important;
}
.zs-text-data-color-2 {
  color: var(--zs-data-color-2, #70C2FE) !important;
}
.zs-text-data-color-1 {
  color: var(--zs-data-color-1, #FF9836) !important;
}
.zs-text-nav {
  color: var(--zs-nav-color, #27A6A4) !important;
}
.zs-text-neutral {
  color: var(--zs-neutral-color, #1A1628) !important;
}
.zs-text-info {
  color: var(--zs-info-color, #2B44C7) !important;
}
.zs-text-warning {
  color: var(--zs-warning-color, #E3A900) !important;
}
.zs-text-success {
  color: var(--zs-success-color, #00AA67) !important;
}
.zs-text-error {
  color: var(--zs-error-color, #B21111) !important;
}
.zs-text-default {
  color: var(--zs-text-color, #2F2C3C) !important;
}
.zs-cursor-zoom-out {
  cursor: zoom-out !important;
}
.zs-cursor-zoom-in {
  cursor: zoom-in !important;
}
.zs-cursor-nwse-resize {
  cursor: nwse-resize !important;
}
.zs-cursor-nesw-resize {
  cursor: nesw-resize !important;
}
.zs-cursor-ns-resize {
  cursor: ns-resize !important;
}
.zs-cursor-ew-resize {
  cursor: ew-resize !important;
}
.zs-cursor-sw-resize {
  cursor: sw-resize !important;
}
.zs-cursor-se-resize {
  cursor: se-resize !important;
}
.zs-cursor-nw-resize {
  cursor: nw-resize !important;
}
.zs-cursor-ne-resize {
  cursor: ne-resize !important;
}
.zs-cursor-w-resize {
  cursor: w-resize !important;
}
.zs-cursor-s-resize {
  cursor: s-resize !important;
}
.zs-cursor-e-resize {
  cursor: e-resize !important;
}
.zs-cursor-n-resize {
  cursor: n-resize !important;
}
.zs-cursor-row-resize {
  cursor: row-resize !important;
}
.zs-cursor-col-resize {
  cursor: col-resize !important;
}
.zs-cursor-all-scroll {
  cursor: all-scroll !important;
}
.zs-cursor-grabbing {
  cursor: grabbing !important;
}
.zs-cursor-grab {
  cursor: grab !important;
}
.zs-cursor-not-allowed {
  cursor: not-allowed !important;
}
.zs-cursor-no-drop {
  cursor: no-drop !important;
}
.zs-cursor-move {
  cursor: move !important;
}
.zs-cursor-copy {
  cursor: copy !important;
}
.zs-cursor-alias {
  cursor: alias !important;
}
.zs-cursor-vertical-text {
  cursor: vertical-text !important;
}
.zs-cursor-text {
  cursor: text !important;
}
.zs-cursor-crosshair {
  cursor: crosshair !important;
}
.zs-cursor-cell {
  cursor: cell !important;
}
.zs-cursor-wait {
  cursor: wait !important;
}
.zs-cursor-progress {
  cursor: progress !important;
}
.zs-cursor-help {
  cursor: help !important;
}
.zs-cursor-context-menu {
  cursor: context-menu !important;
}
.zs-cursor-pointer {
  cursor: pointer !important;
}
.zs-cursor-none {
  cursor: none !important;
}
.zs-cursor-default {
  cursor: default !important;
}
.zs-cursor-auto {
  cursor: auto !important;
}
.zs-layer-5 {
  z-index: 5000 !important;
}
.zs-layer-4 {
  z-index: 4000 !important;
}
.zs-layer-3 {
  z-index: 3000 !important;
}
.zs-layer-2 {
  z-index: 2000 !important;
}
.zs-layer-1 {
  z-index: 1000 !important;
}
.zs-layer-0 {
  z-index: 0 !important;
}
.zs-table {
  font-size: 1em;
  border-collapse: collapse;
  border-spacing: 0;
  color: var(--zs-table-text-color, #454250);
  margin: 0.5em 1em 0.5em 1em;
}
.zs-table caption {
  text-align: left;
  margin: 0em 0em 1em 0em;
  font-size: 0.75em;
  color: var(--zs-table-caption-color, #9C9AA1);
}
.zs-table:first-child {
  margin-top: 0;
}
.zs-table:first-child > caption {
  margin-top: 0;
}
.zs-table tr > td,
.zs-table tr > th {
  padding: 1em 1em 1em 1em;
  line-height: 1.5;
  border: 1px solid var(--zs-table-border-color, #DEDCDE);
  border-left: 0px;
  border-right: 0px;
  text-align: left;
  vertical-align: top;
}
.zs-table tr > td.zs-action-icons,
.zs-table tr > th.zs-action-icons {
  padding-top: 0.3em;
  padding-bottom: 0;
}
.zs-table tr > td.zs-actions,
.zs-table tr > th.zs-actions {
  padding-top: 0.1em;
  padding-bottom: 0.1em;
  vertical-align: middle;
}
.zs-table tr > td:focus,
.zs-table tr > th:focus {
  outline: var(--zs-table-focus-border, 1px solid #27A6A4);
}
.zs-table tr > th {
  min-height: auto;
  height: auto;
  padding: 1em 1em 1em 1em;
  line-height: 1.5;
  font-size: 1em;
  font-weight: 600;
  background: none;
  border-bottom: 1px solid var(--zs-table-header-border-color, #716E79);
  vertical-align: bottom;
}
.zs-table tr > th:focus {
  outline: var(--zs-table-focus-border, 1px solid #27A6A4);
}
.zs-table tr > th a[sort] {
  color: var(--zs-table-text-color, #454250);
}
.zs-table tr > th a[sort]:visited {
  color: var(--zs-table-text-color, #454250);
}
.zs-table tr > th a[sort]:focus {
  color: var(--zs-table-text-color, #454250);
}
.zs-table tr > th a[sort]:hover {
  color: var(--zs-table-text-color, #454250);
}
.zs-table tr > th a[sort]:active {
  color: var(--zs-table-text-color, #454250);
}
.zs-table tr > th[sort],
.zs-table tr > th [sort] {
  padding-right: 1.75em;
  position: relative;
}
.zs-table tr > th[sort]::after,
.zs-table tr > th [sort]::after {
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Enable Ligatures ================ */
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga";
  font-feature-settings: "liga";
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-size: 1.25em;
  font-family: 'zsIcons';
  content: "\ea1e";
  color: var(--zs-interactive-secondary-color, #716E79);
}
.zs-table tr > th[sort]:hover::after,
.zs-table tr > th [sort]:hover::after {
  color: var(--zs-interactive-secondary-hover-color, #9C9AA1);
}
.zs-table tr > th[sort="asc"]::after,
.zs-table tr > th [sort="asc"]::after {
  color: var(--zs-table-sorted-icon-color, #27A6A4);
  content: "\eb80";
}
.zs-table tr > th[sort="desc"]::after,
.zs-table tr > th [sort="desc"]::after {
  color: var(--zs-table-sorted-icon-color, #27A6A4);
  content: "\eb7f";
}
.zs-table tr > th[sort="asc"]:hover::after,
.zs-table tr > th [sort="asc"]:hover::after,
.zs-table tr > th[sort="desc"]:hover::after,
.zs-table tr > th [sort="desc"]:hover::after {
  color: var(--zs-table-sorted-icon-color, #27A6A4);
}
.zs-table tr > th [sort] {
  cursor: pointer;
}
.zs-table tr > th [sort]::after {
  font-size: 1.25em;
}
.zs-table tr:focus {
  outline: var(--zs-table-focus-border, 1px solid #27A6A4);
}
.zs-table td,
.zs-table th,
.zs-table .zs-td {
  padding: 1em 1em 1em 1em;
  line-height: 1.5;
  border: 1px solid var(--zs-table-border-color, #DEDCDE);
  border-left: 0px;
  border-right: 0px;
  text-align: left;
  vertical-align: top;
}
.zs-table td.zs-action-icons,
.zs-table th.zs-action-icons,
.zs-table .zs-td.zs-action-icons {
  padding-top: 0.3em;
  padding-bottom: 0;
}
.zs-table td.zs-actions,
.zs-table th.zs-actions,
.zs-table .zs-td.zs-actions {
  padding-top: 0.1em;
  padding-bottom: 0.1em;
  vertical-align: middle;
}
.zs-table td:focus,
.zs-table th:focus,
.zs-table .zs-td:focus {
  outline: var(--zs-table-focus-border, 1px solid #27A6A4);
}
.zs-table > thead td,
.zs-table > thead th,
.zs-table > tr > th,
.zs-table > th,
.zs-table > tbody > tr > th,
.zs-table .zs-th,
.zs-table > tfoot td,
.zs-table > tfoot th {
  min-height: auto;
  height: auto;
  padding: 1em 1em 1em 1em;
  line-height: 1.5;
  font-size: 1em;
  font-weight: 600;
  background: none;
  border-bottom: 1px solid var(--zs-table-header-border-color, #716E79);
  vertical-align: bottom;
}
.zs-table > thead td:focus,
.zs-table > thead th:focus,
.zs-table > tr > th:focus,
.zs-table > th:focus,
.zs-table > tbody > tr > th:focus,
.zs-table .zs-th:focus,
.zs-table > tfoot td:focus,
.zs-table > tfoot th:focus {
  outline: var(--zs-table-focus-border, 1px solid #27A6A4);
}
.zs-table > thead td a[sort],
.zs-table > thead th a[sort],
.zs-table > tr > th a[sort],
.zs-table > th a[sort],
.zs-table > tbody > tr > th a[sort],
.zs-table .zs-th a[sort],
.zs-table > tfoot td a[sort],
.zs-table > tfoot th a[sort] {
  color: var(--zs-table-text-color, #454250);
}
.zs-table > thead td a[sort]:visited,
.zs-table > thead th a[sort]:visited,
.zs-table > tr > th a[sort]:visited,
.zs-table > th a[sort]:visited,
.zs-table > tbody > tr > th a[sort]:visited,
.zs-table .zs-th a[sort]:visited,
.zs-table > tfoot td a[sort]:visited,
.zs-table > tfoot th a[sort]:visited {
  color: var(--zs-table-text-color, #454250);
}
.zs-table > thead td a[sort]:focus,
.zs-table > thead th a[sort]:focus,
.zs-table > tr > th a[sort]:focus,
.zs-table > th a[sort]:focus,
.zs-table > tbody > tr > th a[sort]:focus,
.zs-table .zs-th a[sort]:focus,
.zs-table > tfoot td a[sort]:focus,
.zs-table > tfoot th a[sort]:focus {
  color: var(--zs-table-text-color, #454250);
}
.zs-table > thead td a[sort]:hover,
.zs-table > thead th a[sort]:hover,
.zs-table > tr > th a[sort]:hover,
.zs-table > th a[sort]:hover,
.zs-table > tbody > tr > th a[sort]:hover,
.zs-table .zs-th a[sort]:hover,
.zs-table > tfoot td a[sort]:hover,
.zs-table > tfoot th a[sort]:hover {
  color: var(--zs-table-text-color, #454250);
}
.zs-table > thead td a[sort]:active,
.zs-table > thead th a[sort]:active,
.zs-table > tr > th a[sort]:active,
.zs-table > th a[sort]:active,
.zs-table > tbody > tr > th a[sort]:active,
.zs-table .zs-th a[sort]:active,
.zs-table > tfoot td a[sort]:active,
.zs-table > tfoot th a[sort]:active {
  color: var(--zs-table-text-color, #454250);
}
.zs-table > thead td[sort],
.zs-table > thead th[sort],
.zs-table > tr > th[sort],
.zs-table > th[sort],
.zs-table > tbody > tr > th[sort],
.zs-table .zs-th[sort],
.zs-table > tfoot td[sort],
.zs-table > tfoot th[sort],
.zs-table > thead td [sort],
.zs-table > thead th [sort],
.zs-table > tr > th [sort],
.zs-table > th [sort],
.zs-table > tbody > tr > th [sort],
.zs-table .zs-th [sort],
.zs-table > tfoot td [sort],
.zs-table > tfoot th [sort] {
  padding-right: 1.75em;
  position: relative;
}
.zs-table > thead td[sort]::after,
.zs-table > thead th[sort]::after,
.zs-table > tr > th[sort]::after,
.zs-table > th[sort]::after,
.zs-table > tbody > tr > th[sort]::after,
.zs-table .zs-th[sort]::after,
.zs-table > tfoot td[sort]::after,
.zs-table > tfoot th[sort]::after,
.zs-table > thead td [sort]::after,
.zs-table > thead th [sort]::after,
.zs-table > tr > th [sort]::after,
.zs-table > th [sort]::after,
.zs-table > tbody > tr > th [sort]::after,
.zs-table .zs-th [sort]::after,
.zs-table > tfoot td [sort]::after,
.zs-table > tfoot th [sort]::after {
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Enable Ligatures ================ */
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga";
  font-feature-settings: "liga";
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-size: 1.25em;
  font-family: 'zsIcons';
  content: "\ea1e";
  color: var(--zs-interactive-secondary-color, #716E79);
}
.zs-table > thead td[sort]:hover::after,
.zs-table > thead th[sort]:hover::after,
.zs-table > tr > th[sort]:hover::after,
.zs-table > th[sort]:hover::after,
.zs-table > tbody > tr > th[sort]:hover::after,
.zs-table .zs-th[sort]:hover::after,
.zs-table > tfoot td[sort]:hover::after,
.zs-table > tfoot th[sort]:hover::after,
.zs-table > thead td [sort]:hover::after,
.zs-table > thead th [sort]:hover::after,
.zs-table > tr > th [sort]:hover::after,
.zs-table > th [sort]:hover::after,
.zs-table > tbody > tr > th [sort]:hover::after,
.zs-table .zs-th [sort]:hover::after,
.zs-table > tfoot td [sort]:hover::after,
.zs-table > tfoot th [sort]:hover::after {
  color: var(--zs-interactive-secondary-hover-color, #9C9AA1);
}
.zs-table > thead td[sort="asc"]::after,
.zs-table > thead th[sort="asc"]::after,
.zs-table > tr > th[sort="asc"]::after,
.zs-table > th[sort="asc"]::after,
.zs-table > tbody > tr > th[sort="asc"]::after,
.zs-table .zs-th[sort="asc"]::after,
.zs-table > tfoot td[sort="asc"]::after,
.zs-table > tfoot th[sort="asc"]::after,
.zs-table > thead td [sort="asc"]::after,
.zs-table > thead th [sort="asc"]::after,
.zs-table > tr > th [sort="asc"]::after,
.zs-table > th [sort="asc"]::after,
.zs-table > tbody > tr > th [sort="asc"]::after,
.zs-table .zs-th [sort="asc"]::after,
.zs-table > tfoot td [sort="asc"]::after,
.zs-table > tfoot th [sort="asc"]::after {
  color: var(--zs-table-sorted-icon-color, #27A6A4);
  content: "\eb80";
}
.zs-table > thead td[sort="desc"]::after,
.zs-table > thead th[sort="desc"]::after,
.zs-table > tr > th[sort="desc"]::after,
.zs-table > th[sort="desc"]::after,
.zs-table > tbody > tr > th[sort="desc"]::after,
.zs-table .zs-th[sort="desc"]::after,
.zs-table > tfoot td[sort="desc"]::after,
.zs-table > tfoot th[sort="desc"]::after,
.zs-table > thead td [sort="desc"]::after,
.zs-table > thead th [sort="desc"]::after,
.zs-table > tr > th [sort="desc"]::after,
.zs-table > th [sort="desc"]::after,
.zs-table > tbody > tr > th [sort="desc"]::after,
.zs-table .zs-th [sort="desc"]::after,
.zs-table > tfoot td [sort="desc"]::after,
.zs-table > tfoot th [sort="desc"]::after {
  color: var(--zs-table-sorted-icon-color, #27A6A4);
  content: "\eb7f";
}
.zs-table > thead td[sort="asc"]:hover::after,
.zs-table > thead th[sort="asc"]:hover::after,
.zs-table > tr > th[sort="asc"]:hover::after,
.zs-table > th[sort="asc"]:hover::after,
.zs-table > tbody > tr > th[sort="asc"]:hover::after,
.zs-table .zs-th[sort="asc"]:hover::after,
.zs-table > tfoot td[sort="asc"]:hover::after,
.zs-table > tfoot th[sort="asc"]:hover::after,
.zs-table > thead td [sort="asc"]:hover::after,
.zs-table > thead th [sort="asc"]:hover::after,
.zs-table > tr > th [sort="asc"]:hover::after,
.zs-table > th [sort="asc"]:hover::after,
.zs-table > tbody > tr > th [sort="asc"]:hover::after,
.zs-table .zs-th [sort="asc"]:hover::after,
.zs-table > tfoot td [sort="asc"]:hover::after,
.zs-table > tfoot th [sort="asc"]:hover::after,
.zs-table > thead td[sort="desc"]:hover::after,
.zs-table > thead th[sort="desc"]:hover::after,
.zs-table > tr > th[sort="desc"]:hover::after,
.zs-table > th[sort="desc"]:hover::after,
.zs-table > tbody > tr > th[sort="desc"]:hover::after,
.zs-table .zs-th[sort="desc"]:hover::after,
.zs-table > tfoot td[sort="desc"]:hover::after,
.zs-table > tfoot th[sort="desc"]:hover::after,
.zs-table > thead td [sort="desc"]:hover::after,
.zs-table > thead th [sort="desc"]:hover::after,
.zs-table > tr > th [sort="desc"]:hover::after,
.zs-table > th [sort="desc"]:hover::after,
.zs-table > tbody > tr > th [sort="desc"]:hover::after,
.zs-table .zs-th [sort="desc"]:hover::after,
.zs-table > tfoot td [sort="desc"]:hover::after,
.zs-table > tfoot th [sort="desc"]:hover::after {
  color: var(--zs-table-sorted-icon-color, #27A6A4);
}
.zs-table > thead td [sort],
.zs-table > thead th [sort],
.zs-table > tr > th [sort],
.zs-table > th [sort],
.zs-table > tbody > tr > th [sort],
.zs-table .zs-th [sort],
.zs-table > tfoot td [sort],
.zs-table > tfoot th [sort] {
  cursor: pointer;
}
.zs-table > thead td [sort]::after,
.zs-table > thead th [sort]::after,
.zs-table > tr > th [sort]::after,
.zs-table > th [sort]::after,
.zs-table > tbody > tr > th [sort]::after,
.zs-table .zs-th [sort]::after,
.zs-table > tfoot td [sort]::after,
.zs-table > tfoot th [sort]::after {
  font-size: 1.25em;
}
.zs-table > tfoot td,
.zs-table > tfoot th {
  background: transparent;
  vertical-align: top;
}
.zs-table > tfoot > tr > td {
  border-bottom: 1px solid var(--zs-table-border-color, #DEDCDE);
}
.zs-table.zs-table-condense > thead > tr > td,
.zs-table.zs-table-condense > tfoot > tr > td,
.zs-table.zs-table-condense > tbody > tr > td,
.zs-table.zs-table-condense > thead > tr > th,
.zs-table.zs-table-condense > tfoot > tr > th,
.zs-table.zs-table-condense > tbody > tr > th {
  padding: 0.5em 0.5em 0.5em 0.5em;
}
.zs-table.zs-table-generous > thead > tr > td,
.zs-table.zs-table-generous > tfoot > tr > td,
.zs-table.zs-table-generous > tbody > tr > td,
.zs-table.zs-table-generous > thead > tr > th,
.zs-table.zs-table-generous > tfoot > tr > th,
.zs-table.zs-table-generous > tbody > tr > th {
  padding: 1.5em 1em 1.5em 1em;
}
.zs-table .zs-danger {
  background: var(--zs-table-danger-color, #FFB6A5);
}
.zs-table .zs-warning {
  background: var(--zs-table-warning-color, #FFDB93);
}
.zs-table .zs-success {
  background: var(--zs-table-success-color, #ADF2E7);
}
.zs-table .zs-active {
  background-color: var(--zs-table-active-color, rgba(236, 114, 0, 0.1));
}
.zs-table tr[border*="none"] > * {
  border: none;
}
.zs-table tr[border*="right"] > * {
  border-right: 1px solid var(--zs-table-border-color, #DEDCDE);
}
.zs-table tr[border*="left"] > * {
  border-left: 1px solid var(--zs-table-border-color, #DEDCDE);
}
.zs-table tr[border*="top"] > * {
  border-top: 1px solid var(--zs-table-border-color, #DEDCDE);
}
.zs-table tr[border*="bottom"] > * {
  border-bottom: 1px solid var(--zs-table-border-color, #DEDCDE);
}
.zs-table [border*="none"] {
  border: 0 !important;
}
.zs-table [border*="right"] {
  border-right: 1px solid var(--zs-table-border-color, #DEDCDE) !important;
}
.zs-table [border*="left"] {
  border-left: 1px solid var(--zs-table-border-color, #DEDCDE) !important;
}
.zs-table [border*="top"] {
  border-top: 1px solid var(--zs-table-border-color, #DEDCDE) !important;
}
.zs-table [border*="bottom"] {
  border-bottom: 1px solid var(--zs-table-border-color, #DEDCDE) !important;
}
.zs-table [scope="colgroup"],
.zs-table [scope="rowgroup"] {
  vertical-align: middle;
  text-align: center;
}
.zs-table tr[align="right"] > td,
.zs-table tr[align="right"] > th {
  text-align: right;
}
.zs-table tr[align="left"] > td,
.zs-table tr[align="left"] > th {
  text-align: left;
}
.zs-table tr[align="center"] > td,
.zs-table tr[align="center"] > th {
  text-align: center;
}
.zs-table tr[valign="top"] > td,
.zs-table tr[valign="top"] > th {
  vertical-align: top;
}
.zs-table tr[valign="middle"] > td,
.zs-table tr[valign="middle"] > th {
  vertical-align: middle;
}
.zs-table tr[valign="bottom"] > td,
.zs-table tr[valign="bottom"] > th {
  vertical-align: bottom;
}
.zs-table tr[valign="baseline"] > td,
.zs-table tr[valign="baseline"] > th {
  vertical-align: baseline;
}
.zs-table [align="right"] {
  text-align: right !important;
}
.zs-table [align="left"] {
  text-align: left !important;
}
.zs-table [align="center"] {
  text-align: center !important;
}
.zs-table [valign="top"] {
  vertical-align: top !important;
}
.zs-table [valign="middle"] {
  vertical-align: middle !important;
}
.zs-table [valign="bottom"] {
  vertical-align: bottom !important;
}
.zs-table [valign="baseline"] {
  vertical-align: baseline !important;
}
.zs-table [mark] {
  transition: background-color 0.1s ease-out;
  background-color: var(--zs-table-highlight-color, rgba(39, 166, 164, 0.1)) !important;
}
@media (prefers-reduced-motion) {
  .zs-table [mark] {
    transition-duration: 0s;
  }
}
.zs-table:focus {
  outline: var(--zs-table-focus-border, 1px solid #27A6A4);
}
.zs-table a:focus {
  outline: var(--zs-table-focus-border, 1px solid #27A6A4);
}
.zs-table tr.zs-table-row-group-divider > td,
.zs-table tr.zs-table-row-group-divider > th {
  border-top: 1px var(--zs-table-divider-border-color, #9C9AA1) solid;
}
.zs-button {
  text-decoration: none;
  vertical-align: middle;
  line-height: 1.5;
  font-size: 1em;
  font-weight: 600;
  box-shadow: none;
  border: 1px solid var(--zs-button-border-color, #B2B0B6);
  color: var(--zs-button-text-color, #454250);
  background-color: var(--zs-button-background-color, #ffffff);
  border-radius: 0.25em;
  padding: 0.75em 1em 0.75em 1em;
  white-space: nowrap;
  -webkit-appearance: none;
  transition: background-color 0.1s ease-out;
}
.zs-button:focus {
  border: 1px solid var(--zs-button-focus-color, #2F6F7B);
}
.zs-button:hover {
  border: 1px solid var(--zs-button-hover-color, #62D2D1);
}
.zs-button:active {
  border: 1px solid var(--zs-button-active-color, #2D535F);
}
@media (prefers-reduced-motion) {
  .zs-button {
    transition-duration: 0s;
  }
}
.zs-button span {
  vertical-align: middle;
}
.zs-button:focus {
  color: var(--zs-button-focus-color, #2F6F7B);
  outline: none;
}
.zs-button:focus > * {
  outline: inherit;
}
.zs-button:focus > .zs-icon {
  color: inherit;
}
.zs-button:hover {
  cursor: pointer;
  background-color: var(--zs-button-hover-color, #62D2D1);
  color: var(--zs-button-background-color, #ffffff);
}
.zs-button:hover .zs-icon {
  color: inherit;
}
.zs-button:active {
  background: var(--zs-button-background-color, #ffffff);
  color: var(--zs-button-active-color, #2D535F);
}
.zs-button:active > .zs-icon {
  color: inherit;
}
.zs-button.zs-danger {
  color: var(--zs-danger-color, #B21111);
  border: 1px solid var(--zs-danger-color, #B21111);
  background: var(--zs-button-background-color, #ffffff);
}
.zs-button.zs-danger:visited {
  color: var(--zs-danger-color, #B21111);
}
.zs-button.zs-danger:focus {
  color: var(--zs-danger-focus-color, #892208);
}
.zs-button.zs-danger:hover {
  color: var(--zs-button-background-color, #ffffff);
}
.zs-button.zs-danger:active {
  color: var(--zs-danger-active-color, #5C1A0B);
}
.zs-button.zs-danger:focus {
  border: 1px solid var(--zs-danger-focus-color, #892208);
}
.zs-button.zs-danger:hover {
  border: 1px solid var(--zs-danger-hover-color, #F27755);
}
.zs-button.zs-danger:active {
  border: 1px solid var(--zs-danger-active-color, #5C1A0B);
}
.zs-button.zs-danger:focus {
  background: var(--zs-button-background-color, #ffffff);
}
.zs-button.zs-danger:hover {
  background: var(--zs-danger-hover-color, #F27755);
}
.zs-button.zs-danger:active {
  background: var(--zs-button-background-color, #ffffff);
}
.zs-button.zs-success {
  color: var(--zs-success-color, #00AA67);
  border: 1px solid var(--zs-success-color, #00AA67);
  background: var(--zs-button-background-color, #ffffff);
}
.zs-button.zs-success:visited {
  color: var(--zs-success-color, #00AA67);
}
.zs-button.zs-success:focus {
  color: var(--zs-success-focus-color, #0A6E5E);
}
.zs-button.zs-success:hover {
  color: var(--zs-button-background-color, #ffffff);
}
.zs-button.zs-success:active {
  color: var(--zs-success-active-color, #0C4846);
}
.zs-button.zs-success:focus {
  border: 1px solid var(--zs-success-focus-color, #0A6E5E);
}
.zs-button.zs-success:hover {
  border: 1px solid var(--zs-success-hover-color, #54D7AF);
}
.zs-button.zs-success:active {
  border: 1px solid var(--zs-success-active-color, #0C4846);
}
.zs-button.zs-success:focus {
  background: var(--zs-button-background-color, #ffffff);
}
.zs-button.zs-success:hover {
  background: var(--zs-success-hover-color, #54D7AF);
}
.zs-button.zs-success:active {
  background: var(--zs-button-background-color, #ffffff);
}
.zs-button.zs-warning {
  color: var(--zs-warning-color, #E3A900);
  border: 1px solid var(--zs-warning-color, #E3A900);
  background: var(--zs-button-background-color, #ffffff);
}
.zs-button.zs-warning:visited {
  color: var(--zs-warning-color, #E3A900);
}
.zs-button.zs-warning:focus {
  color: var(--zs-warning-focus-color, #8A640C);
}
.zs-button.zs-warning:hover {
  color: var(--zs-button-background-color, #ffffff);
}
.zs-button.zs-warning:active {
  color: var(--zs-warning-active-color, #563F0E);
}
.zs-button.zs-warning:focus {
  border: 1px solid var(--zs-warning-focus-color, #8A640C);
}
.zs-button.zs-warning:hover {
  border: 1px solid var(--zs-warning-hover-color, #FDCA63);
}
.zs-button.zs-warning:active {
  border: 1px solid var(--zs-warning-active-color, #563F0E);
}
.zs-button.zs-warning:focus {
  background: var(--zs-button-background-color, #ffffff);
}
.zs-button.zs-warning:hover {
  background: var(--zs-warning-hover-color, #FDCA63);
}
.zs-button.zs-warning:active {
  background: var(--zs-button-background-color, #ffffff);
}
.zs-button.zs-info {
  color: var(--zs-info-color, #2B44C7);
  border: 1px solid var(--zs-info-color, #2B44C7);
  background: var(--zs-button-background-color, #ffffff);
}
.zs-button.zs-info:visited {
  color: var(--zs-info-color, #2B44C7);
}
.zs-button.zs-info:focus {
  color: var(--zs-info-focus-color, #141187);
}
.zs-button.zs-info:hover {
  color: var(--zs-button-background-color, #ffffff);
}
.zs-button.zs-info:active {
  color: var(--zs-info-active-color, #160C60);
}
.zs-button.zs-info:focus {
  border: 1px solid var(--zs-info-focus-color, #141187);
}
.zs-button.zs-info:hover {
  border: 1px solid var(--zs-info-hover-color, #7E92E9);
}
.zs-button.zs-info:active {
  border: 1px solid var(--zs-info-active-color, #160C60);
}
.zs-button.zs-info:focus {
  background: var(--zs-button-background-color, #ffffff);
}
.zs-button.zs-info:hover {
  background: var(--zs-info-hover-color, #7E92E9);
}
.zs-button.zs-info:active {
  background: var(--zs-button-background-color, #ffffff);
}
.zs-button::-moz-focus-inner {
  border: none;
}
.zs-button-action,
.zs-button-solid {
  background-color: var(--zs-solid-button-background-color, #27A6A4);
  color: var(--zs-button-background-color, #ffffff);
  border: 1px solid var(--zs-solid-button-background-color, #27A6A4);
}
.zs-button-action:focus,
.zs-button-solid:focus {
  border: 1px solid var(--zs-button-focus-color, #2F6F7B);
}
.zs-button-action:hover,
.zs-button-solid:hover {
  border: 1px solid var(--zs-button-hover-color, #62D2D1);
}
.zs-button-action:active,
.zs-button-solid:active {
  border: 1px solid var(--zs-button-active-color, #2D535F);
}
.zs-button-action:focus,
.zs-button-solid:focus {
  background: var(--zs-button-focus-color, #2F6F7B);
  color: var(--zs-button-background-color, #ffffff);
}
.zs-button-action:hover,
.zs-button-solid:hover {
  color: var(--zs-button-background-color, #ffffff);
  background: var(--zs-button-hover-color, #62D2D1);
}
.zs-button-action:active,
.zs-button-solid:active {
  background: var(--zs-button-active-color, #2D535F);
  color: var(--zs-button-background-color, #ffffff);
}
.zs-button-action.zs-danger,
.zs-button-solid.zs-danger {
  background: var(--zs-danger-color, #B21111);
  border: 1px solid var(--zs-danger-color, #B21111);
  color: var(--zs-button-background-color, #ffffff);
}
.zs-button-action.zs-danger:focus,
.zs-button-solid.zs-danger:focus {
  background: var(--zs-danger-focus-color, #892208);
}
.zs-button-action.zs-danger:hover,
.zs-button-solid.zs-danger:hover {
  background: var(--zs-danger-hover-color, #F27755);
}
.zs-button-action.zs-danger:active,
.zs-button-solid.zs-danger:active {
  background: var(--zs-danger-active-color, #5C1A0B);
}
.zs-button-action.zs-danger:focus,
.zs-button-solid.zs-danger:focus {
  border: 1px solid var(--zs-danger-focus-color, #892208);
}
.zs-button-action.zs-danger:hover,
.zs-button-solid.zs-danger:hover {
  border: 1px solid var(--zs-danger-hover-color, #F27755);
}
.zs-button-action.zs-danger:active,
.zs-button-solid.zs-danger:active {
  border: 1px solid var(--zs-danger-active-color, #5C1A0B);
}
.zs-button-action.zs-danger:visited,
.zs-button-solid.zs-danger:visited {
  color: var(--zs-button-background-color, #ffffff);
}
.zs-button-action.zs-danger:focus,
.zs-button-solid.zs-danger:focus {
  color: var(--zs-button-background-color, #ffffff);
}
.zs-button-action.zs-danger:hover,
.zs-button-solid.zs-danger:hover {
  color: var(--zs-button-background-color, #ffffff);
}
.zs-button-action.zs-danger:active,
.zs-button-solid.zs-danger:active {
  color: var(--zs-button-background-color, #ffffff);
}
.zs-button-action.zs-success,
.zs-button-solid.zs-success {
  background: var(--zs-success-color, #00AA67);
  border: 1px solid var(--zs-success-color, #00AA67);
  color: var(--zs-button-background-color, #ffffff);
}
.zs-button-action.zs-success:focus,
.zs-button-solid.zs-success:focus {
  background: var(--zs-success-focus-color, #0A6E5E);
}
.zs-button-action.zs-success:hover,
.zs-button-solid.zs-success:hover {
  background: var(--zs-success-hover-color, #54D7AF);
}
.zs-button-action.zs-success:active,
.zs-button-solid.zs-success:active {
  background: var(--zs-success-active-color, #0C4846);
}
.zs-button-action.zs-success:focus,
.zs-button-solid.zs-success:focus {
  border: 1px solid var(--zs-success-focus-color, #0A6E5E);
}
.zs-button-action.zs-success:hover,
.zs-button-solid.zs-success:hover {
  border: 1px solid var(--zs-success-hover-color, #54D7AF);
}
.zs-button-action.zs-success:active,
.zs-button-solid.zs-success:active {
  border: 1px solid var(--zs-success-active-color, #0C4846);
}
.zs-button-action.zs-success:visited,
.zs-button-solid.zs-success:visited {
  color: var(--zs-button-background-color, #ffffff);
}
.zs-button-action.zs-success:focus,
.zs-button-solid.zs-success:focus {
  color: var(--zs-button-background-color, #ffffff);
}
.zs-button-action.zs-success:hover,
.zs-button-solid.zs-success:hover {
  color: var(--zs-button-background-color, #ffffff);
}
.zs-button-action.zs-success:active,
.zs-button-solid.zs-success:active {
  color: var(--zs-button-background-color, #ffffff);
}
.zs-button-action.zs-warning,
.zs-button-solid.zs-warning {
  background: var(--zs-warning-color, #E3A900);
  border: 1px solid var(--zs-warning-color, #E3A900);
  color: var(--zs-button-background-color, #ffffff);
}
.zs-button-action.zs-warning:focus,
.zs-button-solid.zs-warning:focus {
  background: var(--zs-warning-focus-color, #8A640C);
}
.zs-button-action.zs-warning:hover,
.zs-button-solid.zs-warning:hover {
  background: var(--zs-warning-hover-color, #FDCA63);
}
.zs-button-action.zs-warning:active,
.zs-button-solid.zs-warning:active {
  background: var(--zs-warning-active-color, #563F0E);
}
.zs-button-action.zs-warning:focus,
.zs-button-solid.zs-warning:focus {
  border: 1px solid var(--zs-warning-focus-color, #8A640C);
}
.zs-button-action.zs-warning:hover,
.zs-button-solid.zs-warning:hover {
  border: 1px solid var(--zs-warning-hover-color, #FDCA63);
}
.zs-button-action.zs-warning:active,
.zs-button-solid.zs-warning:active {
  border: 1px solid var(--zs-warning-active-color, #563F0E);
}
.zs-button-action.zs-warning:visited,
.zs-button-solid.zs-warning:visited {
  color: var(--zs-button-background-color, #ffffff);
}
.zs-button-action.zs-warning:focus,
.zs-button-solid.zs-warning:focus {
  color: var(--zs-button-background-color, #ffffff);
}
.zs-button-action.zs-warning:hover,
.zs-button-solid.zs-warning:hover {
  color: var(--zs-button-background-color, #ffffff);
}
.zs-button-action.zs-warning:active,
.zs-button-solid.zs-warning:active {
  color: var(--zs-button-background-color, #ffffff);
}
.zs-button-action.zs-info,
.zs-button-solid.zs-info {
  background: var(--zs-info-color, #2B44C7);
  border: 1px solid var(--zs-info-color, #2B44C7);
  color: var(--zs-button-background-color, #ffffff);
}
.zs-button-action.zs-info:focus,
.zs-button-solid.zs-info:focus {
  background: var(--zs-info-focus-color, #141187);
}
.zs-button-action.zs-info:hover,
.zs-button-solid.zs-info:hover {
  background: var(--zs-info-hover-color, #7E92E9);
}
.zs-button-action.zs-info:active,
.zs-button-solid.zs-info:active {
  background: var(--zs-info-active-color, #160C60);
}
.zs-button-action.zs-info:focus,
.zs-button-solid.zs-info:focus {
  border: 1px solid var(--zs-info-focus-color, #141187);
}
.zs-button-action.zs-info:hover,
.zs-button-solid.zs-info:hover {
  border: 1px solid var(--zs-info-hover-color, #7E92E9);
}
.zs-button-action.zs-info:active,
.zs-button-solid.zs-info:active {
  border: 1px solid var(--zs-info-active-color, #160C60);
}
.zs-button-action.zs-info:visited,
.zs-button-solid.zs-info:visited {
  color: var(--zs-button-background-color, #ffffff);
}
.zs-button-action.zs-info:focus,
.zs-button-solid.zs-info:focus {
  color: var(--zs-button-background-color, #ffffff);
}
.zs-button-action.zs-info:hover,
.zs-button-solid.zs-info:hover {
  color: var(--zs-button-background-color, #ffffff);
}
.zs-button-action.zs-info:active,
.zs-button-solid.zs-info:active {
  color: var(--zs-button-background-color, #ffffff);
}
a.zs-button {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  text-decoration: none;
  border: 1px solid transparent;
  color: var(--zs-link-button-text-color, #2D8B93);
}
a.zs-button:focus {
  border: 1px solid var(--zs-transparent-background-color, transparent);
}
a.zs-button:hover {
  border: 1px solid var(--zs-transparent-background-color, transparent);
}
a.zs-button:active {
  border: 1px solid var(--zs-transparent-background-color, transparent);
}
a.zs-button:focus {
  color: var(--zs-link-button-focus-color, #27A6A4);
  background: var(--zs-link-button-focus-background, #F3FCFE);
  box-shadow: inset 0 0 0 2px var(--zs-link-button-focus-color, #27A6A4);
  outline: none;
}
a.zs-button:hover {
  color: var(--zs-link-button-hover-color, #27A6A4);
  background: var(--zs-link-button-hover-background, #F3FCFE);
  box-shadow: none;
}
a.zs-button:active {
  color: var(--zs-link-button-active-color, #ffffff);
  background: var(--zs-link-button-active-background, #2F6F7B);
  box-shadow: none;
}
a.zs-button.zs-danger {
  color: var(--zs-danger-color, #B21111);
  background: var(--zs-button-background-color, #ffffff);
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px transparent;
  border: 1px solid transparent;
}
a.zs-button.zs-danger:visited {
  color: var(--zs-danger-color, #B21111);
}
a.zs-button.zs-danger:focus {
  color: var(--zs-button-background-color, #ffffff);
}
a.zs-button.zs-danger:hover {
  color: var(--zs-button-background-color, #ffffff);
}
a.zs-button.zs-danger:active {
  color: var(--zs-button-background-color, #ffffff);
}
a.zs-button.zs-danger:focus {
  background: var(--zs-danger-focus-color, #892208);
}
a.zs-button.zs-danger:hover {
  background: var(--zs-danger-hover-color, #F27755);
}
a.zs-button.zs-danger:active {
  background: var(--zs-danger-active-color, #5C1A0B);
}
a.zs-button.zs-danger:focus {
  box-shadow: inset 0 0 0 1px var(--zs-danger-focus-color, #892208);
}
a.zs-button.zs-danger:hover {
  box-shadow: inset 0 0 0 1px var(--zs-danger-hover-color, #F27755);
}
a.zs-button.zs-danger:active {
  box-shadow: inset 0 0 0 1px var(--zs-danger-active-color, #5C1A0B);
}
a.zs-button.zs-success {
  color: var(--zs-success-color, #00AA67);
  background: var(--zs-button-background-color, #ffffff);
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px transparent;
  border: 1px solid transparent;
}
a.zs-button.zs-success:visited {
  color: var(--zs-success-color, #00AA67);
}
a.zs-button.zs-success:focus {
  color: var(--zs-button-background-color, #ffffff);
}
a.zs-button.zs-success:hover {
  color: var(--zs-button-background-color, #ffffff);
}
a.zs-button.zs-success:active {
  color: var(--zs-button-background-color, #ffffff);
}
a.zs-button.zs-success:focus {
  background: var(--zs-success-focus-color, #0A6E5E);
}
a.zs-button.zs-success:hover {
  background: var(--zs-success-hover-color, #54D7AF);
}
a.zs-button.zs-success:active {
  background: var(--zs-success-active-color, #0C4846);
}
a.zs-button.zs-success:focus {
  box-shadow: inset 0 0 0 1px var(--zs-success-focus-color, #0A6E5E);
}
a.zs-button.zs-success:hover {
  box-shadow: inset 0 0 0 1px var(--zs-success-hover-color, #54D7AF);
}
a.zs-button.zs-success:active {
  box-shadow: inset 0 0 0 1px var(--zs-success-active-color, #0C4846);
}
a.zs-button.zs-warning {
  color: var(--zs-warning-color, #E3A900);
  background: var(--zs-button-background-color, #ffffff);
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px transparent;
  border: 1px solid transparent;
}
a.zs-button.zs-warning:visited {
  color: var(--zs-warning-color, #E3A900);
}
a.zs-button.zs-warning:focus {
  color: var(--zs-button-background-color, #ffffff);
}
a.zs-button.zs-warning:hover {
  color: var(--zs-button-background-color, #ffffff);
}
a.zs-button.zs-warning:active {
  color: var(--zs-button-background-color, #ffffff);
}
a.zs-button.zs-warning:focus {
  background: var(--zs-warning-focus-color, #8A640C);
}
a.zs-button.zs-warning:hover {
  background: var(--zs-warning-hover-color, #FDCA63);
}
a.zs-button.zs-warning:active {
  background: var(--zs-warning-active-color, #563F0E);
}
a.zs-button.zs-warning:focus {
  box-shadow: inset 0 0 0 1px var(--zs-warning-focus-color, #8A640C);
}
a.zs-button.zs-warning:hover {
  box-shadow: inset 0 0 0 1px var(--zs-warning-hover-color, #FDCA63);
}
a.zs-button.zs-warning:active {
  box-shadow: inset 0 0 0 1px var(--zs-warning-active-color, #563F0E);
}
a.zs-button.zs-info {
  color: var(--zs-info-color, #2B44C7);
  background: var(--zs-button-background-color, #ffffff);
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px transparent;
  border: 1px solid transparent;
}
a.zs-button.zs-info:visited {
  color: var(--zs-info-color, #2B44C7);
}
a.zs-button.zs-info:focus {
  color: var(--zs-button-background-color, #ffffff);
}
a.zs-button.zs-info:hover {
  color: var(--zs-button-background-color, #ffffff);
}
a.zs-button.zs-info:active {
  color: var(--zs-button-background-color, #ffffff);
}
a.zs-button.zs-info:focus {
  background: var(--zs-info-focus-color, #141187);
}
a.zs-button.zs-info:hover {
  background: var(--zs-info-hover-color, #7E92E9);
}
a.zs-button.zs-info:active {
  background: var(--zs-info-active-color, #160C60);
}
a.zs-button.zs-info:focus {
  box-shadow: inset 0 0 0 1px var(--zs-info-focus-color, #141187);
}
a.zs-button.zs-info:hover {
  box-shadow: inset 0 0 0 1px var(--zs-info-hover-color, #7E92E9);
}
a.zs-button.zs-info:active {
  box-shadow: inset 0 0 0 1px var(--zs-info-active-color, #160C60);
}
.zs-button .zs-icon:hover {
  cursor: pointer;
  color: inherit;
}
.zs-button .zs-icon::before,
.zs-button .zs-icon::after {
  margin: 0;
  padding: 0;
  vertical-align: top;
}
.zs-button > .zs-icon + *,
.zs-button > * + .zs-icon {
  vertical-align: middle;
}
.zs-button + .zs-button {
  margin-left: 0.5em;
}
.zs-fab {
  position: relative;
  border-radius: 100%;
  min-height: auto;
  padding: 1.5em;
}
.zs-fab > .zs-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.zs-button[disabled],
.zs-button.zs-disabled,
.zs-field input[type="submit"][disabled],
.zs-field input[type="submit"].zs-disabled,
.zs-field input[type="button"][disabled],
.zs-field input[type="button"].zs-disabled,
.zs-field input[type="reset"][disabled],
.zs-field input[type="reset"].zs-disabled {
  cursor: default;
  pointer-events: none;
  opacity: 1;
  color: var(--zs-disabled-text-color, #B2B0B6);
  background: none;
  background-color: var(--zs-disabled-background-color, #F4F3F3);
  border: 1px solid var(--zs-disabled-border-color, #B2B0B6);
}
.zs-button > *:not(.zs-icon) {
  margin: 0em 0.25em 0em 0.25em;
}
.zs-button > *:first-child {
  margin-left: 0;
}
.zs-button > *:last-child {
  margin-right: 0;
}
.zs-field input[type="submit"] {
  text-decoration: none;
  vertical-align: middle;
  line-height: 1.5;
  font-size: 1em;
  font-weight: 600;
  box-shadow: none;
  border: 1px solid var(--zs-button-border-color, #B2B0B6);
  color: var(--zs-button-text-color, #454250);
  background-color: var(--zs-button-background-color, #ffffff);
  border-radius: 0.25em;
  padding: 0.75em 1em 0.75em 1em;
  white-space: nowrap;
  -webkit-appearance: none;
  transition: background-color 0.1s ease-out;
  background-color: var(--zs-solid-button-background-color, #27A6A4);
  color: var(--zs-button-background-color, #ffffff);
  border: 1px solid var(--zs-solid-button-background-color, #27A6A4);
}
.zs-field input[type="submit"]:focus {
  border: 1px solid var(--zs-button-focus-color, #2F6F7B);
}
.zs-field input[type="submit"]:hover {
  border: 1px solid var(--zs-button-hover-color, #62D2D1);
}
.zs-field input[type="submit"]:active {
  border: 1px solid var(--zs-button-active-color, #2D535F);
}
@media (prefers-reduced-motion) {
  .zs-field input[type="submit"] {
    transition-duration: 0s;
  }
}
.zs-field input[type="submit"] span {
  vertical-align: middle;
}
.zs-field input[type="submit"]:focus {
  color: var(--zs-button-focus-color, #2F6F7B);
  outline: none;
}
.zs-field input[type="submit"]:focus > * {
  outline: inherit;
}
.zs-field input[type="submit"]:focus > .zs-icon {
  color: inherit;
}
.zs-field input[type="submit"]:hover {
  cursor: pointer;
  background-color: var(--zs-button-hover-color, #62D2D1);
  color: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="submit"]:hover .zs-icon {
  color: inherit;
}
.zs-field input[type="submit"]:active {
  background: var(--zs-button-background-color, #ffffff);
  color: var(--zs-button-active-color, #2D535F);
}
.zs-field input[type="submit"]:active > .zs-icon {
  color: inherit;
}
.zs-field input[type="submit"].zs-danger {
  color: var(--zs-danger-color, #B21111);
  border: 1px solid var(--zs-danger-color, #B21111);
  background: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="submit"].zs-danger:visited {
  color: var(--zs-danger-color, #B21111);
}
.zs-field input[type="submit"].zs-danger:focus {
  color: var(--zs-danger-focus-color, #892208);
}
.zs-field input[type="submit"].zs-danger:hover {
  color: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="submit"].zs-danger:active {
  color: var(--zs-danger-active-color, #5C1A0B);
}
.zs-field input[type="submit"].zs-danger:focus {
  border: 1px solid var(--zs-danger-focus-color, #892208);
}
.zs-field input[type="submit"].zs-danger:hover {
  border: 1px solid var(--zs-danger-hover-color, #F27755);
}
.zs-field input[type="submit"].zs-danger:active {
  border: 1px solid var(--zs-danger-active-color, #5C1A0B);
}
.zs-field input[type="submit"].zs-danger:focus {
  background: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="submit"].zs-danger:hover {
  background: var(--zs-danger-hover-color, #F27755);
}
.zs-field input[type="submit"].zs-danger:active {
  background: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="submit"].zs-success {
  color: var(--zs-success-color, #00AA67);
  border: 1px solid var(--zs-success-color, #00AA67);
  background: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="submit"].zs-success:visited {
  color: var(--zs-success-color, #00AA67);
}
.zs-field input[type="submit"].zs-success:focus {
  color: var(--zs-success-focus-color, #0A6E5E);
}
.zs-field input[type="submit"].zs-success:hover {
  color: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="submit"].zs-success:active {
  color: var(--zs-success-active-color, #0C4846);
}
.zs-field input[type="submit"].zs-success:focus {
  border: 1px solid var(--zs-success-focus-color, #0A6E5E);
}
.zs-field input[type="submit"].zs-success:hover {
  border: 1px solid var(--zs-success-hover-color, #54D7AF);
}
.zs-field input[type="submit"].zs-success:active {
  border: 1px solid var(--zs-success-active-color, #0C4846);
}
.zs-field input[type="submit"].zs-success:focus {
  background: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="submit"].zs-success:hover {
  background: var(--zs-success-hover-color, #54D7AF);
}
.zs-field input[type="submit"].zs-success:active {
  background: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="submit"].zs-warning {
  color: var(--zs-warning-color, #E3A900);
  border: 1px solid var(--zs-warning-color, #E3A900);
  background: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="submit"].zs-warning:visited {
  color: var(--zs-warning-color, #E3A900);
}
.zs-field input[type="submit"].zs-warning:focus {
  color: var(--zs-warning-focus-color, #8A640C);
}
.zs-field input[type="submit"].zs-warning:hover {
  color: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="submit"].zs-warning:active {
  color: var(--zs-warning-active-color, #563F0E);
}
.zs-field input[type="submit"].zs-warning:focus {
  border: 1px solid var(--zs-warning-focus-color, #8A640C);
}
.zs-field input[type="submit"].zs-warning:hover {
  border: 1px solid var(--zs-warning-hover-color, #FDCA63);
}
.zs-field input[type="submit"].zs-warning:active {
  border: 1px solid var(--zs-warning-active-color, #563F0E);
}
.zs-field input[type="submit"].zs-warning:focus {
  background: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="submit"].zs-warning:hover {
  background: var(--zs-warning-hover-color, #FDCA63);
}
.zs-field input[type="submit"].zs-warning:active {
  background: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="submit"].zs-info {
  color: var(--zs-info-color, #2B44C7);
  border: 1px solid var(--zs-info-color, #2B44C7);
  background: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="submit"].zs-info:visited {
  color: var(--zs-info-color, #2B44C7);
}
.zs-field input[type="submit"].zs-info:focus {
  color: var(--zs-info-focus-color, #141187);
}
.zs-field input[type="submit"].zs-info:hover {
  color: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="submit"].zs-info:active {
  color: var(--zs-info-active-color, #160C60);
}
.zs-field input[type="submit"].zs-info:focus {
  border: 1px solid var(--zs-info-focus-color, #141187);
}
.zs-field input[type="submit"].zs-info:hover {
  border: 1px solid var(--zs-info-hover-color, #7E92E9);
}
.zs-field input[type="submit"].zs-info:active {
  border: 1px solid var(--zs-info-active-color, #160C60);
}
.zs-field input[type="submit"].zs-info:focus {
  background: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="submit"].zs-info:hover {
  background: var(--zs-info-hover-color, #7E92E9);
}
.zs-field input[type="submit"].zs-info:active {
  background: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="submit"]:focus {
  border: 1px solid var(--zs-button-focus-color, #2F6F7B);
}
.zs-field input[type="submit"]:hover {
  border: 1px solid var(--zs-button-hover-color, #62D2D1);
}
.zs-field input[type="submit"]:active {
  border: 1px solid var(--zs-button-active-color, #2D535F);
}
.zs-field input[type="submit"]:focus {
  background: var(--zs-button-focus-color, #2F6F7B);
  color: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="submit"]:hover {
  color: var(--zs-button-background-color, #ffffff);
  background: var(--zs-button-hover-color, #62D2D1);
}
.zs-field input[type="submit"]:active {
  background: var(--zs-button-active-color, #2D535F);
  color: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="submit"].zs-danger {
  background: var(--zs-danger-color, #B21111);
  border: 1px solid var(--zs-danger-color, #B21111);
  color: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="submit"].zs-danger:focus {
  background: var(--zs-danger-focus-color, #892208);
}
.zs-field input[type="submit"].zs-danger:hover {
  background: var(--zs-danger-hover-color, #F27755);
}
.zs-field input[type="submit"].zs-danger:active {
  background: var(--zs-danger-active-color, #5C1A0B);
}
.zs-field input[type="submit"].zs-danger:focus {
  border: 1px solid var(--zs-danger-focus-color, #892208);
}
.zs-field input[type="submit"].zs-danger:hover {
  border: 1px solid var(--zs-danger-hover-color, #F27755);
}
.zs-field input[type="submit"].zs-danger:active {
  border: 1px solid var(--zs-danger-active-color, #5C1A0B);
}
.zs-field input[type="submit"].zs-danger:visited {
  color: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="submit"].zs-danger:focus {
  color: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="submit"].zs-danger:hover {
  color: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="submit"].zs-danger:active {
  color: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="submit"].zs-success {
  background: var(--zs-success-color, #00AA67);
  border: 1px solid var(--zs-success-color, #00AA67);
  color: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="submit"].zs-success:focus {
  background: var(--zs-success-focus-color, #0A6E5E);
}
.zs-field input[type="submit"].zs-success:hover {
  background: var(--zs-success-hover-color, #54D7AF);
}
.zs-field input[type="submit"].zs-success:active {
  background: var(--zs-success-active-color, #0C4846);
}
.zs-field input[type="submit"].zs-success:focus {
  border: 1px solid var(--zs-success-focus-color, #0A6E5E);
}
.zs-field input[type="submit"].zs-success:hover {
  border: 1px solid var(--zs-success-hover-color, #54D7AF);
}
.zs-field input[type="submit"].zs-success:active {
  border: 1px solid var(--zs-success-active-color, #0C4846);
}
.zs-field input[type="submit"].zs-success:visited {
  color: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="submit"].zs-success:focus {
  color: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="submit"].zs-success:hover {
  color: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="submit"].zs-success:active {
  color: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="submit"].zs-warning {
  background: var(--zs-warning-color, #E3A900);
  border: 1px solid var(--zs-warning-color, #E3A900);
  color: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="submit"].zs-warning:focus {
  background: var(--zs-warning-focus-color, #8A640C);
}
.zs-field input[type="submit"].zs-warning:hover {
  background: var(--zs-warning-hover-color, #FDCA63);
}
.zs-field input[type="submit"].zs-warning:active {
  background: var(--zs-warning-active-color, #563F0E);
}
.zs-field input[type="submit"].zs-warning:focus {
  border: 1px solid var(--zs-warning-focus-color, #8A640C);
}
.zs-field input[type="submit"].zs-warning:hover {
  border: 1px solid var(--zs-warning-hover-color, #FDCA63);
}
.zs-field input[type="submit"].zs-warning:active {
  border: 1px solid var(--zs-warning-active-color, #563F0E);
}
.zs-field input[type="submit"].zs-warning:visited {
  color: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="submit"].zs-warning:focus {
  color: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="submit"].zs-warning:hover {
  color: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="submit"].zs-warning:active {
  color: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="submit"].zs-info {
  background: var(--zs-info-color, #2B44C7);
  border: 1px solid var(--zs-info-color, #2B44C7);
  color: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="submit"].zs-info:focus {
  background: var(--zs-info-focus-color, #141187);
}
.zs-field input[type="submit"].zs-info:hover {
  background: var(--zs-info-hover-color, #7E92E9);
}
.zs-field input[type="submit"].zs-info:active {
  background: var(--zs-info-active-color, #160C60);
}
.zs-field input[type="submit"].zs-info:focus {
  border: 1px solid var(--zs-info-focus-color, #141187);
}
.zs-field input[type="submit"].zs-info:hover {
  border: 1px solid var(--zs-info-hover-color, #7E92E9);
}
.zs-field input[type="submit"].zs-info:active {
  border: 1px solid var(--zs-info-active-color, #160C60);
}
.zs-field input[type="submit"].zs-info:visited {
  color: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="submit"].zs-info:focus {
  color: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="submit"].zs-info:hover {
  color: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="submit"].zs-info:active {
  color: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="button"] {
  text-decoration: none;
  vertical-align: middle;
  line-height: 1.5;
  font-size: 1em;
  font-weight: 600;
  box-shadow: none;
  border: 1px solid var(--zs-button-border-color, #B2B0B6);
  color: var(--zs-button-text-color, #454250);
  background-color: var(--zs-button-background-color, #ffffff);
  border-radius: 0.25em;
  padding: 0.75em 1em 0.75em 1em;
  white-space: nowrap;
  -webkit-appearance: none;
  transition: background-color 0.1s ease-out;
}
.zs-field input[type="button"]:focus {
  border: 1px solid var(--zs-button-focus-color, #2F6F7B);
}
.zs-field input[type="button"]:hover {
  border: 1px solid var(--zs-button-hover-color, #62D2D1);
}
.zs-field input[type="button"]:active {
  border: 1px solid var(--zs-button-active-color, #2D535F);
}
@media (prefers-reduced-motion) {
  .zs-field input[type="button"] {
    transition-duration: 0s;
  }
}
.zs-field input[type="button"] span {
  vertical-align: middle;
}
.zs-field input[type="button"]:focus {
  color: var(--zs-button-focus-color, #2F6F7B);
  outline: none;
}
.zs-field input[type="button"]:focus > * {
  outline: inherit;
}
.zs-field input[type="button"]:focus > .zs-icon {
  color: inherit;
}
.zs-field input[type="button"]:hover {
  cursor: pointer;
  background-color: var(--zs-button-hover-color, #62D2D1);
  color: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="button"]:hover .zs-icon {
  color: inherit;
}
.zs-field input[type="button"]:active {
  background: var(--zs-button-background-color, #ffffff);
  color: var(--zs-button-active-color, #2D535F);
}
.zs-field input[type="button"]:active > .zs-icon {
  color: inherit;
}
.zs-field input[type="button"].zs-danger {
  color: var(--zs-danger-color, #B21111);
  border: 1px solid var(--zs-danger-color, #B21111);
  background: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="button"].zs-danger:visited {
  color: var(--zs-danger-color, #B21111);
}
.zs-field input[type="button"].zs-danger:focus {
  color: var(--zs-danger-focus-color, #892208);
}
.zs-field input[type="button"].zs-danger:hover {
  color: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="button"].zs-danger:active {
  color: var(--zs-danger-active-color, #5C1A0B);
}
.zs-field input[type="button"].zs-danger:focus {
  border: 1px solid var(--zs-danger-focus-color, #892208);
}
.zs-field input[type="button"].zs-danger:hover {
  border: 1px solid var(--zs-danger-hover-color, #F27755);
}
.zs-field input[type="button"].zs-danger:active {
  border: 1px solid var(--zs-danger-active-color, #5C1A0B);
}
.zs-field input[type="button"].zs-danger:focus {
  background: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="button"].zs-danger:hover {
  background: var(--zs-danger-hover-color, #F27755);
}
.zs-field input[type="button"].zs-danger:active {
  background: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="button"].zs-success {
  color: var(--zs-success-color, #00AA67);
  border: 1px solid var(--zs-success-color, #00AA67);
  background: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="button"].zs-success:visited {
  color: var(--zs-success-color, #00AA67);
}
.zs-field input[type="button"].zs-success:focus {
  color: var(--zs-success-focus-color, #0A6E5E);
}
.zs-field input[type="button"].zs-success:hover {
  color: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="button"].zs-success:active {
  color: var(--zs-success-active-color, #0C4846);
}
.zs-field input[type="button"].zs-success:focus {
  border: 1px solid var(--zs-success-focus-color, #0A6E5E);
}
.zs-field input[type="button"].zs-success:hover {
  border: 1px solid var(--zs-success-hover-color, #54D7AF);
}
.zs-field input[type="button"].zs-success:active {
  border: 1px solid var(--zs-success-active-color, #0C4846);
}
.zs-field input[type="button"].zs-success:focus {
  background: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="button"].zs-success:hover {
  background: var(--zs-success-hover-color, #54D7AF);
}
.zs-field input[type="button"].zs-success:active {
  background: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="button"].zs-warning {
  color: var(--zs-warning-color, #E3A900);
  border: 1px solid var(--zs-warning-color, #E3A900);
  background: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="button"].zs-warning:visited {
  color: var(--zs-warning-color, #E3A900);
}
.zs-field input[type="button"].zs-warning:focus {
  color: var(--zs-warning-focus-color, #8A640C);
}
.zs-field input[type="button"].zs-warning:hover {
  color: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="button"].zs-warning:active {
  color: var(--zs-warning-active-color, #563F0E);
}
.zs-field input[type="button"].zs-warning:focus {
  border: 1px solid var(--zs-warning-focus-color, #8A640C);
}
.zs-field input[type="button"].zs-warning:hover {
  border: 1px solid var(--zs-warning-hover-color, #FDCA63);
}
.zs-field input[type="button"].zs-warning:active {
  border: 1px solid var(--zs-warning-active-color, #563F0E);
}
.zs-field input[type="button"].zs-warning:focus {
  background: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="button"].zs-warning:hover {
  background: var(--zs-warning-hover-color, #FDCA63);
}
.zs-field input[type="button"].zs-warning:active {
  background: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="button"].zs-info {
  color: var(--zs-info-color, #2B44C7);
  border: 1px solid var(--zs-info-color, #2B44C7);
  background: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="button"].zs-info:visited {
  color: var(--zs-info-color, #2B44C7);
}
.zs-field input[type="button"].zs-info:focus {
  color: var(--zs-info-focus-color, #141187);
}
.zs-field input[type="button"].zs-info:hover {
  color: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="button"].zs-info:active {
  color: var(--zs-info-active-color, #160C60);
}
.zs-field input[type="button"].zs-info:focus {
  border: 1px solid var(--zs-info-focus-color, #141187);
}
.zs-field input[type="button"].zs-info:hover {
  border: 1px solid var(--zs-info-hover-color, #7E92E9);
}
.zs-field input[type="button"].zs-info:active {
  border: 1px solid var(--zs-info-active-color, #160C60);
}
.zs-field input[type="button"].zs-info:focus {
  background: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="button"].zs-info:hover {
  background: var(--zs-info-hover-color, #7E92E9);
}
.zs-field input[type="button"].zs-info:active {
  background: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="reset"] {
  text-decoration: none;
  vertical-align: middle;
  line-height: 1.5;
  font-size: 1em;
  font-weight: 600;
  box-shadow: none;
  border: 1px solid var(--zs-button-border-color, #B2B0B6);
  color: var(--zs-button-text-color, #454250);
  background-color: var(--zs-button-background-color, #ffffff);
  border-radius: 0.25em;
  padding: 0.75em 1em 0.75em 1em;
  white-space: nowrap;
  -webkit-appearance: none;
  transition: background-color 0.1s ease-out;
}
.zs-field input[type="reset"]:focus {
  border: 1px solid var(--zs-button-focus-color, #2F6F7B);
}
.zs-field input[type="reset"]:hover {
  border: 1px solid var(--zs-button-hover-color, #62D2D1);
}
.zs-field input[type="reset"]:active {
  border: 1px solid var(--zs-button-active-color, #2D535F);
}
@media (prefers-reduced-motion) {
  .zs-field input[type="reset"] {
    transition-duration: 0s;
  }
}
.zs-field input[type="reset"] span {
  vertical-align: middle;
}
.zs-field input[type="reset"]:focus {
  color: var(--zs-button-focus-color, #2F6F7B);
  outline: none;
}
.zs-field input[type="reset"]:focus > * {
  outline: inherit;
}
.zs-field input[type="reset"]:focus > .zs-icon {
  color: inherit;
}
.zs-field input[type="reset"]:hover {
  cursor: pointer;
  background-color: var(--zs-button-hover-color, #62D2D1);
  color: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="reset"]:hover .zs-icon {
  color: inherit;
}
.zs-field input[type="reset"]:active {
  background: var(--zs-button-background-color, #ffffff);
  color: var(--zs-button-active-color, #2D535F);
}
.zs-field input[type="reset"]:active > .zs-icon {
  color: inherit;
}
.zs-field input[type="reset"].zs-danger {
  color: var(--zs-danger-color, #B21111);
  border: 1px solid var(--zs-danger-color, #B21111);
  background: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="reset"].zs-danger:visited {
  color: var(--zs-danger-color, #B21111);
}
.zs-field input[type="reset"].zs-danger:focus {
  color: var(--zs-danger-focus-color, #892208);
}
.zs-field input[type="reset"].zs-danger:hover {
  color: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="reset"].zs-danger:active {
  color: var(--zs-danger-active-color, #5C1A0B);
}
.zs-field input[type="reset"].zs-danger:focus {
  border: 1px solid var(--zs-danger-focus-color, #892208);
}
.zs-field input[type="reset"].zs-danger:hover {
  border: 1px solid var(--zs-danger-hover-color, #F27755);
}
.zs-field input[type="reset"].zs-danger:active {
  border: 1px solid var(--zs-danger-active-color, #5C1A0B);
}
.zs-field input[type="reset"].zs-danger:focus {
  background: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="reset"].zs-danger:hover {
  background: var(--zs-danger-hover-color, #F27755);
}
.zs-field input[type="reset"].zs-danger:active {
  background: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="reset"].zs-success {
  color: var(--zs-success-color, #00AA67);
  border: 1px solid var(--zs-success-color, #00AA67);
  background: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="reset"].zs-success:visited {
  color: var(--zs-success-color, #00AA67);
}
.zs-field input[type="reset"].zs-success:focus {
  color: var(--zs-success-focus-color, #0A6E5E);
}
.zs-field input[type="reset"].zs-success:hover {
  color: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="reset"].zs-success:active {
  color: var(--zs-success-active-color, #0C4846);
}
.zs-field input[type="reset"].zs-success:focus {
  border: 1px solid var(--zs-success-focus-color, #0A6E5E);
}
.zs-field input[type="reset"].zs-success:hover {
  border: 1px solid var(--zs-success-hover-color, #54D7AF);
}
.zs-field input[type="reset"].zs-success:active {
  border: 1px solid var(--zs-success-active-color, #0C4846);
}
.zs-field input[type="reset"].zs-success:focus {
  background: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="reset"].zs-success:hover {
  background: var(--zs-success-hover-color, #54D7AF);
}
.zs-field input[type="reset"].zs-success:active {
  background: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="reset"].zs-warning {
  color: var(--zs-warning-color, #E3A900);
  border: 1px solid var(--zs-warning-color, #E3A900);
  background: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="reset"].zs-warning:visited {
  color: var(--zs-warning-color, #E3A900);
}
.zs-field input[type="reset"].zs-warning:focus {
  color: var(--zs-warning-focus-color, #8A640C);
}
.zs-field input[type="reset"].zs-warning:hover {
  color: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="reset"].zs-warning:active {
  color: var(--zs-warning-active-color, #563F0E);
}
.zs-field input[type="reset"].zs-warning:focus {
  border: 1px solid var(--zs-warning-focus-color, #8A640C);
}
.zs-field input[type="reset"].zs-warning:hover {
  border: 1px solid var(--zs-warning-hover-color, #FDCA63);
}
.zs-field input[type="reset"].zs-warning:active {
  border: 1px solid var(--zs-warning-active-color, #563F0E);
}
.zs-field input[type="reset"].zs-warning:focus {
  background: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="reset"].zs-warning:hover {
  background: var(--zs-warning-hover-color, #FDCA63);
}
.zs-field input[type="reset"].zs-warning:active {
  background: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="reset"].zs-info {
  color: var(--zs-info-color, #2B44C7);
  border: 1px solid var(--zs-info-color, #2B44C7);
  background: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="reset"].zs-info:visited {
  color: var(--zs-info-color, #2B44C7);
}
.zs-field input[type="reset"].zs-info:focus {
  color: var(--zs-info-focus-color, #141187);
}
.zs-field input[type="reset"].zs-info:hover {
  color: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="reset"].zs-info:active {
  color: var(--zs-info-active-color, #160C60);
}
.zs-field input[type="reset"].zs-info:focus {
  border: 1px solid var(--zs-info-focus-color, #141187);
}
.zs-field input[type="reset"].zs-info:hover {
  border: 1px solid var(--zs-info-hover-color, #7E92E9);
}
.zs-field input[type="reset"].zs-info:active {
  border: 1px solid var(--zs-info-active-color, #160C60);
}
.zs-field input[type="reset"].zs-info:focus {
  background: var(--zs-button-background-color, #ffffff);
}
.zs-field input[type="reset"].zs-info:hover {
  background: var(--zs-info-hover-color, #7E92E9);
}
.zs-field input[type="reset"].zs-info:active {
  background: var(--zs-button-background-color, #ffffff);
}
/**
* Popup Menu styles.
*/
.zs-menu {
  display: block;
  background: var(--zs-menu-bg-color, #FAFAFA);
  border-radius: 0em;
  border: var(--zs-menu-border, 1px solid #DEDCDE);
  box-shadow: var(--zs-menu-shadow, 4px 4px 0px rgba(26, 22, 40, 0.15));
  box-sizing: border-box;
  min-width: auto;
  max-width: none;
  z-index: 2000;
}
.zs-menu > a,
.zs-menu > li,
.zs-menu > li > a,
.zs-menu > ul > li > a,
.zs-menu > nav > a {
  color: var(--zs-menu-option-color, #2F2C3C);
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0.5em 1.5em 0.5em 1.5em;
  line-height: 1.5;
  transition: color 0.1s ease-out, background-color 0.1s ease-out;
}
.zs-menu > a span,
.zs-menu > li span,
.zs-menu > li > a span,
.zs-menu > ul > li > a span,
.zs-menu > nav > a span {
  overflow: hidden;
  text-overflow: ellipsis;
}
.zs-menu > a.zs-active,
.zs-menu > li.zs-active,
.zs-menu > li > a.zs-active,
.zs-menu > ul > li > a.zs-active,
.zs-menu > nav > a.zs-active {
  background: var(--zs-menu-active-bg-color, #27A6A4);
}
.zs-menu > a:hover,
.zs-menu > li:hover,
.zs-menu > li > a:hover,
.zs-menu > ul > li > a:hover,
.zs-menu > nav > a:hover {
  background: var(--zs-menu-hover-color, rgba(39, 166, 164, 0.1));
  color: inherit;
}
.zs-menu > a:first-child,
.zs-menu > li:first-child,
.zs-menu > li > a:first-child,
.zs-menu > ul > li > a:first-child,
.zs-menu > nav > a:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.zs-menu > a:last-child,
.zs-menu > li:last-child,
.zs-menu > li > a:last-child,
.zs-menu > ul > li > a:last-child,
.zs-menu > nav > a:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}
.zs-menu > a:active,
.zs-menu > li:active,
.zs-menu > li > a:active,
.zs-menu > ul > li > a:active,
.zs-menu > nav > a:active {
  background: var(--zs-link-action-active-background, #2D8B93);
  color: inherit;
}
.zs-menu > a > *:not(.zs-icon),
.zs-menu > li > *:not(.zs-icon),
.zs-menu > li > a > *:not(.zs-icon),
.zs-menu > ul > li > a > *:not(.zs-icon),
.zs-menu > nav > a > *:not(.zs-icon) {
  vertical-align: middle;
}
@media (prefers-reduced-motion) {
  .zs-menu > a,
  .zs-menu > li,
  .zs-menu > li > a,
  .zs-menu > ul > li > a,
  .zs-menu > nav > a {
    transition-duration: 0s;
  }
}
.zs-menu > li > a {
  padding: 0;
  color: inherit;
}
.zs-menu > li > a:focus,
.zs-menu > li > a:hover,
.zs-menu > li > a:active,
.zs-menu > li > a.zs-active {
  color: inherit;
  background: var(--zs-transparent-background-color, transparent);
}
.zs-menu > li > a:focus {
  outline: 2px solid var(--zs-link-action-focus-outline-color, #27A6A4);
  outline-offset: -2px;
}
.zs-master-style .zs-drawer,
.zs-master-style zs-drawer,
.zs-drawer,
.zs-master-style [role="drawer"] {
  background: var(--zs-drawer-background-color, #ffffff);
  border: var(--zs-drawer-border, 1px solid #DEDCDE);
  box-shadow: none;
  display: inline-block;
  position: fixed;
  padding: 1em 1em 1em 1em;
  min-width: 20em;
  min-height: 10em;
  text-align: left;
  z-index: 5001;
}
.zs-master-style .zs-drawer[embeded],
.zs-master-style zs-drawer[embeded],
.zs-drawer[embeded],
.zs-master-style [role="drawer"][embeded] {
  z-index: auto;
}
.zs-master-style .zs-drawer > section,
.zs-master-style zs-drawer > section,
.zs-drawer > section,
.zs-master-style [role="drawer"] > section {
  position: absolute;
  top: 0px;
  padding: 1em 1em 1em 1em;
  bottom: 0;
  right: 0;
  left: 0;
  overflow-y: auto;
  z-index: 2;
}
.zs-master-style .zs-drawer > tag,
.zs-master-style zs-drawer > tag,
.zs-drawer > tag,
.zs-master-style [role="drawer"] > tag,
.zs-master-style .zs-drawer > toggle,
.zs-master-style zs-drawer > toggle,
.zs-drawer > toggle,
.zs-master-style [role="drawer"] > toggle,
.zs-master-style .zs-drawer [role="toggle"],
.zs-master-style zs-drawer [role="toggle"],
.zs-drawer [role="toggle"],
.zs-master-style [role="drawer"] [role="toggle"],
.zs-master-style .zs-drawer [toggle-element],
.zs-master-style zs-drawer [toggle-element],
.zs-drawer [toggle-element],
.zs-master-style [role="drawer"] [toggle-element] {
  -webkit-tap-highlight-color: transparent;
  z-index: 1;
  position: absolute;
  background: inherit;
  border-radius: 0.25em;
  border: var(--zs-drawer-toggle-border, 1px solid #DEDCDE);
  box-shadow: none;
  transform: rotate(0deg);
  cursor: pointer;
  text-align: center;
  height: 24px;
  border-bottom: 0px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.zs-master-style .zs-drawer > tag:hover,
.zs-master-style zs-drawer > tag:hover,
.zs-drawer > tag:hover,
.zs-master-style [role="drawer"] > tag:hover,
.zs-master-style .zs-drawer > toggle:hover,
.zs-master-style zs-drawer > toggle:hover,
.zs-drawer > toggle:hover,
.zs-master-style [role="drawer"] > toggle:hover,
.zs-master-style .zs-drawer [role="toggle"]:hover,
.zs-master-style zs-drawer [role="toggle"]:hover,
.zs-drawer [role="toggle"]:hover,
.zs-master-style [role="drawer"] [role="toggle"]:hover,
.zs-master-style .zs-drawer [toggle-element]:hover,
.zs-master-style zs-drawer [toggle-element]:hover,
.zs-drawer [toggle-element]:hover,
.zs-master-style [role="drawer"] [toggle-element]:hover {
  color: var(--zs-drawer-toggle-hover-color, #716E79);
}
.zs-master-style .zs-drawer > tag:active,
.zs-master-style zs-drawer > tag:active,
.zs-drawer > tag:active,
.zs-master-style [role="drawer"] > tag:active,
.zs-master-style .zs-drawer > toggle:active,
.zs-master-style zs-drawer > toggle:active,
.zs-drawer > toggle:active,
.zs-master-style [role="drawer"] > toggle:active,
.zs-master-style .zs-drawer [role="toggle"]:active,
.zs-master-style zs-drawer [role="toggle"]:active,
.zs-drawer [role="toggle"]:active,
.zs-master-style [role="drawer"] [role="toggle"]:active,
.zs-master-style .zs-drawer [toggle-element]:active,
.zs-master-style zs-drawer [toggle-element]:active,
.zs-drawer [toggle-element]:active,
.zs-master-style [role="drawer"] [toggle-element]:active {
  color: var(--zs-drawer-toggle-active-color, #454250);
}
.zs-master-style .zs-drawer > tag > span,
.zs-master-style zs-drawer > tag > span,
.zs-drawer > tag > span,
.zs-master-style [role="drawer"] > tag > span,
.zs-master-style .zs-drawer > toggle > span,
.zs-master-style zs-drawer > toggle > span,
.zs-drawer > toggle > span,
.zs-master-style [role="drawer"] > toggle > span,
.zs-master-style .zs-drawer [role="toggle"] > span,
.zs-master-style zs-drawer [role="toggle"] > span,
.zs-drawer [role="toggle"] > span,
.zs-master-style [role="drawer"] [role="toggle"] > span,
.zs-master-style .zs-drawer [toggle-element] > span,
.zs-master-style zs-drawer [toggle-element] > span,
.zs-drawer [toggle-element] > span,
.zs-master-style [role="drawer"] [toggle-element] > span,
.zs-master-style .zs-drawer > tag > a,
.zs-master-style zs-drawer > tag > a,
.zs-drawer > tag > a,
.zs-master-style [role="drawer"] > tag > a,
.zs-master-style .zs-drawer > toggle > a,
.zs-master-style zs-drawer > toggle > a,
.zs-drawer > toggle > a,
.zs-master-style [role="drawer"] > toggle > a,
.zs-master-style .zs-drawer [role="toggle"] > a,
.zs-master-style zs-drawer [role="toggle"] > a,
.zs-drawer [role="toggle"] > a,
.zs-master-style [role="drawer"] [role="toggle"] > a,
.zs-master-style .zs-drawer [toggle-element] > a,
.zs-master-style zs-drawer [toggle-element] > a,
.zs-drawer [toggle-element] > a,
.zs-master-style [role="drawer"] [toggle-element] > a {
  display: inline-block;
  width: 120px;
  white-space: nowrap;
  user-select: none;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0em 0em 0em 0em;
  background-color: transparent;
}
.zs-master-style .zs-drawer > tag > span.zs-icon,
.zs-master-style zs-drawer > tag > span.zs-icon,
.zs-drawer > tag > span.zs-icon,
.zs-master-style [role="drawer"] > tag > span.zs-icon,
.zs-master-style .zs-drawer > toggle > span.zs-icon,
.zs-master-style zs-drawer > toggle > span.zs-icon,
.zs-drawer > toggle > span.zs-icon,
.zs-master-style [role="drawer"] > toggle > span.zs-icon,
.zs-master-style .zs-drawer [role="toggle"] > span.zs-icon,
.zs-master-style zs-drawer [role="toggle"] > span.zs-icon,
.zs-drawer [role="toggle"] > span.zs-icon,
.zs-master-style [role="drawer"] [role="toggle"] > span.zs-icon,
.zs-master-style .zs-drawer [toggle-element] > span.zs-icon,
.zs-master-style zs-drawer [toggle-element] > span.zs-icon,
.zs-drawer [toggle-element] > span.zs-icon,
.zs-master-style [role="drawer"] [toggle-element] > span.zs-icon,
.zs-master-style .zs-drawer > tag > a.zs-icon,
.zs-master-style zs-drawer > tag > a.zs-icon,
.zs-drawer > tag > a.zs-icon,
.zs-master-style [role="drawer"] > tag > a.zs-icon,
.zs-master-style .zs-drawer > toggle > a.zs-icon,
.zs-master-style zs-drawer > toggle > a.zs-icon,
.zs-drawer > toggle > a.zs-icon,
.zs-master-style [role="drawer"] > toggle > a.zs-icon,
.zs-master-style .zs-drawer [role="toggle"] > a.zs-icon,
.zs-master-style zs-drawer [role="toggle"] > a.zs-icon,
.zs-drawer [role="toggle"] > a.zs-icon,
.zs-master-style [role="drawer"] [role="toggle"] > a.zs-icon,
.zs-master-style .zs-drawer [toggle-element] > a.zs-icon,
.zs-master-style zs-drawer [toggle-element] > a.zs-icon,
.zs-drawer [toggle-element] > a.zs-icon,
.zs-master-style [role="drawer"] [toggle-element] > a.zs-icon {
  width: 48px;
}
.zs-master-style .zs-drawer > tag > a,
.zs-master-style zs-drawer > tag > a,
.zs-drawer > tag > a,
.zs-master-style [role="drawer"] > tag > a,
.zs-master-style .zs-drawer > toggle > a,
.zs-master-style zs-drawer > toggle > a,
.zs-drawer > toggle > a,
.zs-master-style [role="drawer"] > toggle > a,
.zs-master-style .zs-drawer [role="toggle"] > a,
.zs-master-style zs-drawer [role="toggle"] > a,
.zs-drawer [role="toggle"] > a,
.zs-master-style [role="drawer"] [role="toggle"] > a,
.zs-master-style .zs-drawer [toggle-element] > a,
.zs-master-style zs-drawer [toggle-element] > a,
.zs-drawer [toggle-element] > a,
.zs-master-style [role="drawer"] [toggle-element] > a {
  color: inherit;
}
.zs-master-style .zs-drawer > tag::after,
.zs-master-style zs-drawer > tag::after,
.zs-drawer > tag::after,
.zs-master-style [role="drawer"] > tag::after,
.zs-master-style .zs-drawer > toggle::after,
.zs-master-style zs-drawer > toggle::after,
.zs-drawer > toggle::after,
.zs-master-style [role="drawer"] > toggle::after,
.zs-master-style .zs-drawer [role="toggle"]::after,
.zs-master-style zs-drawer [role="toggle"]::after,
.zs-drawer [role="toggle"]::after,
.zs-master-style [role="drawer"] [role="toggle"]::after,
.zs-master-style .zs-drawer [toggle-element]::after,
.zs-master-style zs-drawer [toggle-element]::after,
.zs-drawer [toggle-element]::after,
.zs-master-style [role="drawer"] [toggle-element]::after {
  content: '';
  position: absolute;
  display: block;
  left: -7px;
  bottom: -7px;
  right: -7px;
  background: inherit;
  z-index: 1;
  overflow: hidden;
  height: 7px;
}
.zs-master-style .zs-drawer[align="top"],
.zs-master-style zs-drawer[align="top"],
.zs-drawer[align="top"],
.zs-master-style [role="drawer"][align="top"] {
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-100%);
}
.zs-master-style .zs-drawer[align="top"] > tag,
.zs-master-style zs-drawer[align="top"] > tag,
.zs-drawer[align="top"] > tag,
.zs-master-style [role="drawer"][align="top"] > tag,
.zs-master-style .zs-drawer[align="top"] > toggle,
.zs-master-style zs-drawer[align="top"] > toggle,
.zs-drawer[align="top"] > toggle,
.zs-master-style [role="drawer"][align="top"] > toggle,
.zs-master-style .zs-drawer[align="top"] [role="toggle"],
.zs-master-style zs-drawer[align="top"] [role="toggle"],
.zs-drawer[align="top"] [role="toggle"],
.zs-master-style [role="drawer"][align="top"] [role="toggle"],
.zs-master-style .zs-drawer[align="top"] [toggle-element],
.zs-master-style zs-drawer[align="top"] [toggle-element],
.zs-drawer[align="top"] [toggle-element],
.zs-master-style [role="drawer"][align="top"] [toggle-element] {
  left: 50%;
  margin-left: -24px;
  bottom: 0;
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom: var(--zs-drawer-toggle-border, 1px solid #DEDCDE);
  border-bottom-right-radius: 0.25em;
  border-bottom-left-radius: 0.25em;
  margin-bottom: -24px;
}
.zs-master-style .zs-drawer[align="top"] > tag > .zs-icon,
.zs-master-style zs-drawer[align="top"] > tag > .zs-icon,
.zs-drawer[align="top"] > tag > .zs-icon,
.zs-master-style [role="drawer"][align="top"] > tag > .zs-icon,
.zs-master-style .zs-drawer[align="top"] > toggle > .zs-icon,
.zs-master-style zs-drawer[align="top"] > toggle > .zs-icon,
.zs-drawer[align="top"] > toggle > .zs-icon,
.zs-master-style [role="drawer"][align="top"] > toggle > .zs-icon,
.zs-master-style .zs-drawer[align="top"] [role="toggle"] > .zs-icon,
.zs-master-style zs-drawer[align="top"] [role="toggle"] > .zs-icon,
.zs-drawer[align="top"] [role="toggle"] > .zs-icon,
.zs-master-style [role="drawer"][align="top"] [role="toggle"] > .zs-icon,
.zs-master-style .zs-drawer[align="top"] [toggle-element] > .zs-icon,
.zs-master-style zs-drawer[align="top"] [toggle-element] > .zs-icon,
.zs-drawer[align="top"] [toggle-element] > .zs-icon,
.zs-master-style [role="drawer"][align="top"] [toggle-element] > .zs-icon {
  transform: rotate(180deg);
}
.zs-master-style .zs-drawer[align="top"] > tag::after,
.zs-master-style zs-drawer[align="top"] > tag::after,
.zs-drawer[align="top"] > tag::after,
.zs-master-style [role="drawer"][align="top"] > tag::after,
.zs-master-style .zs-drawer[align="top"] > toggle::after,
.zs-master-style zs-drawer[align="top"] > toggle::after,
.zs-drawer[align="top"] > toggle::after,
.zs-master-style [role="drawer"][align="top"] > toggle::after,
.zs-master-style .zs-drawer[align="top"] [role="toggle"]::after,
.zs-master-style zs-drawer[align="top"] [role="toggle"]::after,
.zs-drawer[align="top"] [role="toggle"]::after,
.zs-master-style [role="drawer"][align="top"] [role="toggle"]::after,
.zs-master-style .zs-drawer[align="top"] [toggle-element]::after,
.zs-master-style zs-drawer[align="top"] [toggle-element]::after,
.zs-drawer[align="top"] [toggle-element]::after,
.zs-master-style [role="drawer"][align="top"] [toggle-element]::after {
  top: -7px;
  bottom: auto;
}
.zs-master-style .zs-drawer[align="top"] > tag[align="right"],
.zs-master-style zs-drawer[align="top"] > tag[align="right"],
.zs-drawer[align="top"] > tag[align="right"],
.zs-master-style [role="drawer"][align="top"] > tag[align="right"],
.zs-master-style .zs-drawer[align="top"] > toggle[align="right"],
.zs-master-style zs-drawer[align="top"] > toggle[align="right"],
.zs-drawer[align="top"] > toggle[align="right"],
.zs-master-style [role="drawer"][align="top"] > toggle[align="right"],
.zs-master-style .zs-drawer[align="top"] [role="toggle"][align="right"],
.zs-master-style zs-drawer[align="top"] [role="toggle"][align="right"],
.zs-drawer[align="top"] [role="toggle"][align="right"],
.zs-master-style [role="drawer"][align="top"] [role="toggle"][align="right"],
.zs-master-style .zs-drawer[align="top"] [toggle-element][align="right"],
.zs-master-style zs-drawer[align="top"] [toggle-element][align="right"],
.zs-drawer[align="top"] [toggle-element][align="right"],
.zs-master-style [role="drawer"][align="top"] [toggle-element][align="right"] {
  left: auto;
  right: -1px;
  margin-left: 0;
}
.zs-master-style .zs-drawer[align="top"] > tag[align="right"]::after,
.zs-master-style zs-drawer[align="top"] > tag[align="right"]::after,
.zs-drawer[align="top"] > tag[align="right"]::after,
.zs-master-style [role="drawer"][align="top"] > tag[align="right"]::after,
.zs-master-style .zs-drawer[align="top"] > toggle[align="right"]::after,
.zs-master-style zs-drawer[align="top"] > toggle[align="right"]::after,
.zs-drawer[align="top"] > toggle[align="right"]::after,
.zs-master-style [role="drawer"][align="top"] > toggle[align="right"]::after,
.zs-master-style .zs-drawer[align="top"] [role="toggle"][align="right"]::after,
.zs-master-style zs-drawer[align="top"] [role="toggle"][align="right"]::after,
.zs-drawer[align="top"] [role="toggle"][align="right"]::after,
.zs-master-style [role="drawer"][align="top"] [role="toggle"][align="right"]::after,
.zs-master-style .zs-drawer[align="top"] [toggle-element][align="right"]::after,
.zs-master-style zs-drawer[align="top"] [toggle-element][align="right"]::after,
.zs-drawer[align="top"] [toggle-element][align="right"]::after,
.zs-master-style [role="drawer"][align="top"] [toggle-element][align="right"]::after {
  left: -14px;
  right: 0;
}
.zs-master-style .zs-drawer[align="top"] > tag[align="left"],
.zs-master-style zs-drawer[align="top"] > tag[align="left"],
.zs-drawer[align="top"] > tag[align="left"],
.zs-master-style [role="drawer"][align="top"] > tag[align="left"],
.zs-master-style .zs-drawer[align="top"] > toggle[align="left"],
.zs-master-style zs-drawer[align="top"] > toggle[align="left"],
.zs-drawer[align="top"] > toggle[align="left"],
.zs-master-style [role="drawer"][align="top"] > toggle[align="left"],
.zs-master-style .zs-drawer[align="top"] [role="toggle"][align="left"],
.zs-master-style zs-drawer[align="top"] [role="toggle"][align="left"],
.zs-drawer[align="top"] [role="toggle"][align="left"],
.zs-master-style [role="drawer"][align="top"] [role="toggle"][align="left"],
.zs-master-style .zs-drawer[align="top"] [toggle-element][align="left"],
.zs-master-style zs-drawer[align="top"] [toggle-element][align="left"],
.zs-drawer[align="top"] [toggle-element][align="left"],
.zs-master-style [role="drawer"][align="top"] [toggle-element][align="left"] {
  left: -1px;
  right: auto;
  margin-left: 0;
}
.zs-master-style .zs-drawer[align="top"] > tag[align="left"]::after,
.zs-master-style zs-drawer[align="top"] > tag[align="left"]::after,
.zs-drawer[align="top"] > tag[align="left"]::after,
.zs-master-style [role="drawer"][align="top"] > tag[align="left"]::after,
.zs-master-style .zs-drawer[align="top"] > toggle[align="left"]::after,
.zs-master-style zs-drawer[align="top"] > toggle[align="left"]::after,
.zs-drawer[align="top"] > toggle[align="left"]::after,
.zs-master-style [role="drawer"][align="top"] > toggle[align="left"]::after,
.zs-master-style .zs-drawer[align="top"] [role="toggle"][align="left"]::after,
.zs-master-style zs-drawer[align="top"] [role="toggle"][align="left"]::after,
.zs-drawer[align="top"] [role="toggle"][align="left"]::after,
.zs-master-style [role="drawer"][align="top"] [role="toggle"][align="left"]::after,
.zs-master-style .zs-drawer[align="top"] [toggle-element][align="left"]::after,
.zs-master-style zs-drawer[align="top"] [toggle-element][align="left"]::after,
.zs-drawer[align="top"] [toggle-element][align="left"]::after,
.zs-master-style [role="drawer"][align="top"] [toggle-element][align="left"]::after {
  left: 0;
  right: -14px;
}
.zs-master-style .zs-drawer[align="right"],
.zs-master-style zs-drawer[align="right"],
.zs-drawer[align="right"],
.zs-master-style [role="drawer"][align="right"] {
  transform: translate(100%, 0);
  top: 0;
  bottom: 0;
  right: 0;
}
.zs-master-style .zs-drawer[align="right"] > tag,
.zs-master-style zs-drawer[align="right"] > tag,
.zs-drawer[align="right"] > tag,
.zs-master-style [role="drawer"][align="right"] > tag,
.zs-master-style .zs-drawer[align="right"] > toggle,
.zs-master-style zs-drawer[align="right"] > toggle,
.zs-drawer[align="right"] > toggle,
.zs-master-style [role="drawer"][align="right"] > toggle,
.zs-master-style .zs-drawer[align="right"] [role="toggle"],
.zs-master-style zs-drawer[align="right"] [role="toggle"],
.zs-drawer[align="right"] [role="toggle"],
.zs-master-style [role="drawer"][align="right"] [role="toggle"],
.zs-master-style .zs-drawer[align="right"] [toggle-element],
.zs-master-style zs-drawer[align="right"] [toggle-element],
.zs-drawer[align="right"] [toggle-element],
.zs-master-style [role="drawer"][align="right"] [toggle-element] {
  transform-origin: left bottom;
  transform: rotate(270deg);
  top: 50%;
  bottom: auto;
  left: 0;
}
.zs-master-style .zs-drawer[align="right"] > tag[valign="top"],
.zs-master-style zs-drawer[align="right"] > tag[valign="top"],
.zs-drawer[align="right"] > tag[valign="top"],
.zs-master-style [role="drawer"][align="right"] > tag[valign="top"],
.zs-master-style .zs-drawer[align="right"] > toggle[valign="top"],
.zs-master-style zs-drawer[align="right"] > toggle[valign="top"],
.zs-drawer[align="right"] > toggle[valign="top"],
.zs-master-style [role="drawer"][align="right"] > toggle[valign="top"],
.zs-master-style .zs-drawer[align="right"] [role="toggle"][valign="top"],
.zs-master-style zs-drawer[align="right"] [role="toggle"][valign="top"],
.zs-drawer[align="right"] [role="toggle"][valign="top"],
.zs-master-style [role="drawer"][align="right"] [role="toggle"][valign="top"],
.zs-master-style .zs-drawer[align="right"] [toggle-element][valign="top"],
.zs-master-style zs-drawer[align="right"] [toggle-element][valign="top"],
.zs-drawer[align="right"] [toggle-element][valign="top"],
.zs-master-style [role="drawer"][align="right"] [toggle-element][valign="top"] {
  bottom: auto;
  top: 8px;
  margin-top: 60px;
  margin-left: 0;
}
.zs-master-style .zs-drawer[align="right"] > tag[valign="top"]::after,
.zs-master-style zs-drawer[align="right"] > tag[valign="top"]::after,
.zs-drawer[align="right"] > tag[valign="top"]::after,
.zs-master-style [role="drawer"][align="right"] > tag[valign="top"]::after,
.zs-master-style .zs-drawer[align="right"] > toggle[valign="top"]::after,
.zs-master-style zs-drawer[align="right"] > toggle[valign="top"]::after,
.zs-drawer[align="right"] > toggle[valign="top"]::after,
.zs-master-style [role="drawer"][align="right"] > toggle[valign="top"]::after,
.zs-master-style .zs-drawer[align="right"] [role="toggle"][valign="top"]::after,
.zs-master-style zs-drawer[align="right"] [role="toggle"][valign="top"]::after,
.zs-drawer[align="right"] [role="toggle"][valign="top"]::after,
.zs-master-style [role="drawer"][align="right"] [role="toggle"][valign="top"]::after,
.zs-master-style .zs-drawer[align="right"] [toggle-element][valign="top"]::after,
.zs-master-style zs-drawer[align="right"] [toggle-element][valign="top"]::after,
.zs-drawer[align="right"] [toggle-element][valign="top"]::after,
.zs-master-style [role="drawer"][align="right"] [toggle-element][valign="top"]::after {
  right: 0px;
}
.zs-master-style .zs-drawer[align="right"] > tag[valign="bottom"],
.zs-master-style zs-drawer[align="right"] > tag[valign="bottom"],
.zs-drawer[align="right"] > tag[valign="bottom"],
.zs-master-style [role="drawer"][align="right"] > tag[valign="bottom"],
.zs-master-style .zs-drawer[align="right"] > toggle[valign="bottom"],
.zs-master-style zs-drawer[align="right"] > toggle[valign="bottom"],
.zs-drawer[align="right"] > toggle[valign="bottom"],
.zs-master-style [role="drawer"][align="right"] > toggle[valign="bottom"],
.zs-master-style .zs-drawer[align="right"] [role="toggle"][valign="bottom"],
.zs-master-style zs-drawer[align="right"] [role="toggle"][valign="bottom"],
.zs-drawer[align="right"] [role="toggle"][valign="bottom"],
.zs-master-style [role="drawer"][align="right"] [role="toggle"][valign="bottom"],
.zs-master-style .zs-drawer[align="right"] [toggle-element][valign="bottom"],
.zs-master-style zs-drawer[align="right"] [toggle-element][valign="bottom"],
.zs-drawer[align="right"] [toggle-element][valign="bottom"],
.zs-master-style [role="drawer"][align="right"] [toggle-element][valign="bottom"] {
  bottom: -1px;
  top: auto;
  margin-top: 0;
  margin-bottom: 0px;
  margin-left: 0;
}
.zs-master-style .zs-drawer[align="right"] > tag[valign="bottom"]::after,
.zs-master-style zs-drawer[align="right"] > tag[valign="bottom"]::after,
.zs-drawer[align="right"] > tag[valign="bottom"]::after,
.zs-master-style [role="drawer"][align="right"] > tag[valign="bottom"]::after,
.zs-master-style .zs-drawer[align="right"] > toggle[valign="bottom"]::after,
.zs-master-style zs-drawer[align="right"] > toggle[valign="bottom"]::after,
.zs-drawer[align="right"] > toggle[valign="bottom"]::after,
.zs-master-style [role="drawer"][align="right"] > toggle[valign="bottom"]::after,
.zs-master-style .zs-drawer[align="right"] [role="toggle"][valign="bottom"]::after,
.zs-master-style zs-drawer[align="right"] [role="toggle"][valign="bottom"]::after,
.zs-drawer[align="right"] [role="toggle"][valign="bottom"]::after,
.zs-master-style [role="drawer"][align="right"] [role="toggle"][valign="bottom"]::after,
.zs-master-style .zs-drawer[align="right"] [toggle-element][valign="bottom"]::after,
.zs-master-style zs-drawer[align="right"] [toggle-element][valign="bottom"]::after,
.zs-drawer[align="right"] [toggle-element][valign="bottom"]::after,
.zs-master-style [role="drawer"][align="right"] [toggle-element][valign="bottom"]::after {
  left: 0px;
}
.zs-master-style .zs-drawer[align="bottom"],
.zs-master-style zs-drawer[align="bottom"],
.zs-drawer[align="bottom"],
.zs-master-style [role="drawer"][align="bottom"] {
  transform: translate(0, 100%);
  bottom: 0;
  right: 0;
  left: 0;
}
.zs-master-style .zs-drawer[align="bottom"] > tag,
.zs-master-style zs-drawer[align="bottom"] > tag,
.zs-drawer[align="bottom"] > tag,
.zs-master-style [role="drawer"][align="bottom"] > tag,
.zs-master-style .zs-drawer[align="bottom"] > toggle,
.zs-master-style zs-drawer[align="bottom"] > toggle,
.zs-drawer[align="bottom"] > toggle,
.zs-master-style [role="drawer"][align="bottom"] > toggle,
.zs-master-style .zs-drawer[align="bottom"] [role="toggle"],
.zs-master-style zs-drawer[align="bottom"] [role="toggle"],
.zs-drawer[align="bottom"] [role="toggle"],
.zs-master-style [role="drawer"][align="bottom"] [role="toggle"],
.zs-master-style .zs-drawer[align="bottom"] [toggle-element],
.zs-master-style zs-drawer[align="bottom"] [toggle-element],
.zs-drawer[align="bottom"] [toggle-element],
.zs-master-style [role="drawer"][align="bottom"] [toggle-element] {
  transform: rotate(0deg);
  top: 0;
  margin-top: -24px;
  left: 50%;
  margin-left: -24px;
  border-bottom: 0px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.zs-master-style .zs-drawer[align="bottom"] > tag[align="right"],
.zs-master-style zs-drawer[align="bottom"] > tag[align="right"],
.zs-drawer[align="bottom"] > tag[align="right"],
.zs-master-style [role="drawer"][align="bottom"] > tag[align="right"],
.zs-master-style .zs-drawer[align="bottom"] > toggle[align="right"],
.zs-master-style zs-drawer[align="bottom"] > toggle[align="right"],
.zs-drawer[align="bottom"] > toggle[align="right"],
.zs-master-style [role="drawer"][align="bottom"] > toggle[align="right"],
.zs-master-style .zs-drawer[align="bottom"] [role="toggle"][align="right"],
.zs-master-style zs-drawer[align="bottom"] [role="toggle"][align="right"],
.zs-drawer[align="bottom"] [role="toggle"][align="right"],
.zs-master-style [role="drawer"][align="bottom"] [role="toggle"][align="right"],
.zs-master-style .zs-drawer[align="bottom"] [toggle-element][align="right"],
.zs-master-style zs-drawer[align="bottom"] [toggle-element][align="right"],
.zs-drawer[align="bottom"] [toggle-element][align="right"],
.zs-master-style [role="drawer"][align="bottom"] [toggle-element][align="right"] {
  left: auto;
  right: -1px;
  margin-left: 0;
}
.zs-master-style .zs-drawer[align="bottom"] > tag[align="right"]::after,
.zs-master-style zs-drawer[align="bottom"] > tag[align="right"]::after,
.zs-drawer[align="bottom"] > tag[align="right"]::after,
.zs-master-style [role="drawer"][align="bottom"] > tag[align="right"]::after,
.zs-master-style .zs-drawer[align="bottom"] > toggle[align="right"]::after,
.zs-master-style zs-drawer[align="bottom"] > toggle[align="right"]::after,
.zs-drawer[align="bottom"] > toggle[align="right"]::after,
.zs-master-style [role="drawer"][align="bottom"] > toggle[align="right"]::after,
.zs-master-style .zs-drawer[align="bottom"] [role="toggle"][align="right"]::after,
.zs-master-style zs-drawer[align="bottom"] [role="toggle"][align="right"]::after,
.zs-drawer[align="bottom"] [role="toggle"][align="right"]::after,
.zs-master-style [role="drawer"][align="bottom"] [role="toggle"][align="right"]::after,
.zs-master-style .zs-drawer[align="bottom"] [toggle-element][align="right"]::after,
.zs-master-style zs-drawer[align="bottom"] [toggle-element][align="right"]::after,
.zs-drawer[align="bottom"] [toggle-element][align="right"]::after,
.zs-master-style [role="drawer"][align="bottom"] [toggle-element][align="right"]::after {
  left: -14px;
  right: 0;
}
.zs-master-style .zs-drawer[align="bottom"] > tag[align="left"],
.zs-master-style zs-drawer[align="bottom"] > tag[align="left"],
.zs-drawer[align="bottom"] > tag[align="left"],
.zs-master-style [role="drawer"][align="bottom"] > tag[align="left"],
.zs-master-style .zs-drawer[align="bottom"] > toggle[align="left"],
.zs-master-style zs-drawer[align="bottom"] > toggle[align="left"],
.zs-drawer[align="bottom"] > toggle[align="left"],
.zs-master-style [role="drawer"][align="bottom"] > toggle[align="left"],
.zs-master-style .zs-drawer[align="bottom"] [role="toggle"][align="left"],
.zs-master-style zs-drawer[align="bottom"] [role="toggle"][align="left"],
.zs-drawer[align="bottom"] [role="toggle"][align="left"],
.zs-master-style [role="drawer"][align="bottom"] [role="toggle"][align="left"],
.zs-master-style .zs-drawer[align="bottom"] [toggle-element][align="left"],
.zs-master-style zs-drawer[align="bottom"] [toggle-element][align="left"],
.zs-drawer[align="bottom"] [toggle-element][align="left"],
.zs-master-style [role="drawer"][align="bottom"] [toggle-element][align="left"] {
  left: -1px;
  right: auto;
  margin-left: 0;
}
.zs-master-style .zs-drawer[align="bottom"] > tag[align="left"]::after,
.zs-master-style zs-drawer[align="bottom"] > tag[align="left"]::after,
.zs-drawer[align="bottom"] > tag[align="left"]::after,
.zs-master-style [role="drawer"][align="bottom"] > tag[align="left"]::after,
.zs-master-style .zs-drawer[align="bottom"] > toggle[align="left"]::after,
.zs-master-style zs-drawer[align="bottom"] > toggle[align="left"]::after,
.zs-drawer[align="bottom"] > toggle[align="left"]::after,
.zs-master-style [role="drawer"][align="bottom"] > toggle[align="left"]::after,
.zs-master-style .zs-drawer[align="bottom"] [role="toggle"][align="left"]::after,
.zs-master-style zs-drawer[align="bottom"] [role="toggle"][align="left"]::after,
.zs-drawer[align="bottom"] [role="toggle"][align="left"]::after,
.zs-master-style [role="drawer"][align="bottom"] [role="toggle"][align="left"]::after,
.zs-master-style .zs-drawer[align="bottom"] [toggle-element][align="left"]::after,
.zs-master-style zs-drawer[align="bottom"] [toggle-element][align="left"]::after,
.zs-drawer[align="bottom"] [toggle-element][align="left"]::after,
.zs-master-style [role="drawer"][align="bottom"] [toggle-element][align="left"]::after {
  left: 0;
  right: -14px;
}
.zs-master-style .zs-drawer[align="left"],
.zs-master-style zs-drawer[align="left"],
.zs-drawer[align="left"],
.zs-master-style [role="drawer"][align="left"] {
  transform: translate(-100%, 0);
  top: 0;
  bottom: 0;
  left: 0;
}
.zs-master-style .zs-drawer[align="left"] > tag,
.zs-master-style zs-drawer[align="left"] > tag,
.zs-drawer[align="left"] > tag,
.zs-master-style [role="drawer"][align="left"] > tag,
.zs-master-style .zs-drawer[align="left"] > toggle,
.zs-master-style zs-drawer[align="left"] > toggle,
.zs-drawer[align="left"] > toggle,
.zs-master-style [role="drawer"][align="left"] > toggle,
.zs-master-style .zs-drawer[align="left"] [role="toggle"],
.zs-master-style zs-drawer[align="left"] [role="toggle"],
.zs-drawer[align="left"] [role="toggle"],
.zs-master-style [role="drawer"][align="left"] [role="toggle"],
.zs-master-style .zs-drawer[align="left"] [toggle-element],
.zs-master-style zs-drawer[align="left"] [toggle-element],
.zs-drawer[align="left"] [toggle-element],
.zs-master-style [role="drawer"][align="left"] [toggle-element] {
  transform-origin: right bottom;
  transform: rotate(90deg);
  top: 50%;
  bottom: auto;
  right: 0;
  border-bottom: 0px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.zs-master-style .zs-drawer[align="left"] > tag[valign="top"],
.zs-master-style zs-drawer[align="left"] > tag[valign="top"],
.zs-drawer[align="left"] > tag[valign="top"],
.zs-master-style [role="drawer"][align="left"] > tag[valign="top"],
.zs-master-style .zs-drawer[align="left"] > toggle[valign="top"],
.zs-master-style zs-drawer[align="left"] > toggle[valign="top"],
.zs-drawer[align="left"] > toggle[valign="top"],
.zs-master-style [role="drawer"][align="left"] > toggle[valign="top"],
.zs-master-style .zs-drawer[align="left"] [role="toggle"][valign="top"],
.zs-master-style zs-drawer[align="left"] [role="toggle"][valign="top"],
.zs-drawer[align="left"] [role="toggle"][valign="top"],
.zs-master-style [role="drawer"][align="left"] [role="toggle"][valign="top"],
.zs-master-style .zs-drawer[align="left"] [toggle-element][valign="top"],
.zs-master-style zs-drawer[align="left"] [toggle-element][valign="top"],
.zs-drawer[align="left"] [toggle-element][valign="top"],
.zs-master-style [role="drawer"][align="left"] [toggle-element][valign="top"] {
  bottom: auto;
  top: 1px;
  margin-top: 96px;
  margin-left: 0;
}
.zs-master-style .zs-drawer[align="left"] > tag[valign="top"]::after,
.zs-master-style zs-drawer[align="left"] > tag[valign="top"]::after,
.zs-drawer[align="left"] > tag[valign="top"]::after,
.zs-master-style [role="drawer"][align="left"] > tag[valign="top"]::after,
.zs-master-style .zs-drawer[align="left"] > toggle[valign="top"]::after,
.zs-master-style zs-drawer[align="left"] > toggle[valign="top"]::after,
.zs-drawer[align="left"] > toggle[valign="top"]::after,
.zs-master-style [role="drawer"][align="left"] > toggle[valign="top"]::after,
.zs-master-style .zs-drawer[align="left"] [role="toggle"][valign="top"]::after,
.zs-master-style zs-drawer[align="left"] [role="toggle"][valign="top"]::after,
.zs-drawer[align="left"] [role="toggle"][valign="top"]::after,
.zs-master-style [role="drawer"][align="left"] [role="toggle"][valign="top"]::after,
.zs-master-style .zs-drawer[align="left"] [toggle-element][valign="top"]::after,
.zs-master-style zs-drawer[align="left"] [toggle-element][valign="top"]::after,
.zs-drawer[align="left"] [toggle-element][valign="top"]::after,
.zs-master-style [role="drawer"][align="left"] [toggle-element][valign="top"]::after {
  left: 0px;
}
.zs-master-style .zs-drawer[align="left"] > tag[valign="bottom"],
.zs-master-style zs-drawer[align="left"] > tag[valign="bottom"],
.zs-drawer[align="left"] > tag[valign="bottom"],
.zs-master-style [role="drawer"][align="left"] > tag[valign="bottom"],
.zs-master-style .zs-drawer[align="left"] > toggle[valign="bottom"],
.zs-master-style zs-drawer[align="left"] > toggle[valign="bottom"],
.zs-drawer[align="left"] > toggle[valign="bottom"],
.zs-master-style [role="drawer"][align="left"] > toggle[valign="bottom"],
.zs-master-style .zs-drawer[align="left"] [role="toggle"][valign="bottom"],
.zs-master-style zs-drawer[align="left"] [role="toggle"][valign="bottom"],
.zs-drawer[align="left"] [role="toggle"][valign="bottom"],
.zs-master-style [role="drawer"][align="left"] [role="toggle"][valign="bottom"],
.zs-master-style .zs-drawer[align="left"] [toggle-element][valign="bottom"],
.zs-master-style zs-drawer[align="left"] [toggle-element][valign="bottom"],
.zs-drawer[align="left"] [toggle-element][valign="bottom"],
.zs-master-style [role="drawer"][align="left"] [toggle-element][valign="bottom"] {
  bottom: -1px;
  top: auto;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
}
.zs-master-style .zs-drawer[align="left"] > tag[valign="bottom"]::after,
.zs-master-style zs-drawer[align="left"] > tag[valign="bottom"]::after,
.zs-drawer[align="left"] > tag[valign="bottom"]::after,
.zs-master-style [role="drawer"][align="left"] > tag[valign="bottom"]::after,
.zs-master-style .zs-drawer[align="left"] > toggle[valign="bottom"]::after,
.zs-master-style zs-drawer[align="left"] > toggle[valign="bottom"]::after,
.zs-drawer[align="left"] > toggle[valign="bottom"]::after,
.zs-master-style [role="drawer"][align="left"] > toggle[valign="bottom"]::after,
.zs-master-style .zs-drawer[align="left"] [role="toggle"][valign="bottom"]::after,
.zs-master-style zs-drawer[align="left"] [role="toggle"][valign="bottom"]::after,
.zs-drawer[align="left"] [role="toggle"][valign="bottom"]::after,
.zs-master-style [role="drawer"][align="left"] [role="toggle"][valign="bottom"]::after,
.zs-master-style .zs-drawer[align="left"] [toggle-element][valign="bottom"]::after,
.zs-master-style zs-drawer[align="left"] [toggle-element][valign="bottom"]::after,
.zs-drawer[align="left"] [toggle-element][valign="bottom"]::after,
.zs-master-style [role="drawer"][align="left"] [toggle-element][valign="bottom"]::after {
  margin-right: 7px;
}
.zs-master-style .zs-drawer.zs-notag > tag,
.zs-master-style zs-drawer.zs-notag > tag,
.zs-drawer.zs-notag > tag,
.zs-master-style [role="drawer"].zs-notag > tag,
.zs-master-style .zs-drawer.zs-notag > toggle,
.zs-master-style zs-drawer.zs-notag > toggle,
.zs-drawer.zs-notag > toggle,
.zs-master-style [role="drawer"].zs-notag > toggle,
.zs-master-style .zs-drawer.zs-notag [role="toggle"],
.zs-master-style zs-drawer.zs-notag [role="toggle"],
.zs-drawer.zs-notag [role="toggle"],
.zs-master-style [role="drawer"].zs-notag [role="toggle"],
.zs-master-style .zs-drawer.zs-notag [toggle-element],
.zs-master-style zs-drawer.zs-notag [toggle-element],
.zs-drawer.zs-notag [toggle-element],
.zs-master-style [role="drawer"].zs-notag [toggle-element] {
  display: none;
}
.zs-accordion > [expandable-header] {
  cursor: pointer;
}
.zs-accordion > [expandable-body] {
  overflow: hidden;
}
.zs-accordion[animate] [expandable-body] {
  transition: max-height 0.25s ease-out;
}
@media (prefers-reduced-motion) {
  .zs-accordion[animate] [expandable-body] {
    transition-duration: 0s;
  }
}
@media (prefers-reduced-motion) {
  .zs-accordion[animate] [expandable-body] {
    animation-duration: 0s;
    transition-duration: 0s;
  }
}
.zs-accordion[animate] > [expandable-header] [expandable-icon] {
  transition: transform 0.25s;
}
@media (prefers-reduced-motion) {
  .zs-accordion[animate] > [expandable-header] [expandable-icon] {
    transition-duration: 0s;
  }
}
@media (prefers-reduced-motion) {
  .zs-accordion[animate] > [expandable-header] [expandable-icon] {
    animation-duration: 0s;
    transition-duration: 0s;
  }
}
.zs-accordion[expanded] [expandable-header-text] {
  color: var(--zs-headline-text-color, #1A1628);
}
.zs-accordion[expanded] > [expandable-header] [expandable-icon] {
  transform: rotate(-180deg);
}
.zpf-smart-assist {
  display: block;
}
.zpf-smart-assist [smart-assist-container] {
  overflow: hidden;
}
.zpf-smart-assist [smart-assist-main-section] {
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  position: relative;
}
.zpf-smart-assist [smart-assist-main-section] [smart-assist-chat-section] {
  overflow: hidden;
}
.zpf-smart-assist [smart-assist-main-section] [smart-assist-loading-window] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--zs-smart-assist-loading-window-bg-color, #ffffff);
  z-index: 99;
}
.zpf-smart-assist [smart-assist-window] {
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
  order: 2;
}
.zpf-smart-assist [smart-assist-header] {
  background-image: url(../assets/smartAssistBanner.svg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 45%;
  padding: 1.5em 1.5em 0.5em 1.5em;
}
.zpf-smart-assist [smart-assist-header] ~ [smart-assist-main-section],
.zpf-smart-assist [smart-assist-header] ~ [smart-assist-error-window] {
  height: 55%;
}
.zpf-smart-assist [smart-assist-header] [smart-assist-title-container] {
  display: none;
}
.zpf-smart-assist [smart-assist-header] [smart-assist-banner] {
  text-align: center;
  flex-grow: 1;
  align-content: end;
}
.zpf-smart-assist [smart-assist-header]:not([smart-assist-history-header]) [smart-assist-back-link-container] {
  display: none;
}
.zpf-smart-assist [smart-assist-header] [smart-assist-action-header] {
  flex-direction: row-reverse;
}
.zpf-smart-assist [smart-assist-header][chat-started],
.zpf-smart-assist [smart-assist-header][smart-assist-history-header] {
  height: auto;
  background: var(--zs-surface-color-2, #F4F3F3);
  padding: 1.5em 1.5em 1.5em 1.5em;
}
.zpf-smart-assist [smart-assist-header][chat-started] ~ [smart-assist-main-section],
.zpf-smart-assist [smart-assist-header][smart-assist-history-header] ~ [smart-assist-main-section],
.zpf-smart-assist [smart-assist-header][chat-started] ~ [smart-assist-error-window],
.zpf-smart-assist [smart-assist-header][smart-assist-history-header] ~ [smart-assist-error-window] {
  height: 100%;
}
.zpf-smart-assist [smart-assist-header][chat-started] [smart-assist-title-container],
.zpf-smart-assist [smart-assist-header][smart-assist-history-header] [smart-assist-title-container] {
  color: var(--zs-text-color, #2F2C3C);
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
}
.zpf-smart-assist [smart-assist-header][chat-started] [smart-assist-banner],
.zpf-smart-assist [smart-assist-header][smart-assist-history-header] [smart-assist-banner] {
  display: none;
}
.zpf-smart-assist [smart-assist-header][chat-started] [smart-assist-action-header],
.zpf-smart-assist [smart-assist-header][smart-assist-history-header] [smart-assist-action-header] {
  flex-direction: row;
}
.zpf-smart-assist [smart-assist-header][smart-assist-history-header] [smart-assist-title-container] {
  overflow: hidden;
}
.zpf-smart-assist [smart-assist-header][smart-assist-history-header] [smart-assist-title-container] [smart-assist-title] {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0em 1em 0em 1em;
}
.zpf-smart-assist [smart-assist-header] [smart-assist-more-menu] {
  width: 12.25em;
  top: 2em;
}
.zpf-smart-assist [smart-assist-footer] {
  order: 3;
  padding: 0.25em 1.5em 0.25em 1.25em;
}
.zpf-smart-assist [smart-assist-footer] .zs-field[smart-assist-input] {
  margin: 0em 0em 0em 0em;
}
.zpf-smart-assist [smart-assist-footer] .zs-field[smart-assist-input] [label] {
  padding: 0;
}
.zpf-smart-assist [smart-assist-footer] .zs-field[smart-assist-input] label {
  display: block;
}
.zpf-smart-assist [smart-assist-footer] .zs-field[smart-assist-input] [input][type="text"],
.zpf-smart-assist [smart-assist-footer] .zs-field[smart-assist-input] [input][type="text"]:hover,
.zpf-smart-assist [smart-assist-footer] .zs-field[smart-assist-input] [input][type="text"]:focus,
.zpf-smart-assist [smart-assist-footer] .zs-field[smart-assist-input] [input][type="text"]:active {
  border: 0;
  background-color: transparent;
  resize: none;
  width: 100%;
}
.zpf-smart-assist [smart-assist-footer] [stop-button] > .zpf-stop-button-icon {
  position: absolute;
  height: 0.875em;
  width: 0.875em;
  transform: translate(-50%, -50%);
}
.zpf-smart-assist [smart-assist-input] {
  margin: 0em 0em 0em 0em;
}
.zpf-smart-assist [smart-assist-input] [input],
.zpf-smart-assist [smart-assist-input] [input]:hover,
.zpf-smart-assist [smart-assist-input] [input]:focus,
.zpf-smart-assist [smart-assist-input] [input]:active {
  border: 0;
  background-color: transparent;
}
.zpf-smart-assist [smart-assist-error-window] {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.zpf-smart-assist [smart-assist-notification] {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.zpf-smart-assist .zpf-overflowY-auto {
  overflow-y: auto;
}
.zpf-smart-assist .zpf-overflowX-auto {
  overflow-x: auto;
}
.zpf-smart-assist .zpf-chat-response-section {
  overflow: auto;
}
.zpf-smart-assist .zpf-chat-response-section:not(:first-child).zpf-smart-assist .zpf-chat-response-section:not(:last-child) {
  margin: 1em 0em 1em 0em;
}
.zpf-smart-assist .zpf-chat-response-section:empty {
  margin: 0em 0em 0em 0em;
}
.zpf-smart-assist .zpf-chat-response-section > p {
  margin: 0em 0em 0em 0em;
}
.zpf-smart-assist .zpf-response-action-section {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.zpf-smart-assist .zpf-response-action-section[chart-actions] {
  padding: 0em 1em 0em 1em;
}
.zpf-smart-assist .zpf-response-action-section[table-actions] {
  padding: 1em 0em 1em 0em;
}
.zpf-smart-assist .zpf-chat-response {
  word-wrap: break-word;
  max-width: 80%;
  overflow-y: auto;
  min-height: 100%;
}
.zpf-smart-assist .zpf-chat-bubble {
  border-radius: 0.35em;
}
.zpf-smart-assist .zpf-chat-bubble[smart-assist-request-bubble] {
  word-wrap: break-word;
  max-width: 80%;
  overflow-y: auto;
  min-height: 100%;
  border-bottom-right-radius: 0;
  background: var(--zs-smart-assist-request-bg-color, #F3FCFE);
  border: 0.5px solid var(--zs-smart-assist-request-border-color, #62D2D1);
}
.zpf-smart-assist .zpf-chat-bubble[smart-assist-response-bubble] {
  border-top-left-radius: 0;
}
.zpf-smart-assist .zpf-chat-bubble[name="dots"] {
  width: 100%;
  border: 0;
}
.zpf-smart-assist .zpf-header-logo-container {
  height: 5em;
  width: 5em;
}
.zpf-smart-assist .zpf-chat-response-badge {
  position: relative;
  border-radius: 50%;
  padding: 1.25em 1.25em 1.25em 1.25em;
}
.zpf-smart-assist .zpf-chat-response-badge .zpf-chat-response-badge-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.zpf-smart-assist .zpf-chat-response-badge .zpf-chat-response-badge-image.zpf-chat-logo {
  height: 2em;
  width: 2em;
}
.zpf-smart-assist .zpf-smart-assist-position-left {
  position: absolute;
  right: 0;
}
.zpf-smart-assist .zpf-smart-assist-logo {
  background: url(../assets/smartAssistLogo.svg);
  background-size: cover;
}
.zpf-smart-assist ul,
.zpf-smart-assist ol {
  margin: 0em 0em 0em 2em;
}
.zpf-smart-assist ul > li,
.zpf-smart-assist ol > li {
  margin: 0em 0em 0.25em 0em;
  padding: 0em 0em 0em 0.5em;
  line-height: 1.44;
  letter-spacing: -0.013em;
}
.zpf-smart-assist ul > li:first-child,
.zpf-smart-assist ol > li:first-child {
  margin: 0.5em 0em 0.25em 0em;
}
.zpf-smart-assist ul ul,
.zpf-smart-assist ol ul,
.zpf-smart-assist ul ol,
.zpf-smart-assist ol ol {
  margin: 0em 0em 0em 2.5em;
}
.zpf-smart-assist .zpf-smart-assist-history-link {
  display: block;
}
.zpf-smart-assist .zpf-smart-assist-history-link [smart-assist-history-name] {
  display: flex;
  overflow: hidden;
  align-items: center;
}
.zpf-smart-assist .zpf-smart-assist-history-link [smart-assist-history-name] [history-link] {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.zpf-dot-loader {
  display: flex;
}
.zpf-dot-loader > [dot] {
  width: 8px;
  height: 8px;
  margin: 0em 0.25em 0em 0.25em;
  background-color: var(--zs-dot-loader-static-bg-color, #DEDCDE);
  border-radius: 50%;
  animation: dotloaderanimation 1s infinite;
}
.zpf-dot-loader > [dot]:nth-child(1) {
  animation-delay: 0s;
}
.zpf-dot-loader > [dot]:nth-child(2) {
  animation-delay: 0.3s;
}
.zpf-dot-loader > [dot]:nth-child(3) {
  animation-delay: 0.7s;
}
@keyframes dotloaderanimation {
  0%,
  33%,
  100% {
    background-color: var(--zs-dot-loader-static-bg-color, #DEDCDE);
  }
  50% {
    background-color: var(--zs-dot-loader-moving-bg-color, #27A6A4);
  }
}
.option-button {
  list-style: none;
  background: #e1f9f8;
  color: #27a6a4;
  border: 0;
  border-radius: 20px;
  height: auto;
  line-height: 1;
}
.option-button:hover,
.option-button.active,
.option-button[active] {
  background-color: #27a6a4;
  cursor: pointer;
  color: white;
  border: #27a6a4;
}
.button-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.button-list:empty {
  display: none;
}
