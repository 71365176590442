/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');
@import '~ng-zorro-antd/ng-zorro-antd.min.css';
@import "../node_modules/zpf-smart-assist/css/main.css";
@import "../node_modules/zpf-smart-assist/config/smartAssistConfig.css";


// Override for Tour/Onboard
$tour-next-button-color: #27a6a4;
$tour-next-button-hover: #27a6a4;
$tour-back-button-color: #27a6a4;
$tour-skip-link-color: #27a6a4;
@import '../node_modules/ngx-guided-tour/scss/guided-tour-base-theme.scss';

.app-modal-table,
.activity-log-table {
  width: 100%;
}
/* Global */
html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  background: #ffffff;
}

body::-webkit-scrollbar {
  display: none;
}

[nz-icon].app-style-mid {
  color: #27a6a4;
  margin: 5px;
  font-size: 20px;
  cursor: pointer;
}
[nz-button].app-primary-button {
  background: #27a6a4;
  padding-top: 1px;
  font-weight: 700;
  font-size: 13px;
  outline: 0;
  border: 0;
}
[nz-button].app-primary-button-disabled {
  opacity: 0.5;
  color: #ffffff;
  background: #27a6a4;
}
.ant-btn-primary[disabled]:hover {
  background: #27a6a4;
  color: #ffffff;
}
[nz-button] {
  //background: #27a6a4;
  margin: 5px;
}

.tab-header {
  font-weight: bold;
  font-size: 20px;
  color: #1a1628;
  display: inline-block;
}
.tab-header-wrapper {
  display: inline-block;
  width: 100%;
  position: relative;
}
.app-modal-table {
  .ant-table-thead > tr > th {
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: -0.2px;
    color: #2f2c3c;
  }

  .ant-table-tbody > tr > td {
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: -0.2px;
    color: #2f2c3c;
  }
}
nz-table-sorters .ant-table-column-sorter-down.active,
.ant-table-column-sorter-up.active {
  color: #27a6a4;
}
nz-pagination .ant-pagination-item-active {
  border-color: #27a6a4;
}
nz-pagination .ant-pagination-item-active a {
  color: #27a6a4;
}
nz-table .ant-table-row-expand-icon:hover {
  color: #27a6a4;
}
*::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

nz-content {
  background-color: white;
}

nz-modal-confirm-container {
  .ant-modal-confirm-btns {
    display: flex;
  }

  [nz-button] {
    width: 84px;
    height: 48px;
  }

  .ant-btn-primary {
    background: #27a6a4;
    border-color: #27a6a4;
    color: #ffffff;
    width: 114px;
    height: 48px;
    white-space: normal;
  }
  .ant-btn-primary:hover {
    background: #27a6a4;
    color: #ffffff;
  }
}

.delete-icon {
  color: #716E79;
}

.power-bi-report-container {
  height: 99%;
  width: 100%;

  iframe {
    border: none;
  }
}

::ng-deep .ant-btn-primary {
  color: #fff;
  border-color: #27a6a4;
  background: #27a6a4;
}

::ng-deep .ant-btn-primary:hover {
  background: #27a6a4;
  color: #ffffff;
}

.ant-btn:active, .ant-btn:focus, .ant-btn:hover {
  color: #27a6a4;
  border-color: #27a6a4;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #27a6a4;
  border-color: #27a6a4;
}

.ant-checkbox-input:focus+.ant-checkbox-inner, .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner {
  border-color: #27a6a4;
}

.ant-checkbox-checked::after {
  border: 1px solid #27a6a4;
}